<template>
  <div>
    <!-- <b-button v-if="!isFormFicheTier && can('Create client with project')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-1 @click="isActive = true" variant="primary" size="md" class="ml-1 mt-1">
      <feather-icon icon="PlusIcon" />
      <span class="align-middle"> Créer un projet</span>
    </b-button> -->
      <b-button
        v-if="can('Import project') && interfaceChosen == 'Listing'"
        v-b-toggle.sidebar-2
        @click="
          clearDataSideBar();
          $emit('clear-data-projet-update');
          isActive=true; 
          getAuthCourtier();
        "
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        class="ml-1 mt-1"
      >
      <feather-icon icon="PlusIcon" />
      <span class="align-middle text-uppercase">Enregistrer un devis</span>
    </b-button>
    <template v-if="isFormFicheTier && !isFicheEntreprise">
      <b-button  :disabled="!prospectDataFromFicheTier" @click="affecterProspect(prospectDataFromFicheTier)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="ml-1 mt-1">
        <feather-icon v-if="prospectDataFromFicheTier" icon="PlusIcon" />
        <span class="align-middle text-uppercase"> Réaliser un devis (Marketplace) </span>
      </b-button>
    </template>
    <b-sidebar v-if="!isFormFicheTier" id="sidebar-1" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau Tiers</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="tierRapideComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- <h1 class="text-uppercase">informations</h1> -->
            <b-form-group label="Civilité" label-for="civilite">
              <validation-provider #default="{ errors }" name="civilité" rules="required">
                <b-form-select v-model="prospect_create.civilite" :options="civilite" id="civilite" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.civilite.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->
            <b-form-group label="Nom" label-for="nom">
              <validation-provider #default="{ errors }" name="nom" rules="required">
                <b-form-input class="text-uppercase" id="nom" trim placeholder="Nom" v-model="prospect_create.nom" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.nom.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->
            <b-form-group label="Prénom" label-for="prenom">
              <validation-provider #default="{ errors }" name="prénom" rules="required">
                <b-form-input class="text-capitalize" id="prenom" trim placeholder="Prénom" v-model="prospect_create.prenom" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.prenom.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->

            <b-form-group label="Date de naissance" label-for="date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                <flat-pickr class="form-control" v-model="prospect_create.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" id="date-naissance" :config="configDateNaissance" placeholder="Date de naissance" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.date_naissance.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->

            <b-form-group label="Téléphone" label-for="telephone">
              <validation-provider #default="{ errors }" name="téléphone" rules="required">
                <cleave id="telephone" v-model="prospect_create.tel" class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''" :raw="false" :options="options.delimiter" placeholder="Téléphone" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.tel.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->

            <b-form-group label="E-mail" label-for="email">
              <validation-provider #default="{ errors }" name="e-mail" rules="required">
                <b-form-input id="email" trim placeholder="E-mail" type="email" :state="errors.length > 0 ? false : null" v-model="prospect_create.email" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-invalid-feedback :state="!$v.prospect_create.email.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback> -->
            <!-- <b-form-invalid-feedback :state="!isInvalidePhoneAndMail"> Le tiers existe séjà </b-form-invalid-feedback> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="submitForm_create_prospect"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-sidebar id="sidebar-2" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarProjetExterne" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">{{ projetForUpdate.idDevis && projetForUpdate.idDevis ? 'Modifier le devis ' + (projetForUpdate.num_devis ? '[ ' + projetForUpdate.num_devis + ' ]' : '') : 'Enregistrer un devis' }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="projetExterneComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- <h1 class="text-uppercase">informations</h1> -->
            <b-overlay :show="showLoading" no-wrap />
            <h4>INFORMATIONS</h4>
            <b-form-group label="Courtier" label-for="courtier">
              <b-form-input id="courtier" v-model="currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial" placeholder="Courtier" disabled />
            </b-form-group>

            <validation-provider #default="{ errors }" name="client" :rules="isFormFicheTier ? '' : 'required'">
              <b-form-group v-if="!isFormFicheTier" :state="errors.length > 0 ? false : null" label="Client *" label-for="client">
                <v-select
                  v-model="projet.client" :close-on-select="true" 
                  placeholder="Client"
                  :getOptionKey="client => client.id + client.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="items"
                  :filterable="false"
                  @open="onOpen"
                  @close="onClose"
                  autocomplete
                  @search="autocompleteModeles"
                  label="nameClient"
                  @scroll.native="onScroll" 
                  ref="vSelect">
                  <template #option="{ prenom, nom, type, denomination_commercial, categorie, nameClient, id }">
                    <b-avatar :src="null" size="sm" />
                    <span class="ml-50"> {{ nameClient }} </span>
                    <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                      {{ capitalizeFirstLetter(categorie) }}
                    </b-badge>
                  </template>

                  <template #selected-option="{ prenom, nom, type, denomination_commercial, nameClient, id }">
                    <b-avatar :src="null" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nameClient }} </span>
                  </template>

                  <template #list-footer>
                    <li v-if="loading" class="loader">
                      <span>Chargement des clients...</span>
                    </li>
                    <li v-if="!loading && !hasMore">
                      <span>Toutes les clients sont chargées</span>
                    </li>
                  </template>
                  <div slot="no-options">Aucun client disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!--            :reduce="codeNafReduce => codeNafReduce.id"-->
            <!--
            <b-form-group v-if="!isFormFicheTier" label="Client *" label-for="client">
              <validation-provider #default="{ errors }" name="client" :rules="!isFormFicheTier ? '' : 'required'">
                <b-form-select v-model="projet.client" id="client" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">&#45;&#45; Choisissez &#45;&#45;</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(row, i) in prospectData" :value="row" :key="i">{{ row.type === 'PERSONNE_PHYSIQUE' ? row.prenom + ' ' + row.nom : row.denomination_commercial }}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>-->

            <h4>LE DEVIS</h4>
            <b-form-group label="Risque *" label-for="risque">
              <validation-provider #default="{ errors }" name="risque" rules="required">
                <b-form-select v-model="projet.risque" :options="risquesOptions" id="risque" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Assureur *" label-for="assureur">
              <validation-provider #default="{ errors }" name="assureur" rules="required">
                <b-form-select v-model="projet.assureur" :options="assureursOptions" id="assureur" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Statut *" label-for="statut">
              <validation-provider #default="{ errors }" name="statut" rules="required">
                <b-form-select v-model="projet.statut" :options="statutOptions" id="statut" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="N° de Devis *" label-for="num_devis">
              <validation-provider #default="{ errors }" name="N° de Devis" rules="required">
                <b-form-input v-model="projet.num_devis" id="num_devis" trim placeholder="N° de Devis" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date d'effet " label-for="date-effet-projet">
              <validation-provider #default="{ errors }" name="date d'effet">
                <flat-pickr id="date-effet-projet" v-model="projet.date_effet" class="form-control" placeholder="Date d'effet" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de création " label-for="date-creation-projet">
              <validation-provider #default="{ errors }" name="date de création">
                <flat-pickr id="date-creation-projet" v-model="projet.date_creation" class="form-control" placeholder="Date de création" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="gestionnaire">
              <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="gestionnaire" >
                <v-select v-model="projet.gestionnaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="gestionnaire" label="nom" placeholder="Gestionnaire" >
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune createur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="Type d’origine" label-for="Type-d’origine" style="margin-top: 10px;">
              <b-form-select
                v-model="projet.origine"
                        :options="origine"
                        id="origine"
                        />
            </b-form-group>

            <validation-provider #default="{ errors }" name="createur">
              <b-form-group :state="errors.length > 0 ? false : null" label="Crée par" label-for="Crée-par">
                <v-select v-model="projet.createur" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="createur" label="nom" placeholder="Crée par" :disabled="projetForUpdate.idDevis ? true : false">
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune createur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

         

              <validation-provider #default="{ errors }" name="intermediaire">
                    <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                      <v-select v-model="projet.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                        <template #option="{ avatar, nom }">
                          <b-avatar :src="avatar" size="sm" />
                          <span class="ml-50"> {{ nom }}</span>
                        </template>

                        <template #selected-option="{ avatar, nom }">
                          <b-avatar :src="avatar" class="border border-white" size="sm" />
                          <span class="ml-50"> {{ nom }}</span>
                        </template>
                        <div slot="no-options">Aucune intermediaire disponible.</div>
                      </v-select>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                    </b-form-group>
              </validation-provider>

            <h4>LE BUDGET</h4>
            <b-form-group label="Prime TTC proposée" label-for="budget_propose">
              <validation-provider #default="{ errors }" name="budget proposé">
                <b-form-input type="number" min="0" id="budget_propose" v-model="projet.budget_propose" trim placeholder="Prime TTC proposée" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Prime TTC actuelle" label-for="budget_actuel">
              <b-form-input type="number" min="0" id="budget_actuel" v-model="projet.budget_actuel" trim placeholder="Prime TTC actuelle" />
            </b-form-group>
            <!-- <b-form-group label="Budget actuel *" label-for="budget_actuel">
              <validation-provider #default="{ errors }" name="budget actuel" rules="required">
                <b-form-input id="budget_actuel" v-model="projet.budget_actuel" trim placeholder="budget actuel" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Variation" label-for="variation">
              <validation-provider #default="{ errors }" name="variation">
                <b-form-input id="variation" v-model="projet.variation" disabled trim placeholder="Variation" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <h4>DOCUMENTS</h4>
            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Bulletin d\'adhésion'" @click="openadehsion">
                      <b-form-file type="file" id="openAdhésiontFile" class="d-none" v-model="projet.fileAdhesion" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Bulletin d'adhésion" :value="projet.fileAdhesion ? projet.fileAdhesion.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>
              </validation-provider>
            </b-form-group>
            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Devis'" @click="openprojet">
                      <b-form-file type="file" id="openProjetFile" class="d-none" v-model="projet.fileProjet" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Devis" :value="projet.fileProjet ? projet.fileProjet.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>
              </validation-provider>
            </b-form-group>
            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Fiche d\'information et de conseil'" @click="openconseil">
                      <b-form-file type="file" id="openConseilFile" class="d-none" v-model="projet.fileConseil" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Fiche d'information et de conseil" :value="projet.fileConseil ? projet.fileConseil.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>
              </validation-provider>
            </b-form-group>

       

            <b-form-group v-if="projet.origine==='Autre'" label="Précisez" label-for="origineautre"
                    
                    >
                    
                        <b-form-input
                          id="origineautre"
                          placeholder="Précisez l’origine"
                          v-model="projet.origineautre"
                        />
            </b-form-group>

          


            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordProjetExterne"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { BOverlay, BBadge, BAvatar, BButton, BSidebar, VBToggle, BCardText, BCard, BFormGroup, BFormFile, BFormInput, BFormTextarea, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, BFormSelect, BFormSelectOption, VBTooltip, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { minLength } from 'vuelidate/lib/validators'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import documentService from '@/shared/services/documentService'
import colorPicker from '../messageries/ColorPicker'

export default {
  components: {
    BOverlay,
    BBadge,
    BAvatar,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BFormSelectOption,
    BButton,
    BSidebar,
    BCardText,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    vSelect,
    BFormSelect,
    flatPickr,
    BFormTextarea,
    VBTooltip,
    BFormFile,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    etudeId: {
      type: Number
    },
    clientId: {
      type: Number
    },
    devisId: {
      type: Number
    },
    isFormFicheTier: {
      type: Boolean,
      default: false,
      required: false
    },
    projetForUpdate: {
      type: Object,
      default: null,
      required: false
    },
    prospectDataFromFicheTier: {
      type: Object,
      default: null,
      required: false
    },
    prospectData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: false
    },
    risquesOptions: {
      type: Array,
      default: [],
      required: true
    },
    assureursOptions: {
      type: Array,
      default: [],
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: false
    },
    commercialId: {
      type: Number,
      default: null,
      required: false
    },
    commercialOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    isFromList: {
      type: Boolean,
      required: false
    },
    isFicheEntreprise: {
      type: Boolean,
      required: false,
      default: false
    },
    interfaceChosen: {
      type: String,
      required: false,
      default: 'Listing'
    }
  },
  data() {
    return {
      showLoading: false,
      required,
      famille: null,
      optionsType: [],
      prospectDevisLocal: [],
      composeData: {
        id: null,
        description: '',
        idFamille: null,
        idType: null,
        file: null,
        client_id: this.clientId,
        devis_id: this.devisId,
        etude_id: this.etudeId,
        courtier_id: this.courtierId,
        user_id: null
      },
      origine:[
            { value: null, text: 'Choisissez' },
            { value: 'Co_courtage', text: 'Co-courtage' },
            { value: 'Indicateur_affaires', text: 'Indicateur d\'affaires' },
            { value: 'Weedo_market', text: 'Weedo market' },
            { value: 'Recommandation', text: 'Recommandation' },
            { value: 'Réseaux_sociaux', text: 'Réseaux sociaux' },
            { value: 'Autre', text: 'Autre' },
      ],
      projet: {
        idEtude: null,
        idDevis: null,
        client: null,
        risque: null,
        description: '',
        assureur: null,
        statut: null,
        num_devis: null,
        date_effet: null,
        date_creation: null,
        budget_propose: null,
        budget_actuel: null,
        variation: null,
        idTypeConseil: null,
        idTypeProjet: null,
        idTypeAdhesion: 1,
        fileConseil: null,
        fileProjet: null,
        fileAdhesion: null,
        courtier_id: this.courtierId,
        user_id: null,
        origine:null,
        createur:null,
        intermediaire:null,
        origineautre: null,
        gestionnaire:null,
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      currentUser: JSON.parse(localStorage.getItem('userData')),
      // courtiers: [
      //   { value: null, text: 'Choisissez' },
      //   { value: 'courtier1', text: 'courtier1' },
      //   { value: 'courtier1', text: 'courtier1' },
      //   { value: 'courtier1', text: 'courtier1' }
      // ],
      clients: [
        { value: null, text: 'Choisissez' },
        { value: 'client1', text: 'client1' },
        { value: 'client1', text: 'client1' },
        { value: 'client1', text: 'client1' }
      ],
      risques: [
        { value: null, text: 'Choisissez' },
        { value: 'risque1', text: 'risque1' },
        { value: 'risque1', text: 'risque1' },
        { value: 'risque1', text: 'risque1' }
      ],
      assureurs: [
        { value: null, text: 'Choisissez' },
        { value: 'assureur1', text: 'assureur1' },
        { value: 'assureur1', text: 'assureur1' },
        { value: 'assureur1', text: 'assureur1' }
      ],
      statutOptions: [
        { value: 'Etude', text: 'Etude' },
        { value: 'Devis envoye', text: 'Devis envoyé' },
        { value: 'En attente signature', text: 'En attente signature' },
        { value: 'Devis cloture sans suite', text: 'Devis clôturé sans suite' }
      ],
      selected_client: null,
      selected_risque: null,
      selected_assureur: null,
      date_effet: null,
      date_creation: null,
      selected_courtier: null,
      selected_statut: null,
      configDateNaissance: {
        dateFormat: 'd/m/Y',
        disable: [{ from: moment(new Date(), 'DD/MM/YYYY').subtract(18, 'years')._d, to: '25/02/3000' }]
      },
      prospect_create: {
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        tel: null,
        email: null,
        statut_fiche: 'Prospect'
      },
      civilite: [
        { value: null, text: 'Choisissez' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' }
      ],
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: ' ',
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true
        }
      },
      page: 1,
      items: [],
      selectedItem: null,
      loading: false, // Indique si les données sont en train de charger
      hasMore: true,  // Indique s'il reste des données à charger
      search: '',

      isInvalidePhoneAndMail: false,
      categories: [
        {
          1: 'PARTICULIER',
          2: 'PROFESSIONNEL',
          3: 'ENTREPRISE'
        },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }
      ]
    }
  },
  // validations: {
  //   prospect_create: {
  //     civilite: { required },
  //     nom: { required, minLength: minLength(2) },
  //     prenom: { required, minLength: minLength(2) },
  //     date_naissance: { required },
  //     email: { required },
  //     tel: { required }
  //   }
  // },
  watch: {   
    //  optionsFamille: {
    //     deep:true,
    //     handler(val){
    //         this.famille = val.find(item => item.value.id == 11).value
    //         this.changeListoptionType()
    //     }
    //   },
    prospectDevis: {
      deep: true,
      handler(val) {
        this.prospectDevisLocal = val
        this.rows = this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient
      }
    },
    prospectDataFromFicheTier: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.isFormFicheTier && val) {
          this.projet.client = val
        }
      }
    },
    isFormFicheTier: {
      deep: true,
      handler(val) {
        if (this.prospectDataFromFicheTier && val) {
          this.projet.client = this.prospectDataFromFicheTier
        }
      }
    },
    projetForUpdate: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.idEtude && val.idDevis) {
          this.clearDataSideBar()
          this.getDataForUpdate(val)
        } else {
          this.clearDataSideBar()
        }
      }
    },
    'projet.budget_actuel': {
      handler() {
        if (this.projet.budget_propose && this.projet.budget_actuel) {
          this.projet.variation = (this.projet.budget_propose - this.projet.budget_actuel).toFixed(2)
        } else {
          if (this.projet.budget_propose) {
            this.projet.variation = this.projet.budget_propose
          } else if (this.projet.budget_actuel) {
            this.projet.variation = this.projet.budget_actuel
          } else {
            this.projet.variation = null
          }
        }
      }
    },
    'projet.budget_propose': {
      handler() {
        if (this.projet.budget_propose && this.projet.budget_actuel) {
          this.projet.variation = (this.projet.budget_propose - this.projet.budget_actuel).toFixed(2)
        } else {
          if (this.projet.budget_propose) {
            this.projet.variation = this.projet.budget_propose
          } else if (this.projet.budget_actuel) {
            this.projet.variation = this.projet.budget_actuel
          } else {
            this.projet.variation = null
          }
        }
      }
    }
  },
  // validations: {
  //   prospect_create: {
  //     civilite: { required },
  //     nom: { required },
  //     prenom: { required },
  //     email: { required, email },
  //     tel: { required},
  //   }
  // },
  computed: {
    categorieVariant() {
      const categorieColor = {
        /* eslint-disable key-spacing */
        PARTICULIER: 'light-danger',
        PROFESSIONNEL: 'light-warning',
        ENTREPRISE: 'light-success'
        /* eslint-enable key-spacing */
      }

      return categories => categorieColor[categories]
    }
  },
  created() {
    // Initialise debounce avec un délai de 500 ms
    this.debouncedSearch = debounce(this.getProspectByScrool, 700);
  },
  methods: {
    autocompleteModeles(filtre, loading) {
      this.items = [];
      this.search = filtre
      this.page = 1;
      this.hasMore = true;
      this.debouncedSearch()
       
    },
    onOpen() {
      if (!this.items.length) {
        this.getProspectByScrool(); // Charge les données initiales
      }

      this.$nextTick(() => {
        const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.addEventListener('scroll', this.onScroll);
        }
      });
    },
    onClose() {
      const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
      if (dropdownMenu) {
        dropdownMenu.removeEventListener('scroll', this.onScroll);
      }
    },
    // Méthode appelée pour récupérer les données paginées
    getProspectByScrool(page = 1, scrool = false ) {
      if (this.loading || !this.hasMore) return;

      // Récupérer la hauteur actuelle de la liste avant l'ajout des nouvelles données
      const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
      const previousScrollHeight = dropdownMenu ? dropdownMenu.scrollHeight : 0;

      this.loading = true;
      this.$http
        .post(`/portefeuilles/getDataPagination?page=${page}`, {
          type: 'CLIENT_SELECT',
          type_list: 'CLIENT',
          per_page: 20,
          recherche: this.search || ''  
        })
        .then((r) => {
          const data = r.data.data.data;

          if (data.length) {
            this.items = [...this.items, ...data]; // Ajoute les nouveaux éléments
          }

          if (!r.data.data.next_page_url) {
            this.hasMore = false; // Désactiver le chargement si plus de pages
          }

          this.page++; // Incrémenter la page pour le prochain appel
        })
        .catch((err) => {
          console.error('Erreur lors du chargement des données:', err);
        })
        .finally(() => {
          // this.search = ''
          this.loading = false; // Arrêter le spinner de chargement
          // Restaurer la position de défilement
          if (scrool) {
            this.$nextTick(() => {
              if (dropdownMenu) {
                // Calculer la nouvelle hauteur et ajuster la position de défilement
                const newScrollHeight = dropdownMenu.scrollHeight;
                dropdownMenu.scrollTop += newScrollHeight - previousScrollHeight;
              }
            });
          }
          
        });
    },    
    onScroll(event) {
      const tolerance = 5; // Tolérance de 5 pixels pour détecter le bas
      const dropdown = event.target;
      const atBottom = Math.abs(dropdown.scrollHeight - dropdown.scrollTop - dropdown.clientHeight) <= tolerance;

      console.log("Scroll détecté - Bas atteint : ", atBottom);

      if (atBottom && this.hasMore && !this.loading) {
        this.getProspectByScrool(this.page); // Charger la page suivante
      }
    },
    async isDuplicateMailAndPhone() {
      let email = this.prospect_create.email
      let phone = this.prospect_create.tel
      try {
        let counter_result = (await this.$http.post(`verify/is_duplicate_mail_and_phone`, { email: email, phone: phone })).data
        if (parseInt(counter_result) > 0) {
          this.isInvalidePhoneAndMail = true
        } else {
          this.isInvalidePhoneAndMail = false
        }
      } catch (e) {
        this.isInvalidePhoneAndMail = true
      }
    },
    async affecterProspect(prospect) {
      await this.$http
        .get(`/prospect/fetchDataTiersPro/${prospect.id}/${prospect.type}`)
        .then(res => {
          if (res.data) {
            let prospectLocal = res.data.data
            prospectLocal.date_naissance = prospectLocal.date_naissance ? moment(prospectLocal.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
            try {
              this.$store.dispatch('clearData')
              this.$store.commit('setProspect', {
                Tiers: prospectLocal
              })
              this.$router.push({ name: 'projets-proposition' })
            } catch (e) {
              this.errors = e.response.data.errors
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthCourtier(){
      let userData = JSON.parse(localStorage.getItem('userData'))
    let commercialSelected = {
      id: userData.personne_physique.user_id,
      nom: userData.personne_physique.nom + ' ' + userData.personne_physique.prenom
    }
      this.projet.createur = commercialSelected
      this.projet.gestionnaire = commercialSelected

    
    },

    // eslint-disable-next-line consistent-return
    submitForm_create_prospect() {
      // this.$v.$reset()
      // this.$v.prospect_create.$touch()
      // if (this.$v.prospect_create.$error) {
      //   console.log(this.$v.prospect_create)
      //   return 0
      // }
      // if (this.isInvalidePhoneAndMail) {
      //   return 0
      // }

      this.$refs.tierRapideComposeRules.validate().then(success => {
        if (success) {
          this.prospect_create.statut_fiche = 'Prospect'
          this.prospect_create.date_naissance = this.prospect_create.date_naissance ? moment(this.prospect_create.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''

          this.$http.post('/prospect/addProspectPhysique/', this.prospect_create).then(response => {
            const tmpProspect = response.data.prospect
            tmpProspect.date_naissance = tmpProspect.date_naissance ? moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
            try {
              this.$store.dispatch('clearData')
              this.$store.commit('setProspect', {
                Tiers: tmpProspect
              })
              this.$router.push({ name: 'projets-proposition' })
            } catch (e) {
              this.messageToast(e.response.data.errors[0], 'Erreur', 'error')
              this.errors = e.response.data.errors
            }
          })
        }
      })

      // this.$http.post(`prospect/addProspectPhysique`,this.prospect_create)
      //   .then(res => {
      //     if (res.data !== 'Error') {
      //       this.$toast({
      //         component: ToastificationContent,
      //         position: 'top-right',
      //         props: {
      //           title: `Client créé`,
      //           icon: 'CoffeeIcon',
      //           variant: 'success',
      //           text: `Le tiers ' + this.prospect_create.prenom + ' ' + this.prospect_create.nom + ' a été créé avec succès.`,
      //         },
      //       })
      //       this.$emit('successAddedProspectPp', res.data.prospect)
      //       this.isActive=false
      //       this.resetCreateProspect()
      //       this.$router.push({ name: 'tiers-list' })
      //     } else {
      //       this.$toast({
      //         component: ToastificationContent,
      //         position: 'top-right',
      //         props: {
      //           title: `Client créé`,
      //           icon: 'CoffeeIcon',
      //           variant: 'danger',
      //           text: `Le tiers ' + this.prospect_create.prenom + ' ' + this.prospect_create.nom + ' n'a pas été créer.`,
      //         },
      //       })
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    // openToSelectFile: () => {
    //   document.getElementById('fileautredocument').click()
    // },
    openadehsion: () => {
      document.getElementById('openAdhésiontFile').click()
    },
    openconseil: () => {
      document.getElementById('openConseilFile').click()
    },
    openprojet: () => {
      document.getElementById('openProjetFile').click()
    },
    clearDataSideBar() {
      this.projet.idEtude = null
      this.projet.idDevis = null
      // this.projet.client = null
      this.projet.risque = null
      this.projet.assureur = null
      this.projet.statut = null
      this.projet.num_devis = null
      this.projet.date_effet = null
      this.projet.date_creation = null
      this.projet.budget_propose = null
      this.projet.budget_actuel = null
      this.projet.variation = null
      this.projet.file = null
      this.projet.courtier_id = this.courtierId
      this.projet.user_id = null
      this.projet.fileAdhesion = null
      this.projet.fileProjet = null
      this.projet.fileConseil = null
      this.projet.origine = null
      this.projet.createur = null
      this.projet.gestionnaire = null
      this.projet.intermediaire = null
      this.projet.origineautre = null
     
      if(this.$refs.projetExterneComposeRules){
        this.$refs.projetExterneComposeRules.reset()
      }
    },
    getDataForUpdate(data) {
      console.log(data)
      this.projet.idEtude = data.idEtude
      this.projet.idDevis = data.idDevis

      if (!this.isFormFicheTier) {
        data.client.nameClient =  data.client.categorie == 'ENTREPRISE' ? data.client.denomination_commercial : data.client.nom + ' ' + data.client.prenom
        this.projet.client = data.client
        this.items = [];
        this.page = 1;
        this.hasMore = true;
        this.search = this.projet.client.categorie == 'ENTREPRISE' ? this.projet.client.denomination_commercial : this.projet.client.nom + ' ' + this.projet.client.prenom
        this.getProspectByScrool()
        this.search = ''
        
      }
      this.projet.risque = data.risque
      this.projet.assureur = data.assureur
      this.projet.statut = data.statut
      this.projet.num_devis = data.num_devis
      this.projet.date_effet = this.formattingDateSql(data.date_effet)
      this.projet.date_creation = this.formattingDateSql(data.date_creation)
      this.projet.budget_propose = data.budget_propose
      this.projet.budget_actuel = data.budget_actuel

      if (data.user === null) {
        this.projet.gestionnaire = null
      } else {
        this.projet.gestionnaire = {
          id: data.user.id,
          nom: `${data.user.nom}`,
          type: data.user.type,
        };
      }

      if (data.origine != null) {
        if (data.origine == 'Co_courtage' || data.origine == 'Indicateur_affaires' || data.origine == 'Weedo_market' || data.origine == 'Recommandation' || data.origine == 'Réseaux_sociaux') {
          this.projet.origine = data.origine
        } else {
          this.projet.origine = 'Autre'
          this.projet.origineautre = data.origineautre
        }
      }else{
        this.projet.origine = null
      }   
      
      
      if(data.createur === null){
        this.projet.createur = null
      }else{
        this.projet.createur = {
        id: data.createur.id,
        nom: `${data.createur.nom}`,
        type: data.createur.type,
      };
      }
      
     
      this.projet.intermediaire = [];
        if (data.intermediaire && data.intermediaire.length > 0) {
          data.intermediaire.forEach(courtier => {
            this.projet.intermediaire.push({
              id: courtier.id,
              nom: `${courtier.nom}`,
              type: courtier.type
            });
          });
       }
        
     
    },
    hideSideBare() {
      this.$refs.importNewprojet.hide()
    },
    hideSideBareProjetExterne() {
      this.$refs.sideBarProjetExterne.hide()
    },
    changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },

    // record Data projet
    recordProjetExterne() {
      this.$refs.projetExterneComposeRules.validate().then(success => {
        const formData = new FormData()
        // Append the rest of your form data
        formData.append('assureur', this.checkIsNull(this.projet.assureur))
        formData.append('budget_actuel', this.checkIsNull(this.projet.budget_actuel))
        formData.append('budget_propose', this.checkIsNull(this.projet.budget_propose))
        // console.log(JSON.stringify(this.projet.client))
        formData.append('client', JSON.stringify(this.projet.client))
        formData.append('fileAdhesion', this.checkIsNull(this.projet.fileAdhesion))
        formData.append('fileProjet', this.checkIsNull(this.projet.fileProjet))
        formData.append('fileConseil', this.checkIsNull(this.projet.fileConseil))
        formData.append('idTypeProjet', 30)
        formData.append('idTypeConseil', 43)
        formData.append('idTypeAdhesion', 1)
        formData.append('courtier_id', this.checkIsNull(this.projet.courtier_id))
        formData.append('date_creation', this.checkIsNull(this.projet.date_creation))
        formData.append('date_effet', this.checkIsNull(this.projet.date_effet))
        formData.append('description', this.checkIsNull(this.projet.description))
        formData.append('idDevis', this.checkIsNull(this.projet.idDevis))
        formData.append('idEtude', this.checkIsNull(this.projet.idEtude))
        formData.append('num_devis', this.checkIsNull(this.projet.num_devis))
        formData.append('risque', this.checkIsNull(this.projet.risque))
        formData.append('statut', this.checkIsNull(this.projet.statut))
        formData.append('user_id', this.checkIsNull(this.projet.gestionnaire.id)) //commercialId
        formData.append('variation', this.checkIsNull(this.projet.variation))
        formData.append('origine',this.checkIsNull(this.projet.origine === 'Autre' ? this.projet.origineautre : this.projet.origine))
        formData.append('createur',JSON.stringify(this.projet.createur))
        formData.append('intermediaire',JSON.stringify(this.projet.intermediaire))
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        if (success) {
          this.showLoading = true
          this.$http
            .post('/projet/recordProjetExterne', formData, config)
            .then(res => {
              if (res.data.success) {
                const devisNo = res.data.data.data.etude.source === 'Interne' ? res.data.data.data.devis_no_interne : res.data.data.data.devis_no_externe
              
                res.data.data.data.devisNo = devisNo
                this.$emit('record-projet-externe-returned', res.data.data.data, res.data.data.isCreated, this.interfaceChosen == 'FicheProjet' ? true : false)
                this.showLoading = false
                this.clearDataSideBar()
                this.hideSideBareProjetExterne()
                this.messageToast(res.data.message, 'Succès', 'success')
              }
            })
            .catch(err => {
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    },
    recordDocument() {
      this.$refs.projetExterneJoindreComposeRules.validate().then(success => {
        if (success) {
          // Set formData

          const formData = new FormData()
          // Append the rest of your form data
          formData.append('tiers', this.composeData.id)
          formData.append('type_doc_id', this.composeData.idType)
          formData.append('nature_doc_id', this.composeData.idType)
          formData.append('type_tiers', 'PERSONNE_PHYSIQUE')
          formData.append('file', this.composeData.file)
          formData.append('courtier_id', this.composeData.courtier_id)
          formData.append('description', this.composeData.description)
          formData.append('devis_id', this.composeData.devis_id)
          formData.append('etude_id', this.composeData.etude_id)
          formData.append('client_id', this.composeData.client_id)
          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/importerNewDocumentProjet', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                this.messageToast(res.data.message, 'Succès', 'success')
                this.clearDataSideBar()
                this.hideSideBare()
              } else {
                this.messageToast("Erreur lors de l'importation de nouveau document.", 'Erreur', 'error')
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    }
  }

  //  setup() {
  //     const optionsFamille = ref([])
  //     const fetchDocumentNatureWithTypes = documentService()
  //     fetchDocumentNatureWithTypes
  //       .then(success => {
  //         optionsFamille.value = []
  //         success.data.forEach(el => {
  //           optionsFamille.value.push({
  //             value: { id: el.id, typeDocument: el.documentype },
  //             text: el.libelle
  //           })
  //         })
  //       })
  //       .catch(errors => {
  //         console.log(errors)
  //         optionsFamille.value = []
  //       })

  //     return {
  //       fetchDocumentNatureWithTypes,
  //       optionsFamille
  //     }
  //   }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
