<template>
  <b-sidebar id="sidebar-dirigeant-client-entreprise" ref="sideBarDirigeantClientEntreprise" backdrop bg-variant="white" no-header right
             shadow sidebar-class="sidebar-lg"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Modifier client </span>
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>
      <validation-observer ref="dirigeantClientEntrepriseComposeRules">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Inputs data-->
          <b-overlay :show="showLoading" no-wrap/>
          <h4 class="text-uppercase">Information</h4>

          <b-form-group label="Civilité *" label-for="dirigeant_civilite">
            <validation-provider #default="{ errors }" name="civilité" rules="required">
              <b-form-select v-model="composeData.civilite" :options="civilite" id="dirigeant_civilite" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Nom *" label-for="dirigeant_nom">
            <validation-provider #default="{ errors }" name="nom" rules="required|min:3">
              <b-form-input class="text-uppercase" id="dirigeant_nom" trim placeholder="Nom" v-model="composeData.nom" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Prénom *" label-for="dirigeant_prenom">
            <validation-provider #default="{ errors }" name="prénom" rules="required|min:3">
              <b-form-input class="text-capitalize" id="dirigeant_prenom" trim placeholder="Prénom" v-model="composeData.prenom" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <h4 class="text-uppercase">Contact</h4>

          <b-form-group label="Téléphone portable *" label-for="dirigeant_tel_portable">
            <validation-provider #default="{ errors }" name="téléphone portable" :rules="{ required: true, regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
              <b-form-input id="dirigeant_tel_portable" trim placeholder="ex: 06 00 00 00 00" v-model="composeData.tel" :state="errors.length > 0 ? false : null" v-mask="'## ## ## ## ##'" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Téléphone fixe du dirigeant" label-for="dirigeant_tel">
            <validation-provider #default="{ errors }" name="téléphone fixe du dirigeant" :rules="{ required: true, regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
              <b-form-input id="dirigeant_tel" trim placeholder="ex: 06 00 00 00 00" v-model="composeData.tel_secondaire" :state="errors.length > 0 ? false : null" v-mask="'## ## ## ## ##'" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>


          <b-form-group label="E-mail *" label-for="dirigeant_email">
            <validation-provider #default="{ errors }" name="e-mail" rules="required">
              <b-form-input id="dirigeant_email" trim placeholder="email@example.com" v-model="composeData.email" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <h4 class="text-uppercase">Coordonnées postales</h4>

          <b-form-group label="N° et libellé de la voie*" label-for="composeData_libelle">
            <validation-provider #default="{ errors }" name="n° et libellé de la voie" rules="required|min:3">
              <b-form-input id="composeData_libelle" trim placeholder="N° et libellé de la voie" v-model="composeData.libelle" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Complément d’adresse" label-for="composeData_complement_adresse">
            <b-form-input id="composeData_libelle" trim placeholder="Complément d’adresse (Bâtiment, Appartement…)" v-model="composeData.complement_adresse" />
          </b-form-group>

          <b-form-group label="Lieu-dit ou BP" label-for="composeData_lieu_dit_ou_bp">
            <b-form-input id="composeData_lieu_dit_ou_bp" trim placeholder="Lieu-dit ou BP" v-model="composeData.lieu_dit_ou_bp" />
          </b-form-group>

          <b-form-group label="Code postal*" label-for="composeData_code_postal">
            <validation-provider #default="{ errors }" name="code postal" rules="required|min:3">
              <b-form-input id="composeData_code_postal" trim placeholder="Code postal" v-model="composeData.code_postal" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Ville *" label-for="ville">
            <validation-provider #default="{ errors }" name="ville" rules="required">
              <b-form-select id="ville" v-model="composeData.villeId" :options="villeOptions" :state="errors.length > 0 ? false : null" text-field="ville" value-field="id">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary" @click="updateDirigeant">
              Enregistrer
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BOverlay,
  BSidebar
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BTV
    BOverlay,
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dirigeantData: {
      type: Object,
      default: {
        id: null,
        type: null,
        nom: null,
        prenom: null,
        civilite: null,
        moyen_contact: {
          id: null,
          tel: null,
          tel_secondaire: null,
          email: null,
          libelle: null,
          code_postal: null,
          ville_id: null,
          complement_adresse: null,
          personne_physique_id: null,
          ville: {
            id: null,
            code_postal: null,
            commune: null,
          }
        }
      },
      required: true
    }
  },
  data() {
    return {
      civilite:[
        { value: null, text: 'Choisissez' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' },
      ],
      showLoading: false,
      villeOptions: [],
      required,
      composeData: {
        idPersonnePhysique: null,
        idMoyenContact: null,
        civilite: null,
        nom: null,
        prenom: null,
        tel: null,
        tel_secondaire: null,
        email: null,
        libelle: null,
        complement_adresse: null,
        lieu_dit_ou_bp: null,
        code_postal: null,
        villeId: null
      }
    }
  },
  watch: {
    dirigeantData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val)
        this.affectedDataTierToComposeData()
      }
    },
    'composeData.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  methods: {
    // Methode additional
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                this.composeData.villeId = this.villeOptions[0].id
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    affectedDataTierToComposeData() {
      this.composeData.idPersonnePhysique = this.dirigeantData.id,
      this.composeData.idMoyenContact = this.dirigeantData.moyen_contact.id,
      this.composeData.civilite = this.dirigeantData.civilite,
      this.composeData.nom = this.dirigeantData.nom,
      this.composeData.prenom = this.dirigeantData.prenom,
      this.composeData.tel = this.dirigeantData.moyen_contact.tel,
      this.composeData.tel_secondaire = this.dirigeantData.moyen_contact.tel_secondaire,
      this.composeData.email = this.dirigeantData.moyen_contact.email,
      this.composeData.libelle = this.dirigeantData.moyen_contact.libelle,
      this.composeData.complement_adresse = this.dirigeantData.moyen_contact.complement_adresse,
      this.composeData.lieu_dit_ou_bp = this.dirigeantData.moyen_contact.lieu_dit_ou_bp,
      this.composeData.code_postal = this.dirigeantData.moyen_contact.code_postal,
      this.composeData.villeId = this.dirigeantData.moyen_contact.ville_id
    },
    hideSideBare() {
      this.$refs.sideBarDirigeantClientEntreprise.hide()
    },
    updateDirigeant() {
      this.$refs.dirigeantClientEntrepriseComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              this.$http
                  .post('/prospect/updateDirigeant', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.$emit('update-dirigeant-client-entreprise-returned', res.data.data, res.data.message)
                      this.hideSideBare()
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
