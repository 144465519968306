<template>
  <b-sidebar id="sidebar-client-entreprise" ref="sideBarClientEntreprise" backdrop bg-variant="white" no-header right
             shadow sidebar-class="sidebar-lg"
  >
    <b-overlay :show="showLoading" no-wrap/>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Modifier client </span>
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>
      <validation-observer ref="clientEntrepriseComposeRules">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Inputs data-->

          <h4 class="text-uppercase">Information</h4>
          <b-form-group label="Statut*">
            <validation-provider #default="{ errors }" name="statut" rules="required">
              <b-form-select v-model="composeData.statut_fiche" :options="prospectStatutFicheBoolean" id="choose_statut"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Dénomination commerciale *" label-for="denominationCommercial">
            <validation-provider #default="{ errors }" name="dénomination commerciale" rules="required">
              <b-form-input v-model="composeData.denominationCommercial" :state="errors.length > 0 ? false : null"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <validation-provider #default="{ errors }" name="raison sociale" >
            <b-form-group label="Raison sociale" label-for="raisonSociale">
              <b-form-input v-model="composeData.raisonSociale" :state="errors.length > 0 ? false : null"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="forme juridique" >
            <b-form-group label="Forme juridique" label-for="formeJuridique">
              <b-form-select v-model="composeData.formeJuridique" :state="errors.length > 0 ? false : null">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="(fg,index) in FormJuridique" :key="index" :value="fg.value">{{
                    fg.text
                  }}
                </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <b-form-group label="Chiffre d'affaire" label-for="chiffreAffaire">
            <b-form-input v-model="composeData.chiffreAffaire" type="number"/>
          </b-form-group>

          <validation-provider #default="{ errors }" name="date de création" >
            <b-form-group label="Date de création" label-for="date-creation">
              <flat-pickr id="date-creation" v-model="composeData.dateCreation"
                          :class="errors.length > 0 ? 'is-invalid' : ''"
                          :config="configDate" class="form-control"
                          placeholder="date de création"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="SIRET">
            <b-form-group :label="composeData.siretLabel " :label-for="composeData.siretLabel">
              <b-form-input v-model="composeData.SIRET" :state="errors.length > 0 ? false : null"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="bénéficiaires effectifs">
            <b-form-group label="Bénéficiaires effectifs" label-for="beneficiairesEffectif">
              <b-form-input v-model="composeData.beneficiairesEffectif" :state="errors.length > 0 ? false : null"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="activité" >
            <b-form-group label="Activité de l’entreprise" label-for="activite">
              <b-form-input v-model="composeData.activite" :state="errors.length > 0 ? false : null" placeholder="Activité de l’entreprise"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="IDCC" label-for="IDCC">
            <b-form-input v-model="composeData.IDCC"/>
          </b-form-group>

          <b-form-group label="Effectif" label-for="effectif">
            <b-form-input v-model="composeData.effectif"/>
          </b-form-group>
              <b-form-group label="Période de clôture d’exercice" label-for="cloture-exercice">
                <validation-provider #default="{ errors }" name="cloture d’exercice">
                  <cleave
                    id="date_cloture_exercice"
                    v-model="composeData.date_cloture_exercice"
                    class="form-control"
                    :raw="false"
                    :options="options.date_cloture"
                    placeholder="jour/mois"
                />
                  <!-- <b-form-input id="echeance-anniversaire" placeholder="jour/mois" v-model="composeData.echeance_anniversaire" :maxlength="max"/> -->
                  <div v-if="valdiation!=null" class="echent">Le champ période de clôture d’exercice invalide ( jj/mm )</div>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>

          <b-form-group label="Code NAF" label-for="code-naf">
            <v-select v-model="composeData.IDCodeNAF"
                      :reduce="codeNafReduce => codeNafReduce.id"
                      :close-on-select="true"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="codeNafOptions"
                      input-id="id"
                      label="libelle" placeholder="Code NAF"
            >
              <template #option="{ code, libelle }">
                <span class="ml-50"> {{ code }} - {{ libelle }} </span>
              </template>

              <template #selected-option="{ code, libelle }">
                <span class="ml-50"> {{ code }} - {{ libelle }} </span>
              </template>
              <div slot="no-options">Aucune correspondance.</div>
            </v-select>
          </b-form-group>

          <!--            <b-form-group label="Code NAF" label-for="code-naf">
                        <b-form-select id="code-naf" v-model="composeData.IDCodeNAF" >
                          <template #first>
                            <b-form-select-option :value="null">&#45;&#45; Choisissez &#45;&#45;</b-form-select-option>
                            <b-form-select-option v-for="(naf, index) in codeNafOptions" :key="index" :value="naf.id">{{ naf.code }} - {{ naf.libelle }}</b-form-select-option>
                          </template>
                       </b-form-select>
                      </b-form-group>-->
                      
        <!--   <b-form-group label="Inscrit sur Bloctel" label-for="inscrit-sur-bloctel">
            <validation-provider #default="{ errors }" name="Inscrit sur Bloctel">
            <div class="form-inline" id="inscrit-sur-bloctel">
              <b-form-radio v-model="composeData.isBloctel" name="inscrit-sur-bloctel-radios" value="1" class="mr-2"> oui </b-form-radio>
              <b-form-radio v-model="composeData.isBloctel" name="inscrit-sur-bloctel-radios" value="0"> non </b-form-radio>
            </div>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>   
          
          <b-form-group v-if="composeData.isBloctel == 1" label="Date d’inscription sur Bloctel" label-for="Date-d’inscription-sur-bloctel">
                    <flat-pickr
                      v-model="composeData.date_bloctel"
                      class="form-control"
                      id="date_d’inscription_sur_bloctel"
                      :config="configDate"
                      placeholder="Date d’inscription sur Bloctel"
                    />
          </b-form-group> -->

          <h4 class="text-uppercase">Contact</h4>
          <!-- <b-form-group label="Numéro de téléphone" label-for="phone">
            <validation-provider #default="{ errors }"
                                 :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                 name="numéro de téléphone"
            >
              <cleave id="phone" v-model="composeData.telephone"
                      :class="errors.length > 0 || isInvalideMailandPhone ? 'is-invalid' : ''" :options="options.phone"
                      :raw="false" class="form-control"
                      placeholder="ex: 06 00 00 00 00"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group > -->
                <b-form-group label="Numéro de téléphone" label-for="phone">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="composeData.indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                          <!-- <cleave
                                id="phone" 
                                
                                v-model="composeData.telephone"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                                :class="errors.length > 0 || isInvalideMailandPhone ? 'is-invalid' : ''" :options="options.phone"
                                :raw="false" class="form-control"
                                placeholder="ex: 06 00 00 00 00"
                                @change="errorIndicatifTelFunction(errors)"

                            /> -->
                              <b-form-input
                                id="pro_telephone"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                                v-model="composeData.telephone"
                                trimc
                                placeholder="ex: 06 00 00 00 00"
                                v-mask="'## ## ## ## ##'"
                                @change="errorIndicatifTelFunction(errors)"
                              />
                            <small class="text-danger" >{{ errorIndicatifTelFunction(errors[0]) }}</small>
                        </validation-provider>
                    </div>
                  </div>
                <small class="text-danger">{{ errorIndicatifTel }}</small>
              </b-form-group>

          <b-form-group label="E-mail" label-for="email">
            <validation-provider #default="{ errors }" name="e-mail" rules="email">
              <b-form-input v-model="composeData.email" :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
                            placeholder="example@example.com" type="email"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                  errors[0]
                }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback class="text-danger" v-if="isInvalideMailandPhone">Email et Téléphone sont déjà utilisé</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Site internet" label-for="site-internet">
            <b-form-input v-model="composeData.siteWeb"/>
          </b-form-group>

          <h4 class="text-uppercase">Coordonnées postales</h4>
          <div>
            <b-form-group label="N° et libellé de la voie" label-for="nEtLibelleVoie">
              <validation-provider #default="{ errors }" name="N° et libellé de la voie">
                <b-form-input id="nEtLibelleVoie" v-model="composeData.libelle"
                              :state="errors.length > 0 ? false : null" placeholder="N° et libellé de la voie"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)"
                          label-for="complementAdresse"
            >
              <b-form-input id="complementAdresse" v-model="composeData.complementAdresse"
                            placeholder="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)"
              />
            </b-form-group>

            <b-form-group label="Lieu-dit ou BP" label-for="lieuDitOuBp">
              <b-form-input id="lieuDitOuBp" v-model="composeData.lieuDitOuBp" placeholder="Lieu-dit ou BP"/>
            </b-form-group>

            <b-form-group label="Code postal" label-for="codePostal">
              <validation-provider #default="{ errors }" name="code postal" >
                <b-form-input id="codePostal" v-model="composeData.codePostal"
                              :state="errors.length > 0 ? false : null" placeholder="Code postal"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Ville" label-for="ville">
              <validation-provider #default="{ errors }" name="ville" >
                <b-form-select id="ville" v-model="composeData.villeId" :options="villeOptions"
                               :state="errors.length > 0 ? false : null" text-field="ville" value-field="id"
                >
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </div>
          <b-form-group label="Type d’origine" label-for="Type-d’origine">
             <b-form-select
                    v-model="composeData.origine"
                    :options="origine"
                    id="origine"
                    />
            </b-form-group>
            <b-form-group v-if="composeData.origine==='Autre'" label="Précisez" label-for="origineautre">
                    <b-form-input  
                      id="origineautre"
                      placeholder="Précisez l’origine"
                      v-model="composeData.origineautre"
                    />
                </b-form-group>

          <validation-provider #default="{ errors }" name="commercial" >
              <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="Gestionnaire">
                <v-select v-model="composeData.commercial" :close-on-select="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1"
                  input-id="commercial" label="nom" placeholder="Gestionnaire">
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune gestionnaire disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="createur" >
              <b-form-group :state="errors.length > 0 ? false : null" label="Crée par" label-for="Crée-par">
                <v-select v-model="composeData.createur" :close-on-select="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1"
                  input-id="createur" label="nom" placeholder="Crée par" disabled>
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune createur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="intermediaire">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                    <v-select v-model="composeData.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune intermediaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                      @click="updateTiersEntreprise"
            > Enregistrer
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BOverlay,
  BSidebar,
  VBToggle,
  BAvatar
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import Data from '@/shared/constants/prospect'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    // BTV
    BOverlay,
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormGroup,
    BFormRadio,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // Other component
    vSelect,
    flatPickr,
    Cleave,
    BAvatar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    tiersData: {
      type: Object,
      default: {
        idPersonneMorale: null,
        type: null,
        origine:null,
        statut_gda:null,
        denominationCommercial: null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        indicatif:null,
        email: null,
        adresse: null,
        libelle: null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        statut_fiche: null,
        isVisible: false,
        date_cloture_exercice:null,
        date_bloctel:null,
        idPersonnePhysiq:null
      },
      required: true
    }
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      commercialOptions: [],
      indicatif:[],
      errorIndicatifTel:[],
      commercial: null,
      showLoading: false,
      valdiation:null,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        },
        date_cloture: {
          date: true,
          delimiter: '/',
          datePattern: ['d','m'],

        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique: Data['FORMEJURIDIQUE'],
      villeOptions: [],
      codeNafOptions: [],
      isInvalideMailandPhone:false,
      required,
      origine:[
            { value: null, text: 'Choisissez' },
            { value: 'Co_courtage', text: 'Co-courtage' },
            { value: 'Indicateur_affaires', text: 'Indicateur d\'affaires' },
            { value: 'Weedo_market', text: 'Weedo market' },
            { value: 'Recommandation', text: 'Recommandation' },
            { value: 'Réseaux_sociaux', text: 'Réseaux sociaux' },
            { value: 'Autre', text: 'Autre' },
      ],
      composeData: {
        idPersonneMorale: null,
        type: null,
        denominationCommercial: null,
        origine:null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        siretLabel:'SIRET',
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        indicatif:null,
        email: null,
        libelle: null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        date_cloture_exercice:null,
        origineautre:null,
        statut_gda:null,
        commercial: {
          id: null,
          nom: null,
          type: null,
        },
        createur : null,
        intermediaire: null,
        actif_commercial: null,
        statut_fiche: 'Prospect',
        isVisible: false,
        isBloctel:0,
        date_bloctel:null,
        idPersonnePhysiq:null
      },
      prospectStatutFicheBoolean:[
        {value: 'Client', text: 'Client'},
        {value: 'Prospect', text: 'Prospect'},
        {value: 'Ancien Client', text: 'Ancien Client'},
        {value: 'Aucun', text: 'Aucun'}
      ]
    }
  },
  watch: {
    tiersData: {
      deep: true,
      handler() {
        this.affectedDataTierToComposeData()
      }
    },
    'composeData.codePostal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
    'composeData.formeJuridique' :{
        handler(val){
         
         if(val =="ALA"){
            this.composeData.siretLabel = "RNA"
         }else{
           this.composeData.siretLabel ="SIRET"
         }
      }
    },
    'composeData.email': {
      deep:true,
      handler(val){
        this.isInvalideMailandPhone = false;
        if (this.composeData.email && this.composeData.tel) {
          this.isDuplicateMailAndPhoneIgnoreCurrent(this.composeData.email,this.composeData.tel)
        }
      }
    },
    'composeData.telephone': {
      deep:true,
      handler(val){
        this.isInvalideMailandPhone = false;
        if (this.composeData.tel && this.composeData.email) {
          this.isDuplicateMailAndPhoneIgnoreCurrent(this.composeData.email,this.composeData.tel)
        }
      }
    },
    'composeData.statut_fiche': {
      handler(val) {
        if(val === 'Aucun')
          this.composeData.isVisible = false
        else this.composeData.isVisible = true
      }
    },
  },
  mounted() {
    this.GetCodeNAF()
    this.fetchDataCommercialByCourtier()
  },
  created(){
  this.$http.get(`tiers/getPays`)
      .then((r) => {
        this.pays=r.data
        this.indicatif = r.data
        // let france = r.data.find(item => item.indicatif == "+33")
        // let indexFrance = r.data.indexOf(france)
        // r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        // this.composeData.indicatif = this.indicatif[0].indicatif
        
      })
      .catch((e)=>{
        console.log(e);
      })
  },
  methods: {
     errorIndicatifTelFunction(val) {
      this.errorIndicatifTel = val
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    // Methode additional
    selectIdCodeNAF(e) {
      this.composeData.IDCodeNAF = e.id
    },
    GetCodeNAF() {
      this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e) => {
            console.log(e)
          })
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                this.composeData.villeId = this.villeOptions[0].id
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    affectedDataTierToComposeData() {
      this.composeData.idPersonneMorale = this.tiersData.idPersonneMorale
      this.composeData.type = this.tiersData.type
      this.composeData.denominationCommercial = this.tiersData.denominationCommercial
      this.composeData.raisonSociale = this.tiersData.raisonSociale
      this.composeData.formeJuridique = this.tiersData.formeJuridique
      this.composeData.chiffreAffaire = this.tiersData.chiffreAffaire
      this.composeData.dateCreation = this.tiersData.dateCreation
      this.composeData.SIRET = this.tiersData.SIRET
      this.composeData.beneficiairesEffectif = this.tiersData.beneficiairesEffectif
      this.composeData.activite = this.tiersData.activite
      this.composeData.IDCC = this.tiersData.IDCC
      this.composeData.effectif = this.tiersData.effectif
      this.composeData.idMoyenContact = this.tiersData.idMoyenContact
      this.composeData.telephone = this.tiersData.telephone
      this.composeData.indicatif = this.tiersData.indicatif_tel
      this.composeData.email = this.tiersData.email
      this.composeData.libelle = this.tiersData.libelle
      this.composeData.complementAdresse = this.tiersData.complementAdresse
      this.composeData.codePostal = this.tiersData.codePostal
      this.composeData.villeId = this.tiersData.villeId
      this.composeData.ville = this.tiersData.ville
      this.composeData.lieuDitOuBp = this.tiersData.lieuDitOuBp
      this.composeData.siteWeb = this.tiersData.siteWeb
      this.composeData.IDCodeNAF = this.tiersData.IDCodeNAF
      this.composeData.CodeNAF = this.tiersData.CodeNAF
      this.composeData.date_cloture_exercice = this.tiersData.date_cloture_exercice
      this.composeData.commercial = {
        id  : this.tiersData.distributeur.user.personne_physique.user_id,
        nom : this.tiersData.distributeur.user.personne_physique.prenom + ' ' + this.tiersData.distributeur.user.personne_physique.nom,
        type: this.tiersData.distributeur.user.personne_physique.type
      }
      if(this.tiersData.createurPersonneMorale){
          this.composeData.createur = {
          id  : this.tiersData.createurPersonneMorale.user.personne_physique.user_id,
          nom : this.tiersData.createurPersonneMorale.user.personne_physique.prenom + ' ' + this.tiersData.createurPersonneMorale.user.personne_physique.nom,
          type: this.tiersData.createurPersonneMorale.user.personne_physique.type
        }
      }
      this.composeData.intermediaire = [];
        if (this.tiersData.courtiersUsersByIntermediares && this.tiersData.courtiersUsersByIntermediares.length > 0) {
          this.tiersData.courtiersUsersByIntermediares.forEach(courtier => {
            this.composeData.intermediaire.push({
              id: courtier.user.personne_physique.user_id,
              nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
              type: courtier.user.personne_physique.type
            });
          });
        }
      this.composeData.actif_commercial = this.tiersData.distributeur.user.personne_physique.user_id
      this.composeData.statut_fiche = this.tiersData.statut_fiche
      this.composeData.statut_gda = this.tiersData.statut_gda

      this.composeData.isVisible = this.tiersData.isVisible
      if(this.tiersData.origine!=null){
        if (this.tiersData.origine== 'Co_courtage'||this.tiersData.origine== 'Indicateur_affaires'||this.tiersData.origine== 'Weedo_market'||this.tiersData.origine== 'Recommandation'||this.tiersData.origine== 'Réseaux_sociaux'){
          this.composeData.origineautre=null
          this.composeData.origine = this.tiersData.origine
        }
        else{
          this.composeData.origine='Autre'
          this.composeData.origineautre = this.tiersData.origine
        }
      }
      /* this.composeData.isBloctel = this.tiersData.prospect[0].lienable.isBloctel ? this.tiersData.prospect[0].lienable.isBloctel : 0
      this.composeData.date_bloctel = this.composeData.date_bloctel = this.tiersData.prospect[0].lienable.date_bloctel ? moment(this.tiersData.prospect[0].lienable.date_bloctel, 'YYYY-MM-DD').format('DD/MM/YYYY') : null*/
      this.composeData.idPersonnePhysiq = this.tiersData.prospect[0].lienable_id 
    },
    hideSideBare() {
      this.$refs.sideBarClientEntreprise.hide()
    },
    async isDuplicateMailAndPhoneIgnoreCurrent(email,tel) {
      try {
        if(this.ValidateEmail(email) && tel.length == 14)
        {
          let counter_result = (await this.$http.post(`verify/isDuplicateMailAndPhoneIgnoreCurrent`,{ email: email , phone: tel, id: this.tiersData.idMoyenContact})).data
          if (parseInt(counter_result) > 0) {
            this.isInvalideMailandPhone = true
          } else {
            this.isInvalideMailandPhone = false
          }
        }
        this.isInvalideMailandPhone = false
      } catch (e) {
        this.isInvalideMailandPhone = true
      }
    },
    updateTiersEntreprise() {
      this.$refs.clientEntrepriseComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              this.$http
                  .post('/prospect/updateTiersEntreprise', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
                      this.hideSideBare()
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
