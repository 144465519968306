<template>
  <b-tabs content-class="pt-2" justified>
    <b-tab :title="`Devis ${projetCount > 0 ? '('+projetCount+')' : ''}`" active>
      <b-card-text>
        <list-projets @refersh-document="freshdocumet" :prospect-chosen="prospectChosen" :tiers-data="tiersData" @refresh-data-projets="countprojet" @project-contrat-data-fetching="projectContratDataFetching"/>
      </b-card-text>
    </b-tab>
    <b-tab :title="`Contrats ${contratCount > 0 ? '('+ contratCount +')' : ''}`">
      <b-card-text>
        <list-contrats @refersh-document="freshdocumet" :prospect-chosen="prospectChosen" :tiers-data="tiersData"  @refresh-data-contrats="countcontrat" @project-contrat-data-fetching="projectContratDataFetching"/>
      </b-card-text>
    </b-tab>
    <b-tab :title="`Documents ${documentCount > 0 ? '('+documentCount+')' : ''}`">
      <b-card-text>
        <list-documents :fresh="fresh" :prospect-chosen="prospectChosen" :denomination-commercial="denominationCommercial" :courtier-id="currentUser.courtier_user[0].courtier_id" :projet-contrat-data="projetContratData" @refresh-data-documents="countdocument"/>
      </b-card-text>
    </b-tab>
      <b-tab :title="`Sinistres ${sinistreCount > 0 ? '(' + sinistreCount + ')' : ''}`" >
        <b-card-text>
        <listSinistre @refersh-document="freshdocumet" :prospect-chosene="prospectChosen" @calculate="getSinistreCount" :is-fiche-client="true" :tiers-data="tiersData" />
      </b-card-text>
    </b-tab>
    <b-tab :title="`Réclamations ${reclamationCount > 0 ? '('+reclamationCount+')' : ''}`">

      <b-card-text>
        <listReclamation @refersh-document="freshdocumet" :prospect-chosene="prospectChosen" @calculate="getReclamationCount" :is-fiche-client="true" :tiers-data="tiersData" />   
      </b-card-text>
    </b-tab>
    <b-tab :title="`Notes ${noteCount > 0 ? '('+ noteCount +')' : ''}`">
      <b-card-text>
        <list-notes :prospect-chosen="prospectChosen" @refresh-data-notes="countnote" />
      </b-card-text>
    </b-tab>
    <b-tab :title="`Activités ${activitesCount > 0 ? '('+activitesCount+')' : ''}`">
      <b-card-text>
        <list-activite :client-type="prospectChosen.type" :client-id="prospectChosen.id" :refresh-data-activite="refreshDataActivite" @calculate="getActivitesCount" @reset-refresh-data-activite="resetRefreshDataActivite" />
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import listContrats from './details-fiche-entreprise/contrats/Lists.vue'
import listNotes from './details-fiche-entreprise/notes/Note.vue'
import listDocuments from './details-fiche-entreprise/documents/Documents.vue'
import listProjets from './details-fiche-entreprise/projets/Projet.vue'
import listReclamation from './details-fiche-entreprise/reclamation/Reclamation.vue'
import listActivite from '../global-components/Activite.vue'
import listSinistre from './details-fiche-entreprise/sinistres/Sinistre.vue'




export default {
  components: {
    // BTV
    BTabs,
    BTab,
    BCardText,

    // UI
    listContrats,
    listNotes,
    listDocuments,
    listProjets,
    listActivite,
    listReclamation,
    listSinistre
  },
  props: {
    denominationCommercial:{
      type: String,
      default: null,
      required: false
    },
    prospectChosen: {
      type: Object,
      default: null,
      required: true
    },
    tiersData: {
      type: Object,
      default: null,
      required: false
    },
    refreshDataActivite: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      projetContratData: [],
      note : [],
      projetCount:0,
      reclamationCount:0,
      fresh:0,
      documentCount:0,
      activitesCount: 0,
      contratCount: 0,
      noteCount: 0,
      sinistreCount:0
    }
  },
  watch: {
    prospectChosen: {
      deep: true,
      immediate: true,
      handler(){
          this.countnote()
          this.countcontrat()
          this.countprojet()


      }
    }
  },
  methods: {
    resetRefreshDataActivite(isRefresh) {
      this.$emit('reset-refresh-data-activite', isRefresh)
    },
    projectContratDataFetching(data) {
      data.forEach(element => {
        this.projetContratData.push(element)
      })
    },
    freshdocumet(){
      this.fresh += 1
      console.log(this.fresh)

    },
    countnote(count) {
      this.noteCount = count

    },      
    countcontrat(count) {
      this.contratCount = count
     
    },
    countdocument(count){
      console.log(1)
      this.documentCount = count
     
    },
    countprojet(count) {
      this.projetCount = count
    },
    getReclamationCount(reclamation){
      this.reclamationCount=reclamation
    },
    getSinistreCount(sinistre) {
      this.sinistreCount = sinistre
    },
    getActivitesCount(activites){
      this.activitesCount=activites
    }

  }
}
</script>
