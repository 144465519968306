<template>
  <b-sidebar
    id="sidebar-client-entreprise"
    ref="sideBarClientEntreprise"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
  >
    <b-overlay
      :show="showLoading"
      no-wrap
    />
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Modifier client </span>
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="clientEntrepriseComposeRules">
        <b-form
          class="px-2"
          @submit.prevent="checkPersonneMoralePro"
        >
          <b-form-group
            label="Categories*"
            label-for="categorie_personne"
          >
            <b-form-select
              id="categorie_personne"
              v-model="professionnel.personne_type"
            >
              <b-form-select-option
                value="null"
                disabled
              >
                Choisissez *
              </b-form-select-option>
              <b-form-select-option value="PARTICULIER">
                Particulier
              </b-form-select-option>
              <b-form-select-option value="PROFESSIONNEL">
                Professionnel
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Statut"
          >
            <!-- <b-form-radio-group v-model="professionnel.isClient" :options="optionsChangedToClient" class="demo-inline-spacing"
                    name="radio-inline" /> -->
            <validation-provider
              #default="{ errors }"
              name="statut"
            >
              <b-form-select
                id="choose_statut"
                v-model="professionnel.statut_fiche"
                :options="prospectStatutFicheBoolean"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Civilité*"
            label-for="pro_civilite"
          >
            <validation-provider
              #default="{ errors }"
              name="pro civilite"
              rules="required"
            >
              <b-form-select
                id="pro_civilite"
                v-model="professionnel.civilite"
                :options="civilite"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Nom*"
            label-for="pro_nom"
          >
            <validation-provider
              #default="{ errors }"
              name="pro nom"
              rules="required"
            >
              <b-form-input
                v-if="professionnel.statut_gda==='VERT'"
                id="pro_nom"
                v-model="professionnel.nom"
                trim
                disabled
                placeholder="Nom"
                :state="errors.length > 0 ? false : null"
                class="text-uppercase"
              />
              <b-form-input
                v-else
                id="pro_nom"
                v-model="professionnel.nom"
                trim
                placeholder="Nom"
                :state="errors.length > 0 ? false : null"
                class="text-uppercase"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Nom de naissance"
            label-for="pro_nom_naissance"
          >
            <validation-provider
              #default="{ errors }"
              name="Nom de naissance"
            >

              <b-form-input
                id="pro_nom_naissance"
                v-model="professionnel.nom_naissance"
                trim
                placeholder="Nom de naissance"
                :state="errors.length > 0 ? false : null"
                class="text-uppercase"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Prénom"
            label-for="pro prénom"
          >
            <validation-provider
              #default="{ errors }"
              name="pro prenom"
            >
              <b-form-input
                v-if="professionnel.statut_gda==='VERT'"
                id="pro_prenom"
                v-model="professionnel.prenom"
                trim
                disabled
                placeholder="Prénom"
                :state="errors.length > 0 ? false : null"
                class="text-capitalize"
              />
              <b-form-input
                v-else
                id="pro_prenom"
                v-model="professionnel.prenom"
                trim
                placeholder="Prénom"
                :state="errors.length > 0 ? false : null"
                class="text-capitalize"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Date de naissance"
            label-for="pro_date_naissance"
          >
            <flat-pickr
              v-if="professionnel.statut_gda==='VERT'"
              id="pro_date_naissance"
              v-model="professionnel.date_naissance"
              class="form-control"
              disabled
              :config="configDate"
              placeholder="Date de naissance"
              data-max-date="today"
            />
            <flat-pickr
              v-else
              id="pro_date_naissance"
              v-model="professionnel.date_naissance"
              class="form-control"
              :config="configDate"
              placeholder="Date de naissance"
              data-max-date="today"
            />
          </b-form-group>

          <b-form-group
            label="Code postal de naissance"
            label-for="pro_code_postal_naissance"
          >
            <validation-provider
              #default="{ errors }"
              name="pro code postal naissance"
            >
              <b-form-input
                id="pro_code_postal_naissance"
                v-model="professionnel.code_postal_naissance"
                trim
                placeholder="Code postal de naissance"
                :state="errors.length > 0 ? false : null"
                class="text-uppercase"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <b-form-group
            label="Commune de naissance"
            label-for="pro_commune_naissance"
          >
            <validation-provider
              #default="{ errors }"
              name="pro_commune_naissance"
            >
              <b-form-input
                id="pro_commune_naissance"
                v-model="professionnel.commune_naissance"
                :state="errors.length > 0 ? false : null"
                placeholder="Commune de naissance"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="pays_naissance"
          >
            <b-form-group
              label="Pays de naissance"
              label-for="pays_naissance"
            >
              <v-select
                v-model="professionnel.pays_naissance"
                :close-on-select="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pays"
                class="flex-grow-1"
                input-id="pays_naissance"
                label="pays"
                placeholder="Pays de naissance"
              >
                <template #option="{ pays }">
                  <span class="ml-50"> {{ pays }}</span>
                </template>

                <template #selected-option="{ pays }">
                  <span class="ml-50"> {{ pays }}</span>
                </template>
                <div slot="no-options">
                  Aucune pays disponible.
                </div>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                errors[0]
              }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Situation familiale"
            label-for="pro_situation_familiale"
          >
            <b-form-select
              id="pro_situation_familiale"
              v-model="professionnel.situation_familiale"
            >
              <b-form-select-option
                value="null"
                disabled
              >Choisissez</b-form-select-option>
              <b-form-select-option
                value="CONCUBIN"
              >Concubin(e)</b-form-select-option>
              <b-form-select-option
                value="CELIBATAIRE"
              >Célibataire</b-form-select-option>
              <b-form-select-option
                value="DIVORCE"
              >Divorcé(e)</b-form-select-option>
              <b-form-select-option
                value="MARIE"
              >Marié(e)</b-form-select-option>
              <b-form-select-option
                value="SEPARE"
              >Séparé(e)</b-form-select-option>
              <b-form-select-option
                value="VEUF"
              >Veuf(ve)</b-form-select-option>
              <b-form-select-option
                value="PACSE"
              >Pacsé(e)</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Enfants à charge"
            label-for="pro_nbr_enfant"
          >
            <b-form-input
              id="pro_nbr_enfant"
              v-model="professionnel.enfantCharge"
              trim
              placeholder="Enfants à charge"
              type="number"
              min="0"
            />
          </b-form-group>
          <b-form-group
            label="N° et libellé de la voie"
            label-for="pro_libelle"
          >
            <validation-provider
              #default="{ errors }"
              name="N° et libellé de la voie"
            >
              <b-form-input
                id="pro_libelle"
                v-model="professionnel.libelle"
                trim
                placeholder="N° et libellé de la voie"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Complément d'adresse"
            label-for="pro_complement_adresse"
          >

            <b-form-input
              id="pro_complement_adresse"
              v-model="professionnel.complement_adresse"
              trim
              placeholder="Complément d'adresse"
            />
          </b-form-group>

          <b-form-group
            label="Lieu-dit"
            label-for="lieu_dit"
          >
            <b-form-input
              id="lieu_dit"
              v-model="professionnel.lieu_dit_ou_bp"
              trim
              placeholder="Lieu-dit"
            />
          </b-form-group>

          <b-form-group
            label="Code postal"
            label-for="pro_code_postal"
          >
            <validation-provider
              #default="{ errors }"
              name="pro code postal"
              rules="digits:5"
            >
              <b-form-input
                id="pro_code_postal"
                v-model="professionnel.code_postal"
                v-mask="'#####'"
                trim
                placeholder="Code postal"
                :state="errors.length > 0 ? false : null"
                @keyup="cpostalKeyupPRO()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
          <b-form-group
            label="Ville"
            label-for="pro_ville"
          >
            <validation-provider
              #default="{ errors }"
              name="pro_ville"
            >
              <b-form-select
                id="pro_ville"
                v-model="professionnel.ville_id"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  Choisissez
                </b-form-select-option>
                <b-form-select-option
                  v-for="(val, value) in villeOptions"
                  :key="value"
                  :value="val.id"
                >
                  {{ val.ville }}
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- <b-form-group
                  label="Téléphone Portable"
                  label-for="pro_telephone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pro téléphone"
                    :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                  >
                  <b-form-input
                    id="pro_telephone"
                    v-model="professionnel.tel"
                    trim
                    placeholder="ex: 06 00 00 00 00"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'## ## ## ## ##'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

          <b-form-group label="Inscrit sur Bloctel" label-for="inscrit-sur-bloctel">
            <validation-provider #default="{ errors }" name="Inscrit sur Bloctel">
            <div class="form-inline" id="inscrit-sur-bloctel">
              <b-form-radio v-model="professionnel.isBloctel" name="inscrit-sur-bloctel-radios" value="1" class="mr-2"> oui </b-form-radio>
              <b-form-radio v-model="professionnel.isBloctel" name="inscrit-sur-bloctel-radios" value="0"> non </b-form-radio>
            </div>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>  

          <b-form-group v-if="professionnel.isBloctel == 1" label="Date d’inscription sur Bloctel" label-for="Date-d’inscription-sur-bloctel">
                    <flat-pickr
                      v-model="professionnel.date_bloctel"
                      class="form-control"
                      id="date_d’inscription_sur_bloctel"
                      :config="configDate"
                      placeholder="Date d’inscription sur Bloctel"
                    />
          </b-form-group>

          <b-form-group label="Téléphone portable" label-for="téléphone-portable">
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select
                  v-model="professionnel.indicatif"
                  :close-on-select="true"
                  :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="indicatif"
                  :reduce="indicatif =>indicatif.indicatif"
                  input-id="indicatif"
                  label="indicatif"
                  class="indicatif-chooser w-100"
                >
                  <template #option="{ indicatif,code }">
                    <span> {{ `${code.toUpperCase()} `+indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">
                    Aucune indicatif disponible.
                  </div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                <validation-provider
                  #default="{ errors }"
                  name="téléphone"
                  :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                >
                  <b-form-input
                    id="pro_telephone"
                    v-model="professionnel.tel"
                    v-mask="'## ## ## ## ##'"
                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                    trim
                    placeholder="ex: 06 00 00 00 00"
                  />
                  <small
                    class="text-danger"
                    :style="errors.length > 0 ? 'margin-left:-88px' : '' "
                  >{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Téléphone Fixe"
            label-for="pro_telephone_portable"
          >
            <div class="d-flex bd-highlight">
              <div class="w-25 bd-highlight">
                <v-select
                  v-model="professionnel.indicatif_tel_sec"
                  :close-on-select="true"
                  :clearable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="indicatif"
                  :reduce="indicatif =>indicatif.indicatif"
                  input-id="indicatif"
                  label="indicatif"
                  class="indicatif-chooser w-100"
                >
                  <template #option="{ indicatif,code }">
                    <span> {{ `${code.toUpperCase()} `+indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">
                    Aucune indicatif disponible.
                  </div>
                </v-select>
              </div>
              <div class="w-75 bd-highlight">
                <validation-provider
                  #default="{ errors }"
                  name="pro téléphone portable"
                  :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                >
                  <b-form-input
                    id="pro_telephone_portable"
                    v-model="professionnel.tel_secondaire"
                    v-mask="'## ## ## ## ##'"
                    trim
                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                    placeholder="ex: 06 00 00 00 00"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    class="text-danger"
                    :style="errors.length > 0 ? 'margin-left:-88px' : '' "
                  >{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="pro_email"
          >
            <validation-provider
              #default="{ errors }"
              name="pro email"
            >
              <b-form-input
                id="pro_email"
                v-model="professionnel.email"
                trim
                placeholder="Email"
                type="email"
                :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="isInvalideMailandPhone"
                class="text-danger"
              >Email et Téléphone sont déja utilisé</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Catégorie socioprofessionnelle"
            label-for="Catégorie socioprofessionnelle"
          >
            <b-form-select
              id="activite"
              v-model="professionnel.statut"
            >
              <b-form-select-option
                value="null"
                disabled
              >Choisissez</b-form-select-option>
              <b-form-select-option
                value="AGRICULTEUR"
              >Agriculteur</b-form-select-option>
              <b-form-select-option
                value="ARTISAN"
              >Artisan</b-form-select-option>
              <!-- <b-form-select-option value="AUTO-ENTREPRENEUR"
                        >Auto-entrepreneur</b-form-select-option
                      > -->
              <b-form-select-option
                value="CHEF_ENTREPRISE"
              >Chef d’entreprise</b-form-select-option>
              <b-form-select-option
                value="COMMERCANT"
              >Commerçant</b-form-select-option>
              <b-form-select-option
                value="ETUDIANT"
              >Etudiant</b-form-select-option>
              <!-- <b-form-select-option value="INDEPENDANT"
                        >Independant</b-form-select-option
                      > -->
              <b-form-select-option
                value="FONCTIONNAIRE"
              >Fonctionnaire ou assimilé</b-form-select-option>
              <b-form-select-option
                value="PROFESSION_LIBERALE"
              >Profession libérale</b-form-select-option>
              <b-form-select-option
                value="RETRAITE"
              >Retraité</b-form-select-option>
              <b-form-select-option
                value="SALARIE_CADRE"
              >Salarié cadre</b-form-select-option>
              <b-form-select-option
                value="SALARIE_NON_CADRE"
              >Salarié non cadre</b-form-select-option>
              <b-form-select-option
                value="SANS_EMPLOI"
              >Sans emploi</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Domaine d’activité" label-for="Domaine d’activité">
            <b-form-select id="activite" v-model="professionnel.secteur_activite">
              <b-form-select-option value="null" disabled>Choisissez</b-form-select-option>
              <template v-for="secteur in sortedSecteurs">
                <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="professionnel.personne_type =='PROFESSIONNEL' "
            label="Statut juridique"
            label-for="statut_juridique"
          >
            <b-form-select
              id="statut_juridique"
              v-model="professionnel.statut_juridique"
            >
              <b-form-select-option
                value="null"
                disabled
              >Choisissez</b-form-select-option>
              <b-form-select-option
                value="ARTISANS"
              >Artisan</b-form-select-option>
              <b-form-select-option
                value="COMMERCANTS"
              >Commerçant</b-form-select-option>
              <b-form-select-option
                value="CONJOINTS_COLLABORATEURS"
              >Conjoint collaborateur</b-form-select-option>
              <b-form-select-option
                value="ENTREPRENEURS_INDIVIDUELS_OU_AUTO_ENTREPRENEURS"
              >Entrepreneur individuel ou auto entrepreneur</b-form-select-option>
              <b-form-select-option
                value="GERANTS_MAJORITAIRES"
              >Gérant majoritaire</b-form-select-option>
              <b-form-select-option
                value="GERANTS_MAJORITAIRES_AVEC_CONJOINT"
              >Gérant majoritaire avec conjoint</b-form-select-option>
              <b-form-select-option
                value="MANDATAIRES_SOCIAUX"
              >Mandataire social</b-form-select-option>
              <b-form-select-option
                value="PROFESSIONS_LIBERALES_MEDICALES"
              >Profession libérale médicale</b-form-select-option>
              <b-form-select-option
                value="PROFESSIONS_LIBERALES_NON_MEDICALES"
              >Profession libérale non médicale</b-form-select-option>
              <b-form-select-option
                value="TNS_AUTRES"
              >Autre TNS</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Régime de sécurité sociale"
            label-for="pro_regime_obligatoire"
          >
            <b-form-select
              id="pro_regime_obligatoire"
              v-model="professionnel.regime_obligatoire"
            >
              <b-form-select-option
                value="null"
                disabled
              >Choisissez</b-form-select-option>
              <b-form-select-option
                v-for="(regime, value) in REGIMESOCIAL"
                :key="'r' + value"
                :value="regime.value"
              >
                {{ regime.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Numéro de sécurité social"
            label-for="numSecu"
          >
            <cleave
              id="numSecu"
              v-model="professionnel.numSecu"
              :options="blockSecu"
              class="form-control"
              :raw="false"
              placeholder="Numéro de sécurité social"
            />
            <span
              v-if="errorsNumSecu"
              class="text-danger"
              style="font-size:12px"
            >
              Le numéro de sécurite sociale est erroné.
            </span>
          </b-form-group>
          <b-form-group
            label="Type d’origine"
            label-for="Type-d’origine"
          >
            <b-form-select
              id="origine"
              v-model="professionnel.origine"
              :options="origine"
            />
          </b-form-group>
          <b-form-group
            v-if="professionnel.origine==='Autre'"
            label="Précisez"
            label-for="origineautre"
          >
            <b-form-input
              id="origineautre"
              v-model="professionnel.origineautre"
              placeholder="Précisez l’origine"
            />
          </b-form-group>

          <!-- utilisateur list -->
          <validation-provider
            #default="{ errors }"
            name="commercial"
          >
            <b-form-group
              :state="errors.length > 0 ? false : null"
              label="Gestionnaire"
              label-for="commercial"
            >
              <v-select
                v-model="professionnel.commercial"
                :close-on-select="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="commercialOptions"
                class="flex-grow-1"
                input-id="commercial"
                label="nom"
                placeholder="Crée par"
              >
                <template #option="{ avatar, nom }">
                  <b-avatar
                    :src="avatar"
                    size="sm"
                  />
                  <span class="ml-50"> {{ nom }}</span>
                </template>

                <template #selected-option="{ avatar, nom }">
                  <b-avatar
                    :src="avatar"
                    class="border border-white"
                    size="sm"
                  />
                  <span class="ml-50"> {{ nom }}</span>
                </template>
                <div slot="no-options">
                  Aucune commercial disponible.
                </div>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                errors[0]
              }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="{ errors }" name="Createur" >
                  <b-form-group :state="errors.length > 0 ? false : null" label="Créer par" label-for="Créer-par">
                    <v-select v-model="professionnel.createur" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="createur" label="nom" placeholder="Créer par" disabled>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune createur disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
          </validation-provider>


          <validation-provider #default="{ errors }" name="intermediaire" >
                  <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                    <v-select v-model="professionnel.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune intermediaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div
            class="d-flex mt-2"
            style="float:right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-2"
              size="md"
              type="submit"
            >
              Enregistrer
            </b-button>
          </div>

        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BOverlay,
  BButton,
  BForm,
  BFormRadio,
  BFormGroup,
  BSidebar,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BFormSelectOption,
  BFormSelect,
  BInputGroup,
  BFormRadioGroup,
  BAvatar,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import Data from '@/shared/constants/risquebesoin'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import Besoin from '@/shared/constants/risquebesoin'
import moment from 'moment'
import vSelect from 'vue-select'
import Activite from '@/shared/constants/activite'

export default {
  components: {
    // BTV
    BOverlay,
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    // flatPickr and Cleave
    flatPickr,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    tiersData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      commercialOptions: [],
      commercial: null,
      origine: [
        { value: null, text: 'Choisissez' },
        { value: 'Co_courtage', text: 'Co-courtage' },
        { value: 'Indicateur_affaires', text: 'Indicateur d\'affaires' },
        { value: 'Weedo_market', text: 'Weedo market' },
        { value: 'Recommandation', text: 'Recommandation' },
        { value: 'Réseaux_sociaux', text: 'Réseaux sociaux' },
        { value: 'Autre', text: 'Autre' },
      ],
      blockSecu: {
        blocks: [15],
        uppercase: true,
      },
      errors: '',
      pays: [],
      indicatif: [],
      errorIndicatifTel: [],
      REGIMESOCIAL: Besoin.REGIMESOCIAL,
      showLoading: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true,
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' ',
        },
      },
      optionsChangedToClient: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' },
      ],
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        },
      },
      FormJuridique: Data.FORMEJURIDIQUE,
      DomaineActivite :Data.SECTEUR_ACTIVITES,
      villeOptions: [],
      codeNafOptions: [],
      required,
      isInvalideMailandPhone: false,
      civilite: [
        { value: null, text: 'Choisissez' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' },
      ],
      professionnel: {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        tel: null,
        origine: null,
        origineautre: null,
        indicatif: null,
        tel_secondaire: null,
        indicatif_tel_sec: null,
        email: null,
        libelle: null,
        complement_adresse: null,
        lieu_dit_ou_bp: null,
        code_postal: null,
        ville_id: null,
        date_naissance: null,
        original_date_naissance: null,
        original_civilite: null,
        original_statut: null,
        original_situation_familiale: null,
        original_regime_obligatoire: null,
        original_code_postal: null,
        original_nom:null,
        original_prenom : null,
        original_nom_naissance:null,
        original_code_postal_naissance:null,
        original_commune_naissance:null,
        original_pays_naissance:null,
        original_ville:null,
        situation_familiale: null,
        statut: null,
        statut_gda: null,
        statut_juridique: null,
        enfantCharge: null,
        regime_obligatoire: null,
        nom_naissance: null,
        commune_naissance: null,
        pays_naissance: null,
        code_postal_naissance: null,
        commercial: {
          id: null,
          nom: null,
          type: null,
        },
        createur:null,
        intermediaire :null,
        actif_commercial: null,
        personne_type: null,
        // isClient:false,
        statut_fiche: null,
        numSecu: null,
        secteur_activite:null,
        isBloctel : 0,
        date_bloctel:null,
        },
      entreprise: {
        id: null,
        statut_gda: null,
        origine: null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        site_web: null,
        date_creation: null,
        code_naf: null,
        idcc: null,
        beneficiairesEffectif: null,
        chiffreAffaire: null,
        effectif: null,
        autre: null,
      },
      errorsNumSecu: false,
      prospectStatutFicheBoolean: [
        { value: 'Client', text: 'Client' },
        { value: 'Prospect', text: 'Prospect' },
        { value: 'Ancien Client', text: 'Ancien Client' },
        { value: 'Aucun', text: 'Aucun' },
      ],
    }
  },
  watch: {
    tiersData: {
      deep: true,
      handler(val) {
          this.affectedDataTierToComposeData(val)
      },
    },
    'professionnel.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      },
    },
    // 'professionnel.email': {
    //   deep:true,
    //   handler(val){
    //       this.isInvalideMailandPhone = false;
    //       if (this.professionnel.email && this.professionnel.tel) {
    //         this.isDuplicateMailAndPhoneIgnoreCurrent(this.professionnel.email,this.professionnel.tel)
    //       }
    //   }
    // },
    // 'professionnel.tel': {
    //   deep:true,
    //   handler(val){
    //     this.isInvalideMailandPhone = false;
    //     if (this.professionnel.tel && this.professionnel.email) {
    //         this.isDuplicateMailAndPhoneIgnoreCurrent(this.professionnel.email,this.professionnel.tel)
    //     }
    //   }
    // },
    'professionnel.statut_fiche': {
      handler(val) {
        if (val === 'Aucun') this.professionnel.isVisible = false
        else this.professionnel.isVisible = true
      },
    },
  },
  mounted() {
    // this.GetCodeNAF()
    console.log(this.professionnel.statut)

    this.fetchDataCommercialByCourtier()
  },
  created() {
    this.$http.get('tiers/getPays')
      .then(r => {
        this.pays = r.data
        this.indicatif = r.data
      })
      .catch(e => {
        console.log(e)
      })
  },
   computed: {
    sortedSecteurs() {
      return this.DomaineActivite.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    errorIndicatifTelFunction(val) {
      this.errorIndicatifTel = val
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
        .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
        .then(res => {
          if (res.data.success) {
            res.data.data.forEach(el => {
              this.commercialOptions.push({
                id: el.user_id,
                type: el.type,
                nom: el.nom,
              })
            })
          }
        })
        .catch(err => {
          this.commercialOptions = []
          console.error(err)
        })
    },
    // Methode additional
    GetCodeNAF() {
      this.$http.get('tiers/getCodeNAf')
        .then(r => {
          this.codeNafOptions = r.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
          .get(`/helpers/${code}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.villeOptions = [...res.data]
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.villeOptions = []
      }
    },
    affectedDataTierToComposeData(val) {
      this.clearComposeData();
      this.professionnel.id = val.id
      this.professionnel.civilite = val?.civilite ?? null
      this.professionnel.original_civilite = val?.civilite ?? null
      this.professionnel.nom = val.nom ?? null
      this.professionnel.prenom = val.prenom ?? null
      this.professionnel.tel = val?.moyen_contact?.tel ?? null
      this.professionnel.indicatif = val?.moyen_contact?.indicatif_tel ?? null
      this.professionnel.tel_secondaire = val?.moyen_contact?.tel_secondaire ?? null
      this.professionnel.indicatif_tel_sec = val?.moyen_contact?.indicatif_tel_sec ?? null
      this.professionnel.email = val?.moyen_contact?.email ?? null
      this.professionnel.libelle = val?.moyen_contact?.libelle ?? null
      this.professionnel.complement_adresse = val?.moyen_contact?.complement_adresse ?? null
      this.professionnel.lieu_dit_ou_bp = val?.moyen_contact?.lieu_dit_ou_bp ?? null
      this.professionnel.code_postal = val?.moyen_contact?.code_postal ?? null
      this.professionnel.original_code_postal = val?.moyen_contact?.code_postal ?? null
      this.professionnel.original_nom = val?.nom ?? null
      this.professionnel.original_prenom = val?.prenom ?? null
      this.professionnel.original_nom_naissance = val?.nom_naissance ?? null
      this.professionnel.original_code_postal_naissance = val?.code_postal_naissance ?? null
      this.professionnel.original_commune_naissance = val?.commune_naissance ?? null
      this.professionnel.original_pays_naissance = val?.pays_naissance ?? null
      this.professionnel.original_ville_id = val?.moyen_contact?.ville_id ?? null
      this.professionnel.ville_id = val?.moyen_contact?.ville_id ?? null
      this.professionnel.original_date_naissance = this.professionnel.date_naissance = val.date_naissance ? moment(val.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
      this.professionnel.situation_familiale = val?.situation_familiale ?? null
      this.professionnel.original_situation_familiale = val?.situation_familiale ?? null
      this.professionnel.nom_naissance = val?.nom_naissance ?? null
      this.professionnel.code_postal_naissance = val?.code_postal_naissance ?? null
      this.professionnel.commune_naissance = val?.commune_naissance ?? null
      this.professionnel.pays_naissance = val?.pays_naissance ?? null
      this.professionnel.statut = val?.statut ?? null
      this.professionnel.original_statut = val?.statut ?? null
      this.professionnel.statut_juridique = val?.statut_juridique ?? null
      this.professionnel.secteur_activite = val?.secteur_activite ?? null
      // this.professionnel.pays_naissance = val.pays_naissance
      this.professionnel.enfantCharge = val?.enfantCharge ?? null
      this.professionnel.regime_obligatoire = val?.regime_obligatoire ?? null
      if (val.origine != null) {
        if (val.origine == 'Co_courtage' || val.origine == 'Indicateur_affaires' || val.origine == 'Weedo_market' || val.origine == 'Recommandation' || val.origine == 'Réseaux_sociaux') {
          this.professionnel.origine = val.origine
        } else {
          this.professionnel.origine = 'Autre'
        }
      }
      this.professionnel.numSecu = val?.num_secu ?? null
      this.professionnel.origineautre = val?.origine ?? null
      this.professionnel.statut_gda = val?.statut_gda ?? null

      this.professionnel.original_regime_obligatoire = val?.regime_obligatoire ?? null
      this.professionnel.commercial = {
        id: val.distributeur.user.personne_physique.user_id,
        nom: `${val.distributeur.user.personne_physique.prenom} ${val.distributeur.user.personne_physique.nom}`,
        type: val.distributeur.user.personne_physique.type,
      }

      if( val.createur_personne_physique){
          this.professionnel.createur = {
          id:   val.createur_personne_physique.user.personne_physique.user_id,
          nom: `${val.createur_personne_physique.user.personne_physique.prenom} ${val.createur_personne_physique.user.personne_physique.nom}`,
          type: val.createur_personne_physique.user.personne_physique.type,
        }
      }
      this.professionnel.intermediaire = [];
        if (val.courtiers_users_by_intermediares && val.courtiers_users_by_intermediares.length > 0) {
          val.courtiers_users_by_intermediares.forEach(courtier => {
            this.professionnel.intermediaire.push({
              id: courtier.user.personne_physique.user_id,
              nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
              type: courtier.user.personne_physique.type
            });
          });
        }

      this.professionnel.isBloctel=val.isBloctel ? val.isBloctel : 0
      this.professionnel.date_bloctel = this.professionnel.date_bloctel = val.date_bloctel ? moment(val.date_bloctel, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
      this.professionnel.actif_commercial = val.distributeur.user.personne_physique.user_id
      this.professionnel.personne_type = val.categorie
      // this.professionnel.isClient = val.statut_fiche === 'Client' ? true : false
      this.professionnel.statut_fiche = val?.statut_fiche ?? null
      this.professionnel.isVisible = val?.isVisible ?? null
    },
    hideSideBare() {
      this.$refs.sideBarClientEntreprise.hide()
    },
    // async isDuplicateMailAndPhoneIgnoreCurrent(email,tel) {
    //   try {
    //     if(this.ValidateEmail(email) && tel.length == 14)
    //     {
    //         let counter_result = (await this.$http.post(`verify/isDuplicateMailAndPhoneIgnoreCurrent`,{ email: email , phone: tel, id: this.tiersData.moyen_contact.id})).data
    //         if (parseInt(counter_result) > 0) {
    //           this.isInvalideMailandPhone = true
    //         } else {
    //           this.isInvalideMailandPhone = false
    //         }
    //     }
    //   } catch (e) {
    //     this.isInvalideMailandPhone = true
    //   }
    // },
    checkPersonneMoralePro() {
      this.errorsNumSecu = false
      this.$refs.clientEntrepriseComposeRules.validate()
        .then(success => {
          if (success) {

            if ((this.professionnel.date_naissance != this.professionnel.original_date_naissance)
               || (this.professionnel.regime_obligatoire != this.professionnel.original_regime_obligatoire)
               || (this.professionnel.civilite != this.professionnel.original_civilite)
               || (this.professionnel.statut != this.professionnel.original_statut)
               || (this.professionnel.situation_familiale != this.professionnel.original_situation_familiale)
               || (this.professionnel.code_postal != this.professionnel.original_code_postal)
               || (this.professionnel.nom != this.professionnel.original_nom)
               || (this.professionnel.prenom != this.professionnel.original_prenom)
               || (this.professionnel.nom_naissance != this.professionnel.original_nom_naissance)
               || (this.professionnel.code_postal_naissance != this.professionnel.original_code_postal_naissance)
               || (this.professionnel.commune_naissance != this.professionnel.original_commune_naissance)
               || (this.professionnel.pays_naissance != this.professionnel.original_pays_naissance)
               || (this.professionnel.ville_id != this.professionnel.original_ville_id)) {
              this.$swal({
                html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
                      <br><br>
                      Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
                      <br><br>
                      Souhaitez-vous confirmer cette modification ?</p>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                  html: 'text-left',
                },
                buttonsStyling: false,
              })
                .then(confirmed => {
                  if (confirmed.value) {
                    this.updatePersonneMorale()
                  }
                })
            } else this.updatePersonneMorale()
          }
        })
    },
    updatePersonneMorale() {
      this.showLoading = true
      if (typeof (this.professionnel.pays_naissance) === 'string') {
        this.professionnel.pays_naissance = this.pays.find(item => item.pays == this.professionnel.pays_naissance)
      }
      this.$http
        .post('/prospect/updatePersonneMoralePro', this.professionnel)
        .then(res => {
          if (res.data) {
            this.$emit('update-client-pro-returned', res.data.data, 'Le client a été modifié avec succès')
            this.hideSideBare()
            this.showLoading = false
          }
        })
        .catch(err => {
          this.errorsNumSecu = err.response.data.errors.numSecu ? true : false;
          this.showLoading = false
          this.messageToast('La modification n’a pas pu être enregistrée', 'Erreur', 'error')
          console.error(err)
        })
    },
    clearComposeData(){
      this.professionnel = {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        tel: null,
        origine: null,
        origineautre: null,
        indicatif: null,
        tel_secondaire: null,
        indicatif_tel_sec: null,
        email: null,
        libelle: null,
        complement_adresse: null,
        lieu_dit_ou_bp: null,
        code_postal: null,
        ville_id: null,
        date_naissance: null,
        original_date_naissance: null,
        original_civilite: null,
        original_statut: null,
        original_situation_familiale: null,
        original_regime_obligatoire: null,
        original_code_postal: null,
        original_nom:null,
        original_prenom : null,
        original_nom_naissance:null,
        original_code_postal_naissance:null,
        original_commune_naissance:null,
        original_pays_naissance:null,
        original_ville:null,
        situation_familiale: null,
        statut: null,
        statut_gda: null,
        statut_juridique: null,
        enfantCharge: null,
        regime_obligatoire: null,
        nom_naissance: null,
        commune_naissance: null,
        pays_naissance: null,
        code_postal_naissance: null,
        commercial: {
          id: null,
          nom: null,
          type: null,
        },
        createur:null,
        intermediaire :null,
        actif_commercial: null,
        personne_type: null,
        // isClient:false,
        statut_fiche: null,
        numSecu: null,
        secteur_activite:null,
        isBloctel : 0,
        date_bloctel:null,
        }
    }

  },
}
</script>
<style scoped>
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}
</style>
