
<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <app-collapse accordion type="margin" >
      <updated-fiche-professionel-entreprise  
                 :entreprise-data="entrepriseLocal"
                 :code-naf-data="codeNafData"
                 :prospect-data="prospectLocal" 
                 :hasEntreprise="true" 
                 @update-client-pro-returned="UpdateClientProReturned">

      </updated-fiche-professionel-entreprise>
      <app-collapse-item title="Entreprises" v-if="entrepriseDataDirigeant.length > 0">
        <card-connexite :tiers-data="tiersData" :particulier-data="entrepriseDataDirigeant" @update-connexite-prospect="editEntreprise"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="entrepriseColumns"
              :rows="entrepriseDataDirigeant"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <div v-if="props.column.field == 'lienable.denomination_commercial'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                  <a :style="[{'text-decoration': 'underline'}]">
                    {{ props.row.lienable.denomination_commercial }}
                  </a>
                </div>
                <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                  <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
                </div>
                <span v-else-if="props.column.field === 'lien_professionnel'">
                  <b-badge :variant="sourceVariant(props.row.lien_professionnel)">
                    {{ capitalizeFirstLetter(props.row.lien_professionnel) }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <!-- <b-dropdown-item  @click="displayDocument(props.row.idDocument, props.row.Name, props.row.NomDuDocument)">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span>Visualiser</span>
                      </b-dropdown-item>
                      <b-dropdown-item  @click="$emit('update-formation', collaborateur, props.row)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Éditer</span>
                      </b-dropdown-item> -->
                      <!-- <b-dropdown-item  @click="deleteFormation(props.row.formationId, props.row.vgt_id)"> -->
                      <b-dropdown-item @click="editEntreprise(props.row)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="removeConnexite(props.row)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Supprimer la connexité</span>
                      </b-dropdown-item>
                      <!-- <b-dropdown-item>
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Supprimer</span>
                      </b-dropdown-item> -->
                    </b-dropdown>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <!-- <b-row class="mt-2 align-items-center">
                  <b-col md="3">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Affichage 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> de {{ props.total }} entrées </span>
                    </div>
                  </b-col>
                </b-row> -->
                <b-row class="mt-2 align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
            <!-- <b-row class="mb-4" v-for="(entreprise,index) in entrepriseData"  :key="index">
                <b-col cols="8">
                  <div>
                    <span>Denomination Commerciale : {{entreprise.lienable.denomination_commercial}} </span>
                  </div>
                  <div>
                    <span>Fonction : {{entreprise.lienable.lien_professionnel}}</span>
                  </div>
                  <div>
                    <span>Siret :  {{entreprise.lienable.siret}}</span>
                  </div>
                  <div>
                    <span>Activité : {{entreprise.lienable.activite}}</span>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div>
                    <b-badge
                      pill
                      variant="light-success"
                    >
                      {{entreprise.lienable.statut_fiche}}
                    </b-badge>
                  </div>
                </b-col>
            </b-row> -->
      </app-collapse-item>
      <app-collapse-item title="Connexités professionnelles" v-if="entrepriseDataNotDirigeant.length > 0">
        <card-connexite :tiers-data="tiersData" :particulier-data="entrepriseDataNotDirigeant" @update-connexite-prospect="editEntreprise"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="entrepriseColumns"
              :rows="entrepriseDataNotDirigeant"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <div v-if="props.column.field == 'lienable.denomination_commercial'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                  <a :style="[{'text-decoration': 'underline'}]">
                    {{ props.row.lienable.denomination_commercial }}
                  </a>
                </div>
                <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                  <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
                </div>
                <span v-else-if="props.column.field === 'lien_professionnel'">
                  <b-badge :variant="sourceVariant(props.row.lien_professionnel)">
                    {{ capitalizeFirstLetter(props.row.lien_professionnel) }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <!-- <b-dropdown-item  @click="displayDocument(props.row.idDocument, props.row.Name, props.row.NomDuDocument)">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span>Visualiser</span>
                      </b-dropdown-item>
                      <b-dropdown-item  @click="$emit('update-formation', collaborateur, props.row)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Éditer</span>
                      </b-dropdown-item> -->
                      <!-- <b-dropdown-item  @click="deleteFormation(props.row.formationId, props.row.vgt_id)"> -->
                      <b-dropdown-item @click="editEntreprise(props.row)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="removeConnexite(props.row)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Supprimer la connexité</span>
                      </b-dropdown-item>
                      <!-- <b-dropdown-item>
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Supprimer</span>
                      </b-dropdown-item> -->
                    </b-dropdown>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <!-- <b-row class="mt-2 align-items-center">
                  <b-col md="3">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Affichage 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> de {{ props.total }} entrées </span>
                    </div>
                  </b-col>
                </b-row> -->
                <b-row class="mt-2 align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
            <!-- <b-row class="mb-4" v-for="(entreprise,index) in entrepriseData"  :key="index">
                <b-col cols="8">
                  <div>
                    <span>Denomination Commerciale : {{entreprise.lienable.denomination_commercial}} </span>
                  </div>
                  <div>
                    <span>Fonction : {{entreprise.lienable.lien_professionnel}}</span>
                  </div>
                  <div>
                    <span>Siret :  {{entreprise.lienable.siret}}</span>
                  </div>
                  <div>
                    <span>Activité : {{entreprise.lienable.activite}}</span>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div>
                    <b-badge
                      pill
                      variant="light-success"
                    >
                      {{entreprise.lienable.statut_fiche}}
                    </b-badge>
                  </div>
                </b-col>
            </b-row> -->
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {  BDropdown, BDropdownItem, BPagination,BFormRadioGroup, BFormRadio, BFormGroup,BCol,BRow,BBadge,BOverlay} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Data from "@/shared/constants/prospect";
import updatedFicheProfessionelEntreprise from '../actions-fiche-professionel/UpdatedFicheProfessionelEntreprise.vue'
import store from '@/store'
import CardConnexite from '../../global-components/CardConnexite.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow,
    BBadge,
    VueGoodTable,
    BDropdown, 
    BDropdownItem, 
    BPagination,
    updatedFicheProfessionelEntreprise,
    BOverlay,
    CardConnexite
  },
  props: {
    tiersData: {
      type: Object,
      default: {},
      required: true
    },
    codeNafData: {
      type: Array,
      default: [],
      required: true
    }
  },
  computed:{
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    getFormeJuridique: function () {
    let findEntreprise = this.FormJuridique.find(obj => {
           return obj.value === this.entreprise.forme_juridique;
        }); 
        return findEntreprise ? findEntreprise.text : ''
    },
    entrepriseDataDirigeant: function () {
      return this.entrepriseData.filter(value => {
          return value.lien_professionnel == 'DIRIGEANT';
      }); 
    },
    entrepriseDataNotDirigeant: function () {
      return this.entrepriseData.filter(value => {
          return value.lien_familial == null && value.lien_professionnel !== 'DIRIGEANT' ;
      }); 
    },
  },
  data() {
    return {
      showLoading: false,
      dir: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      villeOptions: [],
      codeNafOptions: [],
      isInvalideMailandPhone:false,
      entrepriseData:[],
      pageLength: 3,
      entrepriseLocal:{},
      prospectLocal:{
        id:null,
        type:null
      },
      entrepriseColumns: [
        // { label: 'objet de la formation ', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Dénominitation Commerciale', field: 'lienable.denomination_commercial', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Siret', field: 'lienable.siret', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Activité', field: 'lienable.activite', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Statut', field: 'lienable.statut_fiche', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Date de création', field: 'lienable.date_creation', type: 'date', dateInputFormat: 'yyyy-MM-dd',maxDate:'today', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Fonction', field: 'lien_professionnel', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
    }
  },
  watch: {
    tiersData: {
      deep: true,
      handler() {
        this.affectedDataTierToComposeData()
      }
    },
    'professionnel.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  mounted(){
    // this.GetCodeNAF()
  },
  methods: {
    GetCodeNAF(){
      this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e)=>{
            console.log(e);
          })
    },
    showProspect(isVisible, prospectId, prospectCategorie, prospectType) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    affectedDataTierToComposeData() {
        this.entrepriseData = this.tiersData.prospect.filter(obj => {
           return obj.lien_familial == null ;
        });
    },
    sourceVariant(status) {
      const statusColor = {
        'DIRIGEANT': 'light-primary',
        'DAF': 'light-warning',
        'EXPERT COMPTABLE': 'light-danger',
        'SALARIE': 'light-success',
      }
      return statusColor[status]
    },
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          Aucun: 'light-dark',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    hideSideBare() {
      this.$refs.sideBarClientEntreprise.hide()
    },
    editEntreprise(data){
          this.prospectLocal.id=this.tiersData.id
          this.prospectLocal.type=this.tiersData.type
          this.entrepriseLocal = data.lienable
          this.$root.$emit('bv::toggle::collapse', 'sidebar-client-pro-entreprise-custom')
    },
    removeConnexite(data){
      let meta ={
        prospect:{id:this.tiersData.id,type:this.tiersData.type},
        connexite:{id:data.lienable.id,type:data.lienable.type},
        lien_familial:{lien_familial:data.lien_familial},
        lien_professionnel:{lien_professionnel:data.lien_professionnel}
      }

      this.$swal({
        title: "Êtes-vous sûr ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.showLoading=true
          this.$http
            .post('/prospect/removeConnexite', meta)
            .then(res => {
              if (res.data) {
                this.$emit('update-client-pro-returned', res.data.data, res.data.message)
                this.showLoading=false
              }
            })
            .catch(err => {
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      });
    },
    UpdateClientProReturned(data, message){
      this.$emit('update-client-pro-returned',data,message)
    },
    updateTiersEntreprise() {
      this.$refs.clientEntrepriseComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              this.$http
                  .post('/prospect/updateTiersEntreprise', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
                      this.hideSideBare()
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    },
    deleteEntreprise(id){

    },
  }
}
</script>
