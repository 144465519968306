<template>
  <b-sidebar id="sidebar-client-entreprise-connexite" ref="sideBarClientConnexite" backdrop bg-variant="white" no-header right v-if="renderComponent" shadow sidebar-class="sidebar-lg">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Ajouter une connexité</span>
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <b-form ref="connexite-switch">
        <div class="px-2">
          <b-form-group label="Type De Connexité*" label-for="choose_connexite">
            <validation-provider #default="{ errors }" name="statut">
              <b-form-select v-model="type_connexite" :options="typeConnexite" id="choose_connexite" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </b-form>

      <div v-if="type_connexite === 'connexite_entreprise'">
        <validation-observer ref="AddNewTierValidationsPM">
          <b-form class="px-2" @submit.prevent="submitForm_create_personne_morale">
            <b-form-group label="Lien*" label-for="choose_lien_pro">
              <validation-provider #default="{ errors }" name="Lien Entreprise" rules="required">
                <b-form-select v-model="prospect_morale.type_lien_prof" :options="typeLienEntreprise" id="choose_lien_pro" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Existe-t'il dans votre CRM ?">
              <b-form-radio-group v-model="prospect_morale.isOldProspect" :options="prospectBoolean" class="demo-inline-spacing" name="radio-inline-pm" />
            </b-form-group>

            <div v-if="prospect_morale.isOldProspect">
              <validation-provider #default="{ errors }" name="prospect" rules="required">
                <b-form-group :state="errors.length > 0 ? false : null" label="Recherche*" label-for="prospect">
                  <v-select v-model="prospect_morale.client" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="prospectOptionsCustomPM" class="flex-grow-1" input-id="nom" label="nom" placeholder="Recherche">
                    <template #option="{ avatar, denomination_commercial }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ denomination_commercial }} </span>
                    </template>

                    <template #selected-option="{ avatar, denomination_commercial }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ denomination_commercial }} </span>
                    </template>
                    <div slot="no-options">Aucune prospect disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div v-if="!prospect_morale.isOldProspect">
              <b-form-group label="Dénomination Commerciale*" label-for="denomination_commercial">
                <validation-provider #default="{ errors }" name="dénomination commerciale" rules="required|min:3">
                  <b-form-input id="denomination_commercial" trim placeholder="Dénomination Commerciale" v-model="prospect_morale.denomination_commercial" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Activité" label-for="activite">
                <!-- <validation-provider #default="{ errors }" name="activité" rules="required|min:3"> -->
                  <b-form-input id="activite" trim placeholder="Activité" v-model="prospect_morale.activite" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Siret" label-for="siret">
                <!-- <validation-provider #default="{ errors }" name="siret" rules="required|min:3"> -->
                  <b-form-input id="siret" trim placeholder="Siret" v-model="prospect_morale.siret" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Raison Sociale" label-for="raison_sociale">
                <validation-provider #default="{ errors }" name="raison sociale" rules="min:3">
                  <b-form-input id="raison_sociale" trim placeholder="Raison Sociale" v-model="prospect_morale.raison_sociale" :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Forme Juridique" label-for="forme_juridique">
                <!-- <validation-provider #default="{ errors }" name="forme juridique" rules="required"> -->
                  <b-form-select v-model="prospect_morale.forme_juridique">
                    <b-form-select-option v-for="(fg, index) in FormJuridique" :key="index" :value="fg.value">
                      {{ fg.text }}
                    </b-form-select-option>
                  </b-form-select>
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Téléphone de l’entreprise" label-for="prospect_morale_telephone">
                <validation-provider #default="{ errors }" name="téléphone de l’entreprise" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                  <b-form-input id="prospect_morale_telephone" v-model="prospect_morale.tel" trim placeholder="ex: 06 00 00 00 00" :state="errors.length > 0 ? false : null" v-mask="'## ## ## ## ##'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email de l’entreprise" label-for="prospect_morale_email">
                <validation-provider #default="{ errors }" name="email de l’entreprise " rules="email">
                  <b-form-input id="prospect_morale_email" v-model="prospect_morale.email" trim placeholder="Email de l’entreprise" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="N° et libellé de la voie" label-for="prospect_morale_libelle">
                <!-- <validation-provider #default="{ errors }" name="n° et libellé de la voie" rules="min:3"> -->
                  <b-form-input id="prospect_morale_libelle" trim placeholder="N° et libellé de la voie" v-model="prospect_morale.libelle" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Complément d’adresse" label-for="prospect_morale_complement_adresse">
                <!-- <validation-provider
                        #default="{ errors }"
                        name="prospect_morale_complement_adresse"
                        rules="required|min:3"
                        > -->
                <b-form-input id="prospect_morale_libelle" trim placeholder="Complément d’adresse (Bâtiment, Appartement…)" v-model="prospect_morale.complement_adresse" />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
              </b-form-group>

              <b-form-group label="Lieu-dit ou BP" label-for="prospect_morale_lieu_dit_ou_bp">
                <b-form-input id="prospect_morale_lieu_dit_ou_bp" trim placeholder="Lieu-dit ou BP" v-model="prospect_morale.lieu_dit_ou_bp" />
              </b-form-group>

              <b-form-group label="Code postal" label-for="prospect_morale_code_postal">
                <!-- <validation-provider #default="{ errors }" name="code postal" rules="min:3"> -->
                  <b-form-input id="prospect_morale_code_postal" trim placeholder="Code postal" @keyup="cpostalKeyupPM()" v-model="prospect_morale.code_postal" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Ville" label-for="prospect_morale_ville_id">
                <!-- <validation-provider #default="{ errors }" name="ville"> -->
                  <b-form-select v-model="prospect_morale.ville_id" id="prospect_morale_ville_id">
                    <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                    <b-form-select-option v-for="(val, value) in villesPM" :key="value" :value="val.id">{{ val.ville }}</b-form-select-option>
                  </b-form-select>
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>

              <b-form-group label="Site internet" label-for="prospect_morale_site_web">
                <b-form-input id="prospect_morale_site_web" trim placeholder="Site internet" v-model="prospect_morale.site_web" />
              </b-form-group>

              <b-form-group label="Date de création" label-for="prospect_morale_date_creation">
                <flat-pickr class="form-control" v-model="prospect_morale.date_creation" id="prospect_morale_date_creation" :config="config" placeholder="Date de création" />
              </b-form-group>

              <b-form-group label="Code NAF" label-for="prospect_morale_code_naf">
                <v-select v-model="prospect_morale.code_naf" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="libelle" :options="codeNafData" input-id="id">
                  <template #option="{ code, libelle }">
                    <span class="ml-50"> {{ code }} {{ libelle }}</span>
                  </template>

                  <template #selected-option="{ code }">
                    <span class="ml-50"> {{ code }} </span>
                  </template>
                  <div slot="no-options">Aucune correspondance.</div>
                </v-select>
              </b-form-group>

              <b-form-group label="IDCC" label-for="prospect_morale_idcc">
                <b-form-input id="prospect_morale_idcc" trim placeholder="IDCC" v-model="prospect_morale.idcc" />
              </b-form-group>

              <b-form-group label="Bénéficiaires effectifs" label-for="prospect_morale_beneficiairesEffectif">
                <b-form-input id="prospect_morale_beneficiairesEffectif" trim placeholder="Bénéficiaires effectifs" v-model="prospect_morale.beneficiairesEffectif" />
              </b-form-group>

              <b-form-group label="Chiffre d’affaires" label-for="prospect_morale_chiffreAffaire">
                <b-form-input id="prospect_morale_chiffreAffaire" trim placeholder="Chiffre d’affaires" v-model="prospect_morale.chiffreAffaire" />
              </b-form-group>

              <b-form-group label="Effectif" label-for="prospect_morale_effectif">
                <b-form-input id="prospect_morale_effectif" trim placeholder="Effectif" v-model="prospect_morale.effectif" />
              </b-form-group>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2" style="float: right">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-2" size="md" type="submit"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>

      <div v-else-if="type_connexite === 'connexite_personnelle'">
        <validation-observer ref="AddNewTierValidations">
          <b-form class="px-2" @submit.prevent="submitForm_create_prospect">
            <b-form-group label="Lien*" label-for="choose_lien">
              <validation-provider #default="{ errors }" name="Lien Familiale" rules="required">
                <b-form-select v-model="prospect_create.type_lien_prof" :options="typeLienFamilial" id="choose_lien" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="Souhaitez-vous en faire un prospect ?">
                    <b-form-radio-group
                        v-model="prospect_create.isVisible"
                        :options="prospectBoolean"
                        class="demo-inline-spacing"
                        name="radio-inline"
                    />
                </b-form-group> -->

            <b-form-group label="Existe-t'il dans votre CRM ?">
              <b-form-radio-group v-model="prospect_create.isOldProspect" :options="prospectBoolean" class="demo-inline-spacing" name="radio-inline-old" />
            </b-form-group>

            <div v-if="prospect_create.isOldProspect">
              <validation-provider #default="{ errors }" name="prospect" rules="required">
                <b-form-group :state="errors.length > 0 ? false : null" label="Recherche*" label-for="prospect">
                  <v-select v-model="prospect_create.client" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="prospectOptionsCustom" class="flex-grow-1" input-id="nom" label="nom" placeholder="Recherche">
                    <template #option="{ avatar, nom, prenom, categorie }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ nom }} {{ prenom }} </span>
                      <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                        {{ capitalizeFirstLetter(categorie) }}
                      </b-badge>
                    </template>

                    <template #selected-option="{ avatar, nom, prenom }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ nom }} {{ prenom }} </span>
                    </template>
                    <div slot="no-options">Aucune prospect disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div v-if="!prospect_create.isOldProspect">
              <b-form-group label="Statut*" label-for="prospect_create_statut">
                <validation-provider #default="{ errors }" name="statut" rules="required">
                  <b-form-select v-model="prospect_create.statut_fiche" :options="statutFiche" id="prospect_create_statut" :state="errors.length > 0 ? false : null">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Civilité*" label-for="civilite">
                <validation-provider #default="{ errors }" name="civilité" rules="required">
                  <b-form-select v-model="prospect_create.civilite" :options="civilite" id="civilite" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nom*" label-for="nom">
                <validation-provider #default="{ errors }" name="nom" rules="required">
                  <b-form-input id="nom" trim placeholder="Nom" v-model="prospect_create.nom" :state="errors.length > 0 ? false : null" class="text-uppercase" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Prénom" label-for="prenom">
                <validation-provider #default="{ errors }" name="prénom">
                  <b-form-input id="prenom" trim placeholder="Prénom" v-model="prospect_create.prenom" :state="errors.length > 0 ? false : null" class="text-capitalize" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Date de naissance" label-for="particulier_date_naissance">
                <flat-pickr class="form-control" v-model="prospect_create.date_naissance" id="particulier_date_naissance" :config="config" placeholder="Date de naissance" data-max-date="today" />
              </b-form-group>

              <b-form-group label="Situation familiale" label-for="particulier_situation_familiale">
                <b-form-select v-model="prospect_create.situation_familiale" id="particulier_situation_familiale">
                  <b-form-select-option value="null" disabled>-- Choisissez --</b-form-select-option>
                  <b-form-select-option value="CONCUBIN">Concubin(e)</b-form-select-option>
                  <b-form-select-option value="CELIBATAIRE">Célibataire</b-form-select-option>
                  <b-form-select-option value="DIVORCE">Divorcé(e)</b-form-select-option>
                  <b-form-select-option value="MARIE">Marié(e)</b-form-select-option>
                  <b-form-select-option value="SEPARE">Séparé(e)</b-form-select-option>
                  <b-form-select-option value="VEUF">Veuf(ve)</b-form-select-option>
                  <b-form-select-option value="PACSE">Pacsé(e)</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Enfants à charge" label-for="particulier_nbr_enfant">
                <b-form-input v-model="prospect_create.enfantCharge" id="particulier_nbr_enfant" trim placeholder="Enfants à charge" type="number" min="0" />
              </b-form-group>

              <b-form-group label="N° et libellé de la voie" label-for="adresse">
                <validation-provider #default="{ errors }" name="n° et libellé de la voie">
                  <b-form-input id="adresse" trim placeholder="N° et libellé de la voie" v-model="prospect_create.libelle" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Complément d'adresse" label-for="complement_adresse">
                <b-form-input id="complément_adresse" trim placeholder="Complément d'adresse" v-model="prospect_create.complement_adresse" />
              </b-form-group>

              <b-form-group label="Lieu-dit" label-for="lieu_dit">
                <b-form-input id="lieu_dit" trim placeholder="Lieu-dit" v-model="prospect_create.lieu_dit_ou_bp" />
              </b-form-group>

              <b-form-group label="Code postal" label-for="code_postal">
                <validation-provider #default="{ errors }" name="code postal" rules="digits:5">
                  <b-form-input id="code_postal" trim placeholder="Code postal" @keyup="cpostalKeyup()" v-model="prospect_create.code_postal" :state="errors.length > 0 ? false : null" v-mask="'#####'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Ville" label-for="ville">
                <validation-provider #default="{ errors }" name="ville">
                  <b-form-select v-model="prospect_create.ville" id="ville" :state="errors.length > 0 ? false : null">
                    <b-form-select-option :value="null" disabled>-- Choisissez --</b-form-select-option>
                    <b-form-select-option v-for="(val, value) in villesPP" :key="value" :value="val.id">{{ val.ville }}</b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Téléphone portable" label-for="particulier_telephone">
                <div class="d-flex bd-highlight">
                  <div class="w-25 bd-highlight">
                    <v-select v-model="prospect_create.indicatif_tel" :close-on-select="true" :clearable="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif" :reduce="indicatif => indicatif.indicatif" input-id="indicatif_telephone" label="indicatif" class="indicatif-chooser w-100">
                      <template #option="{ indicatif, code }">
                        <span> {{ `${code.toUpperCase()} ` + indicatif }}</span>
                      </template>
                      <template #selected-option="{ indicatif }">
                        <span> {{ indicatif }}</span>
                      </template>
                      <div slot="no-options">Aucune indicatif disponible.</div>
                    </v-select>
                  </div>
                  <div class="w-75 bd-highlight">
                    <validation-provider #default="{ errors }" name="téléphone" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                      <b-form-input id="particulier_telephone" v-model="prospect_create.tel" trim placeholder="ex: 06 00 00 00 00" :state="errors.length > 0 ? false : null" v-mask="'## ## ## ## ##'" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>

              <b-form-group label="Téléphone fixe" label-for="particulier_telephone_fixe">
                <div class="d-flex bd-highlight">
                  <div class="w-25 bd-highlight">
                    <v-select v-model="prospect_create.indicatif_tel_sec" :close-on-select="true" :clearable="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif" :reduce="indicatif => indicatif.indicatif" input-id="indicatif_telephone" label="indicatif" class="indicatif-chooser w-100">
                      <template #option="{ indicatif, code }">
                        <span> {{ `${code.toUpperCase()} ` + indicatif }}</span>
                      </template>
                      <template #selected-option="{ indicatif }">
                        <span> {{ indicatif }}</span>
                      </template>
                      <div slot="no-options">Aucune indicatif disponible.</div>
                    </v-select>
                  </div>
                  <div class="w-75 bd-highlight">
                    <validation-provider #default="{ errors }" name="téléphone fixe" :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                      <b-form-input id="particulier_telephone_fixe" v-model="prospect_create.tel_secondaire" trim placeholder="ex: 06 00 00 00 00" v-mask="'## ## ## ## ##'" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>

              <b-form-group label="Email" label-for="email">
                <validation-provider #default="{ errors }" name="email" rules="email">
                  <b-form-input id="email" trim placeholder="Email" v-model="prospect_create.email" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] ? 'Le champ email est obligatoire' : '' }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Activité professionnelle" label-for="activite">
                <b-form-select v-model="prospect_create.statut" id="activite">
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="(activite, value) in ACTIVITE_DATA" :key="'a' + value" :value="activite.value">
                    {{ activite.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Régime de sécurité sociale" label-for="particulier_regime_obligatoire">
                <b-form-select v-model="prospect_create.regime_obligatoire" id="particulier_regime_obligatoire">
                  <b-form-select-option value="null" disabled>-- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="(regime, value) in REGIMESOCIAL" :key="'r' + value" :value="regime.value">
                    {{ regime.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>

              <!-- utilisateur list -->
              <!-- <validation-provider #default="{ errors }" name="commercial" rules="required">
                    <b-form-group :state="errors.length > 0 ? false : null" label="Commercial*" label-for="commercial">
                        <v-select v-model="prospect_create.commercial" :close-on-select="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                                class="flex-grow-1"
                                input-id="commercial" label="nom" placeholder="Commercial"
                        >
                        <template #option="{ avatar, nom }">
                            <b-avatar :src="avatar" size="sm"/>
                            <span class="ml-50"> {{ nom }}</span>
                        </template>

                        <template #selected-option="{ avatar, nom }">
                            <b-avatar :src="avatar" class="border border-white" size="sm"/>
                            <span class="ml-50"> {{ nom }}</span>
                        </template>
                        <div slot="no-options">Aucune commercial disponible.</div>
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                            errors[0]
                        }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider> -->
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2" style="float: right">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-2" size="md" type="submit"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BSidebar, BAvatar, VBToggle, BCardText, BCard, BFormGroup, BFormInput, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormRadioGroup, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import Data from '@/shared/constants/prospect'
import Besoin from '@/shared/constants/risquebesoin'
import Activite from '@/shared/constants/activite'

export default {
  components: {
    BButton,
    BSidebar,
    BCardText,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Cleave,
    BAvatar,
    BFormRadioGroup,
    BBadge
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    commercialOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
      required: false
    },
    prospectOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    tiersData: {
      type: Object,
      default: {},
      required: true
    },
    codeNafData: {
      type: Array,
      default: [],
      required: true
    }
  },
  data() {
    return {
      required,
      villesPP: [],
      villesPM: [],
      villesPRO: [],
      pays: [],
      indicatif: [],
      adresseResult: [],
      personne_type: 'PARTICULIER',
      FormJuridique: Data['FORMEJURIDIQUE'],
      prospect_morale: {
        id: null,
        denomination_commercial: null,
        type_lien_prof: 'DIRIGEANT',
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        libelle: null,
        complement_adresse: null,
        lieu_dit_ou_bp: null,
        code_postal: null,
        ville_id: null,
        email: null,
        tel: null,
        site_web: null,
        date_creation: null,
        code_naf: null,
        idcc: null,
        beneficiairesEffectif: null,
        chiffreAffaire: null,
        effectif: null,
        statut_fiche: null,
        commercial: null,
        personne_morale: {
          id: null,
          type: null
        },
        isOldProspect: false,
        client: null,
        isVisible: false
      },
      prospect_create: {
        id: null,
        type_lien_prof: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        complement_adresse: null,
        ville: null,
        code_postal: null,
        tel: null,
        indicatif_tel: null,
        tel_secondaire: null,
        indicatif_tel_sec: null,
        email: null,
        type: null,
        lieu_dit_ou_bp: null,
        statut_fiche: null,
        date_naissance: null,
        situation_familiale: null,
        enfantCharge: null,
        regime_obligatoire: null,
        statut: null,
        commercial: null,
        isOldProspect: false,
        client: null,
        isVisible: false
      },
      renderComponent: true,
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      statutFiche: [
        { value: 'Prospect', text: 'Prospect' },
        { value: 'Client', text: 'Client' },
        { value: 'Ancien Client', text: 'Ancien Client' },
        { value: 'Aucun', text: 'Aucun' }
      ],
      typeConnexite: [
        { value: null, text: '-- Choisissez --' },
        { value: 'connexite_personnelle', text: 'Connexité Personnelle' },
        { value: 'connexite_entreprise', text: 'Connexité Entreprise' }
      ],
      typeLienFamilial: [
        { value: null, text: '-- Choisissez --' },
        { value: 'ASSOCIE', text: 'Associé' },
        { value: 'DAF', text: 'Daf ' },
        { value: 'DIRIGEANT', text: 'Dirigeant' },
        { value: 'SALARIE', text: 'Salarié' },
        // { value: 'EXPERT COMPTABLE', text: 'Expert comptable' },
        // { value: 'CLIENT', text: 'Client' },
        // { value: 'AMI', text: 'Ami' },
        // { value: 'CONJOINT', text: 'Conjoint' },
        // { value: 'ENFANT', text: 'Enfant' },
        // { value: 'PARENT', text: 'Parent' },
      ],
      typeLienProfessionnel: [
        { value: null, text: '-- Choisissez --' },
        { value: 'ASSOCIE', text: 'Associé' },
        { value: 'DAF', text: 'Daf ' },
        { value: 'DIRIGEANT', text: 'Dirigeant' }
        // { value: 'EXPERT COMPTABLE', text: 'Expert comptable' },
        // { value: 'SALARIE', text: 'Salarié' },
      ],
      typeLienEntreprise: [
        { value: null, text: '-- Choisissez --' },
        { value: 'ACTIONNAIRE_DE', text: 'Actionnaire de' },
        { value: 'DETENU_PAR', text: 'Détenu par' },
        { value: 'FILIALE_SOCIETE_MERE', text: 'Filiale / Société mère' },
        { value: 'FILIALE_DE', text: 'Filiale de' }
      ],
      status: [
        { value: null, text: '-- Choisissez --' },
        { value: 'PARTICULIER', text: 'Particulier' },
        { value: 'PROFESSIONNEL', text: 'Professionnel' },
        { value: 'ENTREPRISE', text: 'Entreprise' }
      ],
      civilite: [
        { value: null, text: '-- Choisissez --' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' }
      ],
      REGIMESOCIAL: Besoin['REGIMESOCIAL'],
      ACTIVITE_DATA: Activite['ACTIVITE'],
      isActive: false,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true
        }
      },
      type_connexite: 'connexite_personnelle',
      prospectBoolean: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
      ]
    }
  },
  computed: {
    prospectOptionsCustom() {
      let prospectArray = []
      const type = 'PERSONNE_PHYSIQUE'
      this.prospectOptions.map(obj => {
        if (obj.all_data_prospect.id != this.tiersData.id && type == obj.all_data_prospect.type) {
          prospectArray.push(obj.all_data_prospect)
        }
      })

      return this.$_.orderBy(prospectArray, ['categorie'], ['asc'])
    },
    prospectOptionsCustomPM() {
      let prospectArray = []
      const type = 'PERSONNE_MORALE'
      this.prospectOptions.map(obj => {
        if (obj.all_data_prospect.id != this.tiersData.id && type == obj.all_data_prospect.type) {
          prospectArray.push(obj.all_data_prospect)
        }
      })
      return prospectArray
    },
    categorieVariant() {
      const categorieColor = {
        /* eslint-disable key-spacing */
        PARTICULIER: 'light-danger',
        PROFESSIONNEL: 'light-warning',
        ENTREPRISE: 'light-success'
        /* eslint-enable key-spacing */
      }

      return categories => categorieColor[categories]
    }
  },
  watch: {
    'prospect_create.statut_fiche': {
      handler(val) {
        if (val !== null && val !== 'Aucun') this.prospect_create.isVisible = true
        else this.prospect_create.isVisible = false
      }
    },
    'prospect_create.isOldProspect': {
      handler() {
        this.prospect_create.statut_fiche = null
      }
    }
  },
  created() {
    this.ACTIVITE_DATA = this.ACTIVITE_DATA.filter(activite => activite.value !== 'INDEPENDANT')
    // this.$http.get(`tiers/getCodeNAf`)
    //   .then((r) => {
    //     this.codeNafData=r.data
    //   })
    //   .catch((e)=>{
    //     console.log(e);
    //   })
    this.$http.get('tiers/getPays').then(r => {
      this.pays = r.data
      let france = r.data.find(item => item.indicatif == '+33')
      let indexFrance = r.data.indexOf(france)
      //r.data.splice(indexFrance, 1, france);
      r.data.sort(function (x, y) {
        return x == france ? -1 : y == france ? 1 : 0
      })
      this.indicatif = r.data
    })
  },
  methods: {
    async cpostalKeyup() {
      let cp = this.prospect_create.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPP = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.prospect_create.ville == null) {
              this.prospect_create.ville = this.villesPP[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPP = []
          this.prospect_create.ville = null
        }
      } catch (e) {}
    },
    async cpostalKeyupPM() {
      let cp = this.prospect_morale.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPM = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.prospect_morale.ville == null) {
              this.prospect_morale.ville = this.villesPM[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPM = []
          this.prospect_morale.ville = null
        }
      } catch (e) {}
    },
    async cpostalKeyupPRO() {
      let cp = this.professionnel.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPRO = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.professionnel.ville == null) {
              this.professionnel.ville = this.villesPRO[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPRO = []
          this.professionnel.ville = null
        }
      } catch (e) {}
    },
    hideSideBare() {
      this.$refs.sideBarClientConnexite.hide()
    },
    submitForm_create_prospect() {
      this.$refs.AddNewTierValidations.validate().then(success => {
        if (success) {
          let data = {
            prospect: {
              id: this.tiersData.id,
              type: this.tiersData.type
            },
            connexite: this.prospect_create
          }
          let url_post = this.prospect_create.isOldProspect ? 'prospect/addLienConnexite' : 'prospect/saveConnexiteMorale'
          this.$http
            .post(url_post, data)
            .then(res => {
              if (res.data !== 'Error') {
                this.resetCreateProspect()
                this.hideSideBare()
                this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client créé',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: "Le client n'a pas été créer."
                  }
                })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    submitForm_create_personne_morale() {
      this.$refs.AddNewTierValidationsPM.validate().then(success => {
        if (success) {
          this.prospect_morale.personne_morale.id = this.tiersData.id
          this.prospect_morale.personne_morale.type = this.tiersData.type
          
          let url_post = this.prospect_morale.isOldProspect ? 'prospect/addLienConnexiteEntreprise' : 'prospect/addPersonneMoraleEntreprise'
          this.$http
            .post(url_post, this.prospect_morale)
            .then(res => {
              if (res.data !== 'Error') {
                this.resetCreateProspectMorale()
                this.hideSideBare()
                this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Client créé',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: "Le client n'a pas été créer."
                  }
                })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    resetCreateProspect() {
      this.type_connexite = 'connexite_personnelle'
      this.prospect_create = {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        complement_adresse: null,
        ville: null,
        code_postal: null,
        tel: null,
        tel_secondaire: null,
        email: null,
        type: null,
        lieu_dit_ou_bp: null,
        statut_fiche: null,
        date_naissance: null,
        situation_familiale: null,
        enfantCharge: null,
        regime_obligatoire: null,
        type_lien_prof: null,
        isVisible: false
      }
    },
    resetCreateProspectMorale() {
      this.type_connexite = 'connexite_personnelle'
      this.prospect_morale = {
        id: null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        libelle: null,
        complement_adresse: null,
        lieu_dit_ou_bp: null,
        code_postal: null,
        ville_id: null,
        site_web: null,
        email: null,
        tel: null,
        date_creation: null,
        code_naf: null,
        idcc: null,
        beneficiairesEffectif: null,
        chiffreAffaire: null,
        effectif: null,
        statut_fiche: null,
        personne_morale: {
          id: null,
          type: null
        },
        isVisible: false
      }
    },
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
