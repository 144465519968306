<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> Fiche : entreprise </span>
        <b-badge :variant="tiersData.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(tiersData.statut_fiche)">{{ tiersData.statut_fiche }}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
            @click="backToListing"
            id="revenir-liste-client"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12"> 
        <card-informations-entreprise :tiers-data="tiersData" :prospect-options="prospectOptions" :code-naf-data="codeNafOptions" @update-client-entreprise-returned="UpdateClientEntrepriseReturned" @go-to-another-fiche-client="goToAnotherFicheClient" @show-email="$emit('show-email', tiersData.email)" :is-boite-sync="isBoiteSync" />
      </b-col>
<!--      <b-col md="3" sm="12">-->
<!--        <b-card class="mb-2">-->
<!--          <b-card-text>-->
<!--            <div class="d-flex bd-highlight">-->
<!--              <div class="bd-highlight"><span class="h4 font-weight-bold mr-1">Salariés</span></div>-->
<!--              <div class="bd-highlight">-->
<!--                <b-badge variant="light-primary"> 0</b-badge>-->
<!--              </div>-->
<!--              <div class="ml-auto bd-highlight">-->
<!--                <feather-icon class="cursor-pointer text-primary" icon="PlusCircleIcon" size="26"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </b-card-text>-->
<!--        </b-card>-->
<!--      </b-col>-->
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <tabs-details-fiche-entreprise :prospect-chosen="prospectChosen" :denomination-commercial="tiersData.denominationCommercial" :tiers-data="tiersData" :refresh-data-activite="refreshDataActivite" @reset-refresh-data-activite="resetRefreshDataActivite" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import cardInformationsEntreprise from './CardInformationsEntreprise'
import tabsDetailsFicheEntreprise from './TabsDetailsFicheEntreprise'

export default {
  components: {
    // UI
    cardInformationsEntreprise,
    tabsDetailsFicheEntreprise,

    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText
  },
  directives: { Ripple },
  props: {
    prospectChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    },
    prospectOptions: {
      type: Array,
      default: [],
      required: true,
    },
    isBoiteSync: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      refreshDataActivite: false,
      showLoading: false,
      typeClient: [
        {
          1: 'PARTICULIER',
          2: 'PROFESSIONNEL',
          3: 'ENTREPRISE'
        },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }
      ],
      tiersData: {
        idPersonneMorale: null,
        type: null,
        denominationCommercial: null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        email: null,
        statut_fiche:null,
        isVisible: false,
        adresse: null,
        libelle: null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        origine:null,
        statut_gda:null,
        distributeur: {
          user_id: null
        },
        categorie: null
      },
      codeNafOptions:[]
    }
  },
  watch: {
    prospectChosen: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.id && val.type) {
          this.showLoading = true
          Promise.all([this.GetCodeNAF(),this.fetchDataTiers(val.id, val.type)]).then(() => {
            this.showLoading = false
          }).catch(()=>{
            this.showLoading = false
          });
        }
      }
    }
  },
  methods: {
    resetRefreshDataActivite(isRefresh) {
      this.refreshDataActivite = isRefresh
    },
    UpdateClientEntrepriseReturned(data, message){
      this.tiersData = data
      this.messageToast(message, 'Succès', 'success')
    },
    clearDataTier(){
      this.tiersData.idPersonneMorale = null
      this.tiersData.type = null
      this.tiersData.denominationCommercial = null
      this.tiersData.raisonSociale = null
      this.tiersData.formeJuridique = null
      this.tiersData.chiffreAffaire = null
      this.tiersData.dateCreation = null
      this.tiersData.SIRET = null
      this.tiersData.beneficiairesEffectif = null
      this.tiersData.activite = null
      this.tiersData.IDCC = null
      this.tiersData.effectif = null
      this.tiersData.idMoyenContact = null
      this.tiersData.telephone = null
      this.tiersData.email = null
      this.tiersData.adresse = null
      this.tiersData.statut_fiche = null
      this.tiersData.isVisible = false
      this.tiersData.libelle = null
      this.tiersData.complementAdresse = null
      this.tiersData.siteWeb = null
      this.tiersData.codePostal = null
      this.tiersData.villeId = null
      this.tiersData.ville = null
      this.tiersData.ieuDitOuBp = null
      this.tiersData.IDCodeNAF = null
      this.tiersData.CodeNAF = null
      this.tiersData.categorie = null
      this.tiersData.origine = null
      this.tiersData.statut_gda = null


    },
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
      this.clearDataTier()
    },
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    fetchDataTiers(id, type) {
      this.showLoading = true
      this.$http.get(`/prospect/fetchDataTiers/${id}/${type}`)
          .then(res => {
            if (res.data.success) {
              console.log(res.data.data)
              this.tiersData = res.data.data
              console.log(this.tiersData)
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            this.backToListing()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.log(err)
          })
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      let prospect = {
        id: prospectId,
        type: prospectType,
        categorie: prospectCategorie
      }
      this.refreshDataActivite = true
      this.$emit('show-prospect', false, prospect)
    },
    async GetCodeNAF(){
      await this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e)=>{
            console.log(e);
          })
    },
  }
}
</script>
