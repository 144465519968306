export  const is422 = function (err){

    return isErrorWithResponseAndStatus(err) && err.response.status===422
};
export  const is401 = function (err){

    return isErrorWithResponseAndStatus(err) && err.response.status===401
};

export  const is429 = function (err){

    return isErrorWithResponseAndStatus(err) && err.response.status===429
};
const isErrorWithResponseAndStatus = function (err){

    return err.response && err.response.status 
};