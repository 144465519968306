<template>
  <div>
    <b-row align-v="center" class="mb-2">
      <b-col md="8" sm="6" v-if="can('Import contract') || can('Update contract')">
        <actions-contrat :assureurs-options="assureursOptions" :clients-options="clientsOptions"
                         :commercial-options="commercialOptions" :current-user="currentUser"
                         :data-selected-for-edit="dataSelectedForEdit" :is-update.sync="isUpdate"
                         :risques-options="risquesOptions" :prospect-chosen="prospectChosen"
                         @is-update-function="isUpdateFunction" @record-contrat-returned="recordContratReturned" 
                         :client-id="joindre_client" :contrat-id="joindre_contrat" @record-document-courtier-returned ="refreshDocument"
                         :client-type="client_type_id" :commercial-id="tiersData.distributeur.user_id" :is-child="true" :is-portfeuille="isPortfeuille"
                         v-if="tiersData.statut_gda!='ROUGE' && tiersData.statut_gda!='ORANGE'"
        />
      </b-col>
      <b-col md="4" sm="6">
        <b-row align-v="center">
          <b-col cols="9" md="9">
          <b-form-group label="Statut" label-for="statut">
                <b-form-select v-model="selectedStatut" :options="statutOptions"
                />
            </b-form-group>
          </b-col>
          <b-col cols="3" md="3" class="mt-0">
                <b-button v-b-tooltip.hover.top="'Tout afficher'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mt-0 mt-md-1" variant="primary" @click="clearAllFilter">
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
              </b-col>
            </b-row>
            </b-col>
      </b-row>
    <b-card
            header-class="py-1 px-0" body-class="p-0"
    >
      <b-overlay :show="showLoading" no-wrap/>
      <!-- table -->
      <vue-good-table
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
          :rows="rows"
          :rtl="direction"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
          :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'lignes sélectionnées',
          clearSelectionText: 'Effacer la sélection',
          disableSelectInfo: false,
          selectAllByGroup: false
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'contratno'">
             <span v-if="tiersData.statut_gda!='ORANGE' && tiersData.statut_gda!='ROUGE'" @click="showContrat(props.row.id, props.row.source)">
               <a style="text-decoration: underline;">{{ props.row.police_no ? props.row.police_no : 'NC' }}</a>
              </span>
               <span v-else >
                 {{ props.row.police_no ? props.row.police_no : 'NC' }} 
                </span>
              </span>
          <span v-else-if="props.column.field === 'source'">
            <b-badge :variant="sourceVariant(props.row.source)">
              {{ capitalizeFirstLetter(props.row.source) }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'statut'">
              {{ changeText(props.row.statut) }}
          </span>
          <span v-else-if="props.column.field == 'contrable_nom'" @click="showProspect(true, props.row)">
            <a style="text-decoration: underline;">{{ props.row.contrable_nom }}</a>
          </span>
         <span class="d-flex justify-content-evenly" v-else-if="props.column.field == 'commission_recurrente'" >
                <span class="w-100  d-flex align-items-center justify-content-center bd-highlight">{{ props.row.commission_recurrente }}</span> 
                <span class="text-info w-50 d-flex align-items-start justify-content-center" v-if="props.row.previsionnel == '1'" style="background:#C5FAF7;border-radius: 180px;padding:6px; font-size: 15px;" >P</span>
              </span>
          <span v-else-if="props.column.field === 'actions' && tiersData.statut_gda!='ROUGE' && tiersData.statut_gda!='ORANGE'">
            <span>
              <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                <template v-slot:button-content>
                  <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                </template>
                <b-dropdown-item @click="showContrat(props.row.id, props.row.source)">
                  <feather-icon class="mr-50" icon="Edit2Icon" />
                  <span>Consulter la fiche devis</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="can('Update contract') && props.row.source === 'EXTERNE'"
                                 v-b-toggle.sidebar-contrat
                                     @click="editContrat(props.row)"
                >
                  <feather-icon class="mr-50" icon="Edit2Icon"/>
                  <span>Modifier</span>
                </b-dropdown-item>
               <b-dropdown-item
                    v-if="props.row.devis_avancement == 'TARIFICATION' && props.row.etude.source === 'INTERNE'"
                    @click="redirectprojet(props.row)"
                >
                  <feather-icon
                      class="mr-50"
                      icon="EditIcon"
                  />
                  <span>Finaliser</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-toggle.sidebar-importNewContrat @click="joindreFile(props.row.contrable_id,props.row.id,props.row.contrable_type)">
                  <feather-icon class="mr-50" icon="PaperclipIcon"/>
                  <span>Joindre</span>
                </b-dropdown-item>
                 <b-dropdown-item v-b-modal.modal_1 @click="attachCommercial(props.row)">
                            <feather-icon
                                class="mr-50"
                                icon="Link2Icon"
                            />
                            <span>Attacher à un gestionnaire</span>
                          </b-dropdown-item>
                <b-dropdown-item  @click="JoindreContrat(props.row.id)">
                  <feather-icon class="mr-50" icon="SearchIcon"/>
                  <span>Visualiser</span>
		            </b-dropdown-item>
                <b-dropdown-item v-if="can('Delete contract') && props.row.source =='EXTERNE'" @click="deleteContrat(props.row.id, props.row.vgt_id)">
                  <feather-icon class="mr-50" icon="TrashIcon"/>
                  <span>Supprimer</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="can('Delete contract') && props.row.source =='INTERNE' && props.row.statut !== 'ANNULER'" @click="annulerContrat(props.row.id, props.row.vgt_id)">
                  <feather-icon class="mr-50" icon="XCircleIcon"/>
                  <span>Annuler</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>

          <b-row class="mt-2 align-items-center">
            <b-col v-if="tiersData.statut_gda!='ROUGE' && tiersData.statut_gda!='ORANGE'">
              <b-input-group>
                <b-form-select v-model="selected" :options="options"/>
                <b-input-group-append>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                    <span>Valider </span>
                    <feather-icon class="mr-50" icon="ArrowRightIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                            last-number next-class="next-item" prev-class="prev-item"
                            @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
    </b-card>
    <b-modal
                id="modal_1"
                hide-footer
                centered
                title="Attacher à un gestionnaire"
              >
              <b-form
                    class="p-2"
                    @submit.prevent="commercialClientSave"
                  >
                <b-form-group label="Gestionnaire" label-for="commercial">
                   <b-form-select
                          v-model="attached_commercial"
                          id="commercial"
                        >
                    <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                    <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}</b-form-select-option>
                   </b-form-select>
                </b-form-group>
                <div class="d-flex mt-2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                        :disabled="attached_commercial == null || (clientsAttacher.length == 1 && clientsAttacher[0].actif_commercial == attached_commercial)"
                      >
                        Attacher
                      </b-button>
                </div>
              </b-form>
              </b-modal>
    <b-modal centered size="lg" id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter" >
             <autres-document :courtier-id="joindre_courtier" :contrat-id-doc="joindre_contrat_doc"/>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  VBToggle,
  VBTooltip,
  BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ActionsContrat from '@/views/portefeuilles/contrats/ActionsContrat'
import AutresDocument from '@/views/portefeuilles/contrats/AutresDocument'
export default {
  components: {
    ActionsContrat,
    AutresDocument,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BBadge,
    BForm,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormSelectOption,
    VBToggle,
    VBTooltip,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    prospectChosen: {
      type: Object,
      default: { id: null, type: null },
      required: true
    },
    tiersData: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      pageLength: 5,
      hideFooter:false,
      joindre_client:null,
      joindre_contrat:null,
      client_type_id:null,
      isPortfeuille:false,
      joindre_contrat_doc:null,
      joindre_courtier:null ,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      dir: false,
      isUpdate: false,

      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'autre',
          text: ' --- '
        }
        // { value: 'Modifier', text: 'Modifier' },
        // { value: 'Archiver', text: 'Archiver' },
        // { value: 'Supprimer', text: 'Supprimer' }
      ],
      optionsTypeClient: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: 'PARTICULIER',
          text: 'Particulier'
        },
        {
          value: 'PROFESSIONNEL',
          text: 'Professionnel'
        },
        {
          value: 'ENTREPRISE',
          text: 'Entreprise'
        }
      ],
      statutOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'EN_COURS',
          text: 'En cours'
        },
        {
          value: 'MISE_EN_DEMEURE',
          text: 'Mise en demeure'
        },
        {
          value: 'REDUIT',
          text: 'Réduit'
        },
        {
          value: 'RESILIE',
          text: 'Résilié'
        },
        {
          value: 'SANS_EFFET',
          text: 'Sans effet'
        },
        {
          value: 'SUSPENDU',
          text: 'Suspendu'
        },
        {
          value: 'TEMPORAIRE',
          text: 'Temporaire'
        },
        {
          value:'ANNULER',
          text:'Annulé'
        }
      ],
      // filter
      selectedStatut: null,
      selectedTypeClient: null,
      selectedAssureur: null,
      selectedRisque: null,
      selectedCommercial: null,

      selected: null,
      dataSelectedForEdit: {
        id: null,
        client: null,
        n_contrat: null,
        risque_id: null,
        assureur_id: null,
        date_effet: null,
        echeance_anniversaire: '',
        conteur:0,
        typeFractionnement: null,
        statut: null,
        modalite_paiement: null,
        commercial: null,
        budget: null,
        taux_commission: null,
        commission_recurrente: null,
        commission_unique_precompte: null,
        frais_courtage: null,
        previsionnel: 0,
        courtier_id: null,
        concurrence: 0,
        origineautre:null,
        origine:null,
        createur:null,
        intermediaire: null,
        gestionnaire:null
      },
      clientsOptions: [],
      commercialOptions: [],
      risquesOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      columns: [
        {
          label: 'N° contrat',
          field: 'contratno',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Risque',
          field: 'risque',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Client',
        //   field: 'contrable_nom',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'Assureur',
          field: 'assureur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Source',
          field: 'source',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'date_effet',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Prime TTC',
          field: 'prime',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Commission',
          field: 'commission_recurrente',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Gestionnaire',
          field: 'commercial_nom',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      source: [{
        1: 'EXTERNE',
        2: 'INTERNE',
      },
        {
          1: 'light-primary',
          2: 'light-warning'
        }],
      rows: [],
      rowsConst: [],
      contratAnnuler:[],
      searchTerm: '',
      showLoading: false,
      attached_commercial: null,
      clientsAttacher: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    // sourceVariant() {
    //   const statusColor = {
    //     /* eslint-disable key-spacing */
    //     EXTERNE: 'light-primary',
    //     INTERNE: 'light-warning',
    //     /* eslint-enable key-spacing */
    //   }
    //
    //   return status => statusColor[status]
    // },
  },
  // watch: {
  //   selectedTypeClient() {
  //     this.filterData()
  //   },
  //   selectedAssureur() {
  //     this.filterData()
  //   },
  //   selectedRisque() {
  //     this.filterData()
  //   },
  //   selectedCommercial() {
  //     this.filterData()
  //   }
  // },
  watch: {
    prospectChosen: {
      deep: true,
      immediate: true,
      handler(){
          this.fetchContratsList()
      }
    },
    selectedStatut(){
      this.filterData()
    }
  },
  mounted() {
    this.hideFooter=true
    this.fetchRisqueList()
    this.fetchAssureurList()
    this.fetchClientList()
    this.fetchDataCommercialByCourtier()
    // this.fetchContratsList()
  },
  methods: {
     commercialClientSave() {

      this.$http.post("contrat/attachementContrat/", { 'contrat_id': this.clientsAttacher[0].actif_contrat, 'user_id': this.attached_commercial })
        .then((res) => {

          if (res.data.success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Le client a été attaché avec un nouveau commercial`,
                  icon: "UserIcon",
                  variant: "success",
                },
              },
              {
                position: "top-right",
                timeout: 4000,
              }
            );
            this.$bvModal.hide('modal_1')
            this.fetchContratsList()

          }
        })
        .catch((err) => { });
    },
    attachCommercial(data) {
      this.clientsAttacher.splice(0);
      this.attached_commercial = data.user_id
      this.clientsAttacher.push({
        actif_contrat: data.id,
        actif_commercial: data.user_id
      })
      console.log(this.clientsAttacher)

    },
  showContrat(idContrat, typeContrat) {
      this.$router.push({
        name: 'contrats-list',
        params: {
          'id': idContrat,
          'type': typeContrat,
          'interfaceContrat': 'FicheContrat'
        }
      })
    },
  refreshDocument(data, message) {
    this.$emit('refersh-document')

  },
  joindreFile(client,contrat,clientType){
    this.joindre_client = client
    this.joindre_contrat = contrat
    console.log('clientType' ,clientType)
    this.client_type_id =clientType
    },
    JoindreContrat(contrat){
        this.joindre_contrat_doc=contrat
        if (this.joindre_contrat_doc) {
          this.$refs['modal-display-document'].show()
       }

      },
    // Methode additional
    isUpdateFunction(boolValue) {
      this.isUpdate = boolValue
    },
    changeText(statut) {
      switch (statut) {
        case 'EN_COURS':
          return 'En cours'
          // eslint-disable-next-line no-unreachable
          break
        case 'SUSPENDU':
          return 'Suspendu'
          // eslint-disable-next-line no-unreachable
          break
        case 'MISE_EN_DEMEURE':
          return 'Mise en demeure'
          // eslint-disable-next-line no-unreachable
          break
        case 'RESILIE':
          return 'Résilié'
          // eslint-disable-next-line no-unreachable
          break
        case 'REDUIT':
          return 'Réduit'
          // eslint-disable-next-line no-unreachable
          break
        case 'SANS_EFFET':
          return 'Sans effet'
          // eslint-disable-next-line no-unreachable
          break
        case 'TEMPORAIRE':
          return 'Temporaire'
          // eslint-disable-next-line no-unreachable
          break
        default:
          return null
          // eslint-disable-next-line no-unreachable
          break
      }
    },
    // fetch Data
    fetchRisqueList() {
      this.risquesOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/risques/fetchRisqueList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.risquesOptions.push({
                  value: el.id,
                  text: el.label
                })
              })
            }
          })
          .catch(err => {
            this.risquesOptions = [{
              value: null,
              text: '-- Choisissez --'
            }]
            console.error(err)
          })
    },
    fetchAssureurList() {
      this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur
                })
              })
            }
          })
          .catch(err => {
            this.assureursOptions = [{
              value: null,
              text: '-- Choisissez --'
            }]
            console.error(err)
          })
    },
    fetchClientList() {
      this.clientsOptions = []
      this.$http
          .post('/prospect/fetchClientList', { courtier_user_id: this.currentUser.courtier_user[0].id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                if (el.id === this.prospectChosen.id && el.type === this.prospectChosen.type) {
                  this.clientsOptions.push({
                    id: el.id,
                    type: el.type,
                    nom: el.nom,
                    categorie: el.categorie,
                    statutFiche: el.statutFiche,
                    commercial: el.commercial_id
                  })
                  return 0
                }
              })
            }
          })
          .catch(err => {
            this.clientsOptions = []
            console.error(err)
          })
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
   redirectprojet(value) {
      let etudeId = value.etude_id
      this.$store.dispatch('clearData')
      if (value.devis_avancement == 'TARIFICATION') {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: etudeId }
        })
      }
      if (value.devis_avancement == 'SOUSCRIPTION') {
        this.$router.push({
          name: 'projets-souscription',
          params: { id: etudeId }
        })
      }

  },
    // actions data
    editContrat(data) {
      this.dataSelectedForEdit.conteur = 0
      this.dataSelectedForEdit.id = data.id
      this.dataSelectedForEdit.client = {
        id: data.contrable_id,
        type: data.contrable_type,
        nom: data.contrable_nom,
        categorie: data.categorie,
        statutFiche: data.statutFiche
      }
      this.dataSelectedForEdit.n_contrat = data.police_no
      this.dataSelectedForEdit.risque_id = data.risque_id
      this.dataSelectedForEdit.assureur_id = data.assureur_id
      this.dataSelectedForEdit.date_effet = data.date_effet
      this.dataSelectedForEdit.typeFractionnement = data.fractionnement
      this.dataSelectedForEdit.statut = data.statut
      this.dataSelectedForEdit.modalite_paiement = data.mode_paiement
      this.dataSelectedForEdit.commercial = {
        id: data.commercial_id,
        type: data.commercial_type,
        nom: data.commercial_nom
      }
      this.dataSelectedForEdit.budget = data.prime
      this.dataSelectedForEdit.taux_commission = data.taux
      this.dataSelectedForEdit.commission_unique_precompte = data.commission_unique_precompte
      this.dataSelectedForEdit.commission_recurrente = data.commission_recurrente
      this.dataSelectedForEdit.frais_courtage = data.frais_courtage
      this.dataSelectedForEdit.previsionnel = data.previsionnel
      this.dataSelectedForEdit.courtier_id = data.courtier_id
      this.dataSelectedForEdit.concurrence = data.concurrence
      this.dataSelectedForEdit.echeance_anniversaire = data.echeance_anniversaire

      if (data.origine != null) {
        if (data.origine == 'Co_courtage' || data.origine == 'Indicateur_affaires' || data.origine == 'Weedo_market' || data.origine == 'Recommandation' || data.origine == 'Réseaux_sociaux') {
          this.dataSelectedForEdit.origine = data.origine
        } else {
          this.dataSelectedForEdit.origine = 'Autre'
          this.dataSelectedForEdit.origineautre = data.origine

        }
      }
      if (data.user === null) {
        this.dataSelectedForEdit.gestionnaire = null
      } else {
        this.dataSelectedForEdit.gestionnaire = {
          id: data.user.personne_physique.user_id,
          nom: `${data.user.personne_physique.prenom} ${data.user.personne_physique.nom} `,
          type: data.user.personne_physique.type,
        };
      }

      if(data.createur_contrat === null){
          this.dataSelectedForEdit.createur = null
      }else{
          this.dataSelectedForEdit.createur = {
          id: data.createur_contrat.user.personne_physique.user_id,
          nom: `${data.createur_contrat.user.personne_physique.prenom} ${data.createur_contrat.user.personne_physique.nom} `,
          type: data.createur_contrat.user.personne_physique.type,
        };
      }

      this.dataSelectedForEdit.intermediaire = [];
      if(data.courtiers_users_by_intermediares === null)
      {
        this.dataSelectedForEdit.intermediaire = null
      }else {
        if (data.courtiers_users_by_intermediares && data.courtiers_users_by_intermediares.length > 0) {
          data.courtiers_users_by_intermediares.forEach(courtier => {
            this.dataSelectedForEdit.intermediaire.push({
              id: courtier.user.personne_physique.user_id,
              nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
              type: courtier.user.personne_physique.type
            });
          });
        } 
      }
      
      this.isUpdate = true
    },
    recordContratReturned(contrat, message) {
      if (!this.isUpdate) {
        this.rows.push(contrat[0])
        this.rowsConst.push(contrat[0])
        this.messageToast(message, 'Succès', 'success')
        this.fetchClientList()
        this.$emit('refresh-data-contrats', this.rowsConst.length)

      } else {
        const indexContrat = this.rows.findIndex(el => el.id === contrat[0].id)
        this.rows.splice(indexContrat, 1)
        this.rows.push(contrat[0])

        const indexContratConst = this.rowsConst.findIndex(el => el.id === contrat[0].id)
        this.rowsConst.splice(indexContratConst, 1)
        this.rowsConst.push(contrat[0])
        let arrayOfContrats = []
        arrayOfContrats.push({
          idContrat: contrat[0].id,
          idEtude: '',
          idDevis: '',
          Numero: contrat[0].police_no ? contrat[0].police_no : 'NC',
          source: contrat[0].source,
          type: 'Contrat'
        })

        this.$emit('project-contrat-data-fetching', arrayOfContrats)
        // // eslint-disable-next-line prefer-destructuring
        // this.rows[indexContrat] = contrat[0]
        // // eslint-disable-next-line prefer-destructuring
        // this.rowsConst[indexContrat] = contrat[0]
        this.messageToast(message, 'Succès', 'success')
        this.fetchClientList()
        this.$emit('refresh-data-contrats', this.rowsConst.length)

      }
    },
    deleteContrat(contratId, index) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir supprimer le contrat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .delete(`/contrat/deleteContrat/${contratId}`)
                  .then(res => {
                    if (res.data.success) {
                      for (let i = 0; i < this.rows.length; i++) {
                        if (this.rows[i].id == contratId) {
                          this.rows.splice(i, 1)
                          break
                        }
                      }
                      for (let i = 0; i < this.rowsConst.length; i++) {
                        if (this.rowsConst[i].id == contratId) {
                          this.rowsConst.splice(i, 1)
                          break
                        }
                      }
                  this.$emit('refresh-data-contrats', this.rowsConst.length)
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
            }
          })
    },
    annulerContrat(contratId, index) {
      this.$swal({
        title: '',
        text: 'êtes vous certain de vouloir annuler ce contrat sur BUBBLE-IN ? Vous devrez faire les démarches auprès de la compagnie pour que l’annulation soit effective',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .put(`/contrat/annulerContrat/${contratId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.fetchContratsList()
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
            }
          })
    },

    // fetch Data
    fetchContratsList() {
      this.showLoading = true
      this.$http
          .post('/contrat/fetchDataContrats', {
            courtier_user_id: this.currentUser.courtier_user[0].id,
            user_id: this.currentUser.courtier_user[0].user_id,
            courtier_id: this.currentUser.courtier_user[0].courtier_id,
            clientId: this.prospectChosen.id,
            clientType:this.prospectChosen.type
          })
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data.filter(item => item.statut !== 'ANNULER')
              this.rowsConst = res.data.data.filter(item => item.statut !== 'ANNULER')
              this.contratAnnuler = res.data.data.filter(item => item.statut === 'ANNULER')
              let arrayOfContrats = []
              res.data.data.forEach(item => {
                arrayOfContrats.push({
                  idContrat: item.id,
                  idEtude: '',
                  idDevis: '',
                  Numero: item.police_no ? item.police_no : 'NC',
                  source: item.source,
                  type: 'Contrat'
                })
              })
              this.$emit('refresh-data-contrats', this.rowsConst.length)
              this.$emit('project-contrat-data-fetching', arrayOfContrats)
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            console.error(err)
          })
    },
    showProspect(is_projet, prospect) {
      this.$router.push({
        name: 'prospects-details',
        params: {
          'id': prospect.contrable_id,
          'type': prospect.type,
          'prospect': prospect
        }
      })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    clearAllFilter() {
      this.selectedStatut = null
    },
    filterData() {
      if (this.rows.length <= 0) this.rows = this.rowsConst
      if (this.selectedStatut) {
        if (this.selectedStatut === 'ANNULER') {
          this.rows = this.contratAnnuler;
        } else {
          this.rows = this.rowsConst.filter(item => item.statut === this.selectedStatut);
        }
      }

      if (!this.selectedStatut) this.rows = this.rowsConst
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
