const StatutJuridique = {
    STATUT:[
        {
            name: "Artisan",
            value: "ARTISANS"
        },
        {
            name: "Commerçant",
            value: "COMMERCANTS"
        },
        {
            name: "Conjoint collaborateur",
            value: "CONJOINTS_COLLABORATEURS"
        },
        {
            name: "Entrepreneur individuel ou auto entrepreneur",
            value: "ENTREPRENEURS_INDIVIDUELS_OU_AUTO_ENTREPRENEURS"
        },
        {
            name: "Gérant majoritaire",
            value: "GERANTS_MAJORITAIRES"
        },
        {
            name: "Gérant majoritaire avec conjoint",
            value: "GERANTS_MAJORITAIRES_AVEC_CONJOINT"
        },
        {
            name: "Mandataire social",
            value: "MANDATAIRES_SOCIAUX"
        },
        {
            name: "Profession libérale médicale",
            value: "PROFESSIONS_LIBERALES_MEDICALES"
        },
        {
            name: "Profession libérale non médicale",
            value: "PROFESSIONS_LIBERALES_NON_MEDICALES"
        },
        {
            name: "Autre TNS",
            value: "TNS_AUTRES"
        },
    ]
}
export default StatutJuridique;