<template>
    <b-sidebar id="sidebar-client-identite" ref="sideBarClientidentite" backdrop bg-variant="white" no-header right
               shadow sidebar-class="sidebar-lg"
    >
    
      <b-overlay :show="showLoading" no-wrap />
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            <span> Vérification de l'identité </span>
          </h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>

          <validation-observer ref="identitevalidation" >
              <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm"
                class="px-2"
              >
              <b-form-group class="mt-1 pt-1"  label-for="download">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button id="action" variant="primary" class="btn-icon"  v-b-tooltip.hover="'Télécharger le certificat de la recherche'" @click="displayDocument" >
                            <feather-icon icon="DownloadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Télécharger le certificat de la recherche" style="background-color: white;border: 0;"/>
                </b-input-group>
              </b-form-group>
              <b-form-group class="Commentaire mt-2" label="Commentaire*" label-for="Commentaire">
              <validation-provider #default="{ errors }" name="commentaire" rules="required">
                <quill-editor  id="Commentaire" :options="snowOption" v-model="composeData.commmantaire" :state="errors.length > 0 ? false : null">
                </quill-editor>
                  <div style="margin-top:120px">
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                    }}
                    </b-form-invalid-feedback>
                  </div>
                </validation-provider>
                
            </b-form-group>
            <b-form-group class="mt-1 pt-1"  label="Pièce d’identité* (CNI, passeport, titre de séjour, certificat de résidence, permis de conduire nouvelle version)" label-for="action">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button id="action" variant="outline-primary" class="btn-icon "  v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile" >
                      <b-form-file  v-model="composeData.file" type="file" id="file" class="d-none" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''"/>
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>             
            </b-form-group>
            <b-form-group class="mt-2" >
              <validation-provider #default="{ errors }" name="declaration" rules="required">
                <b-input-group>
                    <b-form-checkbox
                        id="checkbox_1"
                        v-model="composeData.status"
                        name="checkbox_1"
                        value="accepted"
                        unchecked-value="not_accepted"
                        >
                       <span style="margin-left: 8px;">Je m’engage sur l’honneur à avoir procédé aux formalités de vérification de l’identité de mon client ainsi que sa présence sur une liste de sanctions.</span> 
                    </b-form-checkbox>
                </b-input-group>
                <b-form-invalid-feedback :state="composeData.status == 'not_accepted' && submited ? false : null">
                  La case à cocher est obligatoire
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

     
  
                <div class=" w-100 d-flex mt-2">
                  <b-button 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="butt w-100 mb-2 " 
                    size="md"
                    type="submit"
                    @click="addidentitydanger"

                  >
                  Après vérification de l’identité de mon client/prospect, je confirme que ce dernier est visé par une sanction de gel des avoirs et que je ne peux entretenir une relation d’affaires avec lui                  
                </b-button>
                </div>
                <div class=" w-100 d-flex" >

                <b-button 
                    v-ripple.400="'rgba(255, 255, 255but , 0.15)'"
                    variant="success"
                    class="butt w-100 mb-2 " 
                    size="md"
                    type="submit"
                    @click="addidentitysuccess"

                  >
                  Après vérification de l’identité de mon client/prospect, je confirme que ce dernier n’est pas visé par une sanction de gel des avoirs et que je peux entretenir une relation d’affaires avec lui                  
                </b-button>
                
                </div>
                <div class=" w-100 d-flex" >

                <b-button 
                    v-ripple.400="'rgba(255, 255, 255but , 0.15)'"
                    class=" but w-100 mb-2" 
                    size="md"
                    type="submit"
                    @click="addidentityprimary"

                  >
                  Je souhaite modifier les informations relatives à mon client/prospect (son identité sera à nouveau contrôlée à la prochaine souscription)
                  </b-button>

              </div>
              </b-form>
            </validation-observer>
            <b-modal  id="modal-display-document" ref="modal-display-document"
               :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
               cancel-variant="outline-secondary"
               ok-title="Télécharger"
               @ok="downloadDocument(document.base64, document.nameToDownload)"
              >
        <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
          <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
          <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
        </form>
      </b-modal>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BOverlay,
    BButton,
    BForm,
    BFormGroup,
    BSidebar,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    VBToggle,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormRadioGroup,
    BAvatar,
    BFormCheckbox,
    VBTooltip
  } from 'bootstrap-vue'
  import { quillEditor } from 'vue-quill-editor'

  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import Data from '@/shared/constants/risquebesoin'
  import Cleave from 'vue-cleave-component'
  import flatPickr from 'vue-flatpickr-component'
  import Besoin from "@/shared/constants/risquebesoin"
  import moment from 'moment';
  import vSelect from 'vue-select'
  import Activite from "@/shared/constants/activite"
  
  export default {
    components: {
      // BTV
      BFormFile,
      BFormCheckbox,
      BInputGroupPrepend,
      BOverlay,
      quillEditor,
      BFormInvalidFeedback,
      BButton,
      BSidebar,
      BFormInput,
      BForm,
      BFormSelectOption,
      BFormSelect,
      BInputGroup,
      BFormGroup,
      BFormRadioGroup,
      // flatPickr and Cleave
      flatPickr,
      Cleave,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      vSelect,
      BAvatar
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip
    },
    props: {
      tiersData: {
        type: Object,
        default: {},
        required: true
      }
    },
    data() {
        return{
          currentUser: JSON.parse(localStorage.getItem('userData')),
          snowOption: {
            theme: 'snow',
            placeholder: 'Écrivez un commentaire ...',
            debug:false,
            readOnly:true,
          },
          composeData: {
              id : null,
              commmantaire: null,
              file: null,
              status:'not_accepted'
          },
          showLoading:false,
          submited:false,
          document: {
          base64: null,
          name: null,
          nameToDownload: null,
          extensionDocument: null
          },
        }
    },
    watch: {
      tiersData: {
        deep: true,
        handler() {
          console.log(this.tiersData)
          console.log(this.currentUser.id)
        }
      }
    },
    mounted(){
    },
    created(){
    },
    methods: {
        hideSideBare() {
            this.$refs.sideBarClientidentite.hide()
        },    
        cleardata() {
            this.composeData.file=null
            this.composeData.commmantaire=null
            this.$refs.identitevalidation.reset()
            this.composeData.status='not_accepted'


        },
        addengagmenet(color){
          this.submited=true
          if (this.composeData.status=='accepted'){
                const formData = new FormData()
                this.showLoading = true
                
                formData.append('statut',color)
                formData.append('client_id', this.tiersData.id)
                formData.append('type_client', this.tiersData.type)
                formData.append('commentaire', this.composeData.commmantaire)

                formData.append('id', this.composeData.id ? this.composeData.id : '')
                formData.append('nom',this.composeData.file)
                formData.append('note', '')
                formData.append('type_doc_id', '78')
                formData.append('type_doc_nom', 'Pièce d’identité')
                formData.append('file', this.composeData.file)

                formData.append('courtier_id', this.currentUser.courtier_user[0].courtier_id)
                formData.append('type_tiers', this.tiersData.type)
                formData.append('tiers', this.tiersData.id)
                formData.append('isTransformerDataReturned', '1')

                const config = { headers: { 'Content-Type': 'multipart/form-data' } }
                console.log('1')
                this.$http
                .post('/gda/changeStatutClient',formData, config)
                .then(res => {
                  if (res.data.success) {
                    this.showLoading = false
                    this.tiersData.statut_gda=color
                    this.$emit('update-client-pro-returned', this.tiersData, "Le client a été modifié avec succès")
                    this.hideSideBare()
                    this.cleardata() 
                    this.messageToast('La vérification de identité a passe avec succées', 'Succès', 'success')
                  }
                })
                .catch(err => {
                  this.showLoading = false
                  this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
                  console.error(err)
                })
              }
        },
        addidentitydanger(){
          this.submited=true
            this.$refs.identitevalidation.validate()
          .then(success => {
            if (success) {
              this.addengagmenet('ROUGE')
            }   
          })
        },
        addidentitysuccess(){
          this.submited=true
            this.$refs.identitevalidation.validate()
          .then(success => {
            if (success) {
              this.addengagmenet('VERT')
            }
          })

        },
        addidentityprimary(){
          this.submited=true
            this.$refs.identitevalidation.validate()
          .then(success => {
            if (success) {
              this.addengagmenet('GRIS')
            }  
          })

        },
        openToSelectFile: () => {
          document.getElementById('file').click()
        },
        clearDocumentDisplay() {
        this.document.base64 = null
        this.document.name = null
        this.document.nameToDownload = null
        this.document.extensionDocument = null
      },
      displayDocument() {
        this.$http
          .get(`/document/generate_base64_for_certificate_document/${this.tiersData.id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
                this.downloadDocument(res.data.data.base64, res.data.data.nameToDownload)
      
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', "danger")
            }
          })
          .catch(err => {
            this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            // this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
          })
      },
      downloadDocument(base64, name) {
        if (base64) {
          const a = document.createElement('a')
          a.href = base64
          a.download = name+".pdf"
          a.click()
          this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
        } else {
          this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
        }
      },
    }
  }
  </script>
  <style scoped>
  ::-webkit-input-placeholder { /* WebKit browsers */
      text-transform: none;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      text-transform: none;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
      text-transform: none;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
      text-transform: none;
  }
  ::placeholder { /* Recent browsers */
      text-transform: none;
  }

  .but{
    text-transform: uppercase;
    height: 120px;
    border-color:#4D25BC !important;
    background-color: #4D25BC !important;
  }
  .butt{
    height: 120px;

  }
  #Commentaire{
    height: 150px;
  }
  

  </style>