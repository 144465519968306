<template>
  <div>
   
    <b-sidebar id="sidebar-importNewReclamtion" bg-variant="white" sidebar-class="sidebar-lg"   ref="importNewReclamtion" shadow backdrop no-header right>
      <template>
        <b-overlay
                  :show="showLoading"
                  no-wrap
              />
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Importer un document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideSideBare()" />
        </div>
        <validation-observer ref="reclamtionform">
         <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

            <b-form-group label="Nom de document*" class="mt-2" label-for="nomd">
              <validation-provider #default="{ errors }" name="nom de document" rules="required">
               
                  <b-form-input v-model="composeData.nom" id="nomd"  placeholder="Nom de document" :state="errors.length > 0 ? false : null" />

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

      
                  <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'"  @click="openToSelectFile" >
                      <b-form-file type="file" id="filedocumentreclamtion" class="d-none" v-model="composeData.filee" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.filee ? composeData.filee.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
              @click ='addDocument()'
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BInputGroup,
  BSidebar,
  VBToggle,
  BFormFile,
  VBTooltip,
  BFormRadioGroup,
  BInputGroupPrepend,
  BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import documentService from '@/shared/services/documentService'

export default {
  components: {
    ToastificationContentVue,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BOverlay,
    BInputGroup,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BBadge,
    Cleave,
    vSelect,
    BFormSelect,
    flatPickr,
    BAvatar,
    VBTooltip,
    BFormTextarea,
    BFormSelectOption,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
     BInputGroupPrepend
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    reclamation: {
      type: Object,
      default: null,
      required: false,
            
    },     
  },
  data() {
    return {
      required,
      showLoading:false,
      currentUser: JSON.parse(localStorage.getItem("userData")),
      optionsType:[],
      famille: null,
      isUpdateAction: false,  
      composeData: {
        id: null,
        nom: null,
        description: '',
        idFamille: null,
        idType: null,
        nameType: null,
        file: null,
        filee :null,
        courtier_id: null,
      },   
    }
  },
  watch: {
    reclamation:{
      handler(val){
        console.log(val)

      }
    }

  },

  created() {
    

  },
  methods: {
    hideSideBare() {
      this.$refs.importNewReclamtion.hide()
      this.cleardata()
    },
    openToSelectFile: () => {
      document.getElementById('filedocumentreclamtion').click()
    },
    addDocument(){
      console.log(this.reclamation)
      this.$refs.reclamtionform.validate().then(success => {
        if (success) {
          this.showLoading = true
          const formData = new FormData()
          // Append the rest of your form data
          formData.append('id', this.composeData.id ? this.composeData.id : '')
          formData.append('nom', this.composeData.nom && this.composeData.nom != null && this.composeData.nom != '' && this.composeData.nom != 'null' ? this.composeData.nom : this.composeData.file.name.substring(0, this.composeData.file.name.lastIndexOf(".")))
          formData.append('note', this.composeData.description ? this.composeData.description : '')
          formData.append('type_interface', 'reclamation')

          formData.append('type_doc_id','85')
          formData.append('type_doc_nom', 'Autre')
          formData.append('file', this.composeData.filee)
          formData.append('reclamation_id', this.reclamation.id)
          formData.append('courtier_id', this.currentUser.courtier_user[0].courtier_id)
          if(this.reclamation.reclamation_client != '') {
            formData.append('type_tiers', this.reclamation.reclamation_client.type)
            formData.append('tiers', this.reclamation.reclamation_client.id)
          }
          
          formData.append('isTransformerDataReturned', '1')

          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/createNewDocumentTier', formData, config)
            .then(res => {
              if (res.data.success) {
                this.hideSideBare()
                this.$emit('fetch-document')
                this.showLoading = false

                this.messageToast(res.data.message, "Succès", "success");
              } else {
                this.showLoading = false
                this.hideSideBare()
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.hideSideBare()
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.error(err)
            })

        }
      })

    },
    cleardata(){
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = null
      this.composeData.idFamille = null
      this.composeData.nameType = null
      this.composeData.filee = null
      this.composeData.courtier_id = null
      this.$refs.reclamtionform.reset()

    }


  
    
   
  
 
 
  },
 
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
