
<template>
  <div>
    <app-collapse accordion type="margin" >
      <app-collapse-item title="Entreprise">
            <b-row v-if="entreprise.denomination_commercial">
                <b-col cols="12" >
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder"
                                >Dénomination Commerciale :
                            </span>
                            </th>
                            <td class="pb-50 text-capitalize">
                            {{
                                $_.get(
                                entreprise,
                                "denomination_commercial",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Raison Sociale :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "raison_sociale",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Activité :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "activite",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Date de création :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "date_creation",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Siret :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "siret",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Forme Juridique :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                getFormeJuridique
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Site internet :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "site_web",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Email :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "email",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Téléphone :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(
                                entreprise,
                                "tel",
                                ""
                                )
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Code Naf :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(entreprise, "code_naf.code", "") +
                                " " +
                                $_.get(entreprise, "code_naf.libelle", "") 
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">IDCC :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(entreprise, "idcc", "")
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Bénéficiaires effectifs :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(entreprise, "beneficiairesEffectif", "")
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Chiffre d’affaires :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(entreprise, "chiffreAffaire", "") 
                            }}
                            </td>
                        </tr>
                        <tr>
                            <th class="pb-50">
                            <span class="font-weight-bolder">Effectif :</span>
                            </th>
                            <td class="pb-50 text-lowercase">
                            {{
                                $_.get(entreprise, "effectif", "") 
                            }}
                            </td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {  BFormRadioGroup, BFormRadio, BFormGroup,BCol,BRow} from 'bootstrap-vue'
import Data from "@/shared/constants/prospect";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow
  },
  props: {
    hasEntreprise: {
      type: Boolean,
      required: false,
      default: false
    },
    tiersData: {
      type: Object,
      default: {},
      required: true
    }
  },
  computed:{
    getFormeJuridique: function () {
    let findEntreprise = this.FormJuridique.find(obj => {
           return obj.value === this.entreprise.forme_juridique;
        }); 
        return findEntreprise ? findEntreprise.text : ''
    }
  },
  data() {
    return {
      showLoading: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      villeOptions: [],
      codeNafOptions: [],
      isInvalideMailandPhone:false,
      entreprise:{
        id:null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        site_web:null,
        email:null,
        tel:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
      }
    }
  },
  watch: {
    tiersData: {
      deep: true,
      handler() {
        this.affectedDataTierToComposeData()
      }
    },
    'professionnel.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  mounted(){
    this.GetCodeNAF()
  },
  methods: {
    GetCodeNAF(){
      this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e)=>{
            console.log(e);
          })
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    affectedDataTierToComposeData() {
    let findEntreprise = this.tiersData.prospect.find(obj => {
       return obj.lien_professionnel === 'DIRIGEANT';
    });
    if(findEntreprise){
        this.entreprise.id = findEntreprise.lienable.id
        this.entreprise.siret = findEntreprise.lienable.siret
        this.entreprise.forme_juridique = findEntreprise.lienable.forme_juridique
        this.entreprise.activite = findEntreprise.lienable.activite
        this.entreprise.denomination_commercial = findEntreprise.lienable.denomination_commercial
        this.entreprise.raison_sociale = findEntreprise.lienable.raison_sociale
        this.entreprise.date_creation = findEntreprise.lienable.date_creation
        this.entreprise.code_naf = findEntreprise.lienable.code_naf
        this.entreprise.idcc = findEntreprise.lienable.idcc
        this.entreprise.beneficiairesEffectif = findEntreprise.lienable.beneficiairesEffectif
        this.entreprise.chiffreAffaire = findEntreprise.lienable.chiffreAffaire
        this.entreprise.effectif = findEntreprise.lienable.effectif
        this.entreprise.site_web = findEntreprise.lienable.moyen_contact.site_web
        this.entreprise.email = findEntreprise.lienable.moyen_contact.email
        this.entreprise.tel = findEntreprise.lienable.moyen_contact.tel
        // this.entreprise.id = this.findEntreprise.lienable.id
        // this.entreprise.id = this.findEntreprise.lienable.id
        // this.entreprise.id = this.findEntreprise.lienable.id
    }
    //   this.entreprise.siret = this.tiersData.entreprise
    },
    submitForm_create_personne_morale_pro() {
      if (!this.isInvalideMailandPhone) {
          this.$refs.AddNewTierValidationsPRO.validate().then((success) => {
            if (success) {
              this.$http.post(`prospect/updatePersonneMoralePro`,this.professionnel)
              .then(res => {
                if (res.data !== 'Error') {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Client créé`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Le client a été créé avec succès.',
                    },
                  })
                  this.$emit('reloadTiersData')
                  this.isActive=false
                  this.resetCreateProspectPro()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Client créé`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: 'Le client  n\'a pas été créer.',
                    },
                  })
                }
              })
              .catch(err => {
                console.log(err)
              })
            }
          });
      }
    },
    hideSideBare() {
      this.$refs.sideBarClientEntreprise.hide()
    },
    updateTiersEntreprise() {
      this.$refs.clientEntrepriseComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              this.$http
                  .post('/prospect/updateTiersEntreprise', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
                      this.hideSideBare()
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    },
    deleteEntreprise(id){

    },
  }
}
</script>
