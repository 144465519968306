const ReclamationOptions = {
    CANALS:[
        {
            value: null,
            text: 'Choisissez'
        },
        {
            value: 'COURRIER',
            text: 'Courrier'
        },
        {
            value: 'MAIL',
            text: 'Mail'
        },
        {
            value: 'ORAL',
            text: 'Oral'
        },
        {
            value: 'TELEPHONE',
            text: 'Téléphone'
        },
        {
            value: 'AUTRE',
            text: 'Autre'
        }
    ],
    DECLARANTS: [
        {
            value: null,
            text: 'Choisissez'
        },
        {
            value: 'ACPR',
            text: 'ACPR'
        },
        {
            value: 'ASSOCIATION',
            text: 'Association de consommateurs'
        },
        {
            value: 'AVOCAT',
            text: 'Avocat'
        },
        {
            value: 'CNIL',
            text: 'CNIL'
        },
        {
            value: 'DDPP',
            text: 'DDPP'
        },
        {
            value: 'MANDATAIRE',
            text: 'Mandataire'
        },
        {
            value: 'RECLAMANT',
            text: 'Réclamant'
        },
    ],
    STATUS: [{
        value: null,
        text: 'Choisissez'
    },{
        text: 'Ancient client',
        value: 'ANCIENT_CLIENT'
    }, {
        text: 'Ayant-droit',
        value: 'AYANT_DROIT'
    }, {
        text: 'Bénéficiaire',
        value: 'BENEFICIAIRE'
    }, {
        text: 'Client',
        value: 'CLIENT'
    }, {
        text: 'Prospect',
        value: 'PROSPECT'
    }, {
        text: 'Tiers',
        value: 'TIERS'
    }],
    PROSPECTBOOLEAN: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
    ],
    MOTIFS: [
        {
            value: null,
            text: 'Choisissez'
        },
        {
            label: 'Souscription',
            options: [
                {text: 'Démarchage', value: 'DEMARCHAGE' },
                {text: 'Engagement contesté', value: 'ENGAGEMENT_CONTESTE' },
                {text: 'Documents Contractuels', value: 'DOCUMENTS_CONTRACTUELS_SOUSCRIPTION' },
                {text: 'Abus de Faiblesse', value: 'ABUS_DE_FAIBLESSE' },
                {text: '1er contrat non résilié', value: '1ER_CONTRAT_NON_RESILIE' },
                {text: 'Signature du contrat', value: 'SIGNATURE_DU_CONTRAT' },
            ]
        },
        {
            label: 'Gestion du contrat',
            options: [
                {text: 'Délai de traitement', value: 'DELAI_DE_TRAITEMENT'  },
                {text: 'Télétransmission', value: 'TELETRANSMISSION'  },
                {text: 'Carte Tiers Payant', value: 'CARTE_TIERS_PAYANT'  },
                {text: 'Espace client', value: 'ESPACE_CLIENT'  },
                {text: 'Documents contractuels', value: 'DOCUMENTS_CONTRACTUELS_CONTRAT'  },
                {text: 'Mécontentement général', value: 'MECONTENTEMENT_GENERAL_CONTRAT'  },
            ]
        },
        {
            label: 'Indemnisation/Prestation',
            options: [
                {text: 'Garantie', value: 'GARANTIE' },
                {text: 'Prestations', value: 'PRESTATIONS' },
                {text: 'Prise en charge', value: 'PRISE_EN_CHARGE' },
                {text: 'Liquidation Professionnel de Santé', value: 'LIQUIDATION_PROFESSIONNEL_DE_SANTE' },
                {text: 'Mécontentement général', value: 'MECONTENTEMENT_GENERAL_PRESTATION' },
            ]
        },
        {
            label: 'Tarification',
            options: [
                {text: 'Cotisations', value: 'COTISATIONS'  },
                {text: 'Règlement cotisation', value: 'REGLEMENT_COTISATION' },
                {text: 'Tarif', value: 'TARIF'  },
            ]
        },
        {
            label: 'Fin du contrat',
            options: [
                {text: 'Résiliation', value: 'RESILIATION' },
                {text: 'Renonciation', value: 'RENONCIATION' },
                {text: 'Résiliation Décès', value: 'RESILIATION_DECES' },
            ]
        }
    ],
    SERVICES: [
        {value: null, text: 'Choisissez'},
        {value: 'ASSUREUR', text: 'Assureur'},
        {value: 'CABINET', text: 'Cabinet'},
        {value: 'COURTIER_DU_CABINET', text: 'Courtier du cabinet'},
        {value: 'PARTENAIRE', text: 'Partenaire'},
        {value: 'AUTRE', text: 'Autre'},
    ],
    NIVEAUX: [
        {value: null, text: 'Choisissez'},
        {value: '1', text: '1'},
        {value: '2', text: '2'},
        {value: '2S', text: '2S'},
        {value: '3', text: '3'},
    ],
    ISSUES: [
        {value: null, text: 'Choisissez'},
        {value: 'DEFAVORABLE', text: 'Défavorable'},
        {value: 'DEFENSE_DE_PORTEFEUILLE', text: 'Défense de portefeuille'},
        {value: 'FAVORABLE', text: 'Favorable'},
        {value: 'PARTIELLE', text: 'Partielle'},
    ]
}
export default ReclamationOptions;