<template>
  <div>
    <!-- table -->
    <b-overlay :show="showLoading" no-wrap/>
    <vue-good-table :columns="columns"
                    :pagination-options="{
                      enabled: true,
                      mode: 'records',
                      perPage: pageLength,
                      rowsPerPageLabel: '',
                      dropdownAllowAll: false,
                      perPageDropdownEnabled: false,
                      nextLabel: 'Suivant',
                      prevLabel: 'Précédent',
                      ofLabel: 'de',
                      infoFn: params => ``
                    }"
                    :rows="rows"
                    :rtl="direction"
                    :select-options="{
                      enabled: false,
                      selectOnCheckboxOnly: true,
                      selectionInfoClass: 'custom-class',
                      selectionText: 'lignes sélectionnées',
                      clearSelectionText: 'Effacer la sélection',
                      disableSelectInfo: false,
                      selectAllByGroup: false
                        }"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: {field: 'time', type: 'desc'}
                    }"
                    style-class="vgt-table condensed"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'receiver'">
          <span v-if="props.row.to">

              <div v-if="props.row.to.length > 0" class="m-0 d-flex bd-highlight">
                <div class="py-2 flex-shrink-1 bd-highlight">
                  <b-badge class="mr-1" variant="light-primary">À : </b-badge>
                </div>
                <div class="py-2 px-1 w-100 bd-highlight">
                   <span v-for="(to, index) in props.row.to" :key="index">
                    {{ to.email }} <span v-if="index + 1 < props.row.to.length"><br> </span>
                   </span>
                </div>
              </div>


              <div v-if="props.row.cc && props.row.cc.length > 0" class="m-0 d-flex bd-highlight">
                <div class="py-2 flex-shrink-1 bd-highlight">
                  <b-badge class="mr-1" variant="light-Success">Cc : </b-badge>
                </div>
                <div class="py-2 px-1 w-100 bd-highlight">
                   <span v-for="(cc, index) in props.row.cc" :key="index">
                {{ cc.email }} <span v-if="index < props.row.cc.length"><br> </span>
              </span>
                </div>
              </div>

            </span>
          <span v-else>

              <div v-if="props.row.extendedProps.guests.length > 0" class="m-0 d-flex bd-highlight">
                <div class="py-2 flex-shrink-1 bd-highlight">
                  <b-badge class="mr-1" variant="light-primary">À : </b-badge>
                </div>
                <div class="py-2 px-1 w-100 bd-highlight">
                  <span v-for="(to, index) in props.row.extendedProps.guests" :key="index">
                {{ to.email }} <span v-if="index + 1 < props.row.extendedProps.guests.length"><br> </span>
                  </span>
                </div>
              </div>

          </span>
        </span>
        <span v-else-if="props.column.field === 'subject'"
              @click="props.row.to ? visualiserEmail(props.row, false) : visualiserEmail(props.row, true)"
        >
            <a style="text-decoration: underline;"> {{ props.row.to ? props.row.subject : props.row.title }} </a>
        </span>
        <span v-else-if="props.column.field === 'time'">
               {{
            props.row.to ? changeFormatDateTimeShort(props.row.time) : props.row.allDay ? changeFormatDateShort(props.row.time) : changeFormatDateTimeShort(props.row.time)
          }}
        </span>
        <span v-else-if="props.column.field === 'end'">
               {{ props.row.to ? '' : props.row.allDay ? '' : changeFormatDateTimeShort(props.row.end) }}
        </span>
        <span v-else-if="props.column.field === 'type'">
          <b-badge :variant="sourceVariant(props.row.to ? 'Message' : props.row.extendedProps.calendar)" class="mr-1">
            {{ props.row.to ? 'Message' : props.row.extendedProps.calendar }}
          </b-badge>
          <span v-if="!props.row.to && props.row.extendedProps.description !== ''">
            <b-button
                v-if="props.row.extendedProps.description"
                :id="'popover-activite-client-' + clientType + '-' + clientId + '-' + props.row.vgt_id + '-' + props.row.id + '-' + props.row.extendedProps.calendar"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                size="sm"
                variant="flat-primary"
            >
              <feather-icon icon="EyeIcon"/>
            </b-button>
            <b-popover
                :target="'popover-activite-client-' + clientType + '-' + clientId + '-' + props.row.vgt_id + '-' + props.row.id + '-' + props.row.extendedProps.calendar"
                placement="left" triggers="hover"
            >
              <template #title>Message</template>

            <div class="message-editor">
              <quill-editor id="popover-activite-description" v-model="props.row.extendedProps.description"
                            :options="editorOption"
                            disabled
              />
            </div>
            </b-popover>
          </span>
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <b-dropdown dropright no-caret toggle-class="text-decoration-none p-0" variant="link">
              <template v-slot:button-content>
                  <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
              </template>
              <b-dropdown-item v-if="props.row.to" @click="visualiserEmail(props.row, false)">
                  <feather-icon class="mr-50" icon="SearchIcon"/>
                  <span>Afficher sur messagerie</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!props.row.to" @click="visualiserEmail(props.row, true)">
                  <feather-icon class="mr-50" icon="SearchIcon"/>
                  <span>Afficher sur mon agenda</span>
              </b-dropdown-item>
            <!--            <b-dropdown-item v-if="!props.row.to" @click="visualiserEmail(props.row, true)">
                            <feather-icon class="mr-50" icon="RefreshCwIcon"/>
                            <span>Modifier</span>
                        </b-dropdown-item>-->
            <b-dropdown-divider v-if="!props.row.to"/>
            <b-dropdown-item v-if="!props.row.to" variant="danger" @click="removeEvent(props.row, true)">
                <feather-icon class="mr-50" icon="XIcon"/>
                <span>Annuler</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col md="6" lg="5" xxl="3">
            <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> de {{ props.total }} entrées </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                          last-number next-class="next-item" prev-class="prev-item"
                          @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BPopover,
  BRow,
  VBPopover,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BDropdownDivider,
    quillEditor,
    VueGoodTable,
    BOverlay,
    BImg,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    clientId: {
      type: Number,
      default: null,
      required: true
    },
    clientType: {
      type: String,
      default: null,
      required: true
    },
    refreshDataActivite: {
      type: Boolean,
      default: false,
      required: false
    },
    itemChosen: {
      type: Object,
      default: null,
      required: false
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        if (this.clientId && this.clientType && !this.refreshDataActivite) {
          this.fetchMessageDataAndCalendarByClient(this.clientId, this.clientType)
        }
      }
    },
    clientType: {
      handler() {
        if (this.clientId && this.clientType && !this.refreshDataActivite) {
          this.fetchMessageDataAndCalendarByClient(this.clientId, this.clientType)
        }
      }
    },
    refreshDataActivite: {
      handler(val) {
        if (val && this.clientId && this.clientType) {
          this.fetchMessageDataAndCalendarByClient(this.clientId, this.clientType)
          this.$emit('reset-refresh-data-activite', false)
        }
      }
    }
  },
  data() {
    return {
      editorOption: {
        theme: 'bubble',
        placeholder: '',
      },
      currentUser: JSON.parse(localStorage.getItem('userData')),
      showLoading: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Expéditeur',
          field: 'from.email',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-left m-0 p-1 align-middle h6'
        },
        {
          label: 'Destinataire (s)',
          field: 'receiver',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-left m-0 p-1 align-middle h6'
        },
        {
          label: 'Objet',
          field: 'subject',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-left m-0 p-1 align-middle h6'
        },
        {
          label: 'Date',
          field: 'time',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date fin',
          field: 'end',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Type d\'activité',
          field: 'type',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    // sortDate(activites, sort = 'desc') {
    //   let i = (sort === 'desc') ? -1 : 1
    //   this.rows = activites.sort((act1, act2) => {
    //     let date1 = act1.start ? act1.start : act1.time
    //     let date2 = act2.start ? act2.start : act2.time

    //     return moment(date1, 'YYYY-MM-DD HH:mm:ss').isAfter(date2, 'YYYY-MM-DD HH:mm:ss') ? i : -(i)
    //   })
    // },
    removeEvent(event) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir annuler cet événement ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(
              result => {
                if (result.value) {
                  store.dispatch('calendar/removeEvent', {
                    event
                  })
                      .then(response => {
                        if (response.data.success) {
                          const index = this.rows.map(x => {
                            return x.id
                          })
                              .indexOf(event.id)

                          this.rows.splice(index, 1)
                          this.messageToast(response.data.message, 'Succès', 'success', 'BellIcon')
                          this.$emit('calculate',this.rows.length)

                        }
                      })
                      .catch(err => {
                        this.messageToast(err.response.data.errors[0], 'Attention', 'warning', 'BellIcon')
                      })
                }
              }
          )
    },
    fetchMessageDataAndCalendarByClient(id, type) {
      this.showLoading = true
      this.rows = []
      let url = `/evenements/fetchMessageDataAndCalendarByClient/${id}/${type}/${this.currentUser.courtier_user[0].user_id}`
      if(this.itemChosen) url = url+`/${this.itemChosen.typeInterface}/${this.itemChosen.itemId}`
      this.$http.get(url)
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data
              this.showLoading = false
              this.$emit('calculate',this.rows.length)
            }
          })
          .catch(err => {
            this.showLoading = false
            // this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.log(err)
          })
          
    },
    visualiserEmail(row, isAgenda) {
      if (isAgenda) {
        this.$router.push({
          name: 'calendrier',
          params: {
            event: {
              id: row.id,
              title: row.title,
              start: this.changeFormatDateTimeShort(row.start),
              end: this.changeFormatDateTimeShort(row.end),
              extendedProps: {
                calendar: row.extendedProps.calendar,
                guests: row.extendedProps.guests,
                location: row.extendedProps.location,
                description: row.extendedProps.description,
                createur: row.extendedProps.createur,
                eventUrl: row.extendedProps.eventUrl
              },
              allDay: row.allDay,
              url: row.url
            }
            /*      event: {
                    id: row.id,
                    title: row.title,
                    start: this.changeFormatDateTimeShort(row.start),
                    end: this.changeFormatDateTimeShort(row.end),
                    extendedProps: row.extendedProps,
                    allDay: row.allDay,
                    url: row.url,
                  }*/
          }
        })
      } else {
        this.$router.push({
          name: 'messageries',
          params: {
            operation: 'show',
            email: row
          }
        })
      }
    }
  },
}
</script>
<style scoped>
 .popover {
  max-width: 30%;
} 
</style>
