var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sideBarProjetExterneTier",attrs:{"id":"sidebar-projet-tier","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v(_vm._s(_vm.projetForUpdate.idDevis && _vm.projetForUpdate.idDevis ? 'Modifier le devis ' + (_vm.projetForUpdate.num_devis ? '[ ' + _vm.projetForUpdate.num_devis + ' ]' : '') : 'Enregistrer devis'))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.hideSideBareProjetExterne}})],1),_c('validation-observer',{ref:"projetExterneComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('h4',[_vm._v("INFORMATIONS")]),_c('b-form-group',{attrs:{"label":"Courtier","label-for":"courtier"}},[_c('b-form-input',{attrs:{"id":"courtier","placeholder":"Courtier","disabled":""},model:{value:(_vm.currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial),callback:function ($$v) {_vm.$set(_vm.currentUser.courtier_user[0].courtier.personne_morale, "denomination_commercial", $$v)},expression:"currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial"}})],1),(_vm.isFormFicheTier)?_c('b-form-group',{attrs:{"label":"Client *","label-for":"client"}},[_c('validation-provider',{attrs:{"name":"client","rules":_vm.isFormFicheTier ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","value":_vm.prospectSelected.client,"readonly":""}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('h4',[_vm._v("LE DEVIS")]),_c('b-form-group',{attrs:{"label":"Risque *","label-for":"risque"}},[_c('validation-provider',{attrs:{"name":"risque","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risquesOptions,"id":"risque","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.projet.risque),callback:function ($$v) {_vm.$set(_vm.projet, "risque", $$v)},expression:"projet.risque"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Assureur *","label-for":"assureur"}},[_c('validation-provider',{attrs:{"name":"assureur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assureursOptions,"id":"assureur","state":errors.length > 0 ? false : null},model:{value:(_vm.projet.assureur),callback:function ($$v) {_vm.$set(_vm.projet, "assureur", $$v)},expression:"projet.assureur"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Statut *","label-for":"statut"}},[_c('validation-provider',{attrs:{"name":"statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statutOptions,"id":"statut","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.projet.statut),callback:function ($$v) {_vm.$set(_vm.projet, "statut", $$v)},expression:"projet.statut"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h4',[_vm._v("LE DEVIS")]),_c('b-form-group',{attrs:{"label":"N° de Devis *","label-for":"num_devis"}},[_c('validation-provider',{attrs:{"name":"N° de Devis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"num_devis","trim":"","placeholder":"N° de Devis","state":errors.length > 0 ? false : null},model:{value:(_vm.projet.num_devis),callback:function ($$v) {_vm.$set(_vm.projet, "num_devis", $$v)},expression:"projet.num_devis"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Date d'effet","label-for":"date-effet"}},[_c('validation-provider',{attrs:{"name":"date d'effet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-effet","placeholder":"Date d'effet","config":_vm.configDate},model:{value:(_vm.projet.date_effet),callback:function ($$v) {_vm.$set(_vm.projet, "date_effet", $$v)},expression:"projet.date_effet"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Date de création","label-for":"date-creation"}},[_c('validation-provider',{attrs:{"name":"date de création"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-creation","placeholder":"Date de création","config":_vm.configDate},model:{value:(_vm.projet.date_creation),callback:function ($$v) {_vm.$set(_vm.projet, "date_creation", $$v)},expression:"projet.date_creation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h4',[_vm._v("LE BUDGET")]),_c('b-form-group',{attrs:{"label":"Prime TTC proposé","label-for":"budget_propose"}},[_c('validation-provider',{attrs:{"name":"Prime TTC proposé"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","id":"budget_propose","trim":"","placeholder":"Prime TTC proposé","state":errors.length > 0 ? false : null},model:{value:(_vm.projet.budget_propose),callback:function ($$v) {_vm.$set(_vm.projet, "budget_propose", $$v)},expression:"projet.budget_propose"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Prime TTC actuel","label-for":"budget_actuel"}},[_c('validation-provider',{attrs:{"name":"Prime TTC actuel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0","id":"budget_actuel","trim":"","placeholder":"Prime TTC actuel"},model:{value:(_vm.projet.budget_actuel),callback:function ($$v) {_vm.$set(_vm.projet, "budget_actuel", $$v)},expression:"projet.budget_actuel"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Variation","label-for":"variation"}},[_c('validation-provider',{attrs:{"name":"variation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"variation","disabled":"","trim":"","placeholder":"Variation","state":errors.length > 0 ? false : null},model:{value:(_vm.projet.variation),callback:function ($$v) {_vm.$set(_vm.projet, "variation", $$v)},expression:"projet.variation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h4',[_vm._v("DOCUMENTS")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Bulletin d\'adhésion'),expression:"'Bulletin d\\'adhésion'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openadehsion}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openAdhésiontFile"},model:{value:(_vm.projet.fileAdhesion),callback:function ($$v) {_vm.$set(_vm.projet, "fileAdhesion", $$v)},expression:"projet.fileAdhesion"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Bulletin d'adhésion","value":_vm.projet.fileAdhesion ? _vm.projet.fileAdhesion.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Devis'),expression:"'Devis'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openprojet}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openProjetFile"},model:{value:(_vm.projet.fileProjet),callback:function ($$v) {_vm.$set(_vm.projet, "fileProjet", $$v)},expression:"projet.fileProjet"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Devis","value":_vm.projet.fileProjet ? _vm.projet.fileProjet.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Fiche d\'information et de conseil'),expression:"'Fiche d\\'information et de conseil'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openconseil}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"openConseilFile"},model:{value:(_vm.projet.fileConseil),callback:function ($$v) {_vm.$set(_vm.projet, "fileConseil", $$v)},expression:"projet.fileConseil"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Fiche d'information et de conseil","value":_vm.projet.fileConseil ? _vm.projet.fileConseil.name : '',"state":errors.length > 0 ? false : null}})],1)]}}],null,true)})],1),_c('b-form-group',{staticStyle:{"margin-top":"10px"},attrs:{"label":"Type d'origine","label-for":"Type-d'origine"}},[_c('b-form-select',{attrs:{"options":_vm.origine,"id":"origine"},model:{value:(_vm.projet.origine),callback:function ($$v) {_vm.$set(_vm.projet, "origine", $$v)},expression:"projet.origine"}})],1),(_vm.projet.origine==='Autre')?_c('b-form-group',{attrs:{"label":"Précisez","label-for":"origineautre"}},[_c('b-form-input',{attrs:{"id":"origineautre","placeholder":"Précisez l’origine"},model:{value:(_vm.projet.origineautre),callback:function ($$v) {_vm.$set(_vm.projet, "origineautre", $$v)},expression:"projet.origineautre"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"createur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Crée par","label-for":"Crée-par"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"createur","label":"nom","placeholder":"Crée par"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.projet.createur),callback:function ($$v) {_vm.$set(_vm.projet, "createur", $$v)},expression:"projet.createur"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune commercial disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"intermediaire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Intermédiaire (s)","label-for":"intermediaire"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"createur","label":"nom","placeholder":"Intermédiaire (s)","multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.projet.intermediaire),callback:function ($$v) {_vm.$set(_vm.projet, "intermediaire", $$v)},expression:"projet.intermediaire"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune commercial disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordProjetExterne}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }