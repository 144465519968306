<template>
  <div>
    <!-- search input -->
    <div class="d-flex justify-content-end mb-1" v-if="fromChild">
      <b-button @click="$emit('ajouter-document')" variant="primary">
        <feather-icon class="mr-50" icon="PlusIcon" />
        <span class="align-middle">Ajouter un document</span>
      </b-button>
    </div>
    <div class="custom-search mb-2" v-if="fromChild">
        <!-- advance search input -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Type de document" label-for="filtre-type-document">
              <b-form-select id="filtre-type-document" v-model="typeSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="typed in listType" :key="typed.value" :value="typed.value" > {{ typed.text }} </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
    </div>

    <!-- table -->
    <b-overlay :show="showLoadingo" no-wrap />
    <vue-good-table
        v-if="can('List other documents')"
      :columns="columnsAutresDocuments"
      :rows="rowsAutresDocuments"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
       enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
     <template slot="table-row" slot-scope="props">
      
        <span v-if="props.column.field === 'actions'">     
          <!-- <span>
            <template v-if="can('Visualize other documents')">
                 <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"  @click="displayDocument(props.row.id, props.row.Name, props.row.NomDuDocument)">
                 <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Visualiser</span>
                </b-button>
              </template>
          </span> -->
          <span v-if="fromChild">
            <b-dropdown
              dropright
              no-caret
              toggle-class="text-decoration-none p-0"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                v-if="can('Visualize other documents')"
                @click="
                  displayDocument(
                    props.row.id,
                    props.row.Name,
                    props.row.NomDuDocument
                  )
                "
              >
                <feather-icon class="mr-50" icon="SearchIcon" />
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!props.row.IsGenerated  && !props.row.IsAssureurDoc"   @click="deleteDocumentProjet(props.row.id)">
                <feather-icon class="mr-50" icon="TrashIcon" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
          <span v-else>
            <template v-if="can('Visualize other documents')">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="
                  displayDocument(
                    props.row.id,
                    props.row.Name,
                    props.row.NomDuDocument
                  )
                "
              >
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Visualiser</span>
              </b-button>
            </template>
          </span>
        </span>
        <span  v-else-if="props.column.field === 'TypeDeDocument'">
          <p v-if="props.row.IdDeTypeDeDocument == '85'">{{ props.row.NomDuDocument }}</p>
          <p v-else-if="props.row.IdDeTypeDeDocument == '105'">{{ props.row.NomDuDocument }}</p>
          <p v-else>{{ props.row.TypeDeDocument }}</p>

        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
            <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
    <b-modal v-if="can('Visualize document compliance')" id="modal-display-document" ref="modal-display-document"
             :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
             cancel-variant="outline-secondary"
             ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>

  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect, BFormSelectOption,BDropdown, BDropdownItem, BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BOverlay, BModal, BImg, VBToggle } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
// import ActionsDocumentVue from './ActionsDocument.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BModal,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // UI
    // ActionsDocumentVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    fresh: {
      type: Number,
      default: 0,
      required: false
    },
    courtierId: {
      type: Number,
      default: null,
    },
    contratId: {
      type: Number,
      default: null,
    },
    contratIdDoc: {
      type: Number,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
    listType: {
      type: Array,
      default: () => [{}],
      required: false
    },
    isDocumentAdded: {
      type: Boolean,
      default: false,
      required: false
    },
    fromChild: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data() {
    return {
      countDocs : 0 ,
      showLoadingo: false,
      pageLength: 10,
      dir: false,
      contrat_id_doc:null,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'autre', text: ' --- ' }
      ],
      selected: null,
      columnsAutresDocuments: [
        { label: 'Type de document', field: 'TypeDeDocument', sortable: false, thClass: (this.fromChild ? 'text-center' : 'text-left')+' align-middle bg-primary text-white unbreaking h6', tdClass:(this.fromChild ? 'text-center' : 'text-left') +' m-0 p-1 align-middle h6' },
        { label: 'Date de l\'ajout', field: 'CreeLe', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: (this.fromChild ? 'text-center' : 'text-left')+' align-middle bg-primary text-white unbreaking h6', tdClass:(this.fromChild ? 'text-center' : 'text-left') +' m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      rowsAutresDocuments: [],
      rowsAutresDocumentsKeep: [],
      typeSelected: null,
    }
  },
  watch:{
    contratIdDoc: {
    immediate:true,
      handler(val) {
          console.log(val);
       this.contrat_id_doc=val 
      }
    },
    typeSelected() {
      this.filterData()
    },
    isDocumentAdded: {
      handler(val) {
        if(val) {
          this.fetch_documents_contrat()
        }
      }
    },
    rowsAutresDocuments :{
      handler(val){
        if(val){
          this.countDocs =  this.rowsAutresDocuments.length
          this.$emit("count-docs-refreshed", this.countDocs)
          }
      }
     },
     fresh: {
      deep: true,
      immediate: true,
      handler(){ 
          this.fetch_documents_contrat()
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.fetch_documents_contrat()
  },

// created(){
//     // this.contrat_id_doc = this.props.contratIdDoc
//   this.fetch_documents_contrat()

// },
updated() {
  this.AutreDocumentNom()
},
  methods: {
    deleteDocumentProjet(id) {
      this.$swal({
        title: "Etes-vous sûr de vouloir supprimer ce document ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("document/deleteDocumentClient", { document_id: id })
            .then((res) => {
              for (let i = 0; i < this.rowsAutresDocuments.length; i++) {
                if (this.rowsAutresDocuments[i].id == id) {
                  this.rowsAutresDocuments.splice(i, 1);
                  break;
                }
              }

              // this.countdocument()
              this.messageToast(res.data.message, "Succès", "success");
            })
            .catch((error) => {
              console.log(error);
              this.messageToast(error.response.data, "Erreur", "error");
            });
        }
      });
    },
    recordDocumentCourtierReturned(data, message) {
      this.rowsAutresDocuments.push(data)
      this.messageToast(message, 'Succès', 'success')
    },
      AutreDocumentNom() {
    this.rowsAutresDocuments.forEach(item=>item.IdDeTypeDeDocument == 55  ?item.TypeDeDocument =  item.NomDuDocument : item.TypeDeDocument )

    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.showLoadingo = true;
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, NomDuDocument)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = NomDuDocument
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = this.getUrlbase64(res.data.data.base64)
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = NomDuDocument
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
                // const pdfWindow = window.open('')
                // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
                // this.downloadDocument(res.data.data.base64, name)
              }
              this.showLoadingo = false
            } else {
              this.clearDocumentDisplay()
              this.showLoadingo = false
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    // deleteDocument(id, vgtId) {
    //   this.$swal({
    //     title: 'Etes-vous sûr de vouloir supprimer le document ?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Oui',
    //     cancelButtonText: 'Non',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1'
    //     },
    //     buttonsStyling: false
    //   }).then(confirmed => {
    //     if (confirmed.value) {
    //       this.$http
    //         .delete(`/document/delete_document_courtier/${id}`)
    //         .then(res => {
    //           if (res.data.success) {
    //             const indexDocument = this.rowsAutresDocuments.findIndex(item => item.id === id)
    //             this.rowsAutresDocuments.splice(indexDocument, 1)
    //             this.messageToast(res.data.message, 'Succès', 'success')
    //           } else {
    //             this.messageToast(res.data.message, 'Erreur', 'error')
    //           }
    //         })
    //         .catch(error => {
    //           console.log(error)
    //           this.messageToast(error.response.data, 'Erreur', 'error')
    //         })
    //     }
    //   })
    // },
    fetch_documents_contrat() {
      this.showLoadingo = true
      const { contrat_id_doc } = this
      this.$http
        .post('/document/fetch_documents_contrat', { contrat_id: contrat_id_doc })
        .then(res => {
          console.log(res.data.data)
          this.rowsAutresDocuments     = res.data.data
          this.rowsAutresDocumentsKeep = res.data.data
          this.showLoadingo = false
          this.$emit('initial-docs-refreshed', false)
           this.countDocs =  this.rowsAutresDocuments.length
          this.AutreDocumentNom()
        })
        .catch(err => {
          this.showLoadingo = false
          this.$emit('initial-docs-refreshed', false)
          console.error(err)
        })
    },
    filterData() {
      this.rowsAutresDocuments = this.rowsAutresDocumentsKeep
      if (this.typeSelected)
          this.rowsAutresDocuments = this.rowsAutresDocuments.filter(item => item.IdDeTypeDeDocument === this.typeSelected)
    },
  }
}
</script>
