
<template>
  <div>
    <app-collapse accordion type="margin" >
      <app-collapse-item title="Dirigeant">
        <dirigeant v-if="prospectChosen.id !== null" :prospect-id="prospectChosen.id" />
        <span v-else>Aucun dirigeant de l'entreprise trouvé.</span>
      </app-collapse-item>

<!--      <app-collapse-item title="Salariés">-->
<!--        Salariés-->
<!--      </app-collapse-item>-->
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import dirigeant from './collapses/DirigeantCard.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    // UI
    dirigeant
  },
  props: {
    prospectChosen: {
      type: Object,
      default: { id: null, type: null },
      required: true
    }
  }
}
</script>
