<template>
  <div>
    <b-row align-v="center" class="mb-2">
      <b-col md="8" sm="6" >
        
        <import-new-doc-projet
         :courtier-id="currentUser.courtier_user[0].courtier_id"
          :user-id="currentUser.courtier_user[0].user_id"
          :etude-id="etude_id_joindre"
          :devis-id="devis_id_joindre"
          :client-id="client_id_joindre"
          :client-type="client_type_joindre"
          @record-document-returned="recordDocument"
          
      />
        <import-new-projet
        v-if="(tiersData.statut_gda=='VERT' || tiersData.statut_gda=='BLANC' || tiersData.statut_gda=='GRIS')"
            :courtier-id="currentUser.courtier_user[0].courtier_id"
            :user-id="currentUser.courtier_user[0].user_id"
            :commercial-id="tiersData.distributeur.user_id"
            :is-form-fiche-tier="true"
            :assureurs-options="assureursOptions"
            :commercial-options="commercialOptions"
            :risques-options="risquesOptions"
            :projet-for-update="projetForUpdate"
            :prospect-data-from-fiche-tier="prospect"
            :prospect-data="[]"
            @record-projet-externe-returned="RecordProjetExterneReturned"
            @clear-data-projet-update="clearDataProjetUpdate"
        />
      </b-col>
      <b-col md="4" sm="6">
        <b-row align-v="center">
          <b-col cols="9" md="9">
            <b-form-group
                label="Statut"
                label-for="statut"
            >
              <b-form-select
                  id="statut"
                  v-model="selectedStatut"
                  :options="statutOptions"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    -- Choisissez --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3" md="3" class="mt-50">
            <b-button
                v-b-tooltip.hover.top="'Tout afficher'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="clearAllFilter"
            >
              <feather-icon
                  icon="RefreshCcwIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
<!-- basic modal -->
      <b-modal
        id="modal_renewal"
        hide-footer
        centered
        :title="title_modal"
        @hidden="resetModal"
      >
      <p class="mb-0">{{ msg_modal }}</p>
      <validation-observer ref="formRenewalRules">
      <b-form
            class="p-2"
            @submit.prevent="submitDuplicateProjet"
          >
        <b-form-group label="Date d'effet*" label-for="date-effet">
          <validation-provider #default="{ errors }" name="date d'effet" rules="required" >
            <flat-pickr id="date-effet" v-model="selected_date_effet" class="form-control" placeholder="Date d'effet" :config="configDateEffet" autocomplete="off" :class="errors.length > 0 ? 'is-invalid' : ''" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Confirmer
              </b-button>
        </div>
      </b-form>
      </validation-observer>
      </b-modal>
      <!-- basic modal -->
       <!-- basic modal -->
      <b-modal no-close-on-backdrop
      id="renvoyer_signature" ref="renvoyer_signature" hide-footer centered :title="getTitleRenvoyerSignatureModal"  @hidden="resetRenvoyerSignatureModal">
        <p class="mb-1">Merci de vérifier les données utiles à la signature ou les modifier si nécessaire</p>
        <validation-observer ref="formRenvoyerSignature">
          <b-form class="p-2" @submit.prevent="renvoyerSignature">

            <b-form-group  label-for="lien_signature">
              <b-form-radio-group
                id="lien_signature"
                v-model="renvoyerDataForm.lien_signature"
                :options="options_lien_signature"
              ></b-form-radio-group>                  
            </b-form-group>
        <p class="text-danger my-1" v-if="renvoyerDataForm.lien_signature=='resend'">(Pour modifier le numéro de téléphone, vous devez créer un nouveau lien)</p>

            <b-form-group label="Téléphone portable" label-for="telephone_client">
                      <div class="d-flex bd-highlight">
                        <div class="w-25 bd-highlight">
                              <v-select :disabled="renvoyerDataForm.lien_signature=='resend'"  v-model="renvoyerDataForm.indicatif" :close-on-select="true" :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                                input-id="indicatif" label="indicatif" class="indicatif-chooser w-100  border rounded"
                            >
                                <template #option="{ indicatif,code }">
                                  <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                                </template>
                                <template #selected-option="{ indicatif }">
                                  <span> {{ indicatif }}</span>
                                </template>
                                <div slot="no-options">Aucune indicatif disponible.</div>
                            </v-select>
                        </div>
                      
                        <div class="w-75 bd-highlight">
                          <validation-provider #default="{ errors }" name="Téléphone portable" 
                            :rules="{ regex: /^(0|6|7)\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                            <b-form-input :readonly="renvoyerDataForm.lien_signature=='resend'" id="telephone_client" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" v-mask="'## ## ## ## ##'" v-model="renvoyerDataForm.telephone" placeholder="Téléphone portable" type="tel" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                          </div>
                      </div>
                    </b-form-group>
            <!--  -->
            <b-form-group label="Email" label-for="email">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-input id="email" v-model="renvoyerDataForm.email" placeholder="Email" :state="errors.length > 0 ? false : null" type="email" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

            <div class="d-flex mt-2">
              <b-button :disabled="disableRenvoyer" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Valider
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="$refs['renvoyer_signature'].hide()">
                Annuller
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!--  -->
      <!-- basic modal -->
      <b-modal 
      id="renvoyer_signature_sms" ref="renvoyer_signature_sms" hide-footer centered title="Envoyer un Sms pour signature">
        <p class="mb-1">Etes vous sur de vouloir envoyer par sms au {{ renvoyerDataForm.indicatif + ' ' + renvoyerDataForm.telephone }} le lien pour signature du devis {{ renvoyerDataForm.numDevis }}</p>
        <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="sendSignatureLinkBySms" class="mr-2" :disabled="disableRenvoyer">
                Oui
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="$refs['renvoyer_signature_sms'].hide()">
                Non
              </b-button>
          </div>
      </b-modal>
      <!--  -->
    <b-card  header-class="py-1 px-0" body-class="p-0">
      <b-overlay :show="showLoading" no-wrap/>
      <!-- table -->
      <vue-good-table
          :columns="columns"
          @on-selected-rows-change="selectionChanged"
          :rows="prospectDevisLocal"
          :rtl="direction"
          :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
          theme=""
      >
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'devisNo' && tiersData.statut_gda!='ORANGE' && tiersData.statut_gda!='ROUGE' " @click="showProjet(props.row.id, props.row.etude.source)">
            <a style="text-decoration: underline;">{{ props.row.devisNo }} </a>
          </span>
          <span v-else-if="props.column.field == 'devisNo' && (tiersData.statut_gda=='ORANGE' || tiersData.statut_gda=='ROUGE')">
            {{ props.row.devisNo }}
          </span>
          <span v-else-if="props.column.field === 'commercial'" class="text-nowrap">
            {{ props.row.user.personne_physique.prenom + ' ' + props.row.user.personne_physique.nom }}
          </span>
          <span v-else-if="props.column.field === 'statut'" class="text-nowrap">
            {{ CorrectingAccentByName(props.row.statut) }}
          </span>
          <div v-else-if="props.column.field == 'source'" class="text-uppercase">
            <b-badge :variant="sourceVariant(props.row.etude.source)">
              {{ props.row.etude.source }}
            </b-badge>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>

          <!-- Column: Action -->
          <span v-if="(props.column.field === 'actions' && tiersData.statut_gda!='ROUGE' && tiersData.statut_gda!='ORANGE')">

              <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
                <b-dropdown-item @click="showProjet(props.row.id, props.row.etude.source)">
                  <feather-icon class="mr-50" icon="Edit2Icon" />
                  <span>Consulter la fiche devis</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="props.row.devis_avancement == 'TARIFICATION' && props.row.statut !== 'Devis cloture sans suite' 
                    && props.row.statut !== 'En attente signature' && props.row.statut !== 'Refuse par le client' && props.row.statut !== 'Expire'	 && props.row.etude.source === 'Interne' && can('Update project intern')"
                    @click="redirectprojet(props.row)"
                >
                  <feather-icon
                      class="mr-50"
                      icon="EditIcon"
                  />
                  <span>Finaliser</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-else-if="props.row.etude.source === 'Externe'  && can('Update project extern') && props.row.statut !== 'Expire'"
                    v-b-toggle.sidebar-2
                    @click="updateProjectExterne(props.row)"
                >
                  <feather-icon
                      class="mr-50"
                      icon="EditIcon"
                  />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-else-if="props.row.devis_avancement == 'SOUSCRIPTION' && props.row.statut !== 'Devis cloture sans suite' 
                    && props.row.statut !== 'En attente signature' && props.row.statut !== 'Refuse par le client' && props.row.devis_etape_avancement !== 'devisenregistre' && props.row.statut !== 'Expire' && props.row.etude.source === 'Interne' && can('Subscribe pricing')"
                    @click="redirectprojet(props.row)"
                >
                  <feather-icon
                      class="mr-50"
                      icon="CheckCircleIcon"
                  />
                <span>Souscrire</span>
                </b-dropdown-item>
                <b-dropdown-item
                      v-else-if="props.row.devis_avancement == 'SOUSCRIPTION' && props.row.devis_etape_avancement == 'devisenregistre'
                      && props.row.etude.source === 'Interne' && props.row.devisable.statut_gda != 'ROUGE' && props.row.devisable.statut_gda != 'ORANGE'"
                      @click="redirectExtranet(props.row)">
                      <feather-icon class="mr-50" icon="ExternalLinkIcon" />
                      <span>Accéder à l'extranet</span>
                </b-dropdown-item>
                   <b-dropdown-item v-b-toggle.sidebar-3 @click="joindreFile(props.row.etude_id, props.row.id, props.row.devisable_id, props.row.devisable.type)">
                  <feather-icon
                      class="mr-50"
                      icon="LinkIcon"
                  />
                  <span>Joindre</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="JoindreContrat(props.row.id, props.row.etude.source)">
                  <feather-icon
                      class="mr-50"
                      icon="SearchIcon"
                  />
                  <span >Visualiser</span>
                </b-dropdown-item>
                 <b-dropdown-item v-b-modal.modal_2 @click="attachCommercial(props.row)">
                          <feather-icon
                              class="mr-50"
                              icon="Link2Icon"
                          />
                          <span>Attacher à un gestionnaire</span>
                        </b-dropdown-item>
                <b-dropdown-item @click="dupliquerDevis(props.row.id, props.row.statut)">
                  <feather-icon class="mr-50" icon="CopyIcon"/>
                  <span>Dupliquer</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDevis(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="props.row.etude.source === 'Externe'"
                      @click="AddContratTransformermodel(props.row.id,props.row.date_effet, props.row.createur_id, props.row.origine)">
                      <feather-icon class="mr-50" icon="PlusIcon" />
                      <span>Transformer en contrat</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="can('Update project extern') && props.row.statut !== 'Devis cloture sans suite' && can('Update project intern')"
                    @click="classerDevis(props.row.id)"
                >
                  <feather-icon
                      class="mr-50"
                      icon="ArchiveIcon"
                  />
                  <span>Classer sans suite</span>
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.renvoyer_signature v-if="props.row.statut === 'En attente signature' && props.row.etude.source === 'Interne' && checkDateEnvoiSignValide(props.row.date_envoi_signature)"  @click="fillRenvoyerSignatureModalData(props.row)">
                        <feather-icon
                            class="mr-50"
                            icon="SendIcon"
                        />
                        <span>Renvoyer pour signature</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.renvoyer_signature_sms v-if="props.row.statut === 'En attente signature' && props.row.etude.source === 'Interne' && checkDateEnvoiSignValide(props.row.date_envoi_signature)"  @click="fillRenvoyerSignatureModalData(props.row)">
                        <feather-icon
                            class="mr-50"
                            icon="MessageSquareIcon"
                        />
                        <span>Envoyer un Sms pour signature</span>
                    </b-dropdown-item>
              </b-dropdown>

          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col md="4">
              <b-input-group  v-if="(tiersData.statut_gda=='VERT' || tiersData.statut_gda=='BLANC' || tiersData.statut_gda=='GRIS')">
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                      
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>

      </vue-good-table>
    </b-card>
      <b-modal centered size="lg" id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter">
             <autres-document  :devis-id-doc="joindre_id_contrat" :devis-type-doc="joindre_type_contrat"/>
    </b-modal>

    <b-modal id="transforme-contrat" ref="transforme-contrat" hide-footer centered hide-header-close>
        <b-overlay
                    :show="showLoading"
                    no-wrap
                />
      <validation-observer ref="formContratTransformer">
          <b-form class="p-2" @submit.prevent="AddContratTransformer">
              <img class="rounded mx-auto d-block" src="../../../../../assets/images/be-careful-6-128.png" alt="Icone warnnig">
            <h1 style="margin-top:30px; text-align: center;">Etes-vous sûr de vouloir transformer ce devis en contrat ?</h1>

            <b-form-group label="N° contrat*" label-for="numcontrat">
              <validation-provider #default="{ errors }" name="numcontrat" rules="required">
                <b-form-input id="num_contrat" v-model="contrat.num_contrat" :disabled="isUpdate"
                              :state="errors.length > 0 ? false : null" placeholder="N° contrat"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date d'effet*" label-for="date-effet">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                <flat-pickr id="date-effet" v-model="contrat.date_effet" class="form-control"
                  placeholder="Date d'effet" :config="configDateEffetTransform" autocomplete="off"
                  :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          
            <div class="d-flex mt-2" style="margin: auto; width: 50%; padding: 10px;">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Oui
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-2" @click="resetModall">
                Non
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

       <b-modal
              id="modal_2"
              hide-footer
              centered
              title="Attacher à un gestionnaire"
            >
            <b-form
                  class="p-2"
                  @submit.prevent="commercialClientSave"
                >
              <b-form-group label="Gestionnaire" label-for="commercial">
                 <b-form-select
                        v-model="attached_commercial"
                        id="commercial"
                      >
                  <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}</b-form-select-option>
                 </b-form-select>
              </b-form-group>
              <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                      :disabled="attached_commercial == null || (clientsAttacher.length == 1 && clientsAttacher[0].actif_commercial == attached_commercial)"
                    >
                      Attacher
                    </b-button>
              </div>
            </b-form>
            </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BOverlay,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BSidebar,
  VBToggle,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BBadge,
  BFormInvalidFeedback,
  VBTooltip,
  BFormSelectOption,
  BFormRadioGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ImportNewProjet from '../../../../projets/ImportNewProjet.vue'
import ImportNewDocProjet from '@/views/projets/ImportNewDocProjet'
import AutresDocument from '@/views/projets/AutresDocument'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'
import moment from 'moment'



export default {
  components: {
    ImportNewDocProjet,
    VBTooltip,
    AutresDocument,
    BFormSelectOption,
    Ripple,
    BBadge,
    BOverlay,
    VueGoodTable,
    BAvatar,
    BPagination,
    ToastificationContent,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BSidebar,
    VBToggle,
    ValidationProvider,
    ValidationObserver,
    // UI
    ImportNewProjet,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BFormInvalidFeedback,
    vSelect,
    BFormRadioGroup
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    prospectChosen: {
      type: Object,
      default: null,
      required: true
    },
    tiersData: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      devisidfortransformcontrat :null,
      contrat:{
        date_effet: null,
        num_contrat: null,
        source:null,
        createur_id: null,
        origine: null
      },
      configDateEffetTransform: {
          dateFormat: 'Y/m/d',
          altFormat: 'd/m/Y',
          altInput: true,
          // disable:[{from:moment(new Date(), "DD/MM/YYYY"),to:'25/02/3000'}],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      showLoading:false,
      hideFooter:false,
      selected_date_effet: null,
      selected_devisId: null,
      title_modal: '',
      msg_modal: '',
      selectedStatut: null,
      statutOptions: [
        {
          value: 'Etude',
          text: 'Etude'
        },
        {
          value: 'Devis envoye',
          text: 'Devis envoyé'
        },
        {
          value: 'En attente signature',
          text: 'En attente signature'
        },
        {
          value: 'Devis cloture sans suite',
          text: 'Devis clôturé sans suite'
        },
        {
          value: 'Refuse par le client',
          text: 'Refusé par le client'
        },
        {
          value: 'Expire',
          text: 'Expiré'
        },
        {
          value: 'SIMULAT_EDITEE',
          text: 'Simulation éditée'
        }
      ],
      configDateEffet: {
          dateFormat: 'Y-m-d',
          altFormat: 'd-m-Y',
          altInput: true,
          // disable:[{from:moment(new Date(), "DD/MM/YYYY"),to:'25/02/3000'}],
          disable: [ function(date) {
              let localDay=new Date()
              let firstDayCurrentMonth = new Date(localDay.getFullYear(), localDay.getMonth(), 1) 
              return date < firstDayCurrentMonth
              // return date < localDay.setDate(localDay.getDate()-1)
          }],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      risquesOptions: [],
      assureursOptions: [],
      etude_id_joindre:null,
      devis_id_joindre:null,
      client_id_joindre:null,
      client_type_joindre:null,
      joindre_id_contrat: null ,
      joindre_type_contrat: null,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      projets: [],
      pageLength: 5,
      dir: false,
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        },
        {
          value: 'Classer',
          text: 'Classer sans suite'
        },
      ],
      selected_action: null,
      prospect: null,
      commercialOptions:[],
      columns: [
        {
          label: 'N° devis',
          field: 'devisNo',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Gestionnaire',
          field: 'commercial',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Risque',
          field: 'risque.label',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Assureur',
          field: 'assureur.nom_com_assureur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Avancement',
        //   field: 'devis_avancement',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Source',
          field: 'source',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'date d\'effet',
          field: 'date_effet',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
      ],
      clients: null,
      searchTerm: '',
      prospectDevisLocalKeeper: [],
      prospectDevisLocal: [],
      options_masse: [],
      projetForUpdate: {
        idEtude: null,
        idDevis: null,
        client: null,
        risque: null,
        assureur: null,
        statut: null,
        num_devis: null,
        date_effet: null,
        date_creation: null,
        budget_propose: null,
        budget_actuel: null,
        variation: null,
        courtier_id: null,
        user_id: null,
        origineautre:null,
        origine:null,
        createur:null,
        intermediaire: null,
        user: null,
      },
      selectedrows_masse: [],
      attached_commercial: null,
      clientsAttacher: [],
      renvoyerDataForm:{
        email:"",
        telephone:"",
        indicatif:"",
        idDevis:"",
        idMoyContact:"",
        nomClient:"",
        numDevis:"",
        lien_signature:""
      },
      indicatif:[],
      disableRenvoyer:false,
      options_lien_signature:[
        {
          value: 'resend',
          text: 'Renvoyer le lien actuel'
        },
        { value: 'new', text: 'Créer un nouveau lien'},

      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    getTitleRenvoyerSignatureModal(){
      return `Renvoyer pour signature - Devis ${this.renvoyerDataForm.numDevis} - ${this.renvoyerDataForm.nomClient}`
    }
  },
  watch: {
    selectedStatut: {
      handler() {
        this.filterData()
      }
    },
    prospectChosen: {
      deep: true,
      immediate: true,
      handler(){

          this.fetchprospectDevis()
          this.prospectDevisList()
      }
    }
  },
  created() {
    // this.fetchprospectDevis()
    this.fetchRisqueList()
    this.fetchAssureurList()
    this.fetchDataCommercialByCourtier()
    // this.prospectDevisList()

    this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
      })
      .catch((e)=>{
        console.log(e);
      })
  },
  mounted() {
    this.hideFooter = true
  },
  methods: {
    CorrectingAccentByName($statutDeivs){
      switch ($statutDeivs) {
        case 'Etude':
          return 'Etude'
        case 'Devis envoye':
          return 'Devis envoyé'
        case 'En attente signature':
          return 'En attente signature'
        case 'Devis cloture sans suite':
          return 'Devis clôturé sans suite'
        case 'Refuse par le client':
          return 'Refusé par le client'
        case 'Dupliquer':
          return 'Dupliquer'
        case 'Expire':
          return 'Expiré'
        case 'SIMULAT_EDITEE':
          return 'Simulation éditée'
      }
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    attachCommercial(data) {
      this.clientsAttacher.splice(0);
      this.attached_commercial = data.user_id
      this.clientsAttacher.push({
        actif_devis: data.id,
        actif_commercial: data.user_id
      })
      console.log(this.clientsAttacher)

    },
    commercialClientSave() {

      this.$http.post("devis/attachementDevis/", { 'devis_id': this.clientsAttacher[0].actif_devis, 'user_id': this.attached_commercial })
        .then((res) => {

          if (res.data.success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Le client a été attaché avec un nouveau commercial`,
                  icon: "UserIcon",
                  variant: "success",
                },
              },
              {
                position: "top-right",
                timeout: 4000,
              }
            );
            this.$bvModal.hide('modal_2')
            this.prospectDevisList();
          }
        })
        .catch((err) => { });
    },
    resetModall() {
      this.contrat.date_effet = null
      this.contrat.num_contrat = null
      this.contrat.createur_id = null
      this.contrat.origine = null
      this.$refs['transforme-contrat'].hide()
    },
    recordDocument(data) {
      this.$emit('reload-document-list')
    },
    formatDateAdd(date) {
      return date ? moment(date, 'DD/MM/YYYY')
      .format('YYYY/MM/DD') : ''
    },
    AddContratTransformer(){
      this.$refs.formContratTransformer.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              const formData = new FormData()
      
              formData.append('devis_id', this.devisidfortransformcontrat)
              formData.append('police_no',this.contrat.num_contrat)
              formData.append('date_effet', this.contrat.date_effet)
              formData.append('createur_id',this.contrat.createur_id)
              formData.append('origine', this.contrat.origine)
          

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              this.$http
              .post('/contrat/addContratTransform', formData, config)
              .then(res => {
                if (res.data.success) {
                  
                  this.$router.push({ name: 'contrats-list', params: { id: res.data.data.id ,type:"EXTERNE",interfaceContrat:"FicheContrat"} })
                  this.messageToast('Contrat a été bien ajouter', 'Succès', 'success')
                  this.resetModall()
                  this.showLoading = false

                }
              })
              .catch(err => {
                this.showLoading = false
                this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                console.error(err)
              })
              console.log('11')
              this.resetModal()
             
            }
          })
    },
    AddContratTransformermodel(id,date, createur, origine){
      this.devisidfortransformcontrat = id
      this.contrat.date_effet = date
      this.contrat.createur_id = createur
      this.contrat.origine = origine
      this.$refs['transforme-contrat'].show()
    },
    showProjet(idProjet, typeProjet) {
      this.$router.push({
        name: 'projets-list',
        params: {
          'id': idProjet,
          'type': typeProjet,
          'interfaceChosen': 'FicheProjet'
        }
      })
    },
    clearAllFilter(){
      this.selectedStatut = null
    },
    filterData() {
      this.prospectDevisLocal = this.prospectDevisLocalKeeper
      if (this.selectedStatut) {
        this.prospectDevisLocal = this.prospectDevisLocal.filter(item => item.statut == this.selectedStatut)
      } else if (!this.selectedStatut) {
        this.prospectDevisLocal = this.prospectDevisLocal.filter(item => item.statut !== 'Devis cloture sans suite')
      }
    },
    resetModal() {
      this.selected_date_effet = null
      this.selected_devisId    = null
    },
    sourceVariant(value) {
      const statusColor = {
        Externe: 'light-primary',
        Interne: 'light-warning',
      }
      return  statusColor[value]
    },
   joindreFile(etude,devis,clientId, clientType){
      this.etude_id_joindre = etude
      this.devis_id_joindre = devis
      this.client_id_joindre = clientId
      this.client_type_joindre = clientType
    },
    JoindreContrat(devisId, devisType){
        this.joindre_id_contrat=devisId
        this.joindre_type_contrat=devisType
        if (this.joindre_id_contrat && this.joindre_type_contrat) {
          this.$refs['modal-display-document'].show()
        }
      },
      dupliquerDevis(idDevis, statutDevis) {
        this.$swal({
          html: 'Souhaitez-vous classer le devis, d\'origine, sans suite ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        .then(result => {
          let data = {
            devisId: idDevis,
            dateEffet: null,
            action: ''
          }
          if(result.value) {
            data.action = 'duplicateWithArchive'
            this.recordDuplicateProjet(data)
          } else if(result.dismiss === 'cancel') {
            if(statutDevis != 'Devis cloture sans suite') {
              data.action = 'duplicate'
              this.recordDuplicateProjet(data)
            }
          }
        });
    },
    deleteDevis(id) {
      console.log('ddddd')
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce devis ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`/devis/deleteDevis/${id}`)
            .then(res => {
              for (let i = 0; i < this.prospectDevisLocal.length; i++) {
                if (this.prospectDevisLocal[i].id == id) {
                  this.prospectDevisLocal.splice(i, 1)
                  break
                }
              }
              for (let i = 0; i < this.prospectDevisLocalKeeper.length; i++) {
                if (this.prospectDevisLocalKeeper[i].id == id) {
                  this.prospectDevisLocalKeeper.splice(i, 1)
                  break
                }
              }
              this.$emit('refresh-data-projets', this.prospectDevisLocalKeeper.length)
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    classerDevis(id){
      this.$swal({
              title: 'Êtes-vous sûr(e) de vouloir clôturer ce devis sans suite ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
            .then((result) => {
              if (result.value) {
                this.$http
                .post(`/devis/classerDevis`,{id:id})
                .then(res => {
                  for (let i = 0; i < this.prospectDevisLocalKeeper.length; i++) {
                    if (this.prospectDevisLocalKeeper[i].id == id) {
                      this.prospectDevisLocalKeeper[i].statut = 'Devis cloture sans suite'
                    }
                  }
                  for (let i = 0; i < this.prospectDevisLocal.length; i++) {
                    if (this.prospectDevisLocal[i].id == id) {
                      this.prospectDevisLocal[i].statut = 'Devis cloture sans suite'
                      this.$swal({
                        icon: 'success',
                        title: res.data.message,
                        customClass: {
                          confirmButton: 'btn btn-success',
                        },
                      })
                    }
                  }
                })
                .catch(error => {
                  console.log(error)
                  this.messageToast(error.response.data, 'Erreur', 'error')
                })
              }
            })
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sûr de vouloir supprimer les devis ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("devis/devisDeleteMultiple/",{'devis' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.prospectDevisLocalKeeper.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.prospectDevisLocalKeeper[i].id == res.data.deleted[j].id)
                          {
                            this.prospectDevisLocalKeeper.splice(i, 1)
                          }
                        }
                      }
                      for(let i=0;i<this.prospectDevisLocal.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.prospectDevisLocal[i].id == res.data.deleted[j].id)
                          {
                            this.prospectDevisLocal.splice(i, 1)
                          }
                        }
                      }
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Classer':
            if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              title: 'Êtes-vous sûr(e) de vouloir clôturer ces devis sans suite ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    this.$http
                        .post('devis/devisClasserMultiple/', { 'devis': this.selectedrows_masse.selectedRows,'fiche':true })
                        .then((res) => {
                          if (res.data.success) {
                            this.prospectDevisList();
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: 'Devis clôturés sans suite',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
            }
          break;
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
    },
    clearDataProjetUpdate() {
      this.projetForUpdate.idEtude = null
      this.projetForUpdate.idDevis = null
      this.projetForUpdate.client = null
      this.projetForUpdate.risque = null
      this.projetForUpdate.assureur = null
      this.projetForUpdate.statut = null
      this.projetForUpdate.num_devis = null
      this.projetForUpdate.date_effet = null
      this.projetForUpdate.date_creation = null
      this.projetForUpdate.budget_propose = null
      this.projetForUpdate.budget_actuel = null
      this.projetForUpdate.courtier_id = null
      this.projetForUpdate.user_id = null
      this.projetForUpdate.user = null
    },
    RecordProjetExterneReturned(data, idCreated) {
      if (idCreated) {
        this.prospectDevisLocal.push(data)
        this.prospectDevisLocalKeeper.push(data)
        let arrayOfProjects = []
        arrayOfProjects.push({
          idContrat: '',
          idEtude: data.etude_id,
          idDevis: data.id,
          Numero: data.devis_no_externe,
          source: data.etude.source,
          type: 'Projet'
        })
        this.$emit('refresh-data-projets', this.prospectDevisLocalKeeper.length)
        this.$emit('project-contrat-data-fetching', arrayOfProjects)
      } else {
        const indexProjetFill = this.prospectDevisLocal.findIndex(el => el.etude.id === data.etude.id && el.id === data.id)
        this.prospectDevisLocal.splice(indexProjetFill, 1)
        this.prospectDevisLocal.unshift(data)
        const indexProjetFillKeep = this.prospectDevisLocalKeeper.findIndex(el => el.etude.id === data.etude.id && el.id === data.id)
        this.prospectDevisLocalKeeper.splice(indexProjetFillKeep, 1)
        this.prospectDevisLocalKeeper.unshift(data)
        this.$emit('refresh-data-projets', this.prospectDevisLocalKeeper.length)

      }
    },
    // fetch Data
    fetchRisqueList() {
      this.risquesOptions = []
      this.$http
          .post('/risques/fetchRisqueList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.risquesOptions.push({
                  value: el.id,
                  text: el.label
                })
              })
            }
          })
          .catch(err => {
            this.risquesOptions = []
            console.error(err)
          })
    },
    fetchAssureurList() {
         this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur
                })
              })
            }
          })
          .catch(err => {
              this.assureursOptions = [{
                value: null,
                text: '-- Choisissez --'
              }]
            console.error(err)
          })
    },
    prospectDevisList() {
      this.prospectDevisLocalKeeper = []
      this.prospectDevisLocal = []
      this.showLoading = true
      this.$http
          .get(`/devis/fetchListDevisByTier/${this.prospectChosen.id}/${this.prospectChosen.type}`)
          .then(res => {
            if (res.data.success) {
              let devis = res.data.data
              let arrayOfProjects = []
              devis.forEach(item => {
                const devisNo = item.etude.source === 'Interne' ? item.devis_no_interne : item.devis_no_externe

                this.prospectDevisLocalKeeper.push(item)
                const indexKeep = this.prospectDevisLocalKeeper.findIndex(p => item.id === p.id)
                if(indexKeep > -1){
                    this.prospectDevisLocalKeeper[indexKeep].devisNo = devisNo
                }
                if(item.statut != 'Devis cloture sans suite'){
                  this.prospectDevisLocal.push(item)
                  const index = this.prospectDevisLocal.findIndex(p => item.id === p.id)
                  if(index > -1){
                    this.prospectDevisLocal[index].devisNo = devisNo
                  }
                  arrayOfProjects.push({
                    idContrat: '',
                    idEtude: item.etude_id,
                    idDevis: item.id,
                    Numero: devisNo,
                    source: item.etude.source,
                    type: 'Projet'
                  })
                }
              })
              this.$emit('refresh-data-projets', this.prospectDevisLocalKeeper.length)
              this.$emit('project-contrat-data-fetching', arrayOfProjects)
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            this.prospectDevisLocalKeeper = []
            this.prospectDevisLocal = []
            console.error(err)
          })
    },
    fetchprospectDevis() {
      this.prospect = null
      this.$http
          .get(`/prospect/fetchDataTiersPro/${this.prospectChosen.id}/${this.prospectChosen.type}`)
          .then(res => {
            if (res.data.success) {
              this.prospect = res.data.data
            }
          })
          .catch(err => {
            this.prospect = null
            console.error(err)
          })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    updateProjectExterne(data) {
      this.projetForUpdate.idEtude = data.etude.id
      this.projetForUpdate.idDevis = data.id
      this.projetForUpdate.client = data.devisable
      this.projetForUpdate.risque = data.risque_id
      this.projetForUpdate.assureur = data.assureur_id
      this.projetForUpdate.statut = data.statut
      this.projetForUpdate.num_devis = data.devis_no_externe
      this.projetForUpdate.date_effet = this.formattingDateSql(data.date_effet)
      this.projetForUpdate.date_creation = this.formattingDateSql(data.date_creation)
      this.projetForUpdate.budget_propose = data.prime
      this.projetForUpdate.budget_actuel = data.prime_actuel
      this.projetForUpdate.courtier_id = data.courtier_id
      this.projetForUpdate.user_id = data.user_id

      if (data.origine != null) {
        if (data.origine == 'Co_courtage' || data.origine == 'Indicateur_affaires' || data.origine == 'Weedo_market' || data.origine == 'Recommandation' || data.origine == 'Réseaux_sociaux') {
          this.projetForUpdate.origine = data.origine
        } else {
          this.projetForUpdate.origine = 'Autre'
          this.projetForUpdate.origineautre = data.origine
        }
      }else{
        this.projetForUpdate.origine = null
      }

      console.log(data.createur_devis)
      
      if (data.createur_devis === null) {
        this.projetForUpdate.createur = null
      } else {
        this.projetForUpdate.createur = {
          id: data.createur_devis.user.personne_physique.user_id,
          nom: `${data.createur_devis.user.personne_physique.prenom} ${data.createur_devis.user.personne_physique.nom}`,
          type: data.createur_devis.user.personne_physique.type
        };
      }

      if (data.user === null) {
        this.projetForUpdate.user = null
      } else {
        this.projetForUpdate.user = {
          id: data.user.personne_physique.user_id,
          nom: `${data.user.personne_physique.prenom} ${data.user.personne_physique.nom}`,
          type: data.user.personne_physique.type
        };
      }



      this.projetForUpdate.intermediaire = [];
      if (data.courtiers_users_by_intermediares && data.courtiers_users_by_intermediares.length > 0) {
        data.courtiers_users_by_intermediares.forEach(courtier => {
          this.projetForUpdate.intermediaire.push({
            id: courtier.user.personne_physique.user_id,
            nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
            type: courtier.user.personne_physique.type
          });
        });
      }

                  console.log(data.projetForUpdate)




    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    recordDuplicateProjet(data, modalName = null) {
      this.showLoading = true
      this.$http.post(`devis/replicateDevis/${data.devisId}`, data).then(response => {
          if (response.data.success) {
            this.messageToast(response.data.message, 'Succès', 'success')
            if (response.data.data.etude_id !== null) {
              this.$router.push({
                name: 'projets-proposition',
                params: {
                  id: response.data.data.etude_id
                }
              })
            } else this.prospectDevisList()
            this.$bvModal.hide(modalName)
          }
        })
        .catch(err => {
          this.showLoading = false
          console.log(err)
        })
    },
    submitDuplicateProjet() {
      this.$refs.formRenewalRules.validate().then(success => {
        if (success) {
          let data = {
            devisId: this.selected_devisId,
            dateEffet: this.selected_date_effet,
            action: 'renewal'
          }
          this.recordDuplicateProjet(data, 'modal_renewal')
        }
      });
    },
    redirectprojet(value) {
      this.checkDateEffet(value)
    },
    redirectExtranet(devis) {
      if(devis.espace_link) {
        window.open(devis.espace_link, '_blank');
      }
    },
    checkDateEffet(value) {
      let data = {
        devisId: value.id,
        checkOnlyChoixClient: value.statut == 'En attente signature' ? 1 : 0
      }
      this.$http.post(`devis/checkDateEffet`, data)
        .then(response => {
          if (response.data.expired) {
            this.selected_devisId = value.id
            this.title_modal = response.data.titre
            this.msg_modal   = response.data.message
            this.$bvModal.show('modal_renewal')
          } else {
            const etudeId = value.etude_id
            this.$store.dispatch('clearData')
            if (value.devis_avancement == 'TARIFICATION') {
              this.$router.push({
                name: 'projets-proposition',
                params: { id: etudeId }
              })
            }
            if (value.devis_avancement == 'SOUSCRIPTION') {
              this.$router.push({
                name: 'projets-souscription',
                params: { id: etudeId }
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    renvoyerSignature(){
      this.disableRenvoyer = true
      this.$http.post("portefeuilles/renvoyerSignature",this.renvoyerDataForm).then((res)=>{
        console.log(res.data);
        this.messageToast(res.data.message, 'Succès', 'success')
        this.$refs['renvoyer_signature'].hide()
        this.clearAllFilter()
        this.prospectDevisList()


      }).catch((err)=>{
        console.log(err);
        this.messageToast(err.response.data.message, 'Erreur', "danger")

      }).finally(()=>{
        this.disableRenvoyer = false
      })
      
    },
    resetRenvoyerSignatureModal(){
      this.renvoyerDataForm.email = "";
      this.renvoyerDataForm.indicatif = "";
      this.renvoyerDataForm.telephone = "";
      this.renvoyerDataForm.idDevis = "";
      this.renvoyerDataForm.idMoyContact = "";    
      this.renvoyerDataForm.numDevis = "";    
      this.renvoyerDataForm.nomClient = "";  
      this.renvoyerDataForm.lien_signature = "resend";  
      this.disableRenvoyer = false

    },
    fillRenvoyerSignatureModalData(row){
      console.log(row)
      this.renvoyerDataForm.email = row.devisable.moyen_contact.email;
      this.renvoyerDataForm.indicatif = row.devisable.moyen_contact.indicatif_tel;
      this.renvoyerDataForm.telephone = row.devisable.moyen_contact.tel;
      this.renvoyerDataForm.idDevis = row.id;
      this.renvoyerDataForm.idMoyContact = row.devisable.moyen_contact.id;    
      this.renvoyerDataForm.numDevis = row.devis_no_interne;    
      this.renvoyerDataForm.nomClient = row.devisable.nom;    
      this.renvoyerDataForm.lien_signature = "resend";  

      console.log(this.renvoyerDataForm);      
    },
    checkDateEnvoiSignValide(date_envoi_signature){
      
      const signatureDate = moment(date_envoi_signature);
      const expirationDate = signatureDate.add(14, "days");
      const now = moment();
      
      return now.isBefore(expirationDate);
    },

    sendSignatureLinkBySms(){
      this.disableRenvoyer = true
      this.$http.post("portefeuilles/sendSignatureLinkBySms",this.renvoyerDataForm).then((res)=>{
        console.log(res.data);
        this.messageToast(res.data.message, 'Succès', 'success')
        this.$refs['renvoyer_signature_sms'].hide()
      }).catch((err)=>{
        console.log(err);
        this.messageToast(err.response.data.message, 'Erreur', "danger")

      }).finally(()=>{
        this.disableRenvoyer = false
      })
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


input#indicatif.vs__search{
  padding: 0px;
}
.indicatif-chooser .vs__selected-options{
  height: 31px;
}
.indicatif-chooser .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
  width:111%;
}
.v-select .indicatif-chooser .vs--open .vs--single .vs--searchable
{
  min-width: 30%;
  width:30%;
}
</style>
