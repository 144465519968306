import axios from '@axios'
import url from '@/shared/api/parametre'

export default function fetchDocumentNatureWithTypes() {
    return new Promise((resolve, reject) => {
        axios
            .post(`${url}document_nature/fetch_document_nature_with_types`)
            .then(response => {
                resolve(response)
            })
            .catch(error => reject(error))
    })
}