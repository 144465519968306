<template>
  <div>
    <b-button v-b-toggle.sidebar-sinistre v-if="interfaceChosen == 'Listing' && (can('Make claim') || isPrincipal())" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" @click="$emit('is-update-function', false)">
      <feather-icon icon="PlusIcon" />
      <span class="align-middle"> Enregistrer un sinistre</span>
    </b-button>
    <b-sidebar id="sidebar-sinistre" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarSinistre" shadow backdrop no-header right @hidden="clearDataSideBar">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5  v-if="isUpdate" class="mb-0 text-uppercase text-primary font-weight-bolder"> Modifier le sinistre {{ sinistre.numero_sinistre }}</h5> <h5 v-else class="mb-0 text-uppercase text-primary font-weight-bolder" >Enregistrer un sinistre</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideSideBare" />
        </div>
        <validation-observer ref="sinistreComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <h4>INFORMATIONS</h4>

             <!-- numero sinistre -->
            <b-form-group label="N° du sinistre*" label-for="numero_sinistre">
              <validation-provider #default="{ errors }" name="n° sinistre" rules="required">
                <b-form-input id="numero_sinistre" v-model="sinistre.numero_sinistre"  :state="errors.length > 0 ? false : null" placeholder="N° du sinistre" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- canal sinistre -->
            <!-- <b-form-group label="Canal de sinistre*" label-for="canal-sinistre">
              <validation-provider #default="{ errors }" name="canal de sinistre" rules="required">
                <b-form-select  v-model="sinistre.canal_sinistre" :state="errors.length > 0 ? false : null"  id="canal-sinistre" :options="canalOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->


            <!-- <b-form-group label="Date d’accusé de réception*" label-for="date-réception">
              <validation-provider #default="{ errors }" name="date d'accusé de réception" rules="required">
                <flat-pickr id="date-réception" v-model="sinistre.date_acc_recep" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date d'accusé de réception" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->

            <h4>LE SINISTRE</h4>
          
            <div class="form-group-wrapper">
              <b-form-group label="Le sinistré existe-t-il dans votre CRM ?" class="inline-legend mt-2 display-flex flex-column">
                <b-form-radio-group v-model="sinistre.is_client" :options="crmExiste" class="demo-inline-spacing" name="radio-inline" />
              </b-form-group>
            </div>

            <div v-if="!sinistre.is_client">
              <b-form-group label="Nom du sinistré*" label-for="nom">
                <validation-provider #default="{ errors }" name="nom du sinistré" rules="required">
                  <b-form-input id="nom" v-model="sinistre.sinistree.nom" :state="errors.length > 0 ? false : null" placeholder="Nom de sinistré" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Prenom de sinistré*" label-for="prenom">
                <validation-provider #default="{ errors }" name="prenom du sinistré" rules="required">
                  <b-form-input id="prenom" v-model="sinistre.sinistree.prenom" :state="errors.length > 0 ? false : null" placeholder="Prenom de sinistré" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div>

            <div v-else class="mb-2">
                <b-form-group label="Sinistré*" label-for="prospect">
                  
                  <v-select  v-model="sinistre.sinistree" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="prospectOptions" class="flex-grow-1" input-id="client" label="client" placeholder="Sinistré" >
                    <template #option="{ avatar, client, categorie }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ client}} </span>
                      <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                        {{ capitalizeFirstLetter(categorie) }}
                      </b-badge>
                    </template>

                    <template #selected-option="{ avatar, client }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ client}}</span>
                    </template>
                    <div slot="no-options">Aucune prospect disponible.</div>
                  </v-select>
                </b-form-group>
            </div>

                      <!-- contratOptions -->
            <!-- <b-form-group label="Contrat" label-for="contrat">
              <b-form-select id="contrat" v-model="sinistre.contrat_id" :options="contratsOptions">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group> -->

            <div v-if="sinistre.is_client">
              <b-form-group label="Le contrat existe-t-il dans votre CRM ?" class="inline-legend mt-2 display-flex flex-column">
                <b-form-radio-group v-model="sinistre.is_contrat" :options="crmContratExiste" class="demo-inline-spacing" name="radio-inline-contrat" />
              </b-form-group>
            </div>
            <!-- contratOptions -->
            <b-form-group label="Numéro de contrat*" label-for="Numéro de contrat">
              <validation-provider #default="{ errors }" name="numéro de contrat" rules="required">
              <v-select v-if="sinistre.is_client && sinistre.is_contrat" v-model="sinistre.reference" :options="projetContratData" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="id" label="id" placeholder="Sélectionner un contrat">
                <template #option="{ numero ,type }">
                  <span class="ml-50"> {{ numero }}</span>
                  <b-badge :variant="sourceVariant(type)" class="mr-2 float-right"> {{ type === 'PROJET' ? 'DEVIS' : capitalizeFirstLetter(type) }} </b-badge>
                </template>

                <template #selected-option="{ numero }">
                  <span class="ml-50"> {{ numero }}</span>
                </template>
                <div slot="no-options">Aucune contrat disponible.</div>
                
              </v-select>
              <b-form-input v-else-if="!sinistre.is_client || !sinistre.is_contrat" id="contrat_no" v-model="sinistre.reference.ref_externe" placeholder="Précisez Numéro de contrat*" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
               </validation-provider>
            </b-form-group>

            <!-- <div v-if="sinistre.is_client && sinistre.reference.numero == 'Autre'">
              <b-form-group label="Précisez Numéro de contrat*" label-for="Numéro de contrat">
                <validation-provider #default="{ errors }" name="numéro de contrat" rules="required">
                  <b-form-input id="contrat_no" v-model="sinistre.reference.ref_externe" placeholder="Précisez Numéro de contrat*" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div> -->
  
            <!-- risqueOptions -->
            <div v-if="!sinistre.is_client || !sinistre.is_contrat">
              <b-form-group label="Risque*" label-for="risque">
                <validation-provider #default="{ errors }" name="risque" rules="required">
                <b-form-select id="risque" v-model="sinistre.risque_id" :options="risqueOptions">
                  <template #first>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
               </validation-provider>
              </b-form-group>
            </div>  
              <!-- assureurOptions -->
              <div v-if="!sinistre.is_client || !sinistre.is_contrat">
              <b-form-group label="Assureur" label-for="assureur">
                <b-form-select id="assureur" v-model="sinistre.assureur_id" :options="assureurOptions">
                  <template #first>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
  
            <h4>DÉTAIL DU SINISTRE</h4>

           

            <b-form-group label="Date du sinistre*" label-for="date-sinistre">
              <validation-provider #default="{ errors }" name="date du sinistre" rules="required">
                <flat-pickr id="date-sinistre" v-model="sinistre.date_sinistre" class="form-control" placeholder="Date de sinistre" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de déclaration*" label-for="date-declaration">
              <validation-provider #default="{ errors }" name="date de déclaration" rules="required">
                <flat-pickr id="date-declaration" v-model="sinistre.date_declaration" class="form-control" placeholder="Date de déclaration" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

             <b-form-group label="Statut du sinistre*" label-for="statut">
              <validation-provider #default="{ errors }" name="statut" rules="required">
                <b-form-select v-model="sinistre.statut" :options="statutOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            
            <b-form-group label="Type de sinistre*" label-for="type">
              <validation-provider #default="{ errors }" name="type de sinistre" rules="required">
                <b-form-select v-model="sinistre.type" :options="typeOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> 

            <b-form-group label="Responsabilité*" label-for="responsabilite">
              <validation-provider #default="{ errors }" name="responsabilité" rules="required">
                <b-form-input id="responsabilite" v-model="sinistre.responsabilite" placeholder="Responsabilité" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Montant du sinistre" label-for="montant-sinistre">
              <validation-provider #default="{ errors }" name="montant-sinistre">
                <b-form-input id="montant-sinistre" v-model="sinistre.montant" placeholder="Montant du sinistre" :state="errors.length > 0 ? false : null"  type="number" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Sinistre en délégation*" label-for="sinistre_en_delegation">
              <validation-provider #default="{ errors }" name="sinistre en délégation" rules="required">
                <b-form-radio-group v-model="sinistre.isDelegation" :options="prospectBoolean" class="demo-inline-spacing" name="radio-inline-pm" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group v-if="sinistre.statut == 'CLOS'" label="Date de clôture" label-for="date-declaration">
              <validation-provider #default="{ errors }" name="date de clôture">
                <flat-pickr id="date-declaration" v-model="sinistre.date_cloture" class="form-control" placeholder="Date de clôture" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Coordonnées de l’expert" label-for="expert">
              <validation-provider #default="{ errors }" name="coordonnées de l’expert">
                <b-form-input id="expert" v-model="sinistre.expert" placeholder="Coordonnées de l’expert" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>


            <b-form-group  label="Gestionnaire*" label-for="gestionnaire" >
              <validation-provider v-slot="{ errors }" name="gestionnaire" rules="required">
                  <v-select v-model="sinistre.gestionnaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="gestionnaireOptions" class="flex-grow-1" input-id="gestionnaire" label="nom" placeholder="Gestionnaire" required>
                    <template #option="{ avatar, nom }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>

                    <template #selected-option="{ avatar, nom }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>
                    <div slot="no-options">Aucune gestionnaire disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>

                  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordSinistre"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BFormGroup, BFormRadioGroup, BFormInput, BForm, BInputGroup, BFormInvalidFeedback, BFormSelect, BAvatar, BBadge, BFormSelectOption } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import SinistreOptions from "@/shared/constants/sinistre"
import moment from 'moment'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BBadge,
    Cleave,
    vSelect,
    BFormSelect,
    flatPickr,
    BAvatar,
    BFormSelectOption,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    interfaceChosen: {
        type: String,
        default: "Listing",
        required: false
      },
    risqueOptions : {
      type: Array,
      default: [],
      required: true
    },
    assureurOptions : {
      type: Array,
      default: [],
      required: true
    },
    gestionnaireOptions:{
      type: Array,
      default: [],
      required: true
    },
    projetContratData: {
        type: Array,
        default: [],
        required: false
      },
    isUpdate: {
      type: Boolean,
      default: true,
      required: true
    },
    sinistreForEdit: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        id: null,
              numero_sinistre: null,
              canal_sinistre : null,
              date_acc_recep : null,
              is_client:false,
              isClos  : false,
              is_contrat:false,
              sinistree: {
                id: null,
                type: null,
                categorie: null,
                client: null,
                nom: null,
                prenom: null
              },
                reference: {
                id: null,
                numero: null,
                source: null,
                type: null,
                ref_externe: null,
                assureur_id:null,
                risque_id:null
              },
              risque_id : null,
              assureur_id : null,
              courtier_id: null,
              date_sinistre: null,
              type : null,
              statut: null,
              responsabilite : null,
              date_declaration: null,
              montant : null,
              isDelegation: 0,
              expert : null,
              date_cloture : null,
              gestionnaire : null,
      
      required: false
      },
    },
    currentUser: {
      type: Object,
      default: null,
      required: false
    },
    prospectOptions: {
        type: Array,
        default: [],
        required: true,
      },
    isFicheClient: {
      type: Boolean,
      default: false,
      required: false
    },
    isFicheContrat: {
        type: Boolean,
        default: false,
        required: false
      },
    tiersData: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return this.initialState()
  },
  watch: {

     'sinistre.is_client': {
        handler(val) {
          if(!this.isUpdate && !this.isFicheContrat) {
            this.sinistre.sinistree = this.initialState().sinistre.sinistree
            this.sinistre.reference = this.initialState().sinistre.reference
          }

        }
      }, 

    isUpdate: {
      immediate: true,
      handler(val) {
        if(!val) {
          this.initCurrentClient()
        }
        this.isUpdateAction = val
      }
    },
    'sinistre.sinistree': {
        handler(val, old) {
          if(val && val.id && val.type && val.id != old.id) {
            this.$emit('fetch-data-ref', this.sinistre.sinistree)
          }
        }
      },


    isUpdateAction: {
      immediate: true,
      handler(val) {
        if (val) {
          this.sinistre = this.sinistreForEdit
        } else {
          this.clearDataSideBar()
        }
      }
    },
    sinistreForEdit: {
      deep: true,
      handler(val) {
        if (this.isUpdateAction) {
          this.sinistre = val
        }
      }
    },

    tiersData: {
      handler(val) {
        this.initCurrentClient()
      }
    }
   
  },

  computed: {
      categorieVariant() {
        const categorieColor = {
          PARTICULIER: 'light-danger',
          PROFESSIONNEL: 'light-warning',
          ENTREPRISE: 'light-success'
        }
        return categories => categorieColor[categories]
      },
      /* selectContrat(){
          if (this.projetContratData.length > 0) {
          return this.projetContratData;
        } else {
          return [{ numero: 'Autre', type: 'AUTRE' }];
        }
      } */
    },

  methods: {
    initialState() {
        return {
            required,
            isUpdateAction: false,
            configDate: {
              dateFormat: 'd/m/Y',
              allowInput: true,
              onKeyDown: (selectedDates, dateStr, instance, event) => {
                if (event.keyCode != 8) {
                  var ele = document.activeElement
                  var val = ele.value

                  if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                    val += '/'
                  }
                  ele.value = val
                  ele.dispatchEvent(new Event('input'))
                }
              }
              },

            sinistre: {
              id: null,
              numero_sinistre: null,
              canal_sinistre : null,
              date_acc_recep : null,
              is_client:false,
              sinistree: {
                id: null,
                type: null,
                categorie: null,
                client: null,
                nom: null,
                prenom: null
              },
              reference: {
                id: null,
                numero: null,
                source: null,
                type: null,
                ref_externe: null,
                assureur_id:null,
                risque_id:null
              },
              risque_id : null,
              assureur_id : null,
              courtier_id: null,
              date_sinistre: null,
              type : null,
              statut: null,
              responsabilite : null,
              date_declaration: null,
              montant : null,
              isDelegation: null,
              expert : null,
              date_cloture : null,
              gestionnaire : null,
            },


            canalOptions : SinistreOptions['CANALS'],
            prospectBoolean: SinistreOptions['PROSPECTBOOLEAN'],
            crmExiste : SinistreOptions['CRMEXISTE'],
            crmContratExiste : SinistreOptions['CONTRATCRMEXISTE'],
            statutOptions : SinistreOptions['STATUS'],
            typeOptions : SinistreOptions['TYPES'],
        } 
      },
      
    /* checkIfAutreSelected()
    {
      this.isAutreSelected = this.sinistre.reference.numero === 'Autre';
    }, */
    hideSideBare() {
      this.$refs.sideBarSinistre.hide()
      this.clearDataSideBar()
    },
    // Methode additional
    clearDataSideBar() {
      this.sinistre = this.initialState().sinistre
      this.initCurrentClient()
      this.sinistre.courtier_id = this.currentUser.courtier_user[0].courtier_id
      this.getAuthCourtier()
      this.$refs.sinistreComposeRules.reset()
    },

    getAuthCourtier(){
      let userData = JSON.parse(localStorage.getItem('userData'))
      let gestionnaireSelected = {
        id: userData.personne_physique.user_id,
        type : userData.personne_physique.type,
        nom: userData.personne_physique.nom + ' ' + userData.personne_physique.prenom
      }
      this.sinistre.gestionnaire = gestionnaireSelected
    
    },
    
    recordSinistre(){
        this.showLoading = true
        this.$refs.sinistreComposeRules.validate().then(success => {
            if (success) {
              this.$http
                .post('/sinistre/storeSinistre', {
                  sinistre: this.sinistre
                })
                .then(res => {
                  if (res.data.success) {
                    this.showLoading = false
                    
                    if(this.interfaceChosen == 'FicheSinistre'){
                      this.$emit('refresh-sinistre');
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.hideSideBare()
                    }else{
                      this.$emit('fetch-sinisters-list')
                      this.$emit('record-sinistre-returned', res.data.data, res.data.message)
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.hideSideBare()
                    }     
                  }
                  this.showLoading = false
                })
                .catch(err => {
                  this.showLoading = false
                  console.error(err)
                })
            } else {
              this.showLoading = false
            }
        })
      },

      initCurrentClient() {
        if(!this.isUpdate && this.isFicheClient && this.tiersData) {

          this.sinistre.is_client = true
          this.sinistre.sinistree  = {
            id: this.tiersData.id,
            type: this.tiersData.type,
            categorie: this.tiersData.categorie,
            client: this.tiersData.type === 'PERSONNE_MORALE' ? this.tiersData.denominationCommercial : this.tiersData.nom + ' ' + this.tiersData.prenom,
            nom: null,
            prenom: null
          }
        }

        else if(!this.isUpdate && this.isFicheContrat && this.tiersData && this.tiersData.contrable){
          
           this.sinistre.is_client = true;
           this.sinistre.is_contrat = true;
           this.sinistre.sinistree  = {
            id: this.tiersData.contrable.id,
            type: this.tiersData.contrable.type,
            categorie: this.tiersData.contrable.categorie,
            client: this.tiersData.contrable.type === 'PERSONNE_MORALE' ? this.tiersData.contrable.denominationCommercial : this.tiersData.contrable.nom + ' ' + this.tiersData.contrable.prenom,
            nom: null,
            prenom: null
          }
          this.sinistre.reference.id = this.tiersData.id;
          this.sinistre.reference.numero = this.tiersData.police_no;
          this.sinistre.reference.source = this.tiersData.source;
          this.sinistre.reference.type = 'CONTRAT';
          this.sinistre.reference.assureur_id = this.tiersData.assureur_id;
          this.sinistre.reference.risque_id = this.tiersData.risque_id;
          
        }
      }

    
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.display-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
</style>
