<template>
  <div>
    <b-sidebar id="sidebar-document" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarDocument" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="documentComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->

            <b-form-group label="Famille de document" label-for="h-famille" >
              <validation-provider #default="{ errors }" name="famille de document" rules="required">
                <b-form-select id="h-famille-autre-document" v-model="famille" :options="familleDocuments" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document" label-for="h-type" v-if="!hidetype">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="sélectionner un devis/contrat" rules="required" v-if="composeData.idFamille==4">
              <b-form-group label="devis/contrat" :state="errors.length > 0 ? false : null" label-for="h-selectionner-projet-contrat" v-if="composeData.idFamille==4">
                <v-select v-model="composeData.projetContrat" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projetContratData" input-id="projetcontrat" label="Numero" placeholder="Sélectionner un projet/contrat">
                  <template #option="{ idContrat, idEtude, idDevis, Numero ,type }">
                    <span class="ml-50"> {{ Numero }}</span>
                    <b-badge :variant="sourceVariant(type)" class="mr-2 float-right"> {{ capitalizeFirstLetter(type) }} </b-badge>
                  </template>

                  <template #selected-option="{ Numero }">
                    <span class="ml-50"> {{ Numero }}</span>
                  </template>
                  <div slot="no-options">Aucune projet/contrat disponible.</div>
                </v-select>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="N° Réclamations" label-for="r-recla" v-if="composeData.idFamille == '9'">
              <validation-provider #default="{ errors }" name="N° Réclamations" rules="required">
                <b-form-select id="r-recla" v-model="composeData.reclamation" :options="optionsReclamation" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="N° Sinistre" label-for="r-Sinistre" v-if="composeData.idFamille  == '10'">
                <validation-provider #default="{ errors }" name="N° Sinistre" rules="required">
                  <b-form-select id="r-Sinistre" v-model="composeData.sinistre" :options="optionsSinistre" :state="errors.length > 0 ? false : null">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

            <b-form-group label="Nom du document *" label-for="h-nom-document"  v-if="isAutre">
              <validation-provider #default="{ errors }" name="nom du document" rules="required">
                <b-form-input id="h-nom-document-autre-document" v-model="composeData.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- 
              <b-form-group label="Description" label-for="h-Description">
                <b-form-textarea id="h-Description-autre-document" placeholder="Description" rows="3" v-model="composeData.description" />
              </b-form-group> -->

            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautreDocumentEntre" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordDocument"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BButton, BBadge, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/named
import vSelect from 'vue-select'
import documentService from '@/shared/services/documentService'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,

    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    projetContratData: {
      type: Array,
      default: [],
      required: false
    },
    prospectChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true
    },
    familleDocuments: {
      type: Array,
      default: () => [],
      required: false,
    }
  },
  data() {
    return {
      required,
      famille: null,
      optionsType: [],
      isAutre :false,
      hidetype:false,
      optionsReclamation: [],
      optionsSinistre: [],
      composeData: {
        id: null,
        nom: null,
        description: '',
        idFamille: null,
        idType: null,
        nameType: null,
        reclamation: null,
        file: null,
        courtier_id: this.courtierId,
        sinistre: null,
      }
    }
  },
  watch: {
    'composeData.idFamille': {
      handler(val) {
        if (val == '9') {
          this.fetchreclamtion()
        }
        if (val == '10') {
          this.fetchSinistre()
        }
        if (val != 4) {
          delete this.composeData.projetContrat
        }
      }
    },
    'composeData.idType': {
      handler(val) {
        if (val) {
          this.composeData.nameType = this.optionsType.find(item => item.value === val).text
        }else{
          this.composeData.nameType = null
        }
      }
    }
  },
  mounted() {
    this.$watch(
      (vm) => [vm.composeData.idFamille,vm.composeData.idType],
      (val) => {
          if( val[1] == 54 || val[1] == 55 || val[0]==6 || val[1] == 85 ){
            this.isAutre =true 
          }else{
              this.isAutre =false
          }
      },
      
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.clearData()
      this.$refs.sideBarDocument.hide()
    },
    openToSelectFile: () => {
      document.getElementById('fileautreDocumentEntre').click()
    },
    changeListoptionType() {
      if (this.famille) {
        if(this.famille.id == '9' || this.famille.id == '10'){
          this.hidetype = true
          this.composeData.idType = 85
        }
        else{
          this.hidetype = false
          this.composeData.idType = null

        }
        this.composeData.idFamille = this.famille.id
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
    clearData() {
      this.hidetype = false
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = ''
      this.composeData.idType = null
      this.composeData.nameType = null
      this.composeData.idFamille = null
      this.famille = null
      this.composeData.file = null
      this.composeData.reclamation = null

      this.composeData.courtier_id = this.courtierId
      this.$refs.documentComposeRules.reset()
    },

    // record Data document courtier
    recordDocument() {
      this.$refs.documentComposeRules.validate().then(success => {
        if (success) {
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data
          formData.append('id', this.composeData.id ? this.composeData.id : '')
          formData.append('nom', this.composeData.nom && this.composeData.nom != null && this.composeData.nom != '' && this.composeData.nom != 'null' ? this.composeData.nom : this.composeData.file.name.substring(0, this.composeData.file.name.lastIndexOf(".")))
          formData.append('note', this.composeData.description ? this.composeData.description : '')
          formData.append('type_doc_id', this.composeData.idType)
          formData.append('type_doc_nom', this.composeData.nameType)
          formData.append('file', this.composeData.file)
          formData.append('courtier_id', this.composeData.courtier_id)
          formData.append('type_tiers', this.prospectChosen.type)
          formData.append('tiers', this.prospectChosen.id)
          formData.append('reclamation_id', this.composeData.reclamation)
          formData.append('isTransformerDataReturned', '1')

          if (this.composeData.projetContrat && this.composeData.projetContrat != 'undefined') {
            formData.append('isProjctOrContrat', this.composeData.projetContrat.type)
            formData.append('idEtude', this.composeData.projetContrat.idEtude ? this.composeData.projetContrat.idEtude : '')
            formData.append('idDevis', this.composeData.projetContrat.idDevis ? this.composeData.projetContrat.idDevis : '')
            formData.append('idContrat', this.composeData.projetContrat.idContrat ? this.composeData.projetContrat.idContrat : '')
          }

          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/createNewDocumentTier', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-client-returned', res.data.data, res.data.message)
                this.clearData()
                this.hideSideBare()

              } else {
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.error(err)
            })
        }
      })
    },
    fetchSinistre() {

      console.log('111111111111111')
    },
    fetchreclamtion() {
      console.log(1)
      console.log(this.prospectChosen.id)
      this.showLoading = true
      this.optionsReclamation = [];
      this.$http.get(`/reclamations/getReclamationClient/${this.prospectChosen.type}/${this.prospectChosen.id}`)
          .then(res => {
            console.log(res)
            if (res.data.success) {
              res.data.data.forEach(e1=>{
                this.optionsReclamation.push({
                  value: e1.id,
                  text: e1.num_reclamation
                })
              })
              this.showLoading = false            
            }
                      
          })
          .catch(err => {
            this.showLoading = false
            this.optionsReclamation = [];
            console.error(err)
          })
       
       
    },
  },

  // setup() {
  //   const optionsFamille = ref([])

  //   const fetchDocumentNatureWithTypes = documentService()
  //   fetchDocumentNatureWithTypes
  //     .then(success => {
  //       optionsFamille.value = []
  //       success.data.forEach(el => {
  //         optionsFamille.value.push({
  //           value: { id: el.id, typeDocument: el.documentype },
  //           text: el.libelle
  //         })
  //       })
  //     })
  //     .catch(errors => {
  //       console.log(errors)
  //       optionsFamille.value = []
  //     })

  //   return {
  //     fetchDocumentNatureWithTypes,
  //     optionsFamille
  //   }
  // }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
