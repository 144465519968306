/* eslint-disable eol-last */

const colorPicker = {
        colors: [
            {
                value: 'rgb(0, 0, 0)'
            },
            {
                value: 'rgb(230, 0, 0)'
            },
            {
                value: 'rgb(255, 153, 0)'
            },
            {
                value: 'rgb(255, 255, 0)'
            },
            {
                value: 'rgb(0, 138, 0)'
            },
            {
                value: 'rgb(0, 102, 204)'
            },
            {
                value: 'rgb(153, 51, 255)'
            },
            {
                value: 'rgb(255, 255, 255)'
            },
            {
                value: 'rgb(250, 204, 204)'
            },
            {
                value: 'rgb(255, 235, 204)'
            },
            {
                value: 'rgb(255, 255, 204)'
            },
            {
                value: 'rgb(204, 232, 204)'
            },
            {
                value: 'rgb(204, 224, 245)'
            },
            {
                value: 'rgb(235, 214, 255)'
            },
            {
                value: 'rgb(187, 187, 187)'
            },
            {
                value: 'rgb(240, 102, 102)'
            },
            {
                value: 'rgb(255, 194, 102)'
            },
            {
                value: 'rgb(255, 255, 102)'
            },
            {
                value: 'rgb(102, 185, 102)'
            },
            {
                value: 'rgb(102, 163, 224)'
            },
            {
                value: 'rgb(194, 133, 255)'
            },
            {
                value: 'rgb(136, 136, 136)'
            },
            {
                value: 'rgb(161, 0, 0)'
            },
            {
                value: 'rgb(178, 107, 0)'
            },
            {
                value: 'rgb(178, 178, 0)'
            },
            {
                value: 'rgb(0, 97, 0)'
            },
            {
                value: 'rgb(0, 71, 178)'
            },
            {
                value: 'rgb(107, 36, 178)'
            },
            {
                value: 'rgb(68, 68, 68)'
            },
            {
                value: 'rgb(92, 0, 0)'
            },
            {
                value: 'rgb(102, 61, 0)'
            },
            {
                value: 'rgb(102, 102, 0)'
            },
            {
                value: 'rgb(0, 55, 0)'
            },
            {
                value: 'rgb(0, 41, 102)'
            },
            {
                value: 'rgb(61, 20, 102)'
            }

        ]
    }
export default colorPicker