<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row class="mt-2">
      <b-col class="mb-2" md="12" sm="12" v-if="dirigeantData.civilite && dirigeantData.prenom && dirigeantData.nom">
        <span class="h3 font-weight-bolder">
          {{ checkIsNull(dirigeantData.civilite) + ' ' + checkIsNull(dirigeantData.prenom) + ' ' + checkIsNull(dirigeantData.nom) }}
        </span>
        <b-button variant="flat-primary" class="btn-icon rounded-circle" v-b-toggle.sidebar-dirigeant-client-entreprise>
          <feather-icon icon="EditIcon" size="20"/>
        </b-button>
        <update-dirigeant :dirigeant-data="dirigeantData" @update-dirigeant-client-entreprise-returned="UpdateDirigeantClientEntrepriseReturned"/>
      </b-col>
      <b-col class="mb-1" md="12" sm="12" v-if="dirigeantData.moyen_contact && dirigeantData.moyen_contact.email">
        <strong>E-mail : </strong> {{ dirigeantData.moyen_contact.email }}
      </b-col>
      <b-col class="mb-1" md="12" sm="12" v-if="dirigeantData.moyen_contact && dirigeantData.moyen_contact.tel_secondaire">
        <strong>Téléphone Portable : </strong> {{ checkIsNull(dirigeantData.moyen_contact.tel) }}
      </b-col>
      <b-col class="mb-1" md="12" sm="12" v-if="dirigeantData.moyen_contact && dirigeantData.moyen_contact.tel">
        <strong>Téléphone Fixe du dirigeant : </strong> {{ checkIsNull(dirigeantData.moyen_contact.tel_secondaire) }}
      </b-col>
      <b-col
          v-if="dirigeantData.moyen_contact.libelle ||
                dirigeantData.moyen_contact.complement_adresse ||
                dirigeantData.moyen_contact.lieu_dit_ou_bp ||
                dirigeantData.moyen_contact.code_postal ||
                $_.get(dirigeantData.moyen_contact.ville, 'commune', '')"
          class="mb-1" md="12"
          sm="12"
      >
        <strong>Adresse : </strong>
        {{
          checkIsNull(dirigeantData.moyen_contact.libelle, true)+
          checkIsNull(dirigeantData.moyen_contact.complement_adresse, true)+
          checkIsNull(dirigeantData.moyen_contact.lieu_dit_ou_bp, true)+
          checkIsNull(dirigeantData.moyen_contact.code_postal, true)+
          checkIsNull($_.get(dirigeantData.moyen_contact.ville, 'commune', ''), false)
        }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BOverlay, VBToggle, BCol, BRow } from 'bootstrap-vue'
import updateDirigeant from './UpdateDirigeant.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BVT
    BButton,
    BOverlay,
    BRow,
    BCol,

    // UI
    updateDirigeant
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    prospectId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      showLoading: false,
      dirigeantData: {
        id: null,
        type: null,
        nom: null,
        prenom: null,
        civilite: null,
        moyen_contact: {
          id: null,
          tel: null,
          tel_secondaire: null,
          email: null,
          libelle: null,
          code_postal: null,
          ville_id: null,
          complement_adresse: null,
          lieu_dit_ou_bp: null,
          personne_physique_id: null,
          ville: {
            id: null,
            code_postal: null,
            commune: null,
          }
        }
      },
    }
  },
  watch: {
    prospectId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.getDirigeantByPersonneMorale(id)
        }
      }
    }
  },
  methods: {
    UpdateDirigeantClientEntrepriseReturned(data, message){
      if (data === 'OK'){
        this.getDirigeantByPersonneMorale(this.prospectId)
        this.messageToast(message, 'Succès', 'success')
      }
    },
    getDirigeantByPersonneMorale(id) {
      this.showLoading = true
      this.$http.get(`/prospect/getDirigeantByPersonneMorale/${id}`)
          .then(res => {
            if (res.data.success) {
              this.dirigeantData = res.data.data
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.log(err)
          })
    }
  }
}
</script>
