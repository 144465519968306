import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))
    if((moment(eventData.value.start, 'DD/MM/YYYY hh:mm').diff(moment(eventData.value.end, 'DD/MM/YYYY hh:mm')) <= 0) || eventData.value.allDay) {
      // * If event has id => Edit Event
      // Emit event for add/update event
      if (props.event.value.id) {
        emit('update-event', eventData.value)
      } else {
        emit('add-event', eventData.value)
      }

      if(props.prospectSelected.value !== null) {
        // emit('go-to-another-fiche-client', 14, 'PERSONNE_MORALE', 'ENTREPRISE')
        setTimeout(() => {
          emit('go-to-another-fiche-client', props.prospectSelected.value.id, props.prospectSelected.value.type, props.prospectSelected.value.categorie);
        }, 3000);
      }
      // Close sidebar
      emit('update:is-event-handler-sidebar-active', false)
    }
  }

  const onDelete = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

      emit('remove-event', eventData.value)

      emit('update:is-event-handler-sidebar-active', false)
    
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */

  const guestsOptions = ref([])
  const fetchUsersAndClientContact = () => {
    store.dispatch('messageries/fetchUsersAndClientContact')
      .then(response => {
        guestsOptions.value = response.data
      })
  }
  fetchUsersAndClientContact()
  /* eslint-enable global-require */

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,

    // UI
    guestsOptions,
    onSubmit,
    onDelete,
  }
}
