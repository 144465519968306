<template>
  <div>
    <b-card class="mb-2">
      <b-card-text>


        <b-row>
          <b-col md="12" sm="12">
            <div class="d-flex bd-highlight mb-2">
              <div class="bd-highlight text-uppercase h3 font-weight-bold">{{ checkIsNull(tiersData.denominationCommercial) }} </div>
              <div class="ml-auto bd-highlight">
                <div class="demo-inline-spacing">
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm" class="mt-0"
                    v-b-toggle.sidebar-client-entreprise>
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle text-capitalize">Modifier</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)' || currentUser.courtier_user[0].isPrincipal === 1"
                      :aria-expanded="String(isEventHandlerSidebarActive)"
                      aria-controls="sidebar-add-new-event"
                      size="sm"
                      class="mt-0"
                      variant="primary"
                      @click="isEventHandlerSidebarActive = true"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                    <span class="align-middle text-capitalize">Ajouter un évènement</span>
                  </b-button>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm" class="mt-0"
                  v-b-toggle.sidebar-client-entreprise-connexite>
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle text-capitalize">Ajouter Connexité</span>
                  </b-button>
                </div>
              </div>
              
              <update-fiche-entreprise :tiers-data="tiersData" @update-client-entreprise-returned="UpdateClientEntrepriseReturned"/>
              <add-fiche-entreprise-connexite :tiers-data="tiersData" :code-naf-data="codeNafData" :prospect-options="prospectOptions" @update-client-entreprise-returned="UpdateClientEntrepriseReturned"/>
            </div>
          </b-col>
          <b-col md="12" sm="12">
            <p class="h5 mt-1"> <span class="font-weight-bolder"> Raison sociale :</span> {{ checkIsNull(tiersData.raisonSociale) }}</p>
          </b-col>
          <b-col md="12" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Forme juridique :</span> {{ tiersData.formeJuridique ? checkIsNull(FormJuridique.find(item => item.value === tiersData.formeJuridique).text) : '' }}</p>
          </b-col>
          <b-col md="8" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Activité :</span> {{ checkIsNull(tiersData.activite) }}</p>
          </b-col>
          <b-col md="4" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Téléphone :</span> {{ tiersData.indicatif_tel && tiersData.telephone ? '('+checkIsNull(tiersData.indicatif_tel)+') '+checkIsNull(tiersData.telephone.substring(1)) :checkIsNull(tiersData.telephone) }}</p>
          </b-col>
          <b-col md="8" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Date de création :</span> {{ checkIsNull(tiersData.dateCreation) }}</p>
          </b-col>
          <b-col md="4" sm="12">
           <p class="h5 mt-1"> <span class="font-weight-bolder">Mail :</span> <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="$emit('show-email')">
                        {{ checkIsNull(tiersData.email) }}
                      </a> </p>
          </b-col>
          <b-col md="8" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">SIRET :</span> {{ checkIsNull(tiersData.SIRET) }}</p>
          </b-col>
          <b-col md="4" sm="12" class="mt-1">
            <b-row>
              <b-col cols="4" class="pr-0 w-50">
                <span class="h5 font-weight-bolder">Adresse Complète :</span>
              </b-col>
              <b-col cols="8" class="p-0">
                 {{ checkIsNull(tiersData.libelle, true) +
                    checkIsNull(tiersData.complementAdresse, true) +
                    checkIsNull(tiersData.lieuDitOuBp, true) +
                    checkIsNull(tiersData.codePostal, true) +
                    checkIsNull(tiersData.ville, false) }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="4" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">NAF :</span> {{ checkIsNull(tiersData.CodeNAF) }}</p>
          </b-col>
          <b-col md="4" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">IDCC :</span> {{ checkIsNull(tiersData.IDCC) }}</p>
          </b-col>
          <b-col md="12" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Effectif :</span> {{ checkIsNull(tiersData.effectif) }}</p>
          </b-col>
          <b-col md="12" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Période de clôture d’exercice :</span> {{ checkIsNull(tiersData.date_cloture_exercice) }}</p>
          </b-col>
          <b-col md="12" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Chiffre d'affaires :</span> {{ checkIsNull(tiersData.chiffreAffaire) }}</p>
          </b-col>
          <b-col md="8" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Bénéficiaires effectifs :</span> {{ checkIsNull(tiersData.beneficiairesEffectif) }}</p>
          </b-col>
          <b-col md="4" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Site internet :</span> {{ checkIsNull(tiersData.siteWeb) }}</p>
          </b-col>
          <!-- <b-col md="4" sm="12">
             <p class="h5 mt-1"> <span class="font-weight-bolder">Origine :</span> {{ checkIsNull($_.replace(tiersData.origine,"_"," ")) }}</p>
          </b-col> -->
          <!-- <b-col md="12" sm="12">
            <collapse-dirigeant-salaries :prospect-chosen="{id: tiersData.idPersonneMorale, type: tiersData.type}"/>
          </b-col> -->
          <!-- <b-col md="12" sm="12">
            <Connexite-professionelles-fiche :tiers-data="tiersData" :code-naf-data="codeNafData" @update-client-entreprise-returned="UpdateClientEntrepriseReturned" />
          </b-col> -->
          <b-col md="12" sm="12">
            <Connexite-personnelles-fiche :tiers-data="tiersData" @update-client-entreprise-returned="UpdateClientEntrepriseReturned" @go-to-another-fiche-client="goToAnotherFicheClient" />
          </b-col>
        </b-row>
        
        <b-row>
            <b-col cols="12" xl="12">
                <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1 mt-2"><u>Origine </u></h3>
          </b-col>
            <b-col cols="12" xl="8">
              <table class="mt-2 mt-xl-0 w-100">

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Crée par : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.replace($_.get(tiersData, "createurPersonneMorale.user.personne_physique.prenom",""), '_', ' ') + ' ' + $_.get(tiersData, "createurPersonneMorale.user.personne_physique.nom","") }}
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Intermédiaire(s) : </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      <template v-for="intermediaire in tiersData.courtiersUsersByIntermediares">
                        {{ intermediaire.user.personne_physique.prenom.replace('_', ' ') }} {{ intermediaire.user.personne_physique.nom.replace('_', ' ') }} <br>
                      </template>
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Type : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.replace($_.get(tiersData, "origine",""), '_', ' ') }}
                    </td>
                  </tr>

              </table>
            </b-col>
         
        </b-row>

      </b-card-text>
    </b-card>
    <calendar-event-handler v-model="isEventHandlerSidebarActive" :event="event" :clear-event-data="clearEventData" @add-event="addEvent" :prospect-selected="tiersData" @go-to-another-fiche-client="goToAnotherFicheClient" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { BBadge, BButton, BCard, BCardText, BCol, BRow, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import collapseDirigeantSalaries from './CollapseDirigeantSalaries.vue'
import updateFicheEntreprise from './actions-fiche-entreprise/UpdateFicheEntreprise.vue'
import ConnexitePersonnellesFiche from './connexites-fiche-entreprise/ConnexitePersonnellesFiche.vue'
import ConnexiteProfessionellesFiche from './connexites-fiche-entreprise/ConnexiteProfessionellesFiche.vue'
import Data from '@/shared/constants/prospect'
import addFicheEntrepriseConnexite from './actions-fiche-entreprise/AddFicheEntrepriseConnexite.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './../../calendries/calendarStoreModule'
import CalendarEventHandler from './../../calendries/calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './../../calendries/useCalendar'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    addFicheEntrepriseConnexite,
    FullCalendar,
    // UI
    collapseDirigeantSalaries,
    updateFicheEntreprise,
    ConnexitePersonnellesFiche,
    ConnexiteProfessionellesFiche,
    ConnexiteProfessionellesFiche,
    CalendarEventHandler
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    tiersData: {
      type: Object,
      default: {
        idPersonneMorale: null,
        type: null,
        denominationCommercial: null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        indicatif_tel:null,
        email: null,
        adresse: null,
        libelle: null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        statut_fiche: null,
        isVisible: false,
        categorie: null,
        origine:null,
        date_cloture_exercice:null
      },
      required: true
    },
    codeNafData: {
      type: Array,
      default: [],
      required: true
    },
    prospectOptions: {
      type: Array,
      default: [],
      required: true
    },
    isBoiteSync: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data(){
    return {
      FormJuridique : Data["FORMEJURIDIQUE"],
    }
  },
  methods: {
    UpdateClientEntrepriseReturned(data, message){
      this.$emit('update-client-entreprise-returned', data, message)
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    }
  },
     setup() {
     const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    } = useCalendar()

    return {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    }
  }
}
</script>
