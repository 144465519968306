<template>
  <div>

    <b-sidebar id="sidebar-3" bg-variant="white" sidebar-class="sidebar-lg"  ref="importNewprojet" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Importer un document devis</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="projetJoindreComposeRules">
         <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

        <!-- <b-form-group label="Nature de document" label-for="h-famille">
              <validation-provider #default="{ errors }" name="nature de document" rules="required">
                <b-form-select id="h-famille-autre-document" v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->

             <b-form-group label="Type de document" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nom du document*" label-for="h-nom-document"  v-if="isAutre">
              <validation-provider #default="{ errors }" name="nom du document" rules="required">
                <b-form-input id="h-nom-document-autre-document" v-model="composeData.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocument" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                 @click="recordDocument"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BCardText, BCard, BFormGroup,BFormFile, BFormInput,BFormTextarea, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, BFormSelect, BFormSelectOption,VBTooltip  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { minLength } from 'vuelidate/lib/validators'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import documentService from '@/shared/services/documentService'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BFormSelectOption,
    BSidebar,
    BCardText,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    flatPickr,
    BFormTextarea,
    VBTooltip,
    BFormFile
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    etudeId: {
      type:Number
    },
    clientId: {
      type:Number
    },
    clientType: {
      type:String
    },
    devisId: {
      type:Number
    },
    isFormFicheTier: {
      type: Boolean,
      default: false,
      required: false
    },
    projetForUpdate: {
      type: Object,
      default: null,
      required: false
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: false
    }
  },
  data() {
    return {
      required,
      famille: null,
      optionsType: [],
      prospectDevisLocal: [],
      isAutre:false,
      
      composeData: {
        id: null,
        famille:null,
        description: '',
        idNature: null,
        idType: null,
        file: null,
        client_id:null,
        devis_id:null,
        etude_id:null,
        courtier_id: this.courtierId,
        user_id: null,
        nom:null
      },
      projet: {
        idEtude: null,
        idDevis: null,
        client: null,
        courtier_id: this.courtierId,
        user_id: this.userId
      },

    }
  },
  watch: {
    optionsFamille: {
      deep:true,
      handler(val){
          this.famille = val.find(item => item.value.id == 4).value
          this.changeListoptionType()
      },
   
    },
     'composeData.idType':{
      handler(val){
        if(val == 55 ){
          this.isAutre = true
        }else{
           this.isAutre = false
        }
      }
    },
   prospectDevis: {
      deep: true,
      handler(val) {
        this.prospectDevisLocal = val
        this.rows = this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient
      }
    },
   clientId: {
      immediate: true,
      handler(val) {
       this.composeData.client_id=val
      }
    },
   devisId: {
      immediate: true,
      handler(val) {
       this.composeData.devis_id=val
      }
    },
   etudeId: {
      immediate: true,
      handler(val) {
       this.composeData.etude_id=val
      }
    },
    prospectDataFromFicheTier: {
      deep: true,
      handler(val) {
        if (this.isFormFicheTier && val) {
          this.projet.client = val
        }
      }
    },
    isFormFicheTier: {
      deep: true,
      handler(val) {
        if (this.prospectDataFromFicheTier && val) {
          this.projet.client = this.prospectDataFromFicheTier
        }
      }
    },

  },
  methods: {
    openToSelectFile: () => {
      document.getElementById('fileautredocument').click()
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    clearDataSideBar() {
      this.famille=null
      // this.composeData.devis_id=null
      // this.composeData.etude_id=null
      // this.composeData.client_id=null
      this.composeData.idType=null
      this.composeData.description=null
      this.composeData.file=null
      // this.projet.idEtude = null
      // this.projet.idDevis = null
      // this.projet.client = null
      this.projet.num_devis = null
      this.projet.courtier_id = this.courtierId
      this.projet.user_id = this.userId
      this.$refs.projetJoindreComposeRules.reset()
    },
    getDataForUpdate(data) {
      this.projet.idEtude = data.idEtude
      this.projet.idDevis = data.idDevis
      const clientFind = this.prospectData.find(item => item.id === data.client.id && item.type === data.client.type)
      this.projet.client = clientFind
      this.projet.risque = data.risque
      this.projet.assureur = data.assureur
      this.projet.statut = data.statut
      this.projet.num_devis = data.num_devis
      this.projet.date_effet = data.date_effet
      this.projet.date_creation = data.date_creation
      this.projet.budget_propose = data.budget_propose
      this.projet.budget_actuel = data.budget_actuel
    },
    hideSideBare() {
      this.$refs.importNewprojet.hide()
    },
    changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
    // record Data projet
     recordDocument() {
      this.$refs.projetJoindreComposeRules.validate().then(success => {
        if (success) {
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data
          // formData.append('tiers', this.clientId)
          formData.append('client_id', this.clientId)
          formData.append('type_tiers', this.clientType)
          formData.append('type_doc_id', this.composeData.idType)
          formData.append('nom', this.composeData.nom)
          // formData.append('nature_doc_id', this.composeData.idNature)
          formData.append('file', this.composeData.file)
          formData.append('courtier_id', this.composeData.courtier_id)
          // formData.append('description', this.composeData.description)
          formData.append('description', '')
          formData.append('devis_id', this.composeData.devis_id)
          formData.append('etude_id', this.composeData.etude_id)
          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/importerNewDocumentProjet', formData, config)
            .then(res => {
              if (res.data.success) {

                this.$emit('record-document-returned', true)
                this.messageToast(res.data.message, 'Succès', 'success')
                this.clearDataSideBar()
                this.hideSideBare()
              } else {
                this.messageToast('Erreur lors de l\'importation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.warn(err)
            })
        }
      })
    }
  },

 setup() {
    const optionsFamille = ref([])
    const fetchDocumentNatureWithTypes = documentService()
    fetchDocumentNatureWithTypes
      .then(res => {
      optionsFamille.value = []
        res.data.filter(function(a) {return a.id == 4;}).forEach(el => {
          optionsFamille.value.push({
            value: { id: el.id, typeDocument: el.documentype },
            text: el.libelle
          })
        })
      })
      .catch(errors => {
        console.error(errors)
        optionsFamille.value = []
      })

    return {
      fetchDocumentNatureWithTypes,
      optionsFamille

    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
