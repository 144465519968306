<template>
    <div v-if="renderComponent">
      <b-button
        v-if="can('Create client')"
        v-b-toggle.sidebar-1
        variant="primary"
        size="md"
        @click="isActive=true;getAuthCourtier()"
        
        >
        <feather-icon
                icon="PlusIcon"
        />
        <span class="align-middle" >Enregistrer un Client/Prospect</span>
      </b-button>
      <b-sidebar
        id="sidebar-1"
        v-if="isActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
      >
      <template #default="{ hide }" ref="mySidebar">
      <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          Enregistrer un Client /Prospect
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

        </div>
        <div class="pt-2 pl-2 pr-2">
        <b-form-group
              label="Categorie*"
              label-for="categorie_personne"
            >
            <b-form-select
              v-model="personne_type"
              id="categorie_personne"
              @change="resetValidator($event)"
              >
              <b-form-select-option value="PARTICULIER">Particulier</b-form-select-option>
              <b-form-select-option value="PROFESSIONNEL">Professionnel</b-form-select-option>
              <b-form-select-option value="ENTREPRISE">Entreprise</b-form-select-option>
            </b-form-select>
        </b-form-group>
        </div>
        <div v-if="personne_type === 'ENTREPRISE'">
          <validation-observer ref="AddNewTierValidationsPM" >
            <b-form
              class="px-2"
              @submit.prevent="submitForm_create_personne_morale"
            >
                <b-form-group
                  label="Statut"
                  label-for="entreprise_statut"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="statut"
                    
                    >
                    <b-form-select
                    v-model="prospect_morale.statut_fiche"
                    :options="statutFiche"
                    id="entreprise_statut"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Dénomination Commerciale*"
                  label-for="denomination_commercial"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="dénomination commerciale"
                     rules="required"
                    >
                    <b-form-input
                      id="denomination_commercial"
                      trim
                      placeholder="Dénomination Commerciale"
                      v-model="prospect_morale.denomination_commercial"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Activité de l’entreprise"
                  label-for="activite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="activité"
                      rules="min:3"
                    >
                    <b-form-input
                      id="activite"
                      trim
                      placeholder="Activité de l’entreprise"
                      v-model="prospect_morale.activite"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="siretLabel"
                  label-for="siret"
                >
                  <validation-provider 
                      #default="{ errors }"
                      name="siret"
                      rules="min:3"
                    >
                    <b-form-input
                      id="siret"
                      trim
                      :placeholder="siretLabel"
                      v-model="prospect_morale.siret"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Raison Sociale"
                  label-for="raison_sociale"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="raison sociale"
                      
                    >
                    <b-form-input
                      id="raison_sociale"
                      trim
                      placeholder="Raison Sociale"
                      v-model="prospect_morale.raison_sociale"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Forme Juridique"
                  label-for="forme_juridique"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="forme juridique"
                     
                    >
                    <b-form-select
                      v-model="prospect_morale.forme_juridique" :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option
                        v-for="(fg,index) in FormJuridique"
                        :key="index"
                        :value="fg.value"
                      >
                        {{ fg.text }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="'Civilité du '+labelDirigeant "
                  label-for="dirigeant_civilite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="civilité"
                     
                    >
                    <b-form-select
                    v-model="prospect_morale.dirigeant.civilite"
                    :options="civilite"
                    id="dirigeant_civilite"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="'Nom du '+labelDirigeant"
                  label-for="dirigeant_nom"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="nom"
                     
                    >
                    <b-form-input
                      id="dirigeant_nom"
                      trim
                      :placeholder="'Nom du '+labelDirigeant.toLowerCase()"
                      v-model="prospect_morale.dirigeant.nom"
                      :state="errors.length > 0 ? false : null"
                      class="text-uppercase Nom-entre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="'Prénom du '+labelDirigeant"
                  label-for="dirigeant_prenom"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="prénom"
                     
                    >
                    <b-form-input
                      id="dirigeant_prenom"
                      trim
                      :placeholder="'Prénom du '+labelDirigeant.toLowerCase()"
                      v-model="prospect_morale.dirigeant.prenom"
                      :state="errors.length > 0 ? false : null"
                      class="text-capitalize Nom-entre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              <b-form-group label="Inscrit sur Bloctel" label-for="inscrit-sur-bloctel">
               <validation-provider #default="{ errors }" name="Inscrit sur Bloctel">
                <div class="form-inline" id="inscrit-sur-bloctel">
                  <b-form-radio v-model="prospect_morale.isBloctel" name="inscrit-sur-bloctel-radios" value="1" class="mr-2"> oui </b-form-radio>
                  <b-form-radio v-model="prospect_morale.isBloctel" name="inscrit-sur-bloctel-radios" value="0"> non </b-form-radio>
                </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
               </validation-provider>
              </b-form-group>

              <b-form-group v-if="prospect_morale.isBloctel == 1" label="Date d’inscription sur Bloctel" label-for="Date-d’inscription-sur-bloctel">
                    <flat-pickr
                      v-model="prospect_morale.date_bloctel"
                      class="form-control"
                      id="date_d’inscription_sur_bloctel"
                      :config="config"
                      placeholder="Date d’inscription sur Bloctel"
                    />
                </b-form-group>

              <b-form-group  :label="'Téléphone portable du '+labelDirigeant"
                              label-for="dirigeant_tel">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="prospect_morale.dirigeant.indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                              <b-form-input
                                id="dirigeant_tel"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                          
                                trim
                                placeholder="ex: 06 00 00 00 00"
                                v-model="prospect_morale.dirigeant.tel"
                                :state="errors.length > 0 ? false : null"
                                v-mask="'## ## ## ## ##'"
                                
                              />
                            <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' " >{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                  </div>
              </b-form-group>
                <b-form-group
                  :label="'Téléphone fixe du '+labelDirigeant"
                  label-for="dirigeant_tel_secondaire"
                >
                <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="prospect_morale.dirigeant.indicatif_sec" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                  <validation-provider
                      #default="{ errors }"
                      name="téléphone fixe "
                      :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                    >
                    <b-form-input
                      id="dirigeant_tel_secondaire"
                      trim
                      placeholder="ex: 06 00 00 00 00"
                      style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                          
                      v-model="prospect_morale.dirigeant.tel_secondaire"
                      :state="errors.length > 0 ? false : null"
                      v-mask="'## ## ## ## ##'"
                    />
                    <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' ">{{ errors[0] }}</small>
                  </validation-provider>
                  </div>
                </div>
                </b-form-group>

                <b-form-group
                  :label="'Email du '+labelDirigeant"
                  label-for="dirigeant_email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="email du dirigeant"
                      rules="email"
                    >
                    <b-form-input
                      id="dirigeant_email"
                      trim
                      :placeholder="'Email du '+labelDirigeant"
                      v-model="prospect_morale.dirigeant.email"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                  <b-form-group   :label="'Téléphone de l’'+labelEntreprise"
                                   label-for="prospect_morale_telephone">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="prospect_morale.indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone de l’entreprise"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                              <b-form-input
                                id="prospect_morale_telephone"                       
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"                            
                                v-model="prospect_morale.tel"
                                trim
                                placeholder="ex: 06 00 00 00 00"
                                :state="errors.length > 0 ? false : null"
                                v-mask="'## ## ## ## ##'"                              
                              />
                            <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' ">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                  </div>
                <small class="text-danger">{{ errorIndicatifTel }}</small>
              </b-form-group>
                <!-- <b-form-group
                  :label="'Téléphone de l’'+labelEntreprise"
                  label-for="prospect_morale_telephone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="téléphone de l’entreprise"
                    :rules="{  regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                  >
                  <b-form-input
                    id="prospect_morale_telephone"
                    v-model="prospect_morale.tel"
                    trim
                    placeholder="ex: 06 00 00 00 00"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'## ## ## ## ##'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

                <b-form-group
                  :label="'Email de l’'+labelEntreprise"
                  label-for="prospect_morale_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email de l’entreprise "
                    rules="email"
                  >
                  <b-form-input
                    id="prospect_morale_email"
                    v-model="prospect_morale.email"
                    trim
                    placeholder="Email de l’entreprise"
                    :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="isInvalideMailandPhone">email et téléphone sont déjà utilisé</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="N° et libellé de la voie"
                  label-for="prospect_morale_libelle"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="n° et libellé de la voie"
                      rules="min:3"
                    >
                    <b-form-input
                      id="prospect_morale_libelle"
                      trim
                      placeholder="N° et libellé de la voie"
                      v-model="prospect_morale.libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Complément d’adresse"
                  label-for="prospect_morale_complement_adresse"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="prospect_morale_complement_adresse"
                    >
                    <b-form-input
                      id="prospect_morale_libelle"
                      trim
                      placeholder="Complément d’adresse (Bâtiment, Appartement…)"
                      v-model="prospect_morale.complement_adresse"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Lieu-dit ou BP"
                  label-for="prospect_morale_lieu_dit_ou_bp"
                >
                    <b-form-input
                      id="prospect_morale_lieu_dit_ou_bp"
                      trim
                      placeholder="Lieu-dit ou BP"
                      v-model="prospect_morale.lieu_dit_ou_bp"
                    />
                </b-form-group>

                <b-form-group
                  label="Code postal"
                  label-for="prospect_morale_code_postal"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="code postal"
                      rules="min:3"
                    >
                    <b-form-input
                      id="prospect_morale_code_postal"
                      trim
                      placeholder="Code postal"
                      @keyup="cpostalKeyupPM()"
                      v-model="prospect_morale.code_postal"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                    label="Ville"
                    label-for="prospect_morale_ville_id"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="ville"
        
                    >
                    <b-form-select
                    v-model="prospect_morale.ville_id"
                    id="prospect_morale_ville_id"
                    :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option v-for="(val, value) in villesPM" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Site internet"
                  label-for="prospect_morale_site_web"
                >
                    <b-form-input
                      id="prospect_morale_site_web"
                      trim
                      placeholder="Site internet"
                      v-model="prospect_morale.site_web"
                    />
                </b-form-group>

                <b-form-group
                  label="Date de création"
                  label-for="prospect_morale_date_creation"
                >
                    <flat-pickr
                      class="form-control"
                      v-model="prospect_morale.date_creation"
                      id="prospect_morale_date_creation"
                      :config="config"
                      placeholder="Date de création"
                    />
                </b-form-group>

                <b-form-group
                  label="Code NAF"
                  label-for="prospect_morale_code_naf"
                >
                <v-select
                  v-model="prospect_morale.code_naf"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="libelle"
                  :options="codeNafData"
                  input-id="id"
                >
                  <template #option="{code, libelle }">
                    <span class="ml-50">  {{ code }} {{ libelle }}</span>
                  </template>

                  <template #selected-option="{ code }">
                    <span class="ml-50"> {{ code }} </span>
                  </template>
                  <div slot="no-options">Aucune correspondance.</div>
                </v-select>
                  <!-- <b-form-select
                    v-model="prospect_morale.code_naf"
                  >
                      <b-form-select-option
                        v-for="(cn,index) in codeNafData"
                        :key="index"
                        :value="cn.id"
                      >
                        {{ cn.code }} {{ cn.libelle }}
                      </b-form-select-option>
                  </b-form-select> -->
                </b-form-group>

                <b-form-group
                  label="IDCC"
                  label-for="prospect_morale_idcc"
                >
                  <b-form-input
                    id="prospect_morale_idcc"
                    trim
                    placeholder="IDCC"
                    v-model="prospect_morale.idcc"
                  />
                </b-form-group>

                <b-form-group
                  label="Bénéficiaires effectifs"
                  label-for="prospect_morale_beneficiairesEffectif"
                >
                    <b-form-input
                      id="prospect_morale_beneficiairesEffectif"
                      trim
                      placeholder="Bénéficiaires effectifs"
                      v-model="prospect_morale.beneficiairesEffectif"
                    />
                </b-form-group>
                <b-form-group
                  label="Chiffre d’affaires"
                  label-for="prospect_morale_chiffreAffaire"
                >
                    <b-form-input
                      id="prospect_morale_chiffreAffaire"
                      trim
                      placeholder="Chiffre d’affaires"
                      v-model="prospect_morale.chiffreAffaire"
                    />
                </b-form-group>

                <b-form-group
                  label="Effectif"
                  label-for="prospect_morale_effectif"
                >
                    <b-form-input
                      id="prospect_morale_effectif"
                      trim
                      placeholder="Effectif"
                      v-model="prospect_morale.effectif"
                    />
                </b-form-group>
              <b-form-group label="Période de clôture d’exercice" label-for="cloture-exercice">
                <validation-provider #default="{ errors }" name="cloture d’exercice">
                  <cleave
                    id="date_cloture_exercice"
                    v-model="prospect_morale.date_cloture_exercice"
                    class="form-control"
                    :raw="false"
                    :options="options.date_cloture"
                    placeholder="jour/mois"
                />
                  <!-- <b-form-input id="echeance-anniversaire" placeholder="jour/mois" v-model="composeData.echeance_anniversaire" :maxlength="max"/> -->
                  <div v-if="valdiation!=null" class="echent">Le champ période de clôture d’exercice invalide ( jj/mm )</div>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>
                <b-form-group label="Type d’origine" label-for="Type-d’origine">
                  <b-form-select  
                          v-model="prospect_morale.origine"
                          :options="origine"
                          id="origine"
                          />
                </b-form-group>
                <b-form-group v-if="prospect_morale.origine==='Autre'" label="Précisez" label-for="origineautre"
                    
                    >Précisez
                    
                        <b-form-input
                          id="origineautre"
                          placeholder="Précisez l’origine"
                          v-model="prospect_morale.origineautre"
                        />
                </b-form-group>

               
                <!-- utilisateur list -->
                <validation-provider #default="{ errors }" name="commercial">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="gestionnaire">
                    <v-select v-model="prospect_morale.commercial" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="commercial" label="nom" placeholder="gestionnaire"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune gestionnaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="createur">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Créer par" label-for="Créer-par">
                    <v-select v-model="prospect_morale.createur" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="createur" label="nom" placeholder="Créer par"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune createur disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="intermediaire">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                    <v-select v-model="prospect_morale.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune intermediaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                
            

              <!-- Form Actions -->
              <div class="d-flex mt-2" style="float:right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  size="md"
                  type="submit"
                >
                  Enregistrer
                </b-button>
              </div>

            </b-form>
          </validation-observer>
        </div>

        <div v-else-if="personne_type === 'PROFESSIONNEL' ">
          <validation-observer ref="AddNewTierValidationsPRO" >
            <b-form
              class="px-2"
              @submit.prevent="submitForm_create_personne_morale_pro"
            >
                <b-form-group
                  label="Statut"
                  label-for="professionnel_statut"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="statut"
                    
                    >
                    <b-form-select
                    v-model="professionnel.statut_fiche"
                    :options="statutFiche"
                    id="professionnel_statut"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Civilité*"
                  label-for="pro_civilite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="civilite"
                      rules="required"
                    >
                    <b-form-select
                    v-model="professionnel.civilite"
                    :options="civilite"
                    id="pro_civilite"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Nom*"
                  label-for="pro_nom"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="nom"
                      rules="required"
                    >
                    <b-form-input
                      id="pro_nom"
                      trim
                      placeholder="Nom"
                      v-model="professionnel.nom"
                      :state="errors.length > 0 ? false : null"
                      class="text-uppercase"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
               <b-form-group
                  label="Nom de naissance"
                  label-for="pro_nom_naissance"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_nom_naissance"
                    >
                    <b-form-input
                      id="pro_nom_naissance"
                      trim
                      placeholder="Nom de naissance"
                      v-model="professionnel.nom_naissance"
                      :state="errors.length > 0 ? false : null"
                      class="text-uppercase"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Prénom"
                  label-for="pro_prenom"
                >
                <validation-provider
                    #default="{ errors }"
                    name="prénom"     
                  >
                  <b-form-input
                    id="pro_prenom"
                    trim
                    placeholder="Prénom"
                    v-model="professionnel.prenom"
                    :state="errors.length > 0 ? false : null"
                    class="text-capitalize"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                     <b-form-group
                  label="Date de naissance"
                  label-for="pro_date_naissance"
                >
                    <flat-pickr
                      class="form-control"
                      v-model="professionnel.date_naissance"
                      id="pro_date_naissance"
                      :config="config"
                      placeholder="Date de naissance"
                      data-max-date="today"
                    />
                </b-form-group>
             
                <b-form-group
                  label="Code postal de naissance"
                  label-for="pro_code_postal_naissance"
                >
                <validation-provider
                    #default="{ errors }"
                    name="code postal"
                  >
                  <b-form-input
                    id="pro_code_postal_naissance"
                    trim
                    placeholder="Code postal de naissance"
                    v-model="professionnel.code_postal_naissance"
                    :state="errors.length > 0 ? false : null"
                    class="text-uppercase"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                <b-form-group
                    label="Commune de naissance"
                    label-for="pro_commune_naissance"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_commune_naissance"
                     
                    >
                    <b-form-input
                    v-model="professionnel.commune_naissance"
                    id="pro_commune_naissance"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Commune de naissance"
                    >
                     
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
             <validation-provider #default="{ errors }" name="pays_naissance" >
                <b-form-group label="Pays de naissance" label-for="pays">
                  <v-select v-model="professionnel.pays_naissance" :close-on-select="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                            class="flex-grow-1"
                            input-id="pays" label="pays" placeholder="Pays de naissance"
                  >
                    <template #option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>

                    <template #selected-option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>
                    <div slot="no-options">Aucune pays disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
                         <b-form-group
                  label="Situation familiale"
                  label-for="pro_situation_familiale"
                >
                    <b-form-select
                      v-model="professionnel.situation_familiale"
                      id="pro_situation_familiale"
                    >
                      <b-form-select-option value="null" disabled
                        >Choisissez</b-form-select-option
                      >
                      <b-form-select-option value="CONCUBIN"
                        >Concubin(e)</b-form-select-option
                      >
                      <b-form-select-option value="CELIBATAIRE"
                        >Célibataire</b-form-select-option
                      >
                      <b-form-select-option value="DIVORCE"
                        >Divorcé(e)</b-form-select-option
                      >
                      <b-form-select-option value="MARIE"
                        >Marié(e)</b-form-select-option
                      >
                      <b-form-select-option value="SEPARE"
                        >Séparé(e)</b-form-select-option
                      >
                      <b-form-select-option value="VEUF"
                        >Veuf(ve)</b-form-select-option
                      >
                      <b-form-select-option value="PACSE"
                        >Pacsé(e)</b-form-select-option
                      >
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Enfants à charge" label-for="pro_nbr_enfant">
                  <b-form-input
                    v-model="professionnel.enfantCharge"
                    id="pro_nbr_enfant"
                    trim
                    placeholder="Enfants à charge"
                    type="number"
                    min="0"
                  />
                </b-form-group>
                
                 <b-form-group
                  label="N° et libellé de la voie"
                  label-for="pro_libelle"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="n° et libellé de la voie"
                     
                    >
                    <b-form-input
                      id="pro_libelle"
                      trim
                      placeholder="N° et libellé de la voie"
                      v-model="professionnel.libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              <b-form-group
                  label="Complément d'adresse"
                  label-for="pro_complement_adresse"
                >

                  <b-form-input
                    id="pro_complement_adresse"
                    trim
                    placeholder="Complément d'adresse"
                    v-model="professionnel.complement_adresse"
                  />
              </b-form-group>

                <b-form-group
                  label="Lieu-dit"
                  label-for="lieu_dit"
                >
                  <b-form-input
                    id="lieu_dit"
                    trim
                    placeholder="Lieu-dit"
                    v-model="professionnel.lieu_dit_ou_bp"
                  />
                </b-form-group>

                <b-form-group
                  label="Code postal"
                  label-for="pro_code_postal"
                >
                <validation-provider
                    #default="{ errors }"
                    name="code postal"
                    rules="digits:5"
                  >
                  <b-form-input
                    id="pro_code_postal"
                    trim
                    placeholder="Code postal"
                    @keyup="cpostalKeyupPRO()"
                    v-model="professionnel.code_postal"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'#####'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
      
                <b-form-group
                    label="Ville"
                    label-for="pro_ville"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="ville"
                     
                    >
                    <b-form-select
                    v-model="professionnel.ville_id"
                    id="pro_ville"
                    :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option v-for="(val, value) in villesPRO" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              <b-form-group label="Inscrit sur Bloctel" label-for="inscrit-sur-bloctel">
               <validation-provider #default="{ errors }" name="Inscrit sur Bloctel">
                <div class="form-inline" id="inscrit-sur-bloctel">
                  <b-form-radio v-model="professionnel.isBloctel" name="inscrit-sur-bloctel-radios" value="1" class="mr-2"> oui </b-form-radio>
                  <b-form-radio v-model="professionnel.isBloctel" name="inscrit-sur-bloctel-radios" value="0"> non </b-form-radio>
                </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
               </validation-provider>
              </b-form-group>

              <b-form-group v-if="professionnel.isBloctel == 1" label="Date d’inscription sur Bloctel" label-for="Date-d’inscription-sur-bloctel">
                    <flat-pickr
                      v-model="professionnel.date_bloctel"
                      class="form-control"
                      id="date_d’inscription_sur_bloctel"
                      :config="config"
                      placeholder="Date d’inscription sur Bloctel"
                    />
                </b-form-group>

              <b-form-group label="Téléphone Portable"
                            label-for="pro_telephone">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="professionnel.indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                              <b-form-input
                                id="pro_telephone"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"               
                                v-model="professionnel.tel"
                                placeholder="ex: 06 00 00 00 00"
                                v-mask="'## ## ## ## ##'"           
                              />
                            <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' " >{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                  </div>
                <small class="text-danger">{{ errorIndicatifTel }}</small>
              </b-form-group>

                <b-form-group
                  label="Téléphone Fixe"
                  label-for="pro_telephone_portable"
                >
                       <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="professionnel.indicatif_sec" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                  <validation-provider
                    #default="{ errors }"
                    name="téléphone fixe"
                    :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                  >
                  <b-form-input
                    id="pro_telephone_portable"
                    v-model="professionnel.tel_secondaire"
                    trim
                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"               
                    placeholder="ex: 06 00 00 00 00"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'## ## ## ## ##'"
                  />
                  <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' ">{{ errors[0] }}</small>
                  </validation-provider>
                  </div>
                </div>

                </b-form-group>

                <b-form-group
                  label="Email"
                  label-for="pro_email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="email"
                    >
                    <b-form-input
                      id="pro_email"
                      trim
                      placeholder="Email"
                      type="email"
                      v-model="professionnel.email"
                      :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] ? 'Le champ  email est obligatoire' : '' }}</small>
                    <small class="text-danger" v-if="isInvalideMailandPhone">email et téléphone sont déja utilisé</small>
                  </validation-provider>
                </b-form-group>
              
                <b-form-group
                  label="Régime de sécurité sociale"
                  label-for="pro_regime_obligatoire"
                >
                  <b-form-select
                      v-model="professionnel.regime_obligatoire"
                      id="pro_regime_obligatoire"
                    >
                      <b-form-select-option value="null" disabled
                        >Choisissez</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(regime, value) in REGIMESOCIAL"
                        :key="'r' + value"
                        :value="regime.value"
                      >
                        {{ regime.name }}
                      </b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  label="Activité de l’entreprise"
                  label-for="pro_activite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="activité"
                      rules="min:3"
                    >
                    <b-form-input
                      id="pro_activite"
                      trim
                      placeholder="Activité de l’entreprise"
                      v-model="professionnel.activite"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Catégorie socioprofessionnelle" label-for="activite">
                  <b-form-select
                    v-model="professionnel.statut"
                    id="activite"
                  >
                    <b-form-select-option :value="null"
                      >Choisissez</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="(activite, value) in ACTIVITE_DATA"
                      :key="'a' + value"
                      :value="activite.value"
                    >
                      {{ activite.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                  <b-form-group label="Domaine d’activité" label-for="Domaine d’activité">
                    <b-form-select id="activite" v-model="professionnel.secteur_activite">
                      <b-form-select-option value="null" >Choisissez</b-form-select-option>
                      <template v-for="secteur in sortedSecteurs">
                        <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                      <b-form-group label="Statut juridique" label-for="statut_juridique">
                   <b-form-select
                    v-model="professionnel.statut_juridique"
                    id="statut_juridique"
                  >
                     <b-form-select-option value="null" disabled
                        >Choisissez</b-form-select-option
                      >
                      <b-form-select-option value="ARTISANS"
                        >Artisan</b-form-select-option
                      >
                      <b-form-select-option value="COMMERCANTS"
                        >Commerçant</b-form-select-option
                      >
                      <b-form-select-option value="CONJOINTS_COLLABORATEURS"
                        >Conjoint collaborateur</b-form-select-option
                      >
                      <b-form-select-option value="ENTREPRENEURS_INDIVIDUELS_OU_AUTO_ENTREPRENEURS"
                        >Entrepreneur individuel ou auto entrepreneur</b-form-select-option
                      >
                      <b-form-select-option value="GERANTS_MAJORITAIRES"
                        >Gérant majoritaire</b-form-select-option
                      >
                      <b-form-select-option value="GERANTS_MAJORITAIRES_AVEC_CONJOINT"
                        >Gérant majoritaire avec conjoint</b-form-select-option
                      >
                      <b-form-select-option value="MANDATAIRES_SOCIAUX"
                        >Mandataire social</b-form-select-option
                      >
                      <b-form-select-option value="PROFESSIONS_LIBERALES_MEDICALES"
                        >Profession libérale médicale</b-form-select-option
                      >
                      <b-form-select-option value="PROFESSIONS_LIBERALES_NON_MEDICALES"
                        >Profession libérale non médicale</b-form-select-option
                      >
                      <b-form-select-option value="TNS_AUTRES"
                        >Autre TNS</b-form-select-option
                      >
                  </b-form-select>
                </b-form-group>


                <b-form-group
                  label="Dénomination Commerciale*"
                  label-for="pro_denomination_commercial"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="dénomination Commerciale"
                      rules="required"
                    >
                    <b-form-input
                      id="pro_denomination_commercial"
                      trim
                      placeholder="Dénomination Commerciale"
                      v-model="professionnel.denomination_commercial"
                      :state="errors.length > 0 ? false : null"
                 
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Raison Sociale"
                  label-for="pro_raison_sociale"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="raison sociale"
                     
                    >
                    <b-form-input
                      id="pro_raison_sociale"
                      trim
                      placeholder="Raison Sociale"
                      v-model="professionnel.raison_sociale"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Date de création"
                  label-for="pro_date_creation"
                >
                    <flat-pickr
                      class="form-control"
                      v-model="professionnel.date_creation"
                      id="pro_date_creation"
                      :config="config"
                      placeholder="Date de création"
                    />
                </b-form-group>

                <b-form-group
                  :label="siretLabel"
                  label-for="pro_siret"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="siret"
                      rules="min:3"
                    >
                    <b-form-input
                      id="pro_siret"
                      trim
                      :placeholder="siretLabel"
                      v-model="professionnel.siret"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Forme Juridique"
                  label-for="pro_forme_juridique"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="forme juridique"
                    
                    >
                    <b-form-select
                      v-model="professionnel.forme_juridique" :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option
                        v-for="(fg,index) in FormJuridique"
                        :key="index"
                        :value="fg.value"
                      >
                        {{ fg.text }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            <b-form-group :label="`Téléphone de l'`+labelEntreprise"
                             label-for="pro_telephone_entre">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="professionnel.entreprise_indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                              <b-form-input
                                id="pro_telephone_entre"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                                
                                v-model="professionnel.entreprise_tel"
                                placeholder="ex: 06 00 00 00 00"
                                v-mask="'## ## ## ## ##'"
                             
                              />
                            <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' " >{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                  </div>
                <small class="text-danger">{{ errorIndicatifTel }}</small>
              </b-form-group>
                <b-form-group
                  :label="`Email de l'`+labelEntreprise"
                  label-for="pro_entreprise_email2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email de l'entreprise"
                    rules="email"
                  >
                  <b-form-input
                    id="pro_entreprise_email2"
                    v-model="professionnel.entreprise_email"
                    trim
                    placeholder="Email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>


                <b-form-group
                  label="Site internet"
                  label-for="pro_site_web"
                >
                    <b-form-input
                      id="pro_site_web"
                      trim
                      placeholder="Site internet"
                      v-model="professionnel.site_web"
                    />
                </b-form-group>


                <b-form-group
                  label="Code NAF"
                  label-for="pro_code_naf"
                >

                  <v-select
                    v-model="professionnel.code_naf"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="libelle"
                    :options="codeNafData"
                    input-id="id"
                  >
                    <template #option="{code, libelle }">
                      <span class="ml-50">  {{ code }} {{ libelle }}</span>
                    </template>

                    <template #selected-option="{ code }">
                      <span class="ml-50"> {{ code }} </span>
                    </template>
                    <div slot="no-options">Aucune correspondance.</div>
                  </v-select>
                  <!-- <b-form-select
                    v-model="professionnel.code_naf"
                  >
                      <b-form-select-option
                        v-for="(cn,index) in codeNafData"
                        :key="index"
                        :value="cn.id"
                      >
                        {{ cn.code }} {{ cn.libelle }}
                      </b-form-select-option>
                  </b-form-select> -->
                </b-form-group>

                <b-form-group
                  label="IDCC"
                  label-for="pro_idcc"
                >
                  <b-form-input
                    id="pro_idcc"
                    trim
                    placeholder="IDCC"
                    v-model="professionnel.idcc"
                  />
                </b-form-group>

                <b-form-group
                  label="Bénéficiaires effectifs"
                  label-for="pro_beneficiairesEffectif"
                >
                    <b-form-input
                      id="pro_beneficiairesEffectif"
                      trim
                      placeholder="Bénéficiaires effectifs"
                      v-model="professionnel.beneficiairesEffectif"
                    />
                </b-form-group>

                <b-form-group
                  label="Chiffre d’affaires"
                  label-for="pro_chiffreAffaire"
                >
                    <b-form-input
                      id="pro_chiffreAffaire"
                      trim
                      placeholder="Chiffre d’affaires"
                      v-model="professionnel.chiffreAffaire"
                    />
                </b-form-group>

                <b-form-group
                  label="Effectif"
                  label-for="pro_effectif"
                >
                    <b-form-input
                      id="pro_effectif"
                      trim
                      placeholder="Effectif"
                      v-model="professionnel.effectif"
                    />
                </b-form-group>
              <b-form-group label="Période de clôture d’exercice" label-for="cloture-exercice">
                <validation-provider #default="{ errors }" name="cloture d’exercice">
                  <cleave
                    id="date_cloture_exercice"
                    v-model="professionnel.date_cloture_exercice"
                    class="form-control"
                    :raw="false"
                    :options="options.date_cloture"
                    placeholder="jour/mois"
                />
                  <!-- <b-form-input id="echeance-anniversaire" placeholder="jour/mois" v-model="composeData.echeance_anniversaire" :maxlength="max"/> -->
                  <div v-if="valdiation!=null" class="echent">Le champ période de clôture d’exercice invalide ( jj/mm )</div>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>

            <b-form-group label= "Numéro de sécurité social" label-for="numSecuProfessionnel" >

               <cleave
                id="numSecuProfessionnel"
                v-model="professionnel.numSecu"
                :options="blockSecu"
                class="form-control"
                :raw="false"
                placeholder="Numéro de sécurité social"
              />

              <span class="text-danger" style="font-size:12px" v-if="errorsNumSecu" >
                    Le numéro de sécurite sociale est erroné. 

              </span>
              
             
            </b-form-group>

            <b-form-group label="Type d’origine" label-for="Type-d’origine">
             <b-form-select
                    v-model="professionnel.origine"
                    :options="origine"
                    id="origine"
                    />
            </b-form-group>
            <b-form-group v-if="professionnel.origine==='Autre'" label="Précisez" label-for="origineautre"
                
                >
                    <b-form-input
                      id="origineautre"
                      placeholder="Précisez l’origine"
                      v-model="professionnel.origineautre"
                    />
                </b-form-group>




                <!-- utilisateur list -->
                <validation-provider #default="{ errors }" name="commercial">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="gestionnaire">
                    <v-select v-model="professionnel.commercial" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="commercial" label="nom" placeholder="Crée par"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune gestionnaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>


                <validation-provider #default="{ errors }" name="createur">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Crée par" label-for="Crée-par">
                    <v-select v-model="professionnel.createur" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="createur" label="nom" placeholder="Crée par"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune createur disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="intermediaire">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                    <v-select v-model="professionnel.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune intermediaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              



              <!-- Form Actions -->
              <div class="d-flex mt-2" style="float:right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  size="md"
                  type="submit"
                >
                  Enregistrer
                </b-button>
              </div>

            </b-form>
          </validation-observer>
        </div>

        <div v-else-if="personne_type === 'PARTICULIER'">
          <validation-observer ref="AddNewTierValidations" >
            <b-form
              class="px-2"
              @submit.prevent="submitForm_create_prospect"
            >
                <!-- <b-form-group
                  label="Statut*"
                  label-for="statut"
                >
                <validation-provider
                    #default="{ errors }"
                    name="Statut"
                    rules="required"
                  >
                  <b-form-select
                  v-model="prospect_create.type"
                  :options="status"
                  id="statut"
                  :state="errors.length > 0 ? false : null"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group> -->

                <b-form-group
                  label="Statut"
                  label-for="prospect_create_statut"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="statut"
                     
                    >
                    <b-form-select
                    v-model="prospect_create.statut_fiche"
                    :options="statutFiche"
                    id="prospect_create_statut"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Civilité*"
                  label-for="civilite"
                >
                <validation-provider
                    #default="{ errors }"
                    name="civilité"
                    rules="required"
                  >
                  <b-form-select
                  v-model="prospect_create.civilite"
                  :options="civilite"
                  id="civilite"
                  :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nom*"
                  label-for="nom"
                >
                <validation-provider
                    #default="{ errors }"
                    name="nom"
                    rules="required"
                  >
                  <b-form-input
                    id="nom"
                    trim
                    placeholder="Nom"
                    v-model="prospect_create.nom"
                    :state="errors.length > 0 ? false : null"
                    class="text-uppercase"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nom de naissance"
                  label-for="nom_naissance"
                >
                <validation-provider
                    #default="{ errors }"
                    name="nom_naissance"
                  >
                  <b-form-input
                    id="nom_naissance"
                    trim
                    placeholder="Nom de naissance"
                    v-model="prospect_create.nom_naissance"
                    :state="errors.length > 0 ? false : null"
                    class="text-uppercase"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  label="Prénom"
                  label-for="prenom"
                >
                <validation-provider
                    #default="{ errors }"
                    name="prénom"
                    
                  >
                  <b-form-input
                    id="prenom"
                    trim
                    placeholder="Prénom"
                    v-model="prospect_create.prenom"
                    :state="errors.length > 0 ? false : null"
                    class="text-capitalize"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Date de naissance"
                label-for="particulier_date_naissance"
              >
                  <flat-pickr
                    class="form-control"
                    v-model="prospect_create.date_naissance"
                    id="particulier_date_naissance"
                    :config="config"
                    placeholder="Date de naissance"
                    data-max-date="today"
                  />
              </b-form-group>
               <b-form-group
                  label="Code postal de naissance"
                  label-for="code_postal_naissance"
                >
                <validation-provider
                    #default="{ errors }"
                    name="code_postal_naissance"
                  >
                  <b-form-input
                    id="code_postal_naissance"
                    trim
                    placeholder="Code postal de naissance"
                    v-model="prospect_create.code_postal_naissance"
                    :state="errors.length > 0 ? false : null"
                    class="text-uppercase"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                         <b-form-group
                    label="Commune de naissance"
                    label-for="Ville de naissance"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="commune_naissance"
                    >
                    <b-form-input
                    v-model="prospect_create.commune_naissance"
                    id="commune_naissance"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Commune de naissance"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              <validation-provider #default="{ errors }" name="pays_naissance" >
                <b-form-group label="Pays de naissance" label-for="pays">
                  <v-select v-model="prospect_create.pays_naissance" :close-on-select="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays"
                            class="flex-grow-1"
                            input-id="pays" label="pays" placeholder="Pays de naissance"
                  >
                    <template #option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>

                    <template #selected-option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>
                    <div slot="no-options">Aucune pays disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              <b-form-group
                label="Situation familiale"
                label-for="particulier_situation_familiale"
              >
                  <b-form-select
                    v-model="prospect_create.situation_familiale"
                    id="particulier_situation_familiale"
                  >
                    <b-form-select-option value="null" disabled
                      >Choisissez</b-form-select-option
                    >
                    <b-form-select-option value="CONCUBIN"
                      >Concubin(e)</b-form-select-option
                    >
                    <b-form-select-option value="CELIBATAIRE"
                      >Célibataire</b-form-select-option
                    >
                    <b-form-select-option value="DIVORCE"
                      >Divorcé(e)</b-form-select-option
                    >
                    <b-form-select-option value="MARIE"
                      >Marié(e)</b-form-select-option
                    >
                    <b-form-select-option value="SEPARE"
                      >Séparé(e)</b-form-select-option
                    >
                    <b-form-select-option value="VEUF"
                      >Veuf(ve)</b-form-select-option
                    >
                    <b-form-select-option value="PACSE"
                      >Pacsé(e)</b-form-select-option
                    >
                  </b-form-select>
              </b-form-group>

             

              <b-form-group label="Enfants à charge" label-for="particulier_nbr_enfant">
                <b-form-input
                  v-model="prospect_create.enfantCharge"
                  id="particulier_nbr_enfant"
                  trim
                  placeholder="Enfants à charge"
                  type="number"
                  min="0"
                />
              </b-form-group>

              <b-form-group
                  label="N° et libellé de la voie"
                  label-for="adresse"
                >
                <validation-provider
                    #default="{ errors }"
                    name="n° et libellé de la voie"
                  >
                  <b-form-input
                    id="adresse"
                    trim
                    placeholder="N° et libellé de la voie"
                    v-model="prospect_create.libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>

              <b-form-group
                  label="Complément d'adresse"
                  label-for="complement_adresse"
                >

                  <b-form-input
                    id="complément_adresse"
                    trim
                    placeholder="Complément d'adresse"
                    v-model="prospect_create.complement_adresse"
                  />
                </b-form-group>

              <b-form-group
                  label="Lieu-dit"
                  label-for="lieu_dit"
                >
                  <b-form-input
                    id="lieu_dit"
                    trim
                    placeholder="Lieu-dit"
                    v-model="prospect_create.lieu_dit_ou_bp"
                  />
                </b-form-group>

              <b-form-group
                  label="Code postal"
                  label-for="code_postal"
                >
                <validation-provider
                    #default="{ errors }"
                    name="code postal"
                    rules="digits:5"
                  >
                  <b-form-input
                    id="code_postal"
                    trim
                    placeholder="Code postal"
                    @keyup="cpostalKeyup()"
                    v-model="prospect_create.code_postal"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'#####'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
    
                <b-form-group
                    label="Ville"
                    label-for="ville"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="ville"
                     
                    >
                    <b-form-select
                    v-model="prospect_create.ville"
                    id="ville"
                    :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option v-for="(val, value) in villesPP" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Inscrit sur Bloctel" label-for="inscrit-sur-bloctel">
                 <validation-provider #default="{ errors }" name="Inscrit sur Bloctel">
                  <div class="form-inline" id="inscrit-sur-bloctel">
                    <b-form-radio v-model="prospect_create.isBloctel" name="inscrit-sur-bloctel-radios" value="1" class="mr-2"> oui </b-form-radio>
                    <b-form-radio v-model="prospect_create.isBloctel" name="inscrit-sur-bloctel-radios" value="0"> non </b-form-radio>
                  </div>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                 </validation-provider>
                </b-form-group>

         
                <b-form-group v-if="prospect_create.isBloctel == 1" label="Date d’inscription sur Bloctel" label-for="Date-d’inscription-sur-bloctel">
                    <flat-pickr
                      v-model="prospect_create.date_bloctel"
                      class="form-control"
                      id="date_d’inscription_sur_bloctel"
                      :config="config"
                      placeholder="Date d’inscription sur Bloctel"
                    />
                </b-form-group>

                <b-form-group label="Téléphone portable" label-for="téléphone-portable">
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="prospect_create.indicatif" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider
                                      #default="{ errors }"
                                      name="téléphone"
                                      :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                    >
                              <b-form-input
                                id="particulier_telephone"
                                style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                                v-model="prospect_create.tel"
                                trim
                                placeholder="ex: 06 00 00 00 00"
                                v-mask="'## ## ## ## ##'"
                                @change="errorIndicatifTelFunction(errors)"
                              />
                            <small class="text-danger" >{{ errorIndicatifTelFunction(errors[0]) }}</small>
                        </validation-provider>
                    </div>
                  </div>
                <small class="text-danger">{{ errorIndicatifTel }}</small>
              </b-form-group>

                <b-form-group
                  label="Téléphone fixe"
                  label-for="particulier_telephone_fixe"
                >    
                  <div class="d-flex bd-highlight">
                    <div class="w-25 bd-highlight">
                          <v-select v-model="prospect_create.indicatif_sec" :close-on-select="true" :clearable="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif" 
                            input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                        >
                            <template #option="{ indicatif,code }">
                              <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                            </template>
                            <template #selected-option="{ indicatif }">
                              <span> {{ indicatif }}</span>
                            </template>
                            <div slot="no-options">Aucune indicatif disponible.</div>
                        </v-select>
                    </div>
                    <div class="w-75 bd-highlight">
                  <validation-provider
                      #default="{ errors }"
                      name="téléphone fixe"
                      :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                  >
                  <b-form-input
                    id="particulier_telephone_fixe"
                    v-model="prospect_create.tel_secondaire"
                    trim
                    style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                    placeholder="ex: 06 00 00 00 00"
                    v-mask="'## ## ## ## ##'"
                  />
                    <small class="text-danger" :style="errors.length > 0 ? 'margin-left:-88px' : '' ">{{errors[0] }}</small>
                  </validation-provider>
                   </div>
                </div>
              </b-form-group>

                <b-form-group
                  label="Email"
                  label-for="email"
                >
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="email"
                  >
                  <b-form-input
                    id="email"
                    trim
                    placeholder="Email"
                    v-model="prospect_create.email"
                    :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] ? 'Le champ email est obligatoire': '' }}</small>
                  <small class="text-danger" v-if="isInvalideMailandPhone">Email et Téléphone sont déja utilisé</small>
                </validation-provider>
                </b-form-group>
                <b-form-group label="Catégorie socioprofessionnelle" label-for="activite">
                  <b-form-select
                    v-model="prospect_create.statut"
                    id="activite"
                  >
                    <b-form-select-option :value="null"
                      >Choisissez</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="(activite, value) in ACTIVITE_DATA"
                      :key="'a' + value"
                      :value="activite.value"
                    >
                      {{ activite.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                    <b-form-group label="Domaine d’activité" label-for="Domaine d’activité">
                    <b-form-select id="activite" v-model="prospect_create.secteur_activite">
                      <b-form-select-option value="null" disabled>Choisissez</b-form-select-option>
                      <template v-for="secteur in sortedSecteurs">
                        <b-form-select-option :value="secteur.value" :key="secteur.value">{{ secteur.name }}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                <b-form-group
                  label="Régime de sécurité sociale"
                  label-for="particulier_regime_obligatoire"
                >
                  <b-form-select
                      v-model="prospect_create.regime_obligatoire"
                      id="particulier_regime_obligatoire"

                    >
                      <b-form-select-option value="null" disabled
                        >Choisissez</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(regime, value) in REGIMESOCIAL"
                        :key="'r' + value"
                        :value="regime.value"
                      >
                        {{ regime.name }}
                      </b-form-select-option>
                  </b-form-select>
                </b-form-group>

                 <b-form-group label= "Numéro de sécurité social" label-for="numSecuParticulier" >

                   <cleave
                      id="numSecuParticulier"
                      v-model="prospect_create.numSecu"
                      :options="blockSecu"
                      class="form-control"
                      :raw="false"
                      placeholder="Numéro de sécurité social"
                    />

                     <span class="text-danger" style="font-size:12px" v-if="errorsNumSecu" >
                        Le numéro de sécurite sociale est erroné. 

                  </span>                    
                  </b-form-group>
                <b-form-group label="Type d’origine" label-for="Type-d’origine">
             <b-form-select
                    v-model="prospect_create.origine"
                    :options="origine"
                    id="origine"
                    />
            </b-form-group>
            <b-form-group v-if="prospect_create.origine==='Autre'" label="Précisez" label-for="origineautre"
                
                >
                    <b-form-input
                      id="origineautre"
                      placeholder="Précisez l’origine"
                      v-model="prospect_create.origineautre"
                    />
                </b-form-group>
            

                <!-- utilisateur list -->
                <validation-provider #default="{ errors }" name="commercial">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="Gestionnaire">
                    <v-select v-model="prospect_create.commercial" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="commercial" label="nom" placeholder="Crée par"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune gestionnaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="createur">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Créer par" label-for="Créer-par">
                    <v-select v-model="prospect_create.createur" :close-on-select="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                              class="flex-grow-1"
                              input-id="createur" label="nom" placeholder="Crée par"
                    >
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm"/>
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune createur disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="intermediaire">
                  <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                    <v-select v-model="prospect_create.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                      <template #option="{ avatar, nom }">
                        <b-avatar :src="avatar" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>

                      <template #selected-option="{ avatar, nom }">
                        <b-avatar :src="avatar" class="border border-white" size="sm" />
                        <span class="ml-50"> {{ nom }}</span>
                      </template>
                      <div slot="no-options">Aucune intermediaire disponible.</div>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                

              <!-- Form Actions -->
              <div class="d-flex mt-2" style="float:right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  size="md"
                  type="submit"
                >
                  Enregistrer
                </b-button>
              </div>

            </b-form>
          </validation-observer>
        </div>
      </template>
      </b-sidebar>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BInputGroupAppend, BSidebar,BFormRadio, BAvatar, VBToggle, BCardText,BCard,BFormGroup, BFormInput, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend,BFormInvalidFeedback,BFormSelect,BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import flatPickr from "vue-flatpickr-component";
import 'cleave.js/dist/addons/cleave-phone.us'
import { is422 } from '@/shared/utils/response';
import Data from "@/shared/constants/prospect";
import Besoin from "@/shared/constants/risquebesoin"
import Activite from "@/shared/constants/activite"
import StatutJuridique from "@/shared/constants/statutjuridique"
export default {
  components: {
    BInputGroupAppend,
    BButton,
    BSidebar,
    BFormRadio,
    BCardText,
    BCard,
    BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Cleave,
    BAvatar
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  watch: {
    'prospect_morale.forme_juridique' :{
        handler(val){
         if(val =="ALA"){
            this.siretLabel = "RNA"
            this.labelDirigeant = "président"
            this.labelEntreprise ="association"
         }else{
           this.siretLabel ="Siret"
           this.labelDirigeant = "dirigeant" 
          this.labelDirigeant = "dirigeant" 
           this.labelDirigeant = "dirigeant" 
          this.labelEntreprise ="entreprise"
         }
      }
    },
    'professionnel.forme_juridique' :{
        handler(val){
         if(val =="ALA"){
            this.siretLabel = "RNA"
            this.labelDirigeant = "président"
            this.labelEntreprise ="association"
         }else{
           this.siretLabel ="Siret"
           this.labelDirigeant = "dirigeant" 
          this.labelDirigeant = "dirigeant" 
           this.labelDirigeant = "dirigeant" 
          this.labelEntreprise ="entreprise"
         }
      }
    },
    'prospect_create.email': {
      deep:true,
      handler(val){
          this.isInvalideMailandPhone = false;
          if (this.prospect_create.email && this.prospect_create.tel) {
            this.isDuplicateMailandPhone(this.prospect_create.email,this.prospect_create.tel)
          }
      }
    },
    'prospect_create.tel': {
      deep:true,
      handler(val){
        this.isInvalideMailandPhone = false;
        if (this.prospect_create.tel && this.prospect_create.email) {
            this.isDuplicateMailandPhone(this.prospect_create.email,this.prospect_create.tel)
        }
      }
    },
    'professionnel.email': {
      deep:true,
      immediate:true,
      handler(val){
          this.isInvalideMailandPhone = false;
          if (this.professionnel.email && this.professionnel.tel) {
            this.isDuplicateMailandPhone(this.professionnel.email,this.professionnel.tel)
          }
      }
    },
    'professionnel.tel': {
      deep:true,
      immediate: true,
      handler(val){
        this.isInvalideMailandPhone = false;
        if (this.professionnel.tel && this.professionnel.email) {
            this.isDuplicateMailandPhone(this.professionnel.email,this.professionnel.tel)
        }
      }
    },
    'prospect_morale.email': {
      deep:true,
      immediate:true,
      handler(val){
          this.isInvalideMailandPhone = false;
          if (this.prospect_morale.email && this.prospect_morale.tel) {
            this.isDuplicateMailandPhone(this.prospect_morale.email,this.prospect_morale.tel)
          }
      }
    },
    'prospect_morale.tel': {
      deep:true,
      immediate: true,
      handler(val){
        this.isInvalideMailandPhone = false;
        if (this.prospect_morale.tel && this.prospect_morale.email) {
            this.isDuplicateMailandPhone(this.prospect_morale.email,this.prospect_morale.tel)
        }
      }
    },
    // 'prospect_morale.date_cloture_exercice': {
    //   handler(val) {
    //     if(this.dataSelectedForEdit.date_cloture_exercice != '')
    //       this.prospect_morale.date_cloture_exercice=this.dataSelectedForEdit.date_cloture_exercice
    //     if(this.prospect_morale.date_cloture_exercice.length==0){
    //       this.valdiation=null
    //     }
    //     else{
    //       const jour = this.prospect_morale.date_cloture_exercice.charAt(0)+this.prospect_morale.date_cloture_exercice.charAt(1)
    //       const mois = this.prospect_morale.date_cloture_exercice.charAt(3)+this.prospect_morale.date_cloture_exercice.charAt(4)
    //       if(this.prospect_morale.date_cloture_exercice.length==2){
    //       this.prospect_morale.date_cloture_exercice += '/'
    //     }
    //       if ( (mois >= 1 && mois <= 12) && (jour >= 1 && jour <= 31) ){
    //         if(this.prospect_morale.date_cloture_exercice.length<5){
    //           this.valdiation='taper DD/MM'
    //         }
    //         else{
    //           this.valdiation=null
    //         }
    //       }
    //       else{
    //         this.valdiation='taper DD/MM'
    //       }
    //     }
    //   },
    // }
  },
  mounted() {
    this.getAuthCourtier()
    this.ACTIVITE_DATA = Activite['ACTIVITE'].sort((act1, act2) => {
        return (act1.value > act2.value ? 1 : (act1.value === act2.value ? 0 : -1))
    })
        this.ACTIVITE_DATA =  this.ACTIVITE_DATA.filter(object => {
          return object.name !=="Independant"  && object.name !== 'Auto-entrepreneur' ;
        });
    this.STATUT_DATA = StatutJuridique['STATUT'].sort((act1, act2) => {
        return (act1.value > act2.value ? 1 : (act1.value === act2.value ? 0 : -1))
    })
  },
  created(){
    this.$http.get(`tiers/getCodeNAf`)
      .then((r) => {
        this.codeNafData=r.data
      })
      .catch((e)=>{
        console.log(e);
      })
    this.$http.get(`tiers/getPays`)
      .then((r) => {
        this.pays=r.data
        let france = r.data.find(item => item.indicatif == "+33")
        let indexFrance = r.data.indexOf(france)
        //r.data.splice(indexFrance, 1, france);
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
        // this.prospect_create.indicatif = this.indicatif[0].indicatif
        // this.prospect_create.indicatif_sec = this.indicatif[0].indicatif
        // this.professionnel.indicatif = this.indicatif[0].indicatif
        // this.professionnel.indicatif_sec = this.indicatif[0].indicatif
        // this.professionnel.entreprise_indicatif = this.indicatif[0].indicatif
        // this.prospect_morale.dirigeant.indicatif = this.indicatif[0].indicatif
        // this.prospect_morale.indicatif = this.indicatif[0].indicatif
      })
      .catch((e)=>{
        console.log(e);
      })
  // this.getAuthCourtier()
  },
  data(){
    return{
      errorIndicatifTel: [],
      valdiation:null,
      required,
      origine:[
            { value: null, text: 'Choisissez' },
            { value: 'Co_courtage', text: 'Co-courtage' },
            { value: 'Indicateur_affaires', text: 'Indicateur d\'affaires' },
            { value: 'Weedo_market', text: 'Weedo market' },
            { value: 'Recommandation', text: 'Recommandation' },
            { value: 'Réseaux_sociaux', text: 'Réseaux sociaux' },
            { value: 'Autre', text: 'Autre' },
      ],
      blockSecu: {
        blocks: [15],
        uppercase: true,
      },
      isInvalideMailandPhone: false,
      villesPP: [],
      villesPPN: [],
      villesPPRON: [],
      siretLabel: "Siret",
      labelDirigeant:"Dirigeant",
      labelEntreprise:"Entreprise",
      villesPM: [],
      villesPRO: [],
      adresseResult: [],
      codeNafData: [],
      pays :[],
      indicatif:[],
      personne_type: 'PARTICULIER',
      FormJuridique : Data["FORMEJURIDIQUE"],
      prospect_morale: {
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        date_cloture_exercice:null,
        dirigeant:{
          id:null,
          civilite:null,
          nom:null,
          prenom:null,
          tel:null,
          tel_secondaire:null,
          email:null,
          indicatif:null,
          indicatif_sec:null,
          type_lien_prof:'DIRIGEANT'
        },
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
        email:null,
        tel:null,
        indicatif:null,
        indicatif_sec:null,
        site_web:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
        statut_fiche : 'Prospect',
        commercial: null,
        origine:null,
        origineautre:null,
        createur:null,
        intermediaire:null,
        isBloctel:0,
        date_bloctel:null
      },
      professionnel: {
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        tel:null,
        indicatif:null,
        indicatif_sec:null,
        tel_secondaire:null,
        email:null,
        type_lien_prof:'DIRIGEANT',
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
        date_naissance:null,
        situation_familiale:null,
        enfantCharge:null,
        regime_obligatoire:null,
        entreprise_email:null,
        entreprise_tel:null,
        entreprise_indicatif:null,
        site_web:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
        statut_fiche : 'Prospect',
        commercial: null,
        nom_naissance:null,
        commune_naissance: null,
        pays_naissance: null,
        code_postal_naissance: null,
        origine:null,
        origineautre:null,
        createur:null,
        intermediaire:null,
        statut_juridique:null,
        statut:null,
        date_cloture_exercice: null,
        numSecu:null,
        secteur_activite:null,
        isBloctel:0,
        date_bloctel:null
      },
      prospect_create: {
        civilite: null,
        nom: null,
        origine:null,
        prenom: null,
        libelle: null,
        complement_adresse: null,
        ville: null,
        code_postal: null,
        tel: null,
        indicatif:null,
        indicatif_sec:null,
        tel_secondaire:null,
        email: null,
        type: null,
        lieu_dit_ou_bp: null,
        statut_fiche: 'Prospect',
        date_naissance:null,
        situation_familiale:null,
        enfantCharge:null,
        regime_obligatoire:null,
        statut:null,
        commercial: null,
        nom_naissance:null,
        commune_naissance: null,
        pays_naissance: null,
        code_postal_naissance: null,
        origineautre: null,
        createur:null,
        intermediaire:null,
        numSecu: null,
        secteur_activite:null,
        isBloctel:0,
        date_bloctel:null
      },
      errorsNumSecu: false,
      renderComponent:true,
      config: {
        dateFormat: "d/m/Y",
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      statutFiche:[
          { value: null, text: 'Choisissez' },
          { value: 'Prospect', text: 'Prospect' },
          { value: 'Client', text: 'Client' },
          { value: 'Ancien Client', text: 'Ancien Client' },
      ],
      status:[
          { value: null, text: 'Choisissez' },
          { value: 'PARTICULIER', text: 'Particulier' },
          { value: 'PROFESSIONNEL', text: 'Professionnel' },
      ],
      civilite:[
          { value: null, text: 'Choisissez' },
          { value: 'MR', text: 'M.' },
          { value: 'MME', text: 'Mme' },
      ],
      REGIMESOCIAL:Besoin['REGIMESOCIAL'],
      DomaineActivite :Besoin['SECTEUR_ACTIVITES'],
      ACTIVITE_DATA : [],//Activite['ACTIVITE'],
      STATUT_DATA : [],//Statut['STATUT'],
      isActive:false,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+33',
          blocks: [3, 9],
          uppercase: true,
        },
        date_cloture: {
          date: true,
          delimiter: '/',
          datePattern: ['d','m'],

        }
      },
    }
  },
  props: {
    commercialOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
  },
  computed: {
    sortedSecteurs() {
      return this.DomaineActivite.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    errorIndicatifTelFunction(val) {
      this.errorIndicatifTel = val
    },
    getAuthCourtier(){
      let userData = JSON.parse(localStorage.getItem('userData'))
    let commercialSelected = {
      id: userData.personne_physique.user_id,
      nom: userData.personne_physique.prenom + ' ' + userData.personne_physique.nom
    }
    this.prospect_create.commercial = commercialSelected
    this.professionnel.commercial   = commercialSelected
    this.prospect_morale.commercial = commercialSelected
    this.prospect_create.createur = commercialSelected
    this.professionnel.createur   = commercialSelected
    this.prospect_morale.createur = commercialSelected
    },
    resetValidator($event){
      switch ($event) {
        case 'PARTICULIER':
          this.$refs.AddNewTierValidations.reset()
          break;
        case 'ENTREPRISE':
          this.$refs.AddNewTierValidationsPM.reset()
          break;
        case 'PROFESSIONNEL':
          this.$refs.AddNewTierValidationsPRO.reset()
          break;
      }
    },
    changeorigine(origine){
      
      switch (origine) {
        case 'Autre':
          this.prospect_morale.origine='Autre'

        break;
        case null:
          this.prospect_morale.origine=null

        break;
        case 'Co_courtage':
          this.prospect_morale.origine='Co_courtage'

        break;
        case 'Réseaux_sociaux':
          this.prospect_morale.origine='Réseaux_sociaux'

        break;
        case 'Recommandation':
          this.prospect_morale.origine='Recommandation'

        break;
        case 'Weedo_market':
          this.prospect_morale.origine='Weedo_market'

        break;
        case 'Indicateur_affaires':
          this.prospect_morale.origine='Indicateur_affaires'

        break;
      }
    },
    async cpostalKeyup() {
      let cp = this.prospect_create.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPP = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.prospect_create.ville == null) {
              this.prospect_create.ville = this.villesPP[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPP = []
          this.prospect_create.ville = null
        }
      } catch (e) {}
    },
    async cpostalKeyupPM() {
      let cp = this.prospect_morale.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPM = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.prospect_morale.ville == null) {
              this.prospect_morale.ville = this.villesPM[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPM = []
          this.prospect_morale.ville = null
        }
      } catch (e) {}
    },
    async cpostalKeyupPRO() {
      let cp = this.professionnel.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPRO = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.professionnel.ville == null) {
              this.professionnel.ville = this.villesPRO[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPRO = []
          this.professionnel.ville = null
        }
      } catch (e) {}
    },
    // getName(obj) {
    //   this.prospect_create.adresse = obj.label
    //   this.prospect_create.code_postal = obj.postcode
    //   this.adresseResult = []
    //   this.cpostalKeyup()
    // },
    GetAdresse(val) {
      const adresse = val.replace(/\s/g, '+')
      const options = {
        url: 'https://api-adresse.data.gouv.fr/search/?q=' + adresse + '&type=street',
        method: 'GET',
      }
      this.$axios(options)
        .then(res => {
          this.adresseResult = res.data.features
          console.log('Login Success')
        })
        .catch(err => {
          console.error('Login failed.')
        })
    },
    async isDuplicateMailandPhone(email,tel) {
      try {
        if(this.ValidateEmail(email) && tel.length == 14)
        {
            let counter_result = (await this.$http.post(`verify/is_duplicate_mail_and_phone`,{ email: email , phone: tel})).data
            if (parseInt(counter_result) > 0) {
              this.isInvalideMailandPhone = true
            } else {
              this.isInvalideMailandPhone = false
            }
        }
        //https://bubble-in.atlassian.net/browse/BUB-1686
        this.isInvalideMailandPhone = false
        //https://bubble-in.atlassian.net/browse/BUB-1686
      } catch (e) {
        this.isInvalideMailandPhone = true
      }
    },
    submitForm_create_prospect() {
      this.errorsNumSecu = false
      if (!this.isInvalideMailandPhone) {
        
        this.$refs.AddNewTierValidations.validate().then((success) => {
          if (success) {
            console.log(this.prospect_create)
            this.$http.post(`prospect/saveorupdatePersonneParticulier`,this.prospect_create)
            .then(res => {
              if (res.data !== 'Error') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Client créé`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Le client ' +(this.prospect_create.prenom ?this.prospect_create.prenom :'' )    + ' ' + this.prospect_create.nom + ' a été créé avec succès.',
                  },
                })
                this.$emit('reloadTiersData')
                this.isActive=false
                this.resetCreateProspect()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Client créé`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'Le client ' +(this.prospect_create.prenom ?this.prospect_create.prenom :'' ) + ' ' + this.prospect_create.nom + ' n\'a pas été créer.',
                  },
                })
              }
            })
              .catch(err => {  
                this.errorsNumSecu = err.response.data.errors.numSecu ? true : false;
                console.log(err)
            })
          }
         });
      }
    },
    submitForm_create_personne_morale() {
      this.$refs.AddNewTierValidationsPM.validate().then((success) => {
        if (success) {
          this.$http.post(`prospect/saveorupdatePersonneMorale`,this.prospect_morale)
          .then(res => {
            if (res.data !== 'Error') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Client créé`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Le client a été créé avec succès.',
                },
              })
              this.$emit('reloadTiersData')
              console.log('saveorupdatePersonneMorale')
              this.isActive=false,
              this.resetCreateProspectMorale()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Client créé`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Le client n\'a pas été créer.',
                },
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
        }
      });
    },
    submitForm_create_personne_morale_pro() {
      this.errorsNumSecu = false
      if (!this.isInvalideMailandPhone) {
          this.$refs.AddNewTierValidationsPRO.validate().then((success) => {
            if (success) {
              this.$http.post(`prospect/saveorupdatePersonneMoralePro`,this.professionnel)
              .then(res => {
                if (res.data !== 'Error') {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Client créé`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Le client a été créé avec succès.',
                    },
                  })
                  this.$emit('reloadTiersData')
                  this.isActive=false
                  console.log('saveorupdatePersonneMoralePro')
                  this.resetCreateProspectPro()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Client créé`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: 'Le client  n\'a pas été créer.',
                    },
                  })
                }
              })
                .catch(err => {
                this.errorsNumSecu = err.response.data.errors.numSecu ? true : false;
                console.log(err)
              })
            }
          });
      }
    },
    resetCreateProspect() {
      this.prospect_create={
        statut_fiche: null,
        civilite: null,
        nom: null,
        prenom: null,
        libelle: null,
        complement_adresse: null,
        ville: null,
        code_postal: null,
        tel: null,
        tel_secondaire: null,
        email: null,
        type: null,
        lieu_dit_ou_bp: null,
        statut_fiche: 'Prospect',
        date_naissance:null,
        situation_familiale:null,
        enfantCharge:null,
        regime_obligatoire:null,
        secteur_activite:null
      }
    },
    resetCreateProspectMorale() {
      this.prospect_morale= {
        statut_fiche: null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        date_cloture_exercice:null,
        dirigeant:{
          id:null,
          civilite:null,
          nom:null,
          prenom:null,
          tel:null,
          tel_secondaire:null,
          email:null,
          type_lien_prof:'DIRIGEANT'
        },
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
        site_web:null,
        email:null,
        tel:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
        statut_fiche : 'Prospect'
      }
    },
    resetCreateProspectPro() {
      this.professionnel= {
        statut_fiche: null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        tel:null,
        tel_secondaire:null,
        email:null,
        type_lien_prof:'DIRIGEANT',
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
        date_naissance:null,
        situation_familiale:null,
        enfantCharge:null,
        regime_obligatoire:null,
        site_web:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
        statut_fiche : 'Prospect',
        date_cloture_exercice:null,
        secteur_activite:null
      }
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.Nom-entre::placeholder{
  text-transform: initial;
}
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}
input#indicatif.vs__search{
  padding: 0px;
}
.indicatif-chooser .vs__selected-options{
  height: 31px;
}
.indicatif-chooser .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
  width:111%;
}
.v-select .indicatif-chooser .vs--open .vs--single .vs--searchable
{
  min-width: 30%;
  width:30%;
}
</style>

