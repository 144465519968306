<template>
  <b-sidebar id="sidebar-client-pro-entreprise-custom" ref="sideBarClientProEntreprise" backdrop bg-variant="white" no-header right
             shadow sidebar-class="sidebar-lg"
  >
    <b-overlay :show="showLoading" no-wrap/>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Modifier Entreprise </span>
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>
        <validation-observer ref="clientProEntrepriseComposeRules" >
            <b-form
              class="px-2"
              @submit.prevent="updateProEntreprise"
            >
                <b-form-group
                  label="Activité*"
                  label-for="pro_activite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro activité"
                      rules="required|min:3"
                    >
                    <b-form-input
                      id="pro_activite"
                      trim
                      placeholder="Activité"
                      v-model="entreprise.activite"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Dénomination Commerciale*"
                  label-for="pro dénomination commerciale"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro dénomination commerciale"
                      rules="required|min:3"
                    >
                    <b-form-input
                      id="pro_denomination_commercial"
                      trim
                      placeholder="Dénomination Commerciale"
                      v-model="entreprise.denomination_commercial"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>


                <b-form-group
                  label="Raison Sociale*"
                  label-for="pro raison sociale"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro raison sociale"
                      rules="required|min:3"
                    >
                    <b-form-input
                      id="pro_raison_sociale"
                      trim
                      placeholder="Raison Sociale"
                      v-model="entreprise.raison_sociale"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Date de création"
                  label-for="pro date création"
                >
                    <flat-pickr
                      class="form-control"
                      v-model="entreprise.date_creation"
                      id="pro_date_creation"
                      :config="configDate"
                      placeholder="Date de création"
                    />
                </b-form-group>

                <b-form-group
                  label="Siret*"
                  label-for="pro_siret"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro siret"
                      rules="required|min:3"
                    >
                    <b-form-input
                      id="pro_siret"
                      trim
                      placeholder="Siret"
                      v-model="entreprise.siret"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>


                <b-form-group
                  label="Forme Juridique*"
                  label-for="pro_forme_juridique"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro forme juridique"
                      rules="required"
                    >
                    <b-form-select
                      v-model="entreprise.forme_juridique"
                    >
                      <b-form-select-option
                        v-for="(fg,index) in FormJuridique"
                        :key="index"
                        :value="fg.value"
                      >
                        {{ fg.text }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Téléphone de l’entreprise*"
                  label-for="prospect_morale_telephone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="téléphone de l’entreprise"
                    :rules="{ required: true, regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                  >
                  <b-form-input
                    id="prospect_morale_telephone"
                    v-model="entreprise.tel"
                    trim
                    placeholder="ex: 06 00 00 00 00"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'## ## ## ## ##'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Email de l’entreprise*"
                  label-for="prospect_morale_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                  <b-form-input
                    id="prospect_morale_email"
                    v-model="entreprise.email"
                    trim
                    placeholder="Email de l’entreprise"
                    :state="errors.length > 0  ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>


                <b-form-group
                  label="Site internet"
                  label-for="pro_site_web"
                >
                    <b-form-input
                      id="pro site web"
                      trim
                      placeholder="Site internet"
                      v-model="entreprise.site_web"
                    />
                </b-form-group>


                <b-form-group
                  label="Code NAF"
                  label-for="pro_code_naf"
                >

                  <v-select
                    v-model="entreprise.code_naf"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="libelle"
                    :options="codeNafData"
                    input-id="id"
                  >
                    <template #option="{code, libelle }">
                      <span class="ml-50">  {{ code }} {{ libelle }}</span>
                    </template>

                    <template #selected-option="{ code }">
                      <span class="ml-50"> {{ code }} </span>
                    </template>
                    <div slot="no-options">Aucune correspondance.</div>
                  </v-select>
                  <!-- <b-form-select
                    v-model="professionnel.code_naf"
                  >
                      <b-form-select-option
                        v-for="(cn,index) in codeNafData"
                        :key="index"
                        :value="cn.id"
                      >
                        {{ cn.code }} {{ cn.libelle }}
                      </b-form-select-option>
                  </b-form-select> -->
                </b-form-group>

                <b-form-group
                  label="IDCC"
                  label-for="pro_idcc"
                >
                  <b-form-input
                    id="pro_idcc"
                    trim
                    placeholder="IDCC"
                    v-model="entreprise.idcc"
                  />
                </b-form-group>

                <b-form-group
                  label="Bénéficiaires effectifs"
                  label-for="pro_beneficiairesEffectif"
                >
                    <b-form-input
                      id="pro_beneficiairesEffectif"
                      trim
                      placeholder="Bénéficiaires effectifs"
                      v-model="entreprise.beneficiairesEffectif"
                    />
                </b-form-group>

                <b-form-group
                  label="Chiffre d’affaires"
                  label-for="pro_chiffreAffaire"
                >
                    <b-form-input
                      id="pro_chiffreAffaire"
                      trim
                      placeholder="Chiffre d’affaires"
                      v-model="entreprise.chiffreAffaire"
                    />
                </b-form-group>

                <b-form-group
                  label="Effectif"
                  label-for="pro_effectif"
                >
                    <b-form-input
                      id="pro_effectif"
                      trim
                      placeholder="Effectif"
                      v-model="entreprise.effectif"
                    />
                </b-form-group>
                <!-- <b-form-group
                  label="Civilité*"
                  label-for="pro_civilite"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_civilite"
                      rules="required"
                    >
                    <b-form-select
                    v-model="professionnel.civilite"
                    :options="civilite"
                    id="pro_civilite"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Nom*"
                  label-for="pro_nom"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_nom"
                      rules="required|min:3"
                    >
                    <b-form-input
                      id="pro_nom"
                      trim
                      placeholder="Nom"
                      v-model="professionnel.nom"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Prénom*"
                  label-for="pro_prenom"
                >
                <validation-provider
                    #default="{ errors }"
                    name="pro_prenom"
                    rules="required|min:3"
                  >
                  <b-form-input
                    id="pro_prenom"
                    trim
                    placeholder="Prénom"
                    v-model="professionnel.prenom"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Téléphone*"
                  label-for="pro_telephone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pro_telephone"
                    rules="required|min:14"
                  >
                  <b-form-input
                    id="pro_telephone"
                    v-model="professionnel.tel"
                    trim
                    placeholder="Téléphone"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'## ## ## ## ##'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Téléphone portable*"
                  label-for="pro_telephone_portable"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pro_telephone_portable"
                    rules="required"
                  >
                  <b-form-input
                    id="pro_telephone_portable"
                    v-model="professionnel.tel_secondaire"
                    trim
                    placeholder="Téléphone portable"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Email*"
                  label-for="pro_email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_email"
                      rules="required"
                    >
                    <b-form-input
                      id="pro_email"
                      trim
                      placeholder="Email"
                      type="email"
                      v-model="professionnel.email"
                      :state="errors.length > 0 || isInvalideMailandPhone ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="isInvalideMailandPhone">Email et Téléphone sont déja utilisé</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="n° et libellé de la voie*"
                  label-for="pro_libelle"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="n° et libellé de la voie"
                      rules="required"
                    >
                    <b-form-input
                      id="pro_libelle"
                      trim
                      placeholder="n° et libellé de la voie"
                      v-model="professionnel.libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>


                <b-form-group
                  label="Complément d'adresse"
                  label-for="pro_complement_adresse"
                >

                  <b-form-input
                    id="pro_complement_adresse"
                    trim
                    placeholder="Complément d'adresse"
                    v-model="professionnel.complement_adresse"
                  />
                </b-form-group>

                <b-form-group
                  label="Lieu-dit"
                  label-for="lieu_dit"
                >
                  <b-form-input
                    id="lieu_dit"
                    trim
                    placeholder="Lieu-dit"
                    v-model="professionnel.lieu_dit_ou_bp"
                  />
                </b-form-group>


                <b-form-group
                  label="Code postal*"
                  label-for="pro_code_postal"
                >
                <validation-provider
                    #default="{ errors }"
                    name="pro_code_postal"
                    rules="required|digits:5"
                  >
                  <b-form-input
                    id="pro_code_postal"
                    trim
                    placeholder="Code postal"
                    @keyup="cpostalKeyupPRO()"
                    v-model="professionnel.code_postal"
                    :state="errors.length > 0 ? false : null"
                    v-mask="'#####'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>

                <b-form-group
                    label="Ville*"
                    label-for="pro_ville"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="pro_ville"
                      rules="required"
                    >
                    <b-form-select
                    v-model="professionnel.ville_id"
                    id="pro_ville"
                    :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option v-for="(val, value) in villeOptions" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

              <!-- Form Actions -->
              <div class="d-flex mt-2" style="float:right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  size="md"
                  type="submit"
                >
                  Enregistrer
                </b-button>
              </div>

            </b-form>
          </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BOverlay,
  BButton,
  BForm,
  BFormGroup,
  BSidebar,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BFormSelectOption,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import Data from "@/shared/constants/prospect";
import vSelect from 'vue-select'

export default {
  components: {
    // BTV
    BOverlay,
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormGroup,

    // flatPickr and Cleave
    flatPickr,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    entrepriseData: {
      type: Object,
      default: {},
      required: true
    },
    codeNafData: {
      type: Array,
      default: [],
      required: true
    },
    prospectData: {
      type: Object,
      default: {},
      required: true
    },
    hasEntreprise: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      showLoading: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      villeOptions: [],
      required,
      civilite:[
          { value: null, text: 'Choisissez' },
          { value: 'MR', text: 'M.' },
          { value: 'MME', text: 'Mme' },
      ],
      professionnel: {
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        tel:null,
        tel_secondaire:null,
        email:null,
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
      },
      entreprise:{
        id:null,
        denomination_commercial: null,
        activite: null,
        siret: null,
        raison_sociale: null,
        forme_juridique: null,
        site_web:null,
        email:null,
        tel:null,
        date_creation:null,
        code_naf:null,
        idcc:null,
        beneficiairesEffectif:null,
        chiffreAffaire:null,
        effectif:null,
        personne_physique:{
          id:null,
          type:null,
        }
      }
    }
  },
  watch: {
    entrepriseData: {
      immediate:true,
      deep: true,
      handler(val) {
        if (val.id !== undefined)
          this.mapDataToUpdate(val)
      }
    },
    'professionnel.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  mounted(){
  },
  methods: {
    // Methode additional
    // async GetCodeNAF(){
    //   await this.$http.get(`tiers/getCodeNAf`)
    //       .then((r) => {
    //         this.codeNafOptions = r.data
    //         this.mapDataToUpdate(this.entrepriseData)
    //       })
    //       .catch((e)=>{
    //         console.log(e);
    //       })
    // },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    mapDataToUpdate(data){
          this.entreprise.personne_physique.id=this.prospectData.id
          this.entreprise.personne_physique.type=this.prospectData.type
          this.entreprise.id = data.id
          this.entreprise.siret = data.siret
          this.entreprise.forme_juridique = data.forme_juridique
          this.entreprise.denomination_commercial = data.denomination_commercial
          this.entreprise.raison_sociale = data.raison_sociale
          this.entreprise.activite = data.activite
          this.entreprise.date_creation = data.date_creation
          let codeNAfValue = this.codeNafData.find(obj => {
            return obj.id === data.code_naf_id ;
          });
          this.entreprise.code_naf = codeNAfValue
          this.entreprise.idcc = data.idcc
          this.entreprise.beneficiairesEffectif = data.beneficiairesEffectif
          this.entreprise.chiffreAffaire = data.chiffreAffaire
          this.entreprise.effectif = data.effectif
          this.entreprise.site_web = data.moyen_contact.site_web
          this.entreprise.tel = data.moyen_contact.tel
          this.entreprise.email = data.moyen_contact.email
    },
    // affectedDataTierToComposeData() {
    //     this.entreprise.personne_physique.id=this.tiersData.id
    //     this.entreprise.personne_physique.type=this.tiersData.type
    //     let findEntreprise = this.tiersData.prospect.find(obj => {
    //       return obj.lien_professionnel === 'DIRIGEANT';
    //     });
    //     if(findEntreprise){
    //       this.entreprise.id = findEntreprise.lienable.id
    //       this.entreprise.siret = findEntreprise.lienable.siret
    //       this.entreprise.forme_juridique = findEntreprise.lienable.forme_juridique
    //       this.entreprise.denomination_commercial = findEntreprise.lienable.denomination_commercial
    //       this.entreprise.raison_sociale = findEntreprise.lienable.raison_sociale
    //       this.entreprise.activite = findEntreprise.lienable.activite
    //       this.entreprise.date_creation = findEntreprise.lienable.date_creation
    //       let codeNAfValue = this.codeNafOptions.find(obj => {
    //         return obj.id === findEntreprise.lienable.code_naf_id ;
    //       });
    //       this.entreprise.code_naf = codeNAfValue
    //       this.entreprise.idcc = findEntreprise.lienable.idcc
    //       this.entreprise.beneficiairesEffectif = findEntreprise.lienable.beneficiairesEffectif
    //       this.entreprise.chiffreAffaire = findEntreprise.lienable.chiffreAffaire
    //       this.entreprise.effectif = findEntreprise.lienable.effectif
    //       this.entreprise.site_web = findEntreprise.lienable.moyen_contact.site_web
    //       this.entreprise.tel = findEntreprise.lienable.moyen_contact.tel
    //       this.entreprise.email = findEntreprise.lienable.moyen_contact.email
    //     }
    // },
    hideSideBare() {
      this.$refs.sideBarClientProEntreprise.hide()
    },
    updateProEntreprise() {
      this.$refs.clientProEntrepriseComposeRules.validate()
        .then(success => {
          if (success) {
            this.showLoading = true
            this.$http
              .post('/prospect/updatePersonneMoraleProEntreprise', this.entreprise)
                .then(res => {
                  if (res.data) {
                    this.$emit('update-client-pro-returned', res.data.data, "Le client a été modifié avec succès")
                    this.hideSideBare()
                    this.showLoading = false
                  }
                })
                .catch(err => {
                  this.showLoading = false
                  this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  console.error(err)
                })
          }
        })
      }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

