const RisqueBesoin = {

    TYPEVOIE: [
        {
            name: "allée",
            value: "ALLEE"
        },
        {
            name: "avenue",
            value: "AVENUE"
        },
        {
            name: "boulevard",
            value: "BOULEVARD"
        },
        {
            name: "bourg",
            value: "BOURG"
        }, {
            name: "chemin",
            value: "CHEMIN"
        }, {
            name: "cours",
            value: "COURS"
        }, {
            name: "clos",
            value: "CLOS"
        }, {
            name: "carrefour",
            value: "CARREFOUR"
        }, {
            name: "digue",
            value: "DIGUE"
        }, {
            name: "faubourg",
            value: "FAUBOURG"
        }, {
            name: "impasse",
            value: "IMPASSE"
        }, {
            name: "passage",
            value: "PASSAGE"
        }, {
            name: "parc",
            value: "PARC"
        }, {
            name: "place",
            value: "PLACE"
        }, {
            name: "promenade",
            value: "PROMENADE"
        }, {
            name: "quai",
            value: "QUAI"
        }, {
            name: "rond-point",
            value: "ROND_POINT"
        }, {
            name: "route",
            value: "ROUTE"
        }, {
            name: "rue-point",
            value: "RUE"
        }, {
            name: "ruelle",
            value: "RUELLE"
        }, {
            name: "sentier",
            value: "SENTIER"
        }, {
            name: "square",
            value: "SQUARE"
        }, {
            name: "traverse",
            value: "TRAVERSE"
        }, {
            name: "voie",
            value: "VOIE"
        }, {
            name: "lieu dit",
            value: "LIEU_DIT"
        }
    ],
    REGIMESOCIAL: [
        {
            name: "Régime général de la sécurité sociale",
            value: "REGIME_GENERAL"
        }, {
            name: "Mutualité sociale agricole (AMEXA)",
            value: "REGIME_AGRICOLE"
        }, {
            name: "Régime des travailleurs non salariés et non agricoles (ex RSI)",
            value: "RÉGIME_SOCIAL"
        },
        {
            name:"Régime Alsace-Moselle",
            value:"REGIME_ALSACE_MOSELLE"
        },
        {
            name:"Autre régime spécial",
            value:"REGIME_SPECIAL"
        }

    ],
    COUVERTURE: [
        {
            name: "MOIS 6",
            value: "MOIS_6"
        }, {
            name: "MOIS 12",
            value: "MOIS_12"
        },
    ],
    SITUATIONFAMILIALE: [
        {
            name: "Concubin(e)",
            value: "CONCUBIN"
        },
        {
            name: "Célibataire",
            value: "CELIBATAIRE"
        },
        {
            name: "Divorcé(e)",
            value: "DIVORCE"
        },
        {
            name: "Marié(e)",
            value: "MARIE"
        }, {
            name: "Séparé(e)",
            value: "SEPARE"
        }, {
            name: "Veuf(ve)",
            value: "VEUF"
        }, {
            name: "Pacsé(e)",
            value: "PACSE"
        },

    ],
    TYPESTATUT: [
        {
            name: "sans emploi",
            value: "SANSEMPLOI"
        },
        {
            name: "étudiant",
            value: "ETUDIANT"
        },
        {
            name: "salarié",
            value: "SALARIE"
        },
        {
            name: "tns",
            value: "TNS"
        }, {
            name: "retraite",
            value: "RETRAITE"
        },
    ],
    PROFESSION: [
        {
            name: "AGRICULTEUR",
            value: "AGRICULTEUR"
        },
        {
            name: "ARTISAN_COMMERCANT",
            value: "ARTISAN_COMMERCANT"
        },
        {
            name: "CADRE",
            value: "CADRE"
        },
        {
            name: "INGENIEUR",
            value: "INGENIEUR"
        }, {
            name: "Chef d'entrepris",
            value: "CHEF_D_ENTREPRISE"
        }, {
            name: "COMMERCANT",
            value: "COMMERCANT"
        }, {
            name: "EMPLOYE",
            value: "EMPLOYE"
        }, {
            name: "Agent de la fonction publiqu",
            value: "AGENT_DE_LA_FONCTION_PUBLIQUE"
        }, {
            name: "En recherche d'emploi",
            value: "EN_RECHERCHE_D_EMPLOI"
        }, {
            name: "ENSEIGNANT",
            value: "ENSEIGNANT"
        }, {
            name: "ETUDIANT",
            value: "ETUDIANT"
        }, {
            name: "Homme ou femme au foyer",
            value: "HOMME_OU_FEMME_AU_FOYER"
        }, {
            name: "OUVRIER",
            value: "OUVRIER"
        }, {
            name: "Profession intermédiaire",
            value: "PROFESSION_INTERMEDIARE"
        }, {
            name: "Agent de maîtrise",
            value: "AGENT_DE_MAITRISE"
        }, {
            name: "Profession libérale",
            value: "PROFESSION_LIBERALE"
        }, {
            name: "RETRAITE",
            value: "RETRAITE"
        }, {
            name: "TECHNICIEN",
            value: "TECHNICIEN"
        }, {
            name: "INACTIF",
            value: "INACTIF"
        }, {
            name: "AUTRE",
            value: "AUTRE"
        },
    ],
    LOGEMENT: [
        {
            name: "Logement Actuel",
            value: "LOGEMENT_ACTUEL"
        }, {
            name: "Logement Futur",
            value: "LOGEMENT_FUTUR"
        },
    ],
    OCCUPANT: [
        {
            name: "Propreitaire Occupant",
            value: "PROPREITAIRE_OCCUPANT"
        }, {
            name: "Copropreitaire",
            value: "COPROPREITAIRE"
        },
    ],
    TYPERESIDENCE: [
        {
            name: "Residence Principale",
            value: "RESIDENCE_PRINCIPALE"
        }, {
            name: "Residence Secondaire",
            value: "RESIDENCE_SECONDAIRE"
        },
    ],
    TYPEHEBERGEMENT: [
        {
            name: "Residence Principale",
            value: "RESIDENCE_PRINCIPALE"
        }, {
            name: "Residence Secondaire",
            value: "RESIDENCE_SECONDAIRE"
        },
    ],
    ETAGE: [
        {
            name: "Rez de chaussée",
            value: "REZ_DE_CHAUSSÉE"
        }, {
            name: "Au premier étage",
            value: "AU_PREMIER_ETAGE"
        }, {
            name: "Etage Intermediaire",
            value: "ETAGE_INTERMEDIAIRE"
        }, {
            name: "Dernier Etage",
            value: "DERNIER_ETAGE"
        },
    ],
    TYPEDOMICILE: [
        {
            name: "Appartement ",
            value: "APPARTEMENT "
        }, {
            name: "Maison",
            value: "MAISON"
        }, {
            name: "individuelle",
            value: "INDIVIDUELLE"
        },
    ],
    CHEMINEETYPE: [
        {
            name: "Poêle à Bois ",
            value: "POELE_A_BOIS "
        }, {
            name: "Cheminée à Foyer Ouvert",
            value: "CHEMINEE_A_FOYER_OUVERT"
        }, {
            name: "Cheminée à Foyer Fermé",
            value: "CHEMINEE_A_FOYER_FERME"
        }
    ],
    TYPEHABITATION: [
        {
            name: "APPARTEMENT ",
            value: "APPARTEMENT "
        }, {
            name: "MAISON",
            value: "MAISON"
        }, {
            name: "MAISON INDIVIDUELLE",
            value: "MAISON_INDIVIDUELLE"
        }
    ],
    QUALITEASSURE: [
        {
            name: "LOCATAIRE OCCUPANT",
            value: "LOCATAIRE_OCCUPANT"
        },
        // {
        //       name:  "LOCATAIRE",
        //       value: "LOCATAIRE",
        // },
        // {
        //       name:  "COLOCATAIRE",
        //       value: "COLOCATAIRE",
        // }
    ],
    TYPEANIMAL: [
        {
            name: "Chat",
            value: "CHAT"
        }, {
            name: "Chien",
            value: "CHIEN"
        },
    ],
    TYPERACE: [
        {
            name: "Pure",
            value: "PURE"
        }, {
            name: "Croise",
            value: "CROISE"
        },
    ],
    TYPECONNU: [
        {
            name: "Réseaux sociaux",
            value: "RÉSEAUX_SOCIAUX "
        }, {
            name: "Bouche à oreil",
            value: "BOUCHE_A_OREIL"
        },
    ],
    NIVEAUCAPITAL: [
        {
            name: "K160",
            value: "K160 "
        },
        {
            name: "K310",
            value: "K310 "
        },
        {
            name: "K460",
            value: "K460"
        },
        {
            name: "K610",
            value: "K610"
        }, {
            name: "K770",
            value: "K770"
        }, {
            name: "K1000",
            value: "K1000"
        }, {
            name: "K1500",
            value: "K1500"
        },
    ],
    SEXEANIMAL: [
        {
            name: "Mâle",
            value: "MALE"
        }, {
            name: "Femelle",
            value: "FEMELLE"
        },
    ],
    TYPECHAT: [
        {
            name: "Compagnie",
            value: "COMPAGNIE"
        }, {
            name: "Chasse",
            value: "CHASSE"
        },
    ],
    RACECHIEN: [
        {
            name: "Shih Tzu",
            value: "Shih Tzu"
        },
    ],
    RACECHAT: [
        {
            name: "Shih Tzu",
            value: "Shih Tzu"
        },
    ],
    CHIEN: [
        {
            name: "Shih Tzu",
            value: "Shih Tzu"
        },
        {
            name: "Berger allemand",
            value: "Berger allemand"
        },
        {
            name: "Berger australien",
            value: "Berger australien"
        },
        {
            name: "Bouledogue francais",
            value: "Bouledogue francais"
        }, {
            name: "Chihuahua",
            value: "Chihuahua"
        }, {
            name: "Golden retriever",
            value: "Golden retriever"
        }, {
            name: "Retriever du labrador",
            value: "Retriever du labrador"
        }, {
            name: "Yorkshire terrier",
            value: "Yorkshire terrier"
        },
    ],
    CHAT: [
        {
            name: "Abyssin",
            value: "Abyssin"
        },
        {
            name: "Angora turc",
            value: "Angora turc"
        },
        {
            name: "Bengal",
            value: "Bengal"
        },
        {
            name: "British",
            value: "British"
        }, {
            name: "Chartreux",
            value: "Chartreux"
        }, {
            name: "European shorthair",
            value: "European shorthair"
        }, {
            name: "Maine coon",
            value: "Maine coon"
        }, {
            name: "Persan",
            value: "Persan"
        },
    ],
    TAUXCOMMISSION: [
        {
            name: "5",
            value: "5"
        }, {
            name: "10",
            value: "10"
        }, {
            name: "15",
            value: "15"
        },
    ],
    STRUCTURETARIFAIRE: [
        {
            name: "Adulte Enfant",
            value: "ADULTE_ENFANT"
        },
        // {
        //       name:  "Famille",
        //       value: "FAMILLE",
        // },
        {
            name: "Isole Duo Famille",
            value: "ISOLE_DUO_FAMILLE"
        },
        // {
        //       name:  "Isole Famille",
        //       value: "ISOLE_FAMILLE",
        // },
        // {
        //       name:  "Salarie Conjoint Enfant Gratuite 3éme",
        //       value: "SALARIE_CONJOINT_ENFANT_GRATUITE_3EME",
        // },
    ],
    IDCC: [
        {
            name: "2214",
            value: "2214"
        }, {
            name: "2315",
            value: "2315"
        }, {
            name: "2092",
            value: "2092"
        }, {
            name: "_none",
            value: "_none"
        },
    ],
    FORMEJURIDIQUE:
    [
            {
                name: "Administration de l'etat",
                value: "ADLE",
                value_long: "Administration de l'etat",
            },
            {
                name: "Association loi 1901 ou assimile",
                value: "ALA",
                value_long: "Association loi 1901 ou assimile",
            },
            {
                name: "Autre groupement de droit prive non dote de la personnalite morale",
                value: "AGDP",
                value_long: "Autre groupement de droit prive non dote de la personnalite morale",
            },
            {
                name: "Autre personne morale de droit prive",
                value: "APMDP",
                value_long: "Autre personne morale de droit prive",
            },
            {
                name: "Autre personne morale de droit prive inscrite au registre du commerce et des societes",
                value: "APMDPR",
                value_long: "Autre personne morale de droit prive inscrite au registre du commerce et des societes",
            },
            {
                name: "Autre personne morale de droit public administratif",
                value: "APMDPA",
                value_long: "Autre personne morale de droit public administratif",
            },
            {
                name: "Caisse d'epargne et de prevoyance",
                value: "CEP",
                value_long: "Caisse d'epargne et de prevoyance",
            },
            {
                name: "Collectivite territoriale",
                value: "CT",
                value_long: "Collectivite territoriale",
            },
            {
                name: "Comite d'entreprise",
                value: "CE",
                value_long: "Comite d'entreprise",
            },
            {
                name: "Entrepreneur individuel",
                value: "EI",
                value_long: "Entrepreneur individuel",
            },
            {
                name: "Etablissement public administratif",
                value: "EPA",
                value_long: "Etablissement public administratif",
            },
            {
                name: "Etablissement public ou regie a caractere industriel ou commercial",
                value: "EPRCI",
                value_long: "Etablissement public ou regie a caractere industriel ou commercial",
            },
            {
                name: "Fiducie",
                value: "FIDUCIE",
                value_long: "Fiducie",
            },
            {
                name: "Fondation",
                value: "FONDATION",
                value_long: "Fondation",
            },
            {
                name: "Groupement d'interet economique",
                value: "GIE",
                value_long: "Groupement d'interet economique",
            },
            {
                name: "Indivision",
                value: "INDIVISION",
                value_long: "Pndivision",
            },
            {
                name: "organisme de placement collectif en valeurs mobilieres sans personnalite morale",
                value: "OPCVMSPM",
                value_long: "organisme de placement collectif en valeurs mobilieres sans personnalite morale",
            },
            {
                name: "organisme de retraite a adhesion non obligatoire",
                value: "ORANO",
                value_long: "organisme de retraite a adhesion non obligatoire",
            },
            {
                name: "organisme gerant un regime de protection sociale a adhesion obligatoire",
                value: "OGRP",
                value_long: "organisme gerant un regime de protection sociale a adhesion obligatoire",
            },
            {
                name: "Organisme mutualiste",
                value: "OM",
                value_long: "Organisme mutualiste",
            },
            {
                name: "Organisme professionnel",
                value: "OP",
                value_long: "Organisme professionnel",
            },
            {
                name: "Paroisse hors zone concordataire",
                value: "PHZC",
                value_long: "Paroisse hors zone concordataire",
            },
            {
                name: "Personne morale de droit etranger immatriculee au rcs registre du commerce et des societes",
                value: "PMDEI",
                value_long: "Personne morale de droit etranger immatriculee au rcs registre du commerce et des societes",
            },
            {
                name: "Personne morale de droit etranger non immatriculee au rcs",
                value: "PMDENI",
                value_long: "Personne morale de droit etranger non immatriculee au rcs",
            },
            {
                name: "Société à responsabilité limitée",
                value: "SARL",
                value_long: "Société à responsabilité limitée",
            },
            {
                name: "Societe anonyme",
                value: "SA",
                value_long: "Societe anonyme",
            },
            {
                name: "Societé anonyme a conseil d'administration",
                value: "SACA",
                value_long: "Societé anonyme a conseil d'administration",
            },
            {
                name: "Societé anonyme a directoire",
                value: "SAD",
                value_long: "Societé anonyme a directoire",
            },
            {
                name: "Societé civile",
                value: "SC",
                value_long: "Societé civile",
            },
            {
                name: "Societé cooperative agricole",
                value: "SCA",
                value_long: "Societé cooperative agricole",
            },
            {
                name: "Societé cooperative commerciale particuliere",
                value: "SCOP",
                value_long: "Societé cooperative commerciale particuliere",
            },
            {
                name: "Societé creee de fait",
                value: "SCF",
                value_long: "Societé creee de fait",
            },

            {
                name: "Societé d'assurance mutuelle",
                value: "SAM",
                value_long: "Societé d'assurance mutuelle",
            },
            {
                name: "societé en commandite",
                value: "SEC",
                value_long: "societé en commandite",
            },
            {
                name: "societé en commandite simple",
                value: "SCS",
                value_long: "societé en commandite simple",
            },
            {
                name: "societé en commandite par actions",
                value: "SCA",
                value_long: "societé en commandite par actions",
            },
            {
                name: "Societé en nom collectif",
                value: "SNC",
                value_long: "Societé en nom collectif",
            },
            {
                name: "Societé en participation",
                value: "SP",
                value_long: "Societé en participation",
            },
            {
                name: "Societé europeenne",
                value: "SE",
                value_long: "Societé europeenne",
            },
            {
                name: "Societé par actions simplifiee",
                value: "SAS",
                value_long: "Societé par actions simplifiee",
            },
            {
                name: "Syndicat de proprietaires",
                value: "SDP",
                value_long: "Syndicat de proprietaires",
            },

    ],
    STATUTJURIDIQUE: [
        {
            name: "Artisan",
            value: "ARTISANS"
        },
        {
            name: "Commerçant",
            value: "COMMERCANTS"
        },
        {
            name: "Conjoint collaborateur",
            value: "CONJOINTS_COLLABORATEURS"
        },
        {
            name: "Entrepreneur individuel ou auto entrepreneur",
            value: "ENTREPRENEURS_INDIVIDUELS_OU_AUTO_ENTREPRENEURS"
        },
        {
            name: "Gérant majoritaire",
            value: "GERANTS_MAJORITAIRES"
        },
        {
            name: "Gérant majoritaire avec conjoint",
            value: "GERANTS_MAJORITAIRES_AVEC_CONJOINT"
        },
        {
            name: "Mandataire social",
            value: "MANDATAIRES_SOCIAUX"
        },
        {
            name: "Profession libérale médicale",
            value: "PROFESSIONS_LIBERALES_MEDICALES"
        },
        {
            name: "Profession libérale non médicale",
            value: "PROFESSIONS_LIBERALES_NON_MEDICALES"
        },
        {
            name: "Autre TNS",
            value: "TNS_AUTRES"
        },

    ],
    ACTIVITEPROFESSIONNELLE: [{
        value: null,
        text: 'Choisissez'
      },
      {
        value: "AGRICULTEUR",
        text: "Agriculteur"
      },
      {
        value: "ARTISAN",
        text: "Artisan"
      },
      {
        value: "CHEF_ENTREPRISE",
        text: "Chef d’entreprise"
      },
      {
        value: "COMMERCANT",
        text: "Commerçant"
      },
      {
        value: "ETUDIANT",
        text: "Etudiant"
      },
      {
        value: "FONCTIONNAIRE_ASSIMILE",
        text: "Fonctionnaire ou assimilé"
      },
      {
        value: "PROFESSION_LIBERALE",
        text: "Profession libérale"
      },
      {
        value: "RETRAITE",
        text: "Retraité"
      },
      {
        value: "SALARIE_NON_CADRE",
        text: "Salarié non cadre"
      },
      {
        value: "SALARIE_CADRE",
        text: "Salarié cadre"
      },
      {
        value: "SANS_EMPLOI",
        text: "Sans emploi"
      },
    ],
    NATIONALITES: [
        {
            name: "Afghane",
            value: "Afghane"
        },
        {
            name: "Albanaise",
            value: "Albanaise"
        },
        {
            name: "Algerienne",
            value: "Algerienne"
        },
        {
            name: "Allemande",
            value: "Allemande"
        },
        {
            name: "Americaine",
            value: "Americaine"
        },
        {
            name: "Andorrane",
            value: "Andorrane"
        },
        {
            name: "Angolaise",
            value: "Angolaise"
        },
        {
            name: "Antiguaise et barbudienne",
            value: "Antiguaise et barbudienne"
        },
        {
            name: "Argentine",
            value: "Argentine"
        },
        {
            name: "Armenienne",
            value: "Armenienne"
        },
        {
            name: "Australienne",
            value: "Australienne"
        },
        {
            name: "Autrichienne",
            value: "Autrichienne"
        },
        {
            name: "Azerbaïdjanaise",
            value: "Azerbaïdjanaise"
        },
        {
            name: "Bahamienne",
            value: "Bahamienne"
        },
        {
            name: "Bahreinienne",
            value: "Bahreinienne"
        },
        {
            name: "Bangladaise",
            value: "Bangladaise"
        },
        {
            name: "Barbadienne",
            value: "Barbadienne"
        },
        {
            name: "Belge",
            value: "Belge"
        },
        {
            name: "Belizienne",
            value: "Belizienne"
        },
        {
            name: "Beninoise",
            value: "Beninoise"
        },
        {
            name: "Bhoutanaise",
            value: "Bhoutanaise"
        },
        {
            name: "Bielorusse",
            value: "Bielorusse"
        },
        {
            name: "Birmane",
            value: "Birmane"
        },
        {
            name: "Bissau-Guinéenne",
            value: "Bissau-Guinéenne"
        },
        {
            name: "Bolivienne",
            value: "Bolivienne"
        },
        {
            name: "Bosnienne",
            value: "Bosnienne"
        },
        {
            name: "Botswanaise",
            value: "Botswanaise"
        },
        {
            name: "Bresilienne",
            value: "Bresilienne"
        },
        {
            name: "Britannique",
            value: "Britannique"
        },
        {
            name: "Bruneienne",
            value: "Bruneienne"
        },
        {
            name: "Bulgare",
            value: "Bulgare"
        },
        {
            name: "Burkinabe",
            value: "Burkinabe"
        },
        {
            name: "Burundaise",
            value: "Burundaise"
        },
        {
            name: "Cambodgienne",
            value: "Cambodgienne"
        },
        {
            name: "Camerounaise",
            value: "Camerounaise"
        },
        {
            name: "Canadienne",
            value: "Canadienne"
        },
        {
            name: "Cap-verdienne",
            value: "Cap-verdienne"
        },
        {
            name: "Centrafricaine",
            value: "Centrafricaine"
        },
        {
            name: "Chilienne",
            value: "Chilienne"
        },
        {
            name: "Chinoise",
            value: "Chinoise"
        },
        {
            name: "Chypriote",
            value: "Chypriote"
        },
        {
            name: "Colombienne",
            value: "Colombienne"
        },
        {
            name: "Comorienne",
            value: "Comorienne"
        },
        {
            name: "Congolaise",
            value: "Congolaise"
        },
        {
            name: "Costaricaine",
            value: "Costaricaine"
        },
        {
            name: "Croate",
            value: "Croate"
        },
        {
            name: "Cubaine",
            value: "Cubaine"
        },
        {
            name: "Danoise",
            value: "Danoise"
        },
        {
            name: "Djiboutienne",
            value: "Djiboutienne"
        },
        {
            name: "Dominicaine",
            value: "Dominicaine"
        },
        {
            name: "Dominiquaise",
            value: "Dominiquaise"
        },
        {
            name: "Egyptienne",
            value: "Egyptienne"
        },
        {
            name: "Emirienne",
            value: "Emirienne"
        },
        {
            name: "Equato-guineenne",
            value: "Equato-guineenne"
        },
        {
            name: "Equatorienne",
            value: "Equatorienne"
        },
        {
            name: "Erythreenne",
            value: "Erythreenne"
        },
        {
            name: "Espagnole",
            value: "Espagnole"
        },
        {
            name: "Est-timoraise",
            value: "Est-timoraise"
        },
        {
            name: "Estonienne",
            value: "Estonienne"
        },
        {
            name: "Ethiopienne",
            value: "Ethiopienne"
        },
        {
            name: "Fidjienne",
            value: "Fidjienne"
        },
        {
            name: "Finlandaise",
            value: "Finlandaise"
        },
        {
            name: "Française",
            value: "Française"
        },
        {
            name: "Gabonaise",
            value: "Gabonaise"
        },
        {
            name: "Gambienne",
            value: "Gambienne"
        },
        {
            name: "Georgienne",
            value: "Georgienne"
        },
        {
            name: "Ghaneenne",
            value: "Ghaneenne"
        },
        {
            name: "Grenadienne",
            value: "Grenadienne"
        },
        {
            name: "Guatemalteque",
            value: "Guatemalteque"
        },
        {
            name: "Guineenne",
            value: "Guineenne"
        },
        {
            name: "Guyanienne",
            value: "Guyanienne"
        },
        {
            name: "Haïtienne",
            value: "Haïtienne"
        },
        {
            name: "Hellenique",
            value: "Hellenique"
        },
        {
            name: "Hondurienne",
            value: "Hondurienne"
        },
        {
            name: "Hongroise",
            value: "Hongroise"
        },
        {
            name: "Indienne",
            value: "Indienne"
        },
        {
            name: "Indonesienne",
            value: "Indonesienne"
        },
        {
            name: "Irakienne",
            value: "Irakienne"
        },
        {
            name: "Irlandaise",
            value: "Irlandaise"
        },
        {
            name: "Islandaise",
            value: "Islandaise"
        },
        {
            name: "Israélienne",
            value: "Israélienne"
        },
        {
            name: "Italienne",
            value: "Italienne"
        },
        {
            name: "Ivoirienne",
            value: "Ivoirienne"
        },
        {
            name: "Jamaïcaine",
            value: "Jamaïcaine"
        },
        {
            name: "Japonaise",
            value: "Japonaise"
        },
        {
            name: "Jordanienne",
            value: "Jordanienne"
        },
        {
            name: "Kazakhstanaise",
            value: "Kazakhstanaise"
        },
        {
            name: "Kenyane",
            value: "Kenyane"
        },
        {
            name: "Kirghize",
            value: "Kirghize"
        },
        {
            name: "Kiribatienne",
            value: "Kiribatienne"
        },
        {
            name: "Kittitienne-et-nevicienne",
            value: "Kittitienne-et-nevicienne"
        },
        {
            name: "Kossovienne",
            value: "Kossovienne"
        },
        {
            name: "Koweitienne",
            value: "Koweitienne"
        },
        {
            name: "Laotienne",
            value: "Laotienne"
        },
        {
            name: "Lesothane",
            value: "Lesothane"
        },
        {
            name: "Lettone",
            value: "Lettone"
        },
        {
            name: "Libanaise",
            value: "Libanaise"
        },
        {
            name: "Liberienne",
            value: "Liberienne"
        },
        {
            name: "Libyenne",
            value: "Libyenne"
        },
        {
            name: "Liechtensteinoise",
            value: "Liechtensteinoise"
        },
        {
            name: "Lituanienne",
            value: "Lituanienne"
        },
        {
            name: "Luxembourgeoise",
            value: "Luxembourgeoise"
        },
        {
            name: "Macedonienne",
            value: "Macedonienne"
        },
        {
            name: "Malaisienne",
            value: "Malaisienne"
        },
        {
            name: "Malawienne",
            value: "Malawienne"
        },
        {
            name: "Maldivienne",
            value: "Maldivienne"
        },
        {
            name: "Malgache",
            value: "Malgache"
        },
        {
            name: "Malienne",
            value: "Malienne"
        },
        {
            name: "Maltaise",
            value: "Maltaise"
        },
        {
            name: "Marocaine",
            value: "Marocaine"
        },
        {
            name: "Marshallaise",
            value: "Marshallaise"
        },
        {
            name: "Mauricienne",
            value: "Mauricienne"
        },
        {
            name: "Mauritanienne",
            value: "Mauritanienne"
        },
        {
            name: "Mexicaine",
            value: "Mexicaine"
        },
        {
            name: "Micronesienne",
            value: "Micronesienne"
        },
        {
            name: "Moldave",
            value: "Moldave"
        },
        {
            name: "Monegasque",
            value: "Monegasque"
        },
        {
            name: "Mongole",
            value: "Mongole"
        },
        {
            name: "Montenegrine",
            value: "Montenegrine"
        },
        {
            name: "Mozambicaine",
            value: "Mozambicaine"
        },
        {
            name: "Namibienne",
            value: "Namibienne"
        },
        {
            name: "Nauruane",
            value: "Nauruane"
        },
        {
            name: "Neerlandaise",
            value: "Neerlandaise"
        },
        {
            name: "Neo-zelandaise",
            value: "Neo-zelandaise"
        },
        {
            name: "Nepalaise",
            value: "Nepalaise"
        },
        {
            name: "Nicaraguayenne",
            value: "Nicaraguayenne"
        },
        {
            name: "Nigeriane",
            value: "Nigeriane"
        },
        {
            name: "Nigerienne",
            value: "Nigerienne"
        },
        {
            name: "Nord-coréenne",
            value: "Nord-coréenne"
        },
        {
            name: "Norvegienne",
            value: "Norvegienne"
        },
        {
            name: "Omanaise",
            value: "Omanaise"
        },
        {
            name: "Ougandaise",
            value: "Ougandaise"
        },
        {
            name: "Ouzbeke",
            value: "Ouzbeke"
        },
        {
            name: "Pakistanaise",
            value: "Pakistanaise"
        },
        {
            name: "Palau",
            value: "Palau"
        },
        {
            name: "Palestinienne",
            value: "Palestinienne"
        },
        {
            name: "Panameenne",
            value: "Panameenne"
        },
        {
            name: "Papouane-neoguineenne",
            value: "Papouane-neoguineenne"
        },
        {
            name: "Paraguayenne",
            value: "Paraguayenne"
        },
        {
            name: "Peruvienne",
            value: "Peruvienne"
        },
        {
            name: "Philippine",
            value: "Philippine"
        },
        {
            name: "Polonaise",
            value: "Polonaise"
        },
        {
            name: "Portoricaine",
            value: "Portoricaine"
        },
        {
            name: "Portugaise",
            value: "Portugaise"
        },
        {
            name: "Qatarienne",
            value: "Qatarienne"
        },
        {
            name: "Roumaine",
            value: "Roumaine"
        },
        {
            name: "Russe",
            value: "Russe"
        },
        {
            name: "Rwandaise",
            value: "Rwandaise"
        },
        {
            name: "Saint-lucienne",
            value: "Saint-lucienne"
        },
        {
            name: "Saint-marinaise",
            value: "Saint-marinaise"
        },
        {
            name: "Saint-vincentaise-et-grenadine",
            value: "Saint-vincentaise-et-grenadine"
        },
        {
            name: "Salomonaise",
            value: "Salomonaise"
        },
        {
            name: "Salvadorienne",
            value: "Salvadorienne"
        },
        {
            name: "Samoane",
            value: "Samoane"
        },
        {
            name: "Santomeenne",
            value: "Santomeenne"
        },
        {
            name: "Saoudienne",
            value: "Saoudienne"
        },
        {
            name: "Senegalaise",
            value: "Senegalaise"
        },
        {
            name: "Serbe",
            value: "Serbe"
        },
        {
            name: "Seychelloise",
            value: "Seychelloise"
        },
        {
            name: "Sierra-leonaise",
            value: "Sierra-leonaise"
        },
        {
            name: "Singapourienne",
            value: "Singapourienne"
        },
        {
            name: "Slovaque",
            value: "Slovaque"
        },
        {
            name: "Slovene",
            value: "Slovene"
        },
        {
            name: "Somalienne",
            value: "Somalienne"
        },
        {
            name: "Soudanaise",
            value: "Soudanaise"
        },
        {
            name: "Sri-lankaise",
            value: "Sri-lankaise"
        },
        {
            name: "Sud-africaine",
            value: "Sud-africaine"
        },
        {
            name: "Sud-coréenne",
            value: "Sud-coréenne"
        },
        {
            name: "Suedoise",
            value: "Suedoise"
        },
        {
            name: "Suisse",
            value: "Suisse"
        },
        {
            name: "Surinamaise",
            value: "Surinamaise"
        },
        {
            name: "Swazie",
            value: "Swazie"
        },
        {
            name: "Syrienne",
            value: "Syrienne"
        },
        {
            name: "Tadjike",
            value: "Tadjike"
        },
        {
            name: "Taiwanaise",
            value: "Taiwanaise"
        },
        {
            name: "Tanzanienne",
            value: "Tanzanienne"
        },
        {
            name: "Tchadienne",
            value: "Tchadienne"
        },
        {
            name: "Tcheque",
            value: "Tcheque"
        },
        {
            name: "Thaïlandaise",
            value: "Thaïlandaise"
        },
        {
            name: "Togolaise",
            value: "Togolaise"
        },
        {
            name: "Tonguienne",
            value: "Tonguienne"
        },
        {
            name: "Trinidadienne",
            value: "Trinidadienne"
        },
        {
            name: "Tunisienne",
            value: "Tunisienne"
        },
        {
            name: "Turkmene",
            value: "Turkmene"
        },
        {
            name: "Turque",
            value: "Turque"
        },
        {
            name: "Tuvaluane",
            value: "Tuvaluane"
        },
        {
            name: "Ukrainienne",
            value: "Ukrainienne"
        },
        {
            name: "Uruguayenne",
            value: "Uruguayenne"
        },
        {
            name: "Vanuatuane",
            value: "Vanuatuane"
        },
        {
            name: "Venezuelienne",
            value: "Venezuelienne"
        },
        {
            name: "Vietnamienne",
            value: "Vietnamienne"
        },
        {
            name: "Yemenite",
            value: "Yemenite"
        },
        {
            name: "Zambienne",
            value: "Zambienne"
        },
        {
            name: "Zimbabweenne",
            value: "Zimbabweenne"
        }
    ],
    SECTEUR_ACTIVITES: [
        {
            name: 'Agriculture - sylviculture - pêche',
            value: 'Agriculture - sylviculture - pêche'
        },
       
        {
            name: 'Industries - Fabrication - Production',
            value: 'Industries - Fabrication - Production'
        },
        {
            name: 'Construction',
            value: 'Construction'
        },
        {
            name: 'Commerce - réparation d\'automobiles et de motocycles',
            value: 'Commerce - réparation d\'automobiles et de motocycles'
        },
        {
            name: 'Transports et entreposage',
            value: 'Transports et entreposage'
        },
        {
            name: 'Hébergement et restauration',
            value: 'Hébergement et restauration'
        },
        {
            name: 'Information - communication',
            value: 'Information - communication'
        },
        {
            name: 'Activité financière et d\'assurance',
            value: 'Activité financière et d\'assurance'
        },
        {
            name: 'Activités immobilières',
            value: 'Activités immobilières'
        },
        {
            name: 'Activités juridiques et comptables',
            value: 'Activités juridiques et comptables'
        },
        {
            name: 'Activités des sièges sociaux ; conseil de gestion',
            value: 'Activités des sièges sociaux ; conseil de gestion'
        },
        {
            name: 'Architecture - Ingénierie - Contrôle et analyses techniques',
            value: 'Architecture - Ingénierie - Contrôle et analyses techniques'
        },
        {
            name: 'Recherche-développement scientifique',
            value: 'Recherche-développement scientifique'
        },
        {
            name: 'Publicité et études de marché',
            value: 'Publicité et études de marché'
        },
        {
            name: 'Autres activités spécialisées, scientifiques et techniques et activités vétérinaires',
            value: 'Autres activités spécialisées, scientifiques et techniques et activités vétérinaires'
        },
        {
            name: 'Activités de services administratifs et de soutien',
            value: 'Activités de services administratifs et de soutien'
        },
        {
            name: 'Administration publique',
            value: 'Administration publique'
        },
        {
            name: 'Enseignement',
            value: 'Enseignement'
        },
        {
            name: 'Santé humaine',
            value: 'Santé humaine'
        },
        {
            name: 'Action sociale',
            value: 'Action sociale'
        },
        {
            name: 'Arts - Spectacles - Activités récréatives',
            value: 'Arts - Spectacles - Activités récréatives'
        },
        {
            name: 'Autres activités de services',
            value: 'Autres activités de services'
        },
    ]

}
export default RisqueBesoin;
