<template>
  <b-sidebar id="sidebar-client-entreprise-prospect-custom" ref="sideBarClientProProspectLien" backdrop bg-variant="white" no-header right
             shadow sidebar-class="sidebar-lg"
  >
    <b-overlay :show="showLoading" no-wrap/>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
          <span> Modifier Prospect </span>
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>
        <validation-observer ref="clientProProspectLienComposeRules" >
            <b-form
              class="px-2"
              @submit.prevent="updateProEntreprise"
            >
                  <b-form-group
                    label="Civilité*"
                    label-for="civilite"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="civilité"
                        rules="required"
                    >
                    <b-form-select
                    v-model="prospect_create.civilite"
                    :options="civilite"
                    id="civilite"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Nom*"
                    label-for="nom"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="nom"
                        rules="required|min:3"
                    >
                    <b-form-input
                        id="nom"
                        trim
                        placeholder="Nom"
                        v-model="prospect_create.nom"
                        :state="errors.length > 0 ? false : null"
                        class="text-uppercase"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                        label="Prénom*"
                        label-for="prenom"
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="prénom"
                            rules="required|min:3"
                        >
                        <b-form-input
                            id="prenom"
                            trim
                            placeholder="Prénom"
                            v-model="prospect_create.prenom"
                            :state="errors.length > 0 ? false : null"
                            class="text-capitalize"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>

                  <b-form-group
                        label="Date de naissance"
                        label-for="particulier_date_naissance"
                    >
                        <flat-pickr
                            class="form-control"
                            v-model="prospect_create.date_naissance"
                            id="particulier_date_naissance"
                            :config="config"
                            placeholder="Date de naissance"
                        />
                  </b-form-group>

                  <b-form-group
                        label="Situation familiale"
                        label-for="particulier_situation_familiale"
                    >
                        <b-form-select
                            v-model="prospect_create.situation_familiale"
                            id="particulier_situation_familiale"
                        >
                            <b-form-select-option value="null" disabled
                            >Choisissez</b-form-select-option
                            >
                            <b-form-select-option value="CONCUBIN"
                            >Concubin(e)</b-form-select-option
                            >
                            <b-form-select-option value="CELIBATAIRE"
                            >Célibataire</b-form-select-option
                            >
                            <b-form-select-option value="DIVORCE"
                            >Divorcé(e)</b-form-select-option
                            >
                            <b-form-select-option value="MARIE"
                            >Marié(e)</b-form-select-option
                            >
                            <b-form-select-option value="SEPARE"
                            >Séparé(e)</b-form-select-option
                            >
                            <b-form-select-option value="VEUF"
                            >Veuf(ve)</b-form-select-option
                            >
                            <b-form-select-option value="PACSE"
                            >Pacsé(e)</b-form-select-option
                            >
                        </b-form-select>
                  </b-form-group>

                  <b-form-group label="Enfants à charge" label-for="particulier_nbr_enfant">
                        <b-form-input
                        v-model="prospect_create.enfantCharge"
                        id="particulier_nbr_enfant"
                        trim
                        placeholder="Enfants à charge"
                        type="number"
                        min="0"
                        />
                  </b-form-group>
                  <b-form-group
                    label="N° et libellé de la voie*"
                    label-for="adresse"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="n° et libellé de la voie"
                        rules="required"
                    >
                    <b-form-input
                        id="adresse"
                        trim
                        placeholder="N° et libellé de la voie"
                        v-model="prospect_create.libelle"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Complément d'adresse"
                    label-for="complement_adresse"
                    >

                    <b-form-input
                        id="complément_adresse"
                        trim
                        placeholder="Complément d'adresse"
                        v-model="prospect_create.complement_adresse"
                    />
                  </b-form-group>
    
                  <b-form-group
                    label="Lieu-dit"
                    label-for="lieu_dit"
                    >
                    <b-form-input
                        id="lieu_dit"
                        trim
                        placeholder="Lieu-dit"
                        v-model="prospect_create.lieu_dit_ou_bp"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Code postal*"
                    label-for="code_postal"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="code postal"
                        rules="required|digits:5"
                    >
                    <b-form-input
                        id="code_postal"
                        trim
                        placeholder="Code postal"
                        @keyup="GetVille()"
                        v-model="prospect_create.code_postal"
                        :state="errors.length > 0 ? false : null"
                        v-mask="'#####'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                        label="Ville*"
                        label-for="ville"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="ville"
                        rules="required"
                        >
                        <b-form-select
                        v-model="prospect_create.ville_id"
                        id="ville"
                        :state="errors.length > 0 ? false : null"
                        >
                        <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                        <b-form-select-option v-for="(val, value) in villeOptions" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Téléphone portable*"
                    label-for="particulier_telephone"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="téléphone"
                        :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                    >
                    <b-form-input
                        id="particulier_telephone"
                        v-model="prospect_create.tel"
                        trim
                        placeholder="ex: 06 00 00 00 00"
                        :state="errors.length > 0 ? false : null"
                        v-mask="'## ## ## ## ##'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Téléphone fixe"
                    label-for="particulier_telephone_fixe"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="téléphone fixe"
                        :rules="{regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                    >
                    <b-form-input
                        id="particulier_telephone_fixe"
                        v-model="prospect_create.tel_secondaire"
                        trim
                        placeholder="ex: 06 00 00 00 00"
                        v-mask="'## ## ## ## ##'"
                    />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Email*"
                    label-for="email"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="email"
                    >
                    <b-form-input
                        id="email"
                        trim
                        placeholder="Email"
                        v-model="prospect_create.email"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] ? 'Le champ email est obligatoire': '' }}</small>
                    </validation-provider>
                    </b-form-group>

                    <b-form-group label="Activité professionnelle" label-for="activite">
                    <b-form-select
                        v-model="prospect_create.statut"
                        id="activite"
                    >
                        <b-form-select-option :value="null"
                        >Choisissez</b-form-select-option
                        >
                        <b-form-select-option
                        v-for="(activite, value) in ACTIVITE_DATA"
                        :key="'a' + value"
                        :value="activite.value"
                        >
                        {{ activite.name }}
                        </b-form-select-option>
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Régime de sécurité sociale"
                    label-for="particulier_regime_obligatoire"
                    >
                    <b-form-select
                        v-model="prospect_create.regime_obligatoire"
                        id="particulier_regime_obligatoire"

                        >
                        <b-form-select-option value="null" disabled
                            >Choisissez</b-form-select-option
                        >
                        <b-form-select-option
                            v-for="(regime, value) in REGIMESOCIAL"
                            :key="'r' + value"
                            :value="regime.value"
                        >
                            {{ regime.name }}
                        </b-form-select-option>
                    </b-form-select>
                  </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2" style="float:right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-2"
                  size="md"
                  type="submit"
                >
                  Enregistrer
                </b-button>
              </div>

            </b-form>
          </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BOverlay,
  BButton,
  BForm,
  BFormGroup,
  BSidebar,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BFormSelectOption,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import Data from "@/shared/constants/prospect"
import vSelect from 'vue-select'
import Besoin from "@/shared/constants/risquebesoin"
import Activite from "@/shared/constants/activite"
import moment from 'moment'

export default {
  components: {
    // BTV
    BOverlay,
    BButton,
    BSidebar,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelectOption,
    BFormSelect,
    BInputGroup,
    BFormGroup,

    // flatPickr and Cleave
    flatPickr,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    prospectPrincipalData: {
      type: Object,
      default: {},
      required: true
    },
    prospectData: {
      type: Object,
      default: {},
      required: true
    },
  },
  data() {
    return {
      showLoading: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      config: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      villeOptions: [],
      required,
      civilite:[
          { value: null, text: 'Choisissez' },
          { value: 'MR', text: 'M.' },
          { value: 'MME', text: 'Mme' },
      ],
      prospect_create: {
        id:null,
        civilite:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        situation_familiale:null,
        enfantCharge:null,
        regime_obligatoire:null,
        tel:null,
        tel_secondaire:null,
        email:null,
        libelle:null,
        complement_adresse:null,
        lieu_dit_ou_bp:null,
        code_postal:null,
        ville_id:null,
        statut: null,
        personne_physique:{
          id:null,
          type:null,
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      REGIMESOCIAL:Besoin['REGIMESOCIAL'],
      ACTIVITE_DATA : Activite['ACTIVITE'],
    }
  },
  watch: {
    prospectPrincipalData: {
      immediate:true,
      deep: true,
      handler(val) {
        if (val.id !== undefined)
          this.mapDataToUpdate(val)
      }
    },
    'prospect_create.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  mounted(){
  },
  methods: {
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    mapDataToUpdate(data){
      this.prospect_create.personne_physique.id=this.prospectData.id
      this.prospect_create.personne_physique.type=this.prospectData.type
      this.prospect_create.id                  = data.id
      this.prospect_create.civilite            = data.civilite
      this.prospect_create.nom                 = data.nom
      this.prospect_create.prenom              = data.prenom
      this.prospect_create.tel                 = data.moyen_contact.tel
      this.prospect_create.tel_secondaire      = data.moyen_contact.tel_secondaire
      this.prospect_create.email               = data.moyen_contact.email
      this.prospect_create.libelle             = data.moyen_contact.libelle
      this.prospect_create.complement_adresse  = data.moyen_contact.complement_adresse
      this.prospect_create.lieu_dit_ou_bp      = data.moyen_contact.lieu_dit_ou_bp
      this.prospect_create.code_postal         = data.moyen_contact.code_postal
      this.prospect_create.ville_id            = data.moyen_contact.ville_id
      this.prospect_create.date_naissance      = data.date_naissance ? moment(data.date_naissance,'YYYY-MM-DD').format('DD/MM/YYYY') : null
      this.prospect_create.situation_familiale = data.situation_familiale
      this.prospect_create.enfantCharge        = data.enfantCharge
      this.prospect_create.regime_obligatoire  = data.regime_obligatoire
      this.prospect_create.statut  = data.statut
    },

    hideSideBare() {
      this.$refs.sideBarClientProProspectLien.hide()
    },
    updateProEntreprise() {
      this.$refs.clientProProspectLienComposeRules.validate()
        .then(success => {
          if (success) {
            this.showLoading = true
            this.$http
              .post('/prospect/updateConnexiteMorale', this.prospect_create)
                .then(res => {
                  if (res.data) {
                    this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
                    this.hideSideBare()
                    this.showLoading = false
                  }
                })
                .catch(err => {
                  this.showLoading = false
                  this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  console.error(err)
                })
          }
        })
      }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

