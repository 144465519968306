const Activite = {
    ACTIVITE:[
        {
            name: "Salarié non cadre",
            value: "SALARIE_NON_CADRE"
        },
        {
            name: "Salarié cadre",
            value: "SALARIE_CADRE"
        },
        {
            name: "Etudiant",
            value: "ETUDIANT"
        },
        {
            name: "Retraité",
            value: "RETRAITE"
        },
        {
            name: "Sans emploi",
            value: "SANS_EMPLOI"
        },
        {
            name: "Auto-entrepreneur",
            value: "AUTO-ENTREPRENEUR"
        },
        {
            name: "Profession libérale",
            value: "PROFESSION_LIBERALE"
        },
        {
            name: "Artisan",
            value: "ARTISAN"
        },
        {
            name: "Commerçant",
            value: "COMMERCANT"
        },
        {
            name: "Chef d’entreprise",
            value: "CHEF_ENTREPRISE"
        },
        {
            name: "Agriculteur",
            value: "AGRICULTEUR"
        },
        {
            name: "Independant",
            value: "INDEPENDANT"
        },
        {
            name: "Fonctionnaire ou assimilé",
            value: "FONCTIONNAIRE"
        },
    ]
}
export default Activite;