<template>
  <div>
        <b-overlay
        :show="showLoading"
        no-wrap
    />
    <div v-if="interfaceChosen === 'Listing'">
          <h1 v-if="can('List clients of broker') || can('List clients by commercial')"
              class="text-primary font-weight-bolder text-uppercase"
          >Clients/Prospects ({{ allDataFprFetch.total ? allDataFprFetch.total : 0 }})</h1>
      <div class="d-flex flex-row justify-content-end flex-wrap">
        <div class="d-flex align-items-center mb-1 mt-1 ">
        </div>
        <div class="mr-1 mb-1">
          <import-base-client @reloadTiersData="reloadTiersData" />
        </div>
        <div class=" mb-1">
          <add-new-tier @reloadTiersData="reloadTiersData" :commercial-options="commercialOptions"/>
        </div>
      </div>


      <!-- basic modal -->
      <b-modal
        id="modal_1"
        hide-footer
        centered
        title="Attacher à un gestionnaire"
        @hidden="resetModal"
      >
      <b-form
            class="p-2"
            @submit.prevent="commercialClientSave"
          >
        <b-form-group label="Gestionnaire" label-for="commercial">
           <b-form-select
                  v-model="attached_commercial"
                  id="commercial"
                >
            <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
            <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}</b-form-select-option>
           </b-form-select>
        </b-form-group>
        <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="attached_commercial == null || (clientsAttacher.length == 1 && clientsAttacher[0].actif_commercial == attached_commercial)"
              >
                Attacher
              </b-button>
        </div>
      </b-form>
      </b-modal>



      <b-card title="Filtres">
        <div class="custom-search">

          <!-- advance search input -->
          <b-row class="d-flex align-items-center">
            <b-col md="3">
              <b-form-group label="Gestionnaire">
                <!-- <label>Commercial:</label> -->
                <b-form-select v-model="filter.commercial" @change="reloadTiersData(1)">
                  <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Catégorie">
                <!-- <label>Catégorie:</label> -->
                <b-form-select v-model="filter.categorie" @change="reloadTiersData(1)">
                  <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                  <b-form-select-option value="PARTICULIER">PARTICULIER</b-form-select-option>
                  <b-form-select-option value="PROFESSIONNEL">PROFESSIONNEL</b-form-select-option>
                  <b-form-select-option value="ENTREPRISE">ENTREPRISE</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Statut">
                <!-- <label>Statut:</label> -->
                <b-form-select v-model="filter.statut" @change="reloadTiersData(1)">
                  <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                  <b-form-select-option value="prospect">Prospect</b-form-select-option>
                  <b-form-select-option value="client">Client</b-form-select-option>
                  <b-form-select-option value="Ancien Client">Ancien Client</b-form-select-option>
                  <b-form-select-option value="Aucun">Aucun</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Recherche">
                <b-input-group>
                  <b-form-input
                      v-model="filter.recherche"
                      :lazy="true"
                      id="basic-recherche"
                      class="d-inline-block"
                      type="text"
                      placeholder="Recherche"
                      @keyup.enter="reloadTiersData(1)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        icon="SearchIcon"
                        class="cursor-pointer"
                        @click="reloadTiersData(1)"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                  v-b-tooltip.hover.top="'Tout afficher'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon mt-1"
                  type="button"
                  variant="primary"
                  id="revenir-liste-client"
                  @click="resetFilter"
              >
                <feather-icon
                    icon="RefreshCcwIcon"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card>

        <button
              class="btn btn-primary mb-1"
              :data="json_data"
              :fields="json_fields"
              v-if="can('Export Clients') || currentUser.courtier_user[0].isPrincipal"
            worksheet = "les clients"
              name="liste_client.xls"
              @click="exportClient"
            >
              <span>Exporter </span>
              <feather-icon class="mr-50" icon="DownloadIcon" />
          </button>

        <!-- <export-excel
          class   = "btn btn-primary mb-1"
          :data   = "json_data"
          :fields = "json_fields"
          worksheet = "les clients"
          v-if="can('Export Clients') || currentUser.courtier_user[0].isPrincipal"
          name    = "clients.xls">
              <span>Exporter </span>
              <feather-icon
                class="mr-50"
                icon="DownloadIcon"
              />
          </export-excel> -->
        <!-- table -->
        <vue-good-table
            ref="prospects"
            :columns="headers"
            :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
            @on-selected-rows-change="selectionChanged"
            @on-sort-change="onSortChangeProspectData"
            :rows="prospectData"
            :rtl="direction"
            :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
            theme="my-theme"
        >
          <template slot="table-row" slot-scope="props">
              <div class="d-flex justify-content-evenly" v-if="props.column.field == 'client'" @click="showProspect(true, props.row)"> 
                <div class=" w-50 d-flex align-items-center  justify-content-start"  > 
                  <span class="orange-dot" v-if="props.row.all_data_prospect.statut_gda=='ORANGE'" v-b-tooltip.hover.top="'Gel des avoirs : A vérifier'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
                  <span class="gray-dot" v-if="props.row.all_data_prospect.statut_gda=='GRIS'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
                  <span class="white-dot" v-if="props.row.all_data_prospect.statut_gda=='BLANC'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
                  <span class="green-dot" v-if="props.row.all_data_prospect.statut_gda=='VERT'" v-b-tooltip.hover.top="'Gel des avoirs : Contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
                  <span class="red-dot" v-if="props.row.all_data_prospect.statut_gda=='ROUGE'" v-b-tooltip.hover.top="'Gel des avoirs : Interdit'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>               
                  <img v-if="props.row.origine == 'Weedo_market'" height="35" class="weedo ml-1" style="" src="../../assets/images/logoo.png" alt="">
                </div>
                <div class="w-100  d-flex align-items-center justify-content-center bd-highlight" >
                  <a style="text-decoration: underline;">{{ props.row.client }}</a>
                </div>
              </div>
            <span v-if="props.column.field == 'commercial'" v-text="props.row.commercial">
            </span>
            <span v-if="props.column.field == 'categorie'" v-text="props.row.categorie">
            </span>
            <span v-if="props.column.field == 'tel'">
               {{ (props.row.indicatif_tel && props.row.tel) ? '('+props.row.indicatif_tel+') '+props.row.tel.substring(1) : props.row.tel}} 
            </span>
            <span v-if="props.column.field == 'mail'">
              <a v-if="props.row.mail" :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="showEmail(props.row.mail)">
                  {{ props.row.mail }}
                  <!-- props.row.mail -->
              </a>
            </span>
            <span v-if="props.column.field == 'projet_en_cours'"
                  :class="props.row.projet_en_cours === 'Oui' ? 'box-pointer' : ''"
                  @click="props.row.projet_en_cours === 'Oui' ? showProspect(true, props.row) : ''"
            >
              <a v-if="props.row.projet_en_cours === 'Oui'" style="text-decoration: underline;"
              >{{ props.row.projet_en_cours }}</a>
              <span v-else>{{ props.row.projet_en_cours }}</span>
            </span>
            <span v-if="props.column.field == 'statut'" v-text="props.row.statut">
            </span>
            <div v-if="props.column.field == 'actions'">
              <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
                <b-dropdown-item v-if="can('Show detail client')" @click="showProspect(false, props.row)">
                  <feather-icon
                      class="mr-50"
                      icon="Edit2Icon"
                  />
                  <span>Consulter la fiche client</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="can('Pricing a customer') && props.row.all_data_prospect.statut_gda!='ORANGE' && props.row.all_data_prospect.statut_gda!='ROUGE' && props.row.type !== 'PERSONNE_MORALE'" @click="affecterProspect(props.row)">
                  <span class="mr-50 d-inline-block">€</span>
                  <span>Réaliser un devis (Marketplace)</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.all_data_prospect.statut_gda!='ORANGE' && props.row.all_data_prospect.statut_gda!='ROUGE'" v-b-toggle.sidebar-projet-tier @click="importProjectOrContrat(props.row.id,props.row.type,props.row.client, props.row.all_data_prospect.distributeur.user_id)">
                    <feather-icon
                        class="mr-50"
                        icon="PlusIcon"
                    />
                    <span>Enregistrer un devis externe</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.all_data_prospect.statut_gda!='ORANGE' && props.row.all_data_prospect.statut_gda!='ROUGE'" v-b-toggle.sidebar-contrat-tier @click="importProjectOrContrat(props.row.id,props.row.type,props.row.client, props.row.all_data_prospect.distributeur.user_id)">
                    <feather-icon
                        class="mr-50"
                        icon="PlusIcon"
                    />
                    <span>Enregistrer un contrat externe</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal_1 @click="attachCommercial(props.row.id,props.row.type, props.row.all_data_prospect.distributeur.user_id)">
                  <feather-icon
                      class="mr-50"
                      icon="Link2Icon"
                  />
                  <span>Attacher à un gestionnaire</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="deleteProspect(props.row.id,props.row.type)">
                  <feather-icon
                      class="mr-50"
                      icon="TrashIcon"
                  />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10','25']"
                    class="mx-1"
                    @input="(value) => {
                      reloadTiersData(1);
                      props.perPageChanged({ currentPerPage: value })
                    }"
                  />
                  <span class="text-nowrap"> de {{ allDataFprFetch.total }} entrées </span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 align-items-center">
              <b-col md="4">
                <b-input-group>
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                      @change="(selectedrows_masse.selectedRows != null &&
                                selectedrows_masse.selectedRows.length > 0 &&
                                selected_action == 'Attacher') ? $bvModal.show('modal_1') : null"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col md="8">
                <b-pagination
                    :per-page="pageLength"
                    :total-rows="allDataFprFetch.total"
                     :value="allDataFprFetch.current_page ? allDataFprFetch.current_page : 1 "
                    align="right"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="value => {
                
                      if (pagepagination != value) {
                        reloadTiersData(value);
                        pagepagination = value;
                        
                      }
                  
                    }"
                  >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </template>
          <div slot="emptystate" class="text-center align-middle">
            <span>Aucune donn&eacute;e disponible dans le tableau</span>
          </div>
        </vue-good-table>

        <!-- <template #code>
          {{ codeAdvance }}
        </template> -->
      </b-card>
    </div>
    <div v-else-if="interfaceChosen === 'Entreprise'">
      <parent-fiche-entreprise @back-to-listing="backToListingPro" :prospect-options="prospectData" :prospect-chosen="prospectChosen" @show-prospect="showProspect" @show-email="showEmail" :is-boite-sync="isBoiteSync" />
    </div>
    <div v-if="interfaceChosen === 'Professionnel'">
      <parent-fiche-professionel :prospect-options="prospectData" @back-to-listing="backToListingPro"  :prospect-chosen="prospectChosen" @show-prospect="showProspect" @show-email="showEmail" :is-boite-sync="isBoiteSync" />
    </div>
    <import-new-projet :is-action="true" :courtier-id="currentUser.courtier_user[0].courtier_id"
      :user-id="currentUser.courtier_user[0].user_id" :prospect-data="prospectData"
      :assureurs-options="assureursOptions" :risques-options="risquesOptions"
      :projet-for-update="projetForUpdate" :prospect-selected="prospectSelected" :commercial-options="commercialOptions"
      @clear-data-projet-update="clearDataProjetUpdate" @reloadTiersData="reloadTiersData" />
    <import-new-contrat :assureurs-options="assureursOptions" :commercial-options="commercialOptions"
      :current-user="currentUser" :risques-options="risquesOptions" :prospect-selected="prospectSelected" @reloadTiersData="reloadTiersData" />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BRow,
  VBToggle,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import AddNewTier from './AddNewTier.vue'
import moment from 'moment'
import parentFicheEntreprise from './entreprise/ParentFicheEntreprise'
import parentFicheProfessionel from './professionel/ParentFicheProfessionel'
import ImportNewProjet from './ImportNewProjet.vue';
import ImportNewContrat from './ImportNewContrat.vue';
import ImportBaseClient from '@/views/tiers/ImportBaseClient'
import { ref } from '@vue/composition-api'

export default {
  components: {
    // UI
    AddNewTier,
    parentFicheEntreprise,
    parentFicheProfessionel,
    Ripple,
    VBTooltip,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BInputGroupPrepend,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    //component page
    ImportNewProjet,
    ImportNewContrat,
    ImportBaseClient,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pagepagination:1,
      page:1,
      allDataFprFetch:{},
      showLoading: false,
      json_fields: {
          'Client': 'client',
          'Catégorie': 'categorie',
          'Téléphone': 'tel',
          'Mail': 'mail',
          'Devis en cours': 'projet_en_cours',
          'Statut': 'statut',
          'Gestionnaire': 'commercial',
      },
      json_data: [],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      interfaceChosen: 'Listing',
      isBoiteSync: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      filter: {
        commercial: null,
        categorie: null,
        cabinet: null,
        statut: null,
        recherche: null
      },
      pageLength: 10,
      dir: false,
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        },
        {
          value: 'Attacher',
          text: 'Attacher à un commercial'
        },
        {
          value: 'Envoyer',
          text: 'Envoyer un mail'
        },
      ],
      selected_action: null,
      headers: [
        // {
        //   label: 'Identifiant',
        //   field: 'identifiant',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'Client',
          field: 'client',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Origine',
        //   field: 'origine',
        //   sortable: false,
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'Catégorie',
          field: 'categorie',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Téléphone',
          field: 'tel',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Mail',
          field: 'mail',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Devis en cours',
          field: 'projet_en_cours',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Gestionnaire',
          field: 'commercial',
          sortable: true,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rows: [],
      searchTerm: '',
      prospectPhysique: [],
      prospectEntreprise: [],
      prospectDataGlobal: [],
      prospectParticulier: [],
      prospectProfessionnel: [],
      prospectTouT: [],
      prospectData: [],
      prospectVisible: [],
      prospectDataOptions: [],
      prospect_filtred: [],
      clients_length: [],
      prospect_and_clients_length: [],
      prospect: {
        nom: null,
        prenom: null
      },
      sortData :null,
      prospectSelected: {
        id: null,
        type: null,
        client: null,
        commercial: null
      },
      list_filter_custom: {
        Commercial: [],
        Categorie: [],
        Cabinet: [],
        Statut: []
      },
      prospectChosen: {
        id: null,
        type: null,
        category: null,
      },
      prospectState: 'TouT',
      errors: [],
      commercialOptions: [],
       assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      risquesOptions: [],
      selectedrows_masse : [],
      projetForUpdate: {
        idEtude: null,
        idDevis: null,
        client: null,
        risque: null,
        assureur: null,
        statut: null,
        num_devis: null,
        date_effet: null,
        date_creation: null,
        budget_propose: null,
        budget_actuel: null,
        variation: null,
        courtier_id: null,
        user_id: null,
      },
      clientsAttacher : [
        {
          actif_client: null,
          actif_type : null,
          actif_commercial : null,
        }
      ],
      attached_commercial:null,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.checkSyncBoiteEmail()
    this.fetchRisqueList()
    this.fetchAssureurList()
    this.fetchDataCommercialByCourtier()
    if (this.$route.params.id && this.$route.params.type && this.$route.params.interfaceChosen){
      if (this.$route.params.interfaceChosen.toLowerCase() === 'entreprise' || this.$route.params.interfaceChosen.toLowerCase() === 'professionnel' ||  this.$route.params.interfaceChosen.toLowerCase() === 'particulier' ) {
        this.interfaceChosen = this.$route.params.interfaceChosen.toLowerCase()=="entreprise"?this.$route.params.interfaceChosen:'Professionnel'
        this.prospectChosen.id = this.$route.params.id
        this.prospectChosen.type = this.$route.params.type
        this.prospectChosen.category = this.interfaceChosen
      }
    }
  },
  created() {
    // this.showLoading = true
    this.reloadTiersData();
  },
  methods: {
    attachCommercial(client_id,client_type, commercial_id, multiple = false){
      //single attachement
      if(!multiple) {
        this.$refs['prospects'].unselectAllInternal();
        this.clientsAttacher.splice(0);
        this.attached_commercial = commercial_id
      } else {
        this.attached_commercial = null
      }

      this.clientsAttacher.push({
        actif_client      : client_id,
        actif_type        : client_type,
        actif_commercial  : commercial_id
      })
    },
    checkSyncBoiteEmail() {
      this.isBoitEmailSync().then(response => {
        if(response)
          this.isBoiteSync = true
        else this.isBoiteSync = false
      })
    },
    downloadDocument(blob, name) {
      if (blob) {
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}_${moment().format("YYYYMMDDHHmm")}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    exportClient() {
      const formData = new FormData()       
      formData.append('type_list', 'CLIENT')
      console.log(this.filterData())
      const filterDataJson = JSON.stringify(this.filterData())
      console.log(filterDataJson)
      formData.append('filter', filterDataJson)
      formData.append('sort', JSON.stringify(this.sortData))

      const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: "blob" }
      this.$http
        .post(`/portefeuilles/exportPagination`, formData, config)
        .then((response) => {
          this.downloadDocument(response.data, 'list client')
        })
        .catch((error) => {
          console.error(error);

          // Display an error message
          this.messageToast('Une erreur a été survenue lors du téléchargement.', 'Erreur', 'warning');
        });
    },
    commercialClientSave(){
      this.$http.post("prospect/attatche_commercial/",{'clients' : this.clientsAttacher, 'commercial': this.attached_commercial})
                  .then((res) => {

                     if (res.status == 200) {
                      this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: `Le client a été attaché avec un nouveau commercial`,
                          icon: "UserIcon",
                          variant: "success",
                        },
                      },
                      {
                        position: "top-right",
                        timeout: 4000,
                      }
                      );
                      this.$bvModal.hide('modal_1')
                      this.selected_action = null
                      this.reloadTiersData()
                     }
                  })
                  .catch((err) => {});
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sur de vouloir supprimer le/les clients sélectionnés ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("prospect/deleteprospectmultiple/",{'prospects' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.prospectData.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.prospectData[i].id == res.data.deleted[j].id && this.prospectData[i].type == res.data.deleted[j].type)
                          {
                            this.prospectData.splice(i, 1)
                            this.allDataFprFetch.total = this.allDataFprFetch.total - 1;
                          }
                        }
                      }
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Envoyer':
            let emails = [];
            this.selectedrows_masse.selectedRows.forEach(el => {
              if (el.mail) {
                emails.push(/*el.mail ''*/ `${el.mail}/${el.id}/${el.categorie}` )
              }
            })
            if(emails.length > 0 && emails.length <= 20)
            {
              this.$swal({
                title: "Souhaitez-vous envoyer les e-mails en tant que :",
                icon: "Question",
                showCancelButton: true,
                confirmButtonText: "Envoyer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-danger ml-1",
                },
                input: 'radio',
                inputValue: 'Cci',
                inputOptions: {
                  'To': '<span> Destinataire</span>',
                  'Cci': '<span> Copie cachée</span>',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {

                  // for (let i = 0; i < this.selectedrows_masse.selectedRows.length; i++) {
                  //     emails.push(this.selectedrows_masse.selectedRows[i].mail)
                  // }

                  this.showEmail(emails, result.value)
                }
              })
            } else if (emails.length > 20){
              this.messageToast('Le nombre de destinataires ne peux pas dépasser 20 destinataires au maximum', 'Attention', 'warning', 'AlertCircleIcon')
            } else {
              this.messageToast('Veuillez sélectionner les destinataires qui ont un e-mail', 'Attention', 'warning', 'AlertCircleIcon')
            }
          // case 'Attacher':
          //   if(this.selectedrows_masse.selectedRows.length > 0)
          //   {
              // this.$bvModal.show('modal_1')
              // this.$swal({
              //   title: "Êtes-vous sûr?",
              //   icon: "warning",
              //   showCancelButton: true,
              //   confirmButtonText: "Oui",
              //   cancelButtonText: "Non",
              //   customClass: {
              //     confirmButton: "btn btn-primary",
              //     cancelButton: "btn btn-outline-danger ml-1",
              //   },
              //   buttonsStyling: false,
              // }).then((result) => {
                //if (result.value) {
                  // this.$http
                  //   .post("prospect/attacheprospectmultiple/",{'prospects' : this.selectedrows_masse.selectedRows})
                  //   .then((res) => {

                  //   })
                  //   .catch((err) => {});

                  // this.$swal({
                  //   icon: "success",
                  //   title: "Attaché!",
                  //   customClass: {
                  //     confirmButton: "btn btn-success",
                  //   },
                  // });
                //}
              // });
          //   }
          // break;
        default:

      }
    },
    showEmail (email, copierCarbon = null) {
      if(this.isBoiteSync) {
          this.$router.push({
            name: 'messageries',
            params: {
              operation: 'send',
              email,
              copierCarbon
            }
          })
      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params
      this.selected_action = null
      if(this.selectedrows_masse.selectedRows.length > 0) {
        this.clientsAttacher.splice(0)
        this.selectedrows_masse.selectedRows.forEach((client) => {
          this.attachCommercial(client.id, client.type, client.all_data_prospect.distributeur.user_id, true)
        })
      }
    },
    deleteProspect(id,type) {
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer ce client ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete("prospect/"+id+"/deleteprospect/"+type)
            .then((res) => {

              if (res.data.success) {
                for(let i=0;i<this.prospectData.length;i++)
                {
                  if(this.prospectData[i].id == id && this.prospectData[i].type == type)
                  {
                    this.prospectData.splice(i, 1)
                    this.allDataFprFetch.total = this.allDataFprFetch.total - 1;

                  }
                }
              }
            })
            .catch((err) => {});

          this.$swal({
            icon: "success",
            title: "Votre client a été supprimé",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    onSortChangeProspectData(params) {
      this.sortData = params[0]
      this.reloadTiersData(1)
    },
    reloadTiersData(page = 1) {
      this.page =  page
      this.showLoading = true
      if(!this.sortData){
        this.sortData = {field: "created_at", type: "desc"}
      }
      this.$http
        .post('/portefeuilles/getDataPagination?page=' + page, {
          type: 'CLIENT_LIST',
          type_list: 'CLIENT',
          per_page: this.pageLength,
          filter: this.filterData(),
          sort : this.sortData
        })
        .then(r => {
          this.allDataFprFetch = r.data.data
          this.prospectTouT = r.data.data.data

          this.pushAndFusiondata(this.prospectTouT)
          this.showLoading = false
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    backToListing(value) {
      this.interfaceChosen = value
      this.prospectChosen.id = null
      this.prospectChosen.type = null
      this.prospectChosen.category = null
    },
    backToListingPro(value) {
      this.backToListing(value)
      this.reloadTiersData()
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    filterData() {
      let objet = {}
      if (this.filter.statut) objet.statut_fiche = this.filter.statut
      if (this.filter.commercial) objet.commercial = this.filter.commercial
      if (this.filter.categorie) objet.categorie = this.filter.categorie 
      if (this.filter.recherche) objet.recherche = this.filter.recherche
      return objet
    },
    // advanceSearch() {
    //   //console.log(this.searchTerm)
    //   this.$http.post('/prospect/filterAllProspect',{'category':this.filter.categorie,'statut':this.filter.statut,'commercial':this.filter.commercial})
    //     .then(r => {
    //       this.prospectPhysique = this.prospectPhysiqueGlobal = r.data[0]
    //       this.prospectEntreprise = this.prospectEntrepriseGlobal = r.data[1]
    //       this.prospectDataGlobal = this.prospectDataGlobalConst = this.prospectPhysique.concat(this.prospectEntreprise)
    //       this.prospectParticulier = this.prospectPhysique.filter(pp => pp.categorie == 'PARTICULIER')
    //       this.prospectProfessionnel = this.prospectPhysique.filter(pp => pp.categorie == 'PROFESSIONNEL')

    //       this.prospectTouT = this.prospectDataGlobal

    //       this.clients_length = this.prospectTouT.filter(item => item.statut_fiche === 'Client').length
    //       this.prospects_length = this.prospectTouT.filter(item => item.statut_fiche === 'Prospect').length
    //       this.prospect_and_clients_length = this.prospectTouT.length

    //       //if (this.prospectTouT.length > 0) {
    //         this.pushAndFusiondata(this.prospectTouT)
    //       //}
    //     })
    //     .catch(err=>{
    //       console.log(err)
    //     })
    // },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.filter.recherche != null) {
          this.searchTerm = this.filter.recherche
          this.showLoading = false
        }
        this.showLoading = false
      }, 500);
    },
    resetFilter() {
      this.filter.statut = null
      this.filter.cabinet = null
      this.filter.categorie = null
      this.filter.commercial = null
      this.filter.recherche = null
      this.searchTerm = ''
      this.reloadTiersData(1)
    },
    resetModal() {
      this.selected_action = null
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    pushAndFusiondata(array) {
      this.prospect_filtred = []
      this.prospect_filtred.splice(0, this.prospect_filtred.length)
      array.forEach(item => {
        let localMail=null
        if(item.categorie !== 'ENTREPRISE'){
          localMail=item?.moyencontacte?.email
        }
        //console.log(item)
        else{
          if(item.prospect.length>0){
            let findMail = item.prospect.find(obj => {
              return obj.lien_professionnel == 'DIRIGEANT';
            });

            if(findMail){
              localMail= (findMail.lienable !== null && findMail.lienable.moyen_contact) ? findMail.lienable.moyen_contact.email : null
            }
          }
        }
        this.prospect_filtred.push({
          id: item.id,
          identifiant: item.client_no,
          client: item.client,
          categorie: item.categorie,
          statut: item.statut_fiche,
          date_naissance: item.date_naissance,
          created_at: item.created_at,
          type: item.type,
          origine:item.origine,
          projet_en_cours: item.devis_count > 0 ? 'Oui' : 'Non',
          tel: item.categorie =='ENTREPRISE' ?  item.moyen_contact ? item.moyen_contact.tel : null : item.moyencontacte ? item.moyencontacte.tel : null,
          indicatif_tel: item.categorie =='ENTREPRISE' ?  item.moyen_contact ? item.moyen_contact.indicatif_tel : null : item.moyencontacte ? item.moyencontacte.indicatif_tel : null,
          // indicatif_tel: item.moyencontacte ? item.moyencontacte.indicatif_tel : null,
          mail: localMail,
          cabinet: item.distributeur.courtier.personne_morale.denomination_commercial,
          commercial: item.distributeur.user.personne_physique.prenom+ ' ' + item.distributeur.user.personne_physique.nom ,
          all_data_prospect: item
        })
      })
      this.prospectData = this.$_.orderBy(this.prospect_filtred, ['created_at'], ['desc'])

      // this.$emit('loadprospectfiltred', this.prospect_filtred)
    },
    showProspect(is_projet = false, prospect) {
      if (prospect.categorie.toLowerCase() === 'entreprise') {
        this.interfaceChosen = 'Entreprise'
        this.prospectChosen.id = prospect.id
        this.prospectChosen.type = prospect.type
      } else if(prospect.categorie.toLowerCase() === 'professionnel' || prospect.categorie.toLowerCase() === 'particulier') {
        this.interfaceChosen = 'Professionnel'
        this.prospectChosen.id = prospect.id
        this.prospectChosen.type = prospect.type
      }

      this.prospectChosen.category = prospect.categorie.toUpperCase()
    },
    importProjectOrContrat(prospectId, prospectType, prospectClient, prospectCommercial) {
      this.prospectSelected.id = prospectId
      this.prospectSelected.type = prospectType
      this.prospectSelected.client = prospectClient
      this.prospectSelected.commercial = prospectCommercial
    },
    async affecterProspect(prospectValue) {
      let prospectData = {}
      let tmpProspect = prospectValue.all_data_prospect ? prospectValue.all_data_prospect : prospectValue
      this.prospect = tmpProspect
      prospectData.id = tmpProspect.id
      prospectData.type = tmpProspect.type

      await this.$http.get(`/prospect/fetchDataTiersPro/${tmpProspect.id}/${tmpProspect.type}`)
        .then(res => {
          if (res.data) {
              let prospectLocal=res.data.data
              prospectLocal.date_naissance = prospectLocal.date_naissance ? moment(prospectLocal.date_naissance, 'YYYY-MM-DD')
                                             .format('DD/MM/YYYY') : ''
            try {
              this.$store.dispatch('clearData')
              this.$store.commit('setProspect', {
                Tiers: prospectLocal
              })
              this.$router.push({ name: 'projets-proposition' })
            } catch (e) {
              this.errors = e.response.data.errors
            }
          }
        })
        .catch(err => {
          console.log(err)
        })

    },
    souscription(etudeValue) {
      let etudeId = etudeValue.id
      this.$router.push({
        name: 'projets-souscription',
        params: { etudeId }
      })
    },
    successAddedProspectPp(arrayValue) {
      this.prospect = arrayValue
      this.prospectTouT.push(this.prospect)
      this.prospectPhysique.push(this.prospect)
      var type = this.prospectState
      this.filtreProspect('Prospects')
      this.filtreProspect('Clients')
      this.filtreProspect('TouT')
      this.filtreProspect(type)
      this.prospect = {
        nom: null,
        prenom: null
      }
      this.pushAndFusiondata(this.prospectTouT)
    },
    filtreProspect(type) {
      let array_before_filter = null
      switch (type) {
        case 'TouT':
          this.prospectTouT = this.prospectPhysique.concat(this.prospectEntreprise)
          this.prospect_and_clients_length = this.prospectTouT.length
          this.pushAndFusiondata(this.prospectTouT)
          this.checkAndFilter()
          this.prospectState = 'TouT'
          break
        case 'Clients':
          this.prospectTouT = this.prospectPhysique.concat(this.prospectEntreprise)
          array_before_filter = this.prospectTouT.filter(item => item.statut_fiche === 'Client')
          this.clients_length = array_before_filter.length
          this.pushAndFusiondata(array_before_filter)
          this.checkAndFilter()
          this.prospectState = 'Clients'
          break
        case 'Prospects':
          this.prospectTouT = this.prospectPhysique.concat(this.prospectEntreprise)
          array_before_filter = this.prospectTouT.filter(item => item.statut_fiche === 'Prospect')
          this.prospects_length = array_before_filter.length
          this.pushAndFusiondata(array_before_filter)
          this.checkAndFilter()
          this.prospectState = 'Prospects'
          break
      }
    },
    filteredCabinet() {
      if (this.list_filter_custom.Cabinet.length > 0) {
        let arrayResult = this.prospectData.filter(el => {
          return this.list_filter_custom.Cabinet.map(sel => {
            return sel
          })
              .includes(el.cabinet)
        })
        this.prospectData = arrayResult
      }
    },
    filteredCommercial() {
      if (this.list_filter_custom.Commercial.length > 0) {
        let arrayResult = this.prospectData.filter(el => {
          return this.list_filter_custom.Commercial.map(sel => {
            return sel
          })
              .includes(el.commercial)
        })
        this.prospectData = arrayResult
      }
    },
    filteredCategorie() {
      if (this.list_filter_custom.Categorie.length > 0) {
        let arrayResult = this.prospectData.filter(el => {
          return this.list_filter_custom.Categorie.map(sel => {
            return sel
          })
              .includes(el.categorie)
        })
        this.prospectData = arrayResult
      }
    },
    filteredStatut() {
      if (this.list_filter_custom.Statut.length > 0) {
        let arrayResult = this.prospectData.filter(el => {
          return this.list_filter_custom.Statut.map(sel => {
            return sel
          })
              .includes(el.statut)
        })
        this.prospectData = arrayResult
      }
    },
    methodesFilter() {
      this.prospectData = this.$_.orderBy(this.prospect_filtred, ['created_at'], ['desc'])
      this.filteredCabinet()
      this.filteredCommercial()
      this.filteredCategorie()
      this.filteredStatut()
    },
    checkAndFilter() {
      if (this.list_filter_custom != null || this.list_filter_custom.length > 0) {
        if (this.list_filter_custom.Cabinet.length <= 0 && this.list_filter_custom.Commercial.length <= 0 && this.list_filter_custom.Categorie.length <= 0 && this.list_filter_custom.Statut.length <= 0) {
          this.prospectData = this.$_.orderBy(this.prospect_filtred, ['created_at'], ['desc'])
        } else {
          this.methodesFilter()
        }
      } else {
        this.prospectData = this.$_.orderBy(this.prospect_filtred, ['created_at'], ['desc'])
      }
    },
    fetchAssureurList() {
       this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur,
                })
              })
            }
          })
          .catch(err => {
              this.assureursOptions = [{
                value: null,
                text: '-- Choisissez --'
              }]
            console.error(err)
          })
    },
    fetchRisqueList() {
      this.risquesOptions = []
      this.$http
          .post('/risques/fetchRisqueList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.risquesOptions.push({
                  value: el.id,
                  text: el.label,
                })
              })
            }
          })
          .catch(err => {
            this.risquesOptions = []
            console.error(err)
          })
    },
    clearDataProjetUpdate() {
      this.projetForUpdate.idEtude = null
      this.projetForUpdate.idDevis = null
      this.projetForUpdate.client = null
      this.projetForUpdate.risque = null
      this.projetForUpdate.assureur = null
      this.projetForUpdate.statut = null
      this.projetForUpdate.num_devis = null
      this.projetForUpdate.date_effet = null
      this.projetForUpdate.date_creation = null
      this.projetForUpdate.budget_propose = null
      this.projetForUpdate.budget_actuel = null
      this.projetForUpdate.courtier_id = null
      this.projetForUpdate.user_id = null
    },
  },
  setup(){
    const boiteMails = ref(null)
    const boiteMailsId = ref(null)
    const userHasBoiteEmailSync = () => {
      store
          .dispatch('messageries/userHasBoiteEmailSync')
          .then(response => {
            if (response.data.success) {
              boiteMails.value = response.data.email
              boiteMailsId.value = response.data.id
            } else {
              boiteMails.value = null
              boiteMailsId.value = null
            }
          })
          .catch(err => {
            boiteMails.value = null
            boiteMailsId.value = null
            console.error(err)
          })
    }
    userHasBoiteEmailSync()
    return {
      boiteMailsId,
      boiteMails
    }
  },
  watch: {
    'prospectData': {
      deep: true,
      immediate: true,
      handler(val) {
        this.prospectVisible = val.filter((prospect) => {
          if(this.filter.statut != 'Aucun')
          {
            return prospect.all_data_prospect.isVisible == 1 && prospect.all_data_prospect.statut_fiche != 'Aucun'
          }
          else
          {
            return prospect.all_data_prospect.isVisible == 0 && prospect.all_data_prospect.statut_fiche == 'Aucun'
          }
        })
        this.json_data = this.prospectVisible
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
.swal2selectlist{
  width: 100vw !important;
  min-width: 100%;
}
/* table.vgt-table {
  font-size: 14px !important;
}

.vgt-table thead th, table.vgt-table td {
  padding: 0.5em !important;
  vertical-align: middle;
} */

</style>
<style>
  .swal2-title {
  font-size: 18px !important;
}
.weedo{
  width:35px;
}
.red-dot {
  height: 20px;
  width: 20px;
  background-color: #ff4d4d;
  border-radius: 50%;
  display: inline-block;
}
.orange-dot {
  height: 20px;
  width: 20px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}
.white-dot {
  height: 20px;
  width: 20px;
  background-color: #e5e5e5;
  border-radius: 50%;
  display: inline-block;
}
.green-dot {
  height: 20px;
  width: 20px;
  background-color: #2ab92a;
  border-radius: 50%;
  display: inline-block;
}
.gray-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: gray;
  display: inline-block;
}
</style>