<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> Fiche : {{ tiersData.categorie }} </span>
        <b-badge :variant="tiersData.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(tiersData.statut_fiche)">{{ tiersData.statut_fiche}}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
            @click="backToListing"
            id="revenir-liste-client"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <card-informations-Professionel :tiers-data="tiersData" :prospect-options="prospectOptions" :code-naf-data="codeNafOptions" @update-client-pro-returned="UpdateClientProReturned" @go-to-another-fiche-client="goToAnotherFicheClient" @show-email="$emit('show-email', tiersData.moyen_contact.email)" :is-boite-sync="isBoiteSync" />
      </b-col>
      <!-- <b-col md="3" sm="12">
        <b-card class="mb-2">
          <b-card-text>
            <div class="d-flex bd-highlight">
              <div class="bd-highlight"><span class="h4 font-weight-bold mr-1">Entreprise</span></div>
              <div class="ml-auto bd-highlight">
                <feather-icon class="cursor-pointer text-primary" v-if="!hasEntreprise" icon="PlusCircleIcon" size="26" v-b-toggle.sidebar-client-pro-entreprise/>
                <feather-icon class="cursor-pointer text-primary" v-else icon="EditIcon" size="26" v-b-toggle.sidebar-client-pro-entreprise/>
                <updated-fiche-professionel-entreprise  :tiers-data="tiersData" :hasEntreprise="hasEntreprise" @update-client-pro-returned="UpdateClientProReturned"></updated-fiche-professionel-entreprise>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col> -->
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <tabs-details-fiche-professionel :prospect-chosen="prospectChosen" :tiers-data="tiersData" :refresh-data-activite="refreshDataActivite" @reset-refresh-data-activite="resetRefreshDataActivite" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow,VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import cardInformationsProfessionel from './CardInformationsProfessionel'
import tabsDetailsFicheProfessionel from './TabsDetailsFicheProfessionel'
import updatedFicheProfessionelEntreprise from './actions-fiche-professionel/UpdatedFicheProfessionelEntreprise.vue'

export default {
  components: {
    // UI
    cardInformationsProfessionel,
    tabsDetailsFicheProfessionel,
    updatedFicheProfessionelEntreprise,
    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle
  },
  directives: { Ripple },
  props: {
    prospectChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    },
    prospectOptions: {
      type: Array,
      default: [],
      required: true,
    },
    isBoiteSync: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      refreshDataActivite: false,
      showLoading: false,
      hasEntreprise:false,
      typeClient: [
        {
          1: 'PARTICULIER',
          2: 'PROFESSIONNEL',
          3: 'ENTREPRISE'
        },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }
      ],
      tiersData: {
        idPersonneMorale: null,
        type: null,
        denominationCommercial: null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        email: null,
        adresse: null,
        statut_fiche:null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        origine:null,
        statut_gda: null,
        distributeur: {
          user_id: null
        }
      },
      codeNafOptions:[]
    }
  },
  watch: {
    prospectChosen: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.id && val.type) {
          this.showLoading = true
          Promise.all([this.GetCodeNAF(),this.fetchDataTiers(val.id, val.type)]).then(() => {
            this.showLoading = false
          }).catch(()=>{
            this.showLoading = false
          });
        }
      }
    }
  },

  methods: {
    resetRefreshDataActivite(isRefresh) {
      this.refreshDataActivite = isRefresh
    },
    UpdateClientProReturned(data, message){
      this.tiersData = data
      this.messageToast(message, 'Succès', 'success')
    },
    clearDataTier(){
      this.tiersData.idPersonneMorale = null
      this.tiersData.type = null
      this.tiersData.denominationCommercial = null
      this.tiersData.raisonSociale = null
      this.tiersData.formeJuridique = null
      this.tiersData.chiffreAffaire = null
      this.tiersData.dateCreation = null
      this.tiersData.SIRET = null
      this.tiersData.beneficiairesEffectif = null
      this.tiersData.activite = null
      this.tiersData.IDCC = null
      this.tiersData.effectif = null
      this.tiersData.idMoyenContact = null
      this.tiersData.telephone = null
      this.tiersData.email = null
      this.tiersData.statut_fiche = null
      this.tiersData.adresse = null
      this.tiersData.complementAdresse = null
      this.tiersData.siteWeb = null
      this.tiersData.codePostal = null
      this.tiersData.villeId = null
      this.tiersData.ville = null
      this.tiersData.ieuDitOuBp = null
      this.tiersData.IDCodeNAF = null
      this.tiersData.CodeNAF = null
      this.tiersData.origine = null
      this.tiersData.statut_gda = null
      this.numSecu = null



    },
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
      //this.clearDataTier()
    },
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    async fetchDataTiers(id, type) {
      await this.$http.get(`/prospect/fetchDataTiersPro/${id}/${type}`)
          .then(res => {
            if (res.data.success) {
              console.log(res.data.data)
              this.tiersData = res.data.data
              console.log('khonaling')
              console.log(this.tiersData)
              let findEntreprise = this.tiersData.prospect.find(obj => {
                return obj.lien_professionnel === 'DIRIGEANT';
              });
              this.hasEntreprise=findEntreprise ? true : false
              this.showWarningMessage()

              // this.showLoading = false
            }
          })
          .catch(err => {
            // this.showLoading = false
            this.backToListing()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.log(err)
          })
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      let prospect = {
        id: prospectId,
        type: prospectType,
        categorie: prospectCategorie
      }
      this.refreshDataActivite = true
      this.$emit('show-prospect', false, prospect)
    },
    async GetCodeNAF(){
      await this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e)=>{
            console.log(e);
          })
    },
    showWarningMessage(){
      if(this.tiersData.statut_gda=="ORANGE" ){
        console.log("OK")
                  let str="<div style='text-align: justify;'>Attention, le contrôle du gel des avoirs a identifié votre client/prospect comme susceptible d\'appartenir au registre national des gels.<br/><br/>";
                  str=str+"Celui-ci est dorénavant bloqué à toute modification ou souscription.<br/><br/>";
                  str=str+"Pour le débloquer, vous devez procéder à différents contrôles (pour plus de détails, voir l\'onglet <a target='_blank' href='https://bubbleinsupport.zendesk.com/hc/fr/articles/8612072274333-Qu-est-ce-que-le-gel-des-avoirs-'  style='text-decoration: underline;'> FAQ</a>)</div>";
                  this.$swal({
                    html: str ,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#4d25bc',

                  })
      }
      let suspect=false
      if((this.tiersData.statut_gda=="BLANC" || this.tiersData.statut_gda=="GRIS") || this.tiersData.statut_gda=="VERT")
      for(let i in this.tiersData.prospect){
        if(this.tiersData.prospect[i].lienable.statut_gda=="ORANGE" || this.tiersData.prospect[i].lienable.statut_gda=="ROUGE"){
          suspect=true
          break;
        }
      }
      if(suspect){
        
        this.$swal({
          html:'<p class="text-justify" style="font-size: 15px">Attention, cette personne est connectée avec un client/prospect ayant été suspecté (ou présent) sur le registre du gel des avoirs.</p>',
          icon:"warning",
          confirmButtonText:"OK",
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

  }
}
</script>
