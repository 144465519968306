<template>
  <div>
    <b-button v-if="can('Import contract') && interfaceChosen=='Listing'" v-b-toggle.sidebar-contrat v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="primary" @click="isActive=true;getAuthCourtier();$emit('is-update-function', false) ">
      <feather-icon icon="PlusIcon" />
      <span class="align-middle">Enregistrer un contrat</span>
    </b-button>
    <b-sidebar v-if="can('Import contract') || can('Update contract')" id="sidebar-contrat" ref="sideBarContrat" backdrop bg-variant="white" no-header right shadow sidebar-class="sidebar-lg">
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            <span v-if="isUpdate"> Modifier le contrat {{ composeData.n_contrat }} </span> <span v-else>Enregistrer un contrat</span>
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideSideBare" />
        </div>
        <validation-observer ref="contratComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <!-- clients list -->
            <h4>INFORMATIONS</h4>
            <validation-provider #default="{ errors }" name="client" rules="required" v-if="!isChild && isPortfeuille">
              <b-form-group :state="errors.length > 0 ? false : null" label="Client *" label-for="client">
                <v-select
                  v-model="composeData.client" 
                  :close-on-select="true" 
                  placeholder="Client"
                  :getOptionKey="client => client.id + client.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="items"
                  :filterable="false"
                  @open="onOpen"
                  @close="onClose"
                  autocomplete
                  @search="autocompleteModeles"
                  label="nameClient"
                  @scroll.native="onScroll" 
                  ref="vSelect">
                  <template #option="{ prenom, nom, type, denomination_commercial, categorie, nameClient, id }">
                    <b-avatar :src="null" size="sm" />
                    <span class="ml-50"> {{ nameClient }} </span>
                    <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                      {{ capitalizeFirstLetter(categorie) }}
                    </b-badge>
                  </template>

                  <template #selected-option="{ prenom, nom, type, denomination_commercial, nameClient, id }">
                    <b-avatar :src="null" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nameClient }} </span>
                  </template>

                  <template #list-footer>
                    
                    <li v-if="loading" class="loader">
                      <span>Chargement des clients...</span>
                    </li>
                    <li v-if="!loading && !hasMore">
                      <span>Toutes les clients sont chargées</span>
                    </li>
                  </template>
                  <div slot="no-options">Aucun client disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group v-if="composeData.client && composeData.client.statutFiche === 'Prospect'" label="Souhaitez vous changer le statut du prospect en client ?">
              <b-form-radio-group v-model="composeData.isChangedToProspect" :options="optionsChangedToProspect" class="demo-inline-spacing" name="radio-inline" />
            </b-form-group>

            <h4>CONTRAT</h4>
            <!-- N contrat -->
            <b-form-group label="N° contrat*" label-for="num_contrat">
              <validation-provider #default="{ errors }" name="n° contrat" rules="required">
                <b-form-input id="num_contrat" v-model="composeData.n_contrat"  :state="errors.length > 0 ? false : null" placeholder="N° contrat" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- risque -->
            <b-form-group label="Risque*" label-for="Risque">
              <validation-provider #default="{ errors }" name="risque" rules="required">
                <b-form-select v-model="composeData.risque_id" :options="risquesOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- assureur -->
            <validation-provider #default="{ errors }" name="assureur" rules="required">
              <b-form-group label="Assureur *" :state="errors.length > 0 ? false : null" label-for="assureur">
                <v-select v-model="composeData.assureur_id" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :getOptionKey="assureur => assureur.value + assureur.text" :options="assureursOptions" :reduce="assureur => assureur.value" label="text" placeholder="assureur">
                  <template #option="{ text }">
                    <span class="ml-50"> {{ text }}</span>
                  </template>
                  <div slot="no-options">Aucun assureur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- date d'effet -->
            <b-form-group label="Date d'effet*" label-for="date-effet-contrat">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                <flat-pickr id="date-effet-contrat" v-model="composeData.date_effet" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date d'effet" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Echeance anniversaire -->
            <b-form-group label="Echéance anniversaire" label-for="echeance-anniversaire">
              <validation-provider #default="{ errors }" name="échéance d'anniversaire">
                <cleave
                  id="date"
                  v-model="composeData.echeance_anniversaire"
                  class="form-control"
                  :raw="false"
                  :options="options.date"
                  placeholder="jour/mois"
               />
                <!-- <b-form-input id="echeance-anniversaire" placeholder="jour/mois" v-model="composeData.echeance_anniversaire" :maxlength="max"/> -->
                <div v-if="valdiation!=null" class="echent">Le champ date d'échéance invalide ( jj/mm )</div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            

            <!-- -->
            <b-form-group label="Contrat concurrence" label-for="contrat-concurrence">
              <validation-provider #default="{ errors }" name="Contrat concurrence">
                <div class="demo-inline-spacing" id="contrat-concurrence">
                  <b-form-radio v-model="composeData.concurrence" name="concurrence-radios" value="1"> oui </b-form-radio>
                  <b-form-radio v-model="composeData.concurrence" name="concurrence-radios" value="0"> non </b-form-radio>
                </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Type de paiement -->
            <b-form-group label="Type de paiement" label-for="typeFractionnement">
              <b-form-select v-model="composeData.typeFractionnement" :options="typeFractionnementOptions" />
            </b-form-group>

            <!-- statut -->
            <b-form-group label="Statut*" label-for="statut">
              <validation-provider #default="{ errors }" name="statut" rules="required">
                <b-form-select v-model="composeData.statut" :options="statutOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date de fin" label-for="date-fin-contrat" v-if="isRésilié || isTemporaire">
              <validation-provider #default="{ errors }" name="date de fin">
                <flat-pickr id="date-fin-contrat" v-model="composeData.date_fin" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date de fin" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Modalité de paiement -->
            <b-form-group label="Modalité de paiement" label-for="Modalite_paiement">
              <!--              <validation-provider #default="{ errors }" name="modalité de paiement" rules="required">-->
              <b-form-select v-model="composeData.modalite_paiement" :options="modalitePaiementOptions" />
              <!--              :state="errors.length > 0 ? false : null"-->
              <!--                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>-->
              <!--              </validation-provider>-->
            </b-form-group>

            <!-- budget -->
            <b-form-group label="Prime TTC" label-for="budget">
              <validation-provider #default="{ errors }" name="Prime TTC">
                <!-- <b-form-input id="echeance-anniversaire" placeholder="10 000,00"  v-model="composeData.budget" :maxlength="max"/>
                <div v-if="valdiation!=null" class="echent">Le champ date D'échéance invalide Jour/mois</div> -->
                <cleave id="budget" v-model.trim="composeData.budget" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.number" :raw="false" :state="errors.length > 0 ? false : null" class="form-control" placeholder="10 000,00" />

                  <div v-if="validbudget!=null" class="echent">Le champ Prime ttc est invalide </div>

                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

             <!-- Type origine -->
         
              <b-form-group label="Type d’origine" label-for="Type-d’origine" style="margin-top: 10px;">
                  <b-form-select
                  v-model="composeData.origine"
                          :options="origine"
                          id="origine"
                          />
              </b-form-group>

              <b-form-group v-if="composeData.origine === 'Autre'" label="Précisez" label-for="origineautre"
                    
                      >
                    
                          <b-form-input
                            id="origineautre"
                            placeholder="Précisez l’origine"
                            v-model="composeData.origineautre"
                          />
              </b-form-group>

               <validation-provider #default="{ errors }" name="gestionnaire">
                <b-form-group :state="errors.length > 0 ? false : null" label="Gestionnaire" label-for="gestionnaire" >
                  <v-select v-model="composeData.gestionnaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="gestionnaire" label="nom" placeholder="Gestionnaire" >
                    <template #option="{ avatar, nom }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>

                    <template #selected-option="{ avatar, nom }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>
                    <div slot="no-options">Aucune createur disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            
              <validation-provider #default="{ errors }" name="createur">
                <b-form-group :state="errors.length > 0 ? false : null" label="Crée par" label-for="Crée-par" >
                  <v-select v-model="composeData.createur" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="createur" label="nom" placeholder="Crée par" :disabled="isUpdate">
                    <template #option="{ avatar, nom }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>

                    <template #selected-option="{ avatar, nom }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ nom }}</span>
                    </template>
                    <div slot="no-options">Aucune createur disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>


            <validation-provider #default="{ errors }" name="intermediaire">
              <b-form-group :state="errors.length > 0 ? false : null" label="Intermédiaire (s)" label-for="intermediaire">
                <v-select v-model="composeData.intermediaire" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="intermediaire" label="nom" placeholder="Intermédiaire (s)" multiple>
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune intermediaire disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="my-2 d-flex align-items-center">
              <h4 class="mr-2 mb-0">COMMISSIONS</h4>
              <b-form-group class="mb-0">
                <label class="float-left pl-2">Prévisionnel</label>
                <b-form-checkbox id="previsionnel" v-model="composeData.previsionnel" :value="1"
                  :unchecked-value="null"></b-form-checkbox>
              </b-form-group>
            </div>
            <!-- taux de commission -->
            <validation-provider #default="{ errors }" name="taux de commission">
              <b-form-group label="Taux de commission" label-for="taux_commission">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : ''" append="%">
                  <cleave v-model.trim="composeData.taux_commission" :state="errors.length > 0 ? false : null" placeholder="10,00" class="form-control" :options="options.percent" :raw="false" />
                  </b-input-group>
                  <div v-if="validtaux_commission!=null" class="echent">Le champ taux de commission est invalide </div>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Frais Courtage -->
            <b-form-group label="Frais de courtage" label-for="frais de courtage">
              <validation-provider #default="{ errors }" name="frais de courtage">
                <cleave id="frais de courtage" v-model.trim="composeData.frais_courtage" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.number" :raw="false" :state="errors.length > 0 ? false : null" class="form-control" placeholder="10,00" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Commission récurrente -->
            <b-form-group label="Commission récurrente" label-for="commission recurrente">
              <validation-provider #default="{ errors }" name="commission recurrente">
                <cleave id="commission recurrente" v-model.trim="composeData.commission_recurrente" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.number" :raw="false" :state="errors.length > 0 ? false : null" class="form-control" placeholder="10 000,00" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Commission Unique Précompte -->
            <b-form-group label="Commission unique précompte" label-for="commission unique precompte" v-if="composeData.typeFractionnement == 'PRIME_UNIQUE' || composeData.typeFractionnement == 'VERSEMENTS_LIBRES'">
              <validation-provider #default="{ errors }" name="commission unique precompte">
                <cleave id="commission unique precompte" v-model.trim="composeData.commission_unique_precompte" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.number" :raw="false" :state="errors.length > 0 ? false : null" class="form-control" placeholder="10 000,00" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <h4>RATTACHEMENT</h4>

            <!-- Courtier -->
            <b-form-group label="Courtier" label-for="courtier">
              <b-form-input id="courtier" v-model="currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial" disabled placeholder="Courtier" />
            </b-form-group>

            <!-- utilisateur list -->
            <validation-provider #default="{ errors }" name="commercial" rules="required" v-if="false">
              <b-form-group :state="errors.length > 0 ? false : null" label="Commercial*" label-for="commercial">
                <v-select v-model="composeData.commercial" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1" input-id="commercial" label="nom" placeholder="Commercial">
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune commercial disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <h4>DOCUMENTS</h4>
            <app-collapse>
              <app-collapse-item title="Documents de souscription">
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Conditions particulières'" @click="openConditionParticulier">
                          <b-form-file type="file" id="openConditionParticulier" class="d-none" v-model="composeData.fileConditionParticulier" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Conditions particulières" :value="composeData.fileConditionParticulier ? composeData.fileConditionParticulier.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Bulletin d\'adhésion signé'" @click="openBulteinAdhesionSigne">
                          <b-form-file type="file" id="openBulteinAdhesionSigne" class="d-none" v-model="composeData.fileBulteinAdhésionSigne" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Bulletin d'adhésion signé" :value="composeData.fileBulteinAdhésionSigne ? composeData.fileBulteinAdhésionSigne.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Fiche d\'information et de conseil'" @click="openFicheInformationConseil">
                          <b-form-file type="file" id="openFicheInformationConseil" class="d-none" v-model="composeData.fileFicheInformationConseil" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Fiche d'information et de conseil" :value="composeData.fileFicheInformationConseil ? composeData.fileFicheInformationConseil.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </app-collapse-item>
              <app-collapse-item title="Documents produit">
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'IPID'" @click="openfileIpid">
                          <b-form-file type="file" id="openfileIpid" class="d-none" v-model="composeData.fileIpid" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="IPID" :value="composeData.fileIpid ? composeData.fileIpid.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Conditions generale'" @click="openFileConditionsGenerale">
                          <b-form-file type="file" id="openFileConditionsGenerale" class="d-none" v-model="composeData.fileConditionsGenerale" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Condition générale" :value="composeData.fileConditionsGenerale ? composeData.fileConditionsGenerale.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Notice d\'information'" @click="openFileNoticeInformation">
                          <b-form-file type="file" id="openFileNoticeInformation" class="d-none" v-model="composeData.fileNoticeInformation" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Notice D'information" :value="composeData.fileNoticeInformation ? composeData.fileNoticeInformation.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Fiche d\'information et de conseil'" @click="openFileTableauGarantie">
                          <b-form-file type="file" id="openFileTableauGarantie" class="d-none" v-model="composeData.fileTableauGarantie" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Tableau de garantie" :value="composeData.fileTableauGarantie ? composeData.fileTableauGarantie.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>


            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary" @click="recordContrat"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-sidebar id="sidebar-importNewContrat" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarImportContrat" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Importer un document contrat</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="validateImportContratRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-form-group label="Nature de document" label-for="h-famille" v-if="false">
              <validation-provider #default="{ errors }" name="nature de document" rules="required">
                <b-form-select id="h-famille-autre-document" v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nom du document*" label-for="h-nom-document" v-if="isAutre">
              <validation-provider #default="{ errors }" name="nom du document" rules="required">
                <b-form-input id="h-nom-document-autre-document" v-model="composeData.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Description" label-for="h-Description">
              <b-form-textarea id="h-Description-autre-document" placeholder="Description" rows="3" v-model="composeData.description" />
            </b-form-group> -->
            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocumentPro" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary" @click="recordDocument"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>

        
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { BAvatar, BBadge, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea, BFormSelectOption, BInputGroup, BSidebar, VBToggle, BFormFile, VBTooltip, BFormRadioGroup, BInputGroupPrepend, BOverlay, BFormRadio, BFormCheckbox, BFormCheckboxGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import documentService from '@/shared/services/documentService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BBadge,
    Cleave,
    vSelect,
    BFormSelect,
    flatPickr,
    BAvatar,
    VBTooltip,
    BFormTextarea,
    BFormSelectOption,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BOverlay,
    BFormRadio,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    isPortfeuille: {
      type: Boolean,
      required: true
    },
    commercialId: {
      type: Number,
      default: null,
      required: false
    },
    interfaceChosen: {
      type: String,
      default: "Listing",
      required: false
    },
    isChild: {
      type: Boolean,
      default: false,
      required: false
    },
    clientsOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    contratId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null
    },
    clientType: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null
    },
    clientId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null
    },

    commercialOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    risquesOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    assureursOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true
    },
    isUpdate: {
      type: Boolean,
      default: true,
      required: true
    },
    dataSelectedForEdit: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        id: null,
        client: null,
        n_contrat: null,
        risque_id: null,
        assureur_id: null,
        date_effet: null,
        date_fin: null,
        echeance_anniversaire: '',
        conteur:0,
        typeFractionnement: null,
        statut: null,
        modalite_paiement: null,
        commercial: null,
        budget: null,
        taux_commission: null,
        courtier_id: null,
        commission_unique_precompte: null,
        commission_recurrente: null,
        frais_courtage: null,
        previsionnel: 0,
        origine : null,
        origineautre:null,
        createur : null,
        intermediaire: null,
        gestionnaire:null
      },
      required: false
    },
    currentUser: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      validtaux_commission:null,
      validbudget:null,
      counteur:0,
      valdiation:null,
      max:5,
      showLoading: false,
      required,
      famille: null,
      optionsType: [],
      isUpdateAction: false,
      isAutre: false,
      isRésilié: false,
      isTemporaire: false,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        },
        percent: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          numeralPositiveOnly: true
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d','m'],

        }
      },
      origine:[
            { value: null, text: 'Choisissez' },
            { value: 'Co_courtage', text: 'Co-courtage' },
            { value: 'Indicateur_affaires', text: 'Indicateur d\'affaires' },
            { value: 'Weedo_market', text: 'Weedo market' },
            { value: 'Recommandation', text: 'Recommandation' },
            { value: 'Réseaux_sociaux', text: 'Réseaux sociaux' },
            { value: 'Autre', text: 'Autre' },
      ],
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      optionsChangedToProspect: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
      ],
      statutOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'EN_COURS',
          text: 'En cours'
        },
        {
          value: 'MISE_EN_DEMEURE',
          text: 'Mise en demeure'
        },
        {
          value: 'REDUIT',
          text: 'Réduit'
        },
        {
          value: 'RESILIE',
          text: 'Résilié'
        },
        {
          value: 'SANS_EFFET',
          text: 'Sans effet'
        },
        {
          value: 'SUSPENDU',
          text: 'Suspendu'
        },
        {
          value: 'TEMPORAIRE',
          text: 'Temporaire'
        }
      ],
      typeFractionnementOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'ANNUEL',
          text: 'Annuel'
        },
        {
          value: 'MENSUEL',
          text: 'Mensuel'
        },
        {
          value: 'PRIME_UNIQUE',
          text: 'Prime unique'
        },
        {
          value: 'SEMESTRIEL',
          text: 'Semestriel'
        },
        {
          value: 'TRIMESTRIEL',
          text: 'Trimestriel'
        },
        {
          value: 'VERSEMENTS_LIBRES',
          text: 'Versements libres'
        }
      ],
      modalitePaiementOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'CB',
          text: 'CB'
        },
        {
          value: 'CHEQUE',
          text: 'Chèque'
        },
        {
          value: 'PRELEVEMENT',
          text: 'Prélèvement'
        }
      ],
      composeData: {
        id: null,
        client: null,
        description: null,
        fileBulteinAdhésionSigne: null,
        idType: null,
        fileConditionParticulier: null,
        fileFicheInformationConseil: null,
        fileIpid: null,
        fileConditionsGenerale: null,
        fileTableauGarantie: null,
        fileNoticeInformation: null,
        contrat_Id: null,
        client_Id: null,
        n_contrat: null,
        risque_id: null,
        assureur_id: null,
        date_effet: null,
        date_fin: null,
        echeance_anniversaire: "",
        typeFractionnement: null,
        statut: null,
        modalite_paiement: null,
        commercial: null,
        budget: null,
        taux_commission: null,
        commission_recurrente: null,
        commission_unique_precompte: null,
        frais_courtage: null,
        previsionnel: 0,
        courtier_id: null,
        isChangedToProspect: false,
        concurrence: 0,
        origine : null,
        createur : null,
        origineautre : null,
        intermediaire: null,
        gestionnaire:null,
      },
      selected_client: null,
      selected_risque: null,
      selected_assureur: null,
      date_effet: null,
      date_fin: null,
      date_creation: null,

      page: 1,
      items: [],
      selectedItem: null,
      loading: false, // Indique si les données sont en train de charger
      hasMore: true,  // Indique s'il reste des données à charger
      search: '',

      categories: [
        {
          1: 'PARTICULIER',
          2: 'PROFESSIONNEL',
          3: 'ENTREPRISE'
        },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }
      ]
    }
  },
  watch: {
    'composeData.typeFractionnement': {
      handler(val) {
        if(!val || (val != 'PRIME_UNIQUE' && val != 'VERSEMENTS_LIBRES')) {
          this.composeData.commission_unique_precompte = null
        }
      }
    },
    'composeData.statut': {
      handler(val) {
        if (val === 'RESILIE') {
          this.isRésilié = true
        }else if(val === 'TEMPORAIRE'){
          this.isTemporaire = true;
        }
         else {
          this.isRésilié = false
          this.isTemporaire = false;

        }
      }
    },
    'composeData.idType': {
      handler(val) {
        if (val == 55) {
          this.isAutre = true
        } else {
          this.isAutre = false
        }
      }
    },
    'composeData.date_effet': {
      handler(val) {
        if(this.isUpdate == false){
          if(this.dataSelectedForEdit.echeance_anniversaire !=''){
          this.dataSelectedForEdit.conteur += 1
          if(this.dataSelectedForEdit.conteur != 1)
            this.composeData.echeance_anniversaire = this.formatDateAdd(val)
          this.dataSelectedForEdit.conteur += 1
        }
        else{
          this.composeData.echeance_anniversaire = this.formatDateAdd(val)
        }
        }
      }
    },
    'composeData.echeance_anniversaire': {
      handler(val) {
        if(this.dataSelectedForEdit.echeance_anniversaire != '')
          this.composeData.echeance_anniversaire=this.dataSelectedForEdit.echeance_anniversaire
        if(this.composeData.echeance_anniversaire.length==0){
          this.valdiation=null
        }
        else{
          const jour = this.composeData.echeance_anniversaire.charAt(0)+this.composeData.echeance_anniversaire.charAt(1)
          const mois = this.composeData.echeance_anniversaire.charAt(3)+this.composeData.echeance_anniversaire.charAt(4)
          if(this.composeData.echeance_anniversaire.length==2){
          this.composeData.echeance_anniversaire += '/'
        }
          if ( (mois >= 1 && mois <= 12) && (jour >= 1 && jour <= 31) ){
            if(this.composeData.echeance_anniversaire.length<5){
              this.valdiation='taper DD/MM'
            }
            else{
              this.valdiation=null
            }
          }
          else{
            this.valdiation='taper DD/MM'
          }
        }
      }
    },
    'composeData.client': {
      handler(val) {
        this.composeData.isChangedToProspect = false
      }
    },
    'composeData.budget': {
      handler(val) {
        if(this.composeData.budget == ','){
          this.validbudget = 'champs prime invalide'
        }
        else{
          this.validbudget = null
          if(this.composeData.budget.charAt(this.composeData.budget.length - 1) == ','){
            this.validbudget = 'champs prime invalide'
          }
          else if(this.composeData.budget.charAt(0) == ','){
            var taux = this.composeData.budget
            var output = [taux.slice(0, 0), '0',taux.slice(0)].join('')
            this.composeData.budget = output
          }
          else{
            this.validbudget=null
          } 
        }
      }
    },
    'composeData.taux_commission':{
      handler(val) {
        if(this.composeData.taux_commission == ','){
          this.validtaux_commission = 'champs prime invalide'
        }
        else{
          this.validtaux_commission = null
          if(this.composeData.taux_commission.charAt(this.composeData.taux_commission.length - 1) == ','){         
            this.validtaux_commission = 'champs prime invalide'
          }
          else if(this.composeData.taux_commission.charAt(0) == ','){
            var taux = this.composeData.taux_commission
            var output = [taux.slice(0, 0), '0',taux.slice(0)].join('')
            this.composeData.taux_commission = output
          }
          else{
            this.validtaux_commission=null
          } 
        }
      }
    },
    contratId: {
      immediate: true,
      handler(val) {
        this.composeData.contrat_Id = val
      }
    },
    clientId: {
      immediate: true,
      handler(val) {
        this.composeData.client_Id = val
      }
    },
    isUpdate: {
      immediate: true,
      handler(val) {
        console.log(val)
        this.isUpdateAction = val
      }
    },
    isUpdateAction: {
      handler(val) {
        if (val) {
          this.composeData.isChangedToProspect = false
          // this.composeData = this.dataSelectedForEdit
          this.updateComposeData(this.dataSelectedForEdit)
          this.items = [];
          this.page = 1;
          this.hasMore = true;
          this.composeData.client.nameClient = this.composeData.client.nom 
          this.search = this.composeData.client.nom 
          console.log('this.composeData a')
          console.log(this.composeData)
          this.getProspectByScrool()
          this.search = ''
        } else {
          this.clearDataSideBar()
        }
      }
    },
    dataSelectedForEdit: {
      deep:true,
      handler(val) {
        if (this.isUpdateAction) {
          this.composeData.isChangedToProspect = false
          this.updateComposeData(val)
          // this.composeData = val
          this.items = [];
          this.page = 1;
          this.hasMore = true;
          this.composeData.client.nameClient = this.composeData.client.nom 
          this.search = this.composeData.client.nom
          console.log('this.composeData b')
          console.log(this.composeData)
          this.getProspectByScrool()
          this.search = ''
        }
        // else {
        //   this.clearDataSideBar()
        // }
      }
    },
    clientsOptions: {
      immediate:true,
      deep: true,
      handler(val) {
        if (this.isChild) {
          this.composeData.client = val[0] 
        }
      }
    },
    optionsFamille: {
      handler(val) {
        if (val.length > 0) {
          this.famille = val[0].value
          this.changeListoptionType()
        }
      }
    }
  },
  computed: {
    categorieVariant() {
      const categorieColor = {
        /* eslint-disable key-spacing */
        PARTICULIER: 'light-danger',
        PROFESSIONNEL: 'light-warning',
        ENTREPRISE: 'light-success'
        /* eslint-enable key-spacing */
      }

      return categories => categorieColor[categories]
    }
  },
  created() {
    this.composeData.courtier_id = this.currentUser.courtier_user[0].courtier_id
    // Initialise debounce avec un délai de 500 ms
    this.debouncedSearch = debounce(this.getProspectByScrool, 700);

  },
  methods: {
    updateComposeData(data) {
      // this.composeData.id = data
        this.composeData.id= data.id
        this.composeData.client= data.client
        this.composeData.n_contrat= data.n_contrat
        this.composeData.risque_id= data.risque_id
        this.composeData.assureur_id= data.assureur_id
        this.composeData.date_effet= data.date_effet
        this.composeData.date_fin= data.date_fin
        this.composeData.echeance_anniversaire= data.echeance_anniversaire
        this.composeData.conteur= data.conteur
        this.composeData.typeFractionnement= data.typeFractionnement
        this.composeData.statut= data.statut
        this.composeData.modalite_paiement= data.modalite_paiement
        this.composeData.commercial= data.commercial
        this.composeData.budget= data.budget
        this.composeData.taux_commission= data.taux_commission
        this.composeData.courtier_id= data.courtier_id
        this.composeData.concurrence= data.concurrence
        this.composeData.commission_unique_precompte= data.commission_unique_precompte
        this.composeData.commission_recurrente= data.commission_recurrente
        this.composeData.frais_courtage= data.frais_courtage
        this.composeData.previsionnel= data.previsionnel
        this.composeData.origine = data.origine
        this.composeData.createur = data.createur
        this.composeData.intermediaire= data.intermediaire
        this.composeData.gestionnaire=data.gestionnaire
    },
    autocompleteModeles(filtre, loading) {
      this.items = [];
      this.search = filtre
      this.page = 1;
      this.hasMore = true;
      this.debouncedSearch()
       
    },
    onOpen() {
      if (!this.items.length) {
        this.getProspectByScrool(); 
      }
      this.$nextTick(() => {
        const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.addEventListener('scroll', this.onScroll);
          console.log("Scroll event attached");
        }
      });
    },

    onClose() {
      const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
      if (dropdownMenu) {
        dropdownMenu.removeEventListener('scroll', this.onScroll);
      }
    },
    // Méthode appelée pour récupérer les données paginées
    getProspectByScrool(page = 1, scrool = false ) {
      if (this.loading || !this.hasMore) return;

      // Récupérer la hauteur actuelle de la liste avant l'ajout des nouvelles données
      const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
      const previousScrollHeight = dropdownMenu ? dropdownMenu.scrollHeight : 0;

      this.loading = true;
      this.$http
        .post(`/portefeuilles/getDataPagination?page=${page}`, {
          type: 'CLIENT_SELECT',
          type_list: 'CLIENT',
          per_page: 20,
          recherche: this.search || ''  
        })
        .then((r) => {
          const data = r.data.data.data;

          if (data.length) {
            this.items = [...this.items, ...data]; // Ajoute les nouveaux éléments
          }

          if (!r.data.data.next_page_url) {
            this.hasMore = false; // Désactiver le chargement si plus de pages
          }

          this.page++; // Incrémenter la page pour le prochain appel
        })
        .catch((err) => {
          console.error('Erreur lors du chargement des données:', err);
        })
        .finally(() => {
          // this.search = ''
          this.loading = false; // Arrêter le spinner de chargement
          // Restaurer la position de défilement
          if (scrool) {
            this.$nextTick(() => {
              if (dropdownMenu) {
                // Calculer la nouvelle hauteur et ajuster la position de défilement
                const newScrollHeight = dropdownMenu.scrollHeight;
                dropdownMenu.scrollTop += newScrollHeight - previousScrollHeight;
              }
            });
          }
          
        });
    },    
    onScroll(event) {
      const tolerance = 5; // Tolérance de 5 pixels pour détecter le bas
      const dropdown = event.target;
      const atBottom = Math.abs(dropdown.scrollHeight - dropdown.scrollTop - dropdown.clientHeight) <= tolerance;

      console.log("Scroll détecté - Bas atteint : ", atBottom);

      if (atBottom && this.hasMore && !this.loading) {
        this.getProspectByScrool(this.page); // Charger la page suivante
      }
    },
    // open File Document Souscription  //
    openConditionParticulier: () => {
      document.getElementById('openConditionParticulier').click()
    },
    openBulteinAdhesionSigne: () => {
      document.getElementById('openBulteinAdhesionSigne').click()
    },
    openFicheInformationConseil: () => {
      document.getElementById('openFicheInformationConseil').click()
    },
    //Open File Document Produits //
    openfileIpid: () => {
      document.getElementById('openfileIpid').click()
    },
    openFileConditionsGenerale: () => {
      document.getElementById('openFileConditionsGenerale').click()
    },
    openFileTableauGarantie: () => {
      document.getElementById('openFileTableauGarantie').click()
    },
    openFileNoticeInformation: () => {
      document.getElementById('openFileNoticeInformation').click()
    },

    hideSideBare() {
      this.$refs.sideBarContrat.hide()
      this.clearDataSideBar()
    },
    hideSideBareImportContrat() {
      this.$refs.sideBarImportContrat.hide()
    },
    clearDataSideBarImportContrat() {
      //  this.famille = null,
      ;(this.composeData.idType = null), (this.composeData.description = null), (this.composeData.file = null), this.$refs.validateImportContratRules.reset()
    },
    openToSelectFile: () => {
      document.getElementById('fileautredocumentPro').click()
    },

    getAuthCourtier(){
      let userData = JSON.parse(localStorage.getItem('userData'))
    let commercialSelected = {
      id: userData.personne_physique.user_id,
      nom: userData.personne_physique.nom + ' ' + userData.personne_physique.prenom
    }
      this.composeData.createur = commercialSelected
    this.composeData.gestionnaire = commercialSelected
    
    },

    changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
    recordDocument() {
      this.$refs.validateImportContratRules.validate().then(success => {
        if (success) {
          // Set formData

          const formData = new FormData()
          // Append the rest of your form data
          formData.append('tiers', this.composeData.id)
          formData.append('type_tiers', this.clientType)
          formData.append('file', this.composeData.file)
          formData.append('type_doc_id', this.composeData.idType)
          formData.append('nom', this.composeData.nom)
          formData.append('courtier_id', this.composeData.courtier_id)
          formData.append('description', '')
          // formData.append('description', this.composeData.description)
          formData.append('contrat_id', this.composeData.contrat_Id)
          formData.append('client_id', this.composeData.client_Id)
          formData.append('etude_id', this.composeData.etude_id)
          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/importerNewDocumentContrat', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                this.messageToast(res.data.message, 'Succès', 'success')
                this.clearDataSideBarImportContrat()
                this.$emit('record-document-returned', true)
                this.hideSideBareImportContrat()
              } else {
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', "danger")
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
              console.error(err)
            })
        }
      })
    },

    // Methode additional
    formatDateAdd(date) {
      return date ? moment(date, 'DD/MM')
      .format('DD/MM') : ''
    },
    clearDataSideBar() {
      this.composeData.id = null
      this.composeData.client = null
      this.composeData.n_contrat = null
      this.composeData.risque_id = null
      this.composeData.assureur_id = null
      this.composeData.date_effet = null
      this.composeData.echeance_anniversaire = ''
      this.composeData.typeFractionnement = null
      this.composeData.statut = null
      this.composeData.modalite_paiement = null
      this.composeData.commercial = null
      this.composeData.budget = null
      this.composeData.taux_commission = null
      this.composeData.commission_recurrente = null
      this.composeData.commission_unique_precompte = null
      this.composeData.frais_courtage = null
      this.composeData.previsionnel = 0
      this.composeData.isChangedToProspect = false
      this.composeData.fileBulteinAdhésionSigne = null
      this.composeData.fileConditionParticulier = null
      this.composeData.fileFicheInformationConseil = null
      this.composeData.fileIpid = null
      this.composeData.fileConditionsGenerale = null
      this.composeData.fileTableauGarantie = null
      this.composeData.fileNoticeInformation = null
      this.composeData.concurrence = 0
      this.composeData.origine = null
      this.composeData.createur = null
      this.composeData.intermediaire = null
      this.composeData.origineautre = null
      this.composeData.gestionnaire = null
      this.$refs.contratComposeRules.reset()
      this.clearDataSideBarImportContrat()
    },
    // record Data contrat
    methodRecordData() {
      // statutFiche
      const formData = new FormData()
      // Append the rest of your form data
      if (this.composeData.id) {
        formData.append('id', this.checkIsNull(this.composeData.id))
      }
      formData.append('description', this.checkIsNull(this.composeData.description))
      formData.append('client', JSON.stringify(this.composeData.client))
      formData.append('commercial_id', this.checkIsNull(this.composeData.gestionnaire.id))
      formData.append('fileBulteinAdhésionSigne', this.checkIsNull(this.composeData.fileBulteinAdhésionSigne))
      formData.append('fileConditionParticulier', this.checkIsNull(this.composeData.fileConditionParticulier))
      formData.append('fileFicheInformationConseil', this.checkIsNull(this.composeData.fileFicheInformationConseil))
      formData.append('fileIpid', this.checkIsNull(this.composeData.fileIpid))
      formData.append('fileConditionsGenerale', this.checkIsNull(this.composeData.fileConditionsGenerale))
      formData.append('fileTableauGarantie', this.checkIsNull(this.composeData.fileTableauGarantie))
      formData.append('fileNoticeInformation', this.checkIsNull(this.composeData.fileNoticeInformation))
      formData.append('contrat_Id', this.checkIsNull(this.composeData.contrat_Id))
      formData.append('client_Id', this.checkIsNull(this.composeData.client_Id))
      formData.append('n_contrat', this.checkIsNull(this.composeData.n_contrat))
      formData.append('risque_id', this.checkIsNull(this.composeData.risque_id))
      formData.append('assureur_id', this.checkIsNull(this.composeData.assureur_id))
      formData.append('date_effet', this.checkIsNull(this.composeData.date_effet))
      formData.append('date_fin', this.checkIsNull(this.composeData.date_fin))
      formData.append('echeance_anniversaire', this.composeData.echeance_anniversaire)
      formData.append('typeFractionnement', this.checkIsNull(this.composeData.typeFractionnement))
      formData.append('statut', this.checkIsNull(this.composeData.statut))
      formData.append('modalite_paiement', this.checkIsNull(this.composeData.modalite_paiement))
      formData.append('commercial', JSON.stringify(this.checkIsNull(this.composeData.commercial)))
      formData.append('budget', this.checkIsNull(this.composeData.budget))
      formData.append('taux_commission', this.checkIsNull(this.composeData.taux_commission))
      formData.append('courtier_id', this.checkIsNull(this.composeData.courtier_id))
      formData.append('isChangedToProspect', this.checkIsNull(this.composeData.isChangedToProspect))
      formData.append('concurrence', this.composeData.concurrence)
      formData.append('commission_unique_precompte', this.checkIsNull(this.composeData.commission_unique_precompte))
      formData.append('commission_recurrente', this.checkIsNull(this.composeData.commission_recurrente))
      formData.append('frais_courtage', this.checkIsNull(this.composeData.frais_courtage))
      formData.append('previsionnel', this.composeData.previsionnel)
      formData.append('origine', this.checkIsNull(this.composeData.origine === 'Autre' ? this.composeData.origineautre : this.composeData.origine));
      formData.append('createur',JSON.stringify(this.composeData.createur))
      formData.append('intermediaire', JSON.stringify(this.composeData.intermediaire));
 
      // console.log(JSON.stringify(this.projet.client))

      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.showLoading = true
      this.$http
        .post('/contrat/recordContrat', formData, config)
        .then(res => {
          if (res.data.success) {
            this.showLoading = false
            console.log(this.composeData.intermediaire)
            this.$emit('record-contrat-returned', res.data.data, res.data.message,this.interfaceChosen == 'FicheContrat' ? true : false)
            this.clearDataSideBar()
            this.hideSideBare()
            this.$emit('refresh-data-contrat')

          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
          console.error(err)
        })
    },
    recordContrat() {
      this.$refs.contratComposeRules.validate().then(success => {
        if (success) {
          if(this.valdiation == null && this.validbudget == null && this.validtaux_commission == null){
            this.methodRecordData()
          }

        }
      })
    }
  },
  setup() {
    const optionsFamille = ref([])
    const fetchDocumentNatureWithTypes = documentService()
    fetchDocumentNatureWithTypes
      .then(success => {
        optionsFamille.value = []
        success.data
          .filter(function (a) {
            return a.id == 4
          })
          .forEach(el => {
            optionsFamille.value.push({
              value: { id: el.id, typeDocument: el.documentype },
              text: el.libelle
            })
          })
      })
      .catch(errors => {
        console.log(errors)
        optionsFamille.value = []
      })

    return {
      fetchDocumentNatureWithTypes,
      optionsFamille
    }
  }
}
</script>
<style lang="scss">
.vs__dropdown-menu{
  overflow-y: auto;
  overflow-y: scroll;
}


.echent{
  width: 100%;
    font-size: 0.857rem;
    color: #ea5455;}
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
