<template>
  <div>

    <!-- <div class="custom-search d-flex justify-content-start">
      <div class="me-auto bd-highlight mb-1">
        <b-button
            v-b-toggle.sidebar-document
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
        >
          <feather-icon
              class="mr-50"
              icon="PlusIcon"
          />
          <span class="align-middle">Ajouter un document</span>
        </b-button>
        <action-document :courtier-id="courtierId" :prospect-chosen="prospectChosen" @record-document-client-returned="recordDocumentClientReturned" />
      </div>
    </div> -->
     <action-document :courtier-id="courtierId"
                      :famille-documents="familleDocuments"
                      :prospect-chosen="prospectChosen"
                      :projet-contrat-data="projetContratData"
                      @record-document-client-returned="recordDocumentClientReturned"
                      @refresh-data-document="countdocument" />
    <b-card :title="isFromMenu ? '' : ''" :body-class="isFromMenu ? '' : 'py-0'">
       <div class="d-flex justify-content-end mb-1">
              <b-button
            v-b-toggle.sidebar-document
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
        >
          <feather-icon
              class="mr-50"
              icon="PlusIcon"
          />
          <span class="align-middle">Ajouter un document</span>
        </b-button>
       </div>
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Type de document" label-for="filtre-type-document">
              <b-form-select id="filtre-type-document" v-model="typeSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="typed in typesDocument" :key="typed.id" :value="typed.id" > {{ typed.type }} </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="N° contrat / N° devis" label-for="filtre-contrat-document">
              <b-form-input id="filtre-contrat-document" v-model="contratSearch" placeholder="N° contrat / N° devis"/>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Recherche" label-for="recherche-input">
                <b-form-input
                    v-model="recherche"
                    class="d-inline-block"
                    type="text"
                    placeholder="Recherche par type de document"
                    id="recherche-input"
                />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon"
                style="margin-top:1.9rem;"
                v-b-tooltip.hover.top="'Tout afficher'"
                variant="primary"
                @click="resetFilter">
                <feather-icon icon="RefreshCwIcon"/></b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- table -->
    <b-overlay
        :show="showLoading"
        no-wrap
    />
    <vue-good-table
        :columns="columns"
        :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
        :rows="rows"
        :rtl="direction"
        :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
        style-class="vgt-table condensed"
        @on-selected-rows-change="selectionChanged"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <span v-if="props.column.field === 'statut'">
          <b-badge :variant="statutVariant(props.row.statut)">
            {{ props.row.statut }}
          </b-badge>
        </span>
        <span v-if="props.column.field === 'numProjet'">
          {{ props.row.numProjet }}
        </span>

        <span  v-else-if="props.column.field === 'TypeDeDocument'">
          <p v-if="props.row.idTypeDeDocument == '85'">{{ props.row.NomDuDocument }}</p>
          <p v-else-if="props.row.idTypeDeDocument == '105'">{{ props.row.NomDuDocument }}</p>
          <p v-else>{{ props.row.TypeDeDocument }}</p>

        </span>
        
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
                dropright
                no-caret
                toggle-class="text-decoration-none p-0"
                variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                    class="text-body align-middle mr-25"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item @click="displayDocument(props.row.id, props.row.Name, props.row.NomDuDocument, props.row.ExtDuDocument)">
                <feather-icon
                    class="mr-50"
                    icon="SearchIcon"
                />
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.FamilleDeDocument != 'GDA'" @click="deleteDocumentClient(props.row.id)">
                <feather-icon
                    class="mr-50"
                    icon="TrashIcon"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
              
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col md="4">
            <b-input-group>
              <b-form-select v-model="selected" :options="options" />
              <b-input-group-append>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                  @click="action_masse(selected)">
                  <span>Valider </span>
                  <feather-icon icon="ArrowRightIcon" class="mr-50" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col>
            <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div
          slot="emptystate"
          class="text-center align-middle"
      >
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>

    <b-modal
        id="modal-display-document"
        ref="modal-display-document"
        :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'"
        :title="document.name"
        cancel-title="Fermer"
        cancel-variant="outline-secondary"
        ok-title="Télécharger"
        @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img
            v-if="document.extensionDocument === 'IMAGE'"
            :alt="document.name"
            :src="document.base64"
            fluid
        />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  VBTooltip,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import actionDocument from './ActionsDocument.vue'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BImg,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    VBTooltip,
    // Ui
    actionDocument,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    fresh: {
      type: Number,
      default: 0,
      required: false
    },
    prospectChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true
    },
    projetContratData: {
      type: Array,
      default: [],
      required: false
    },
    denominationCommercial: {
      type: String,
      default: null,
      required: false
    },
  },
  data() {
    return {
      contratSearch: null,
      familleDocuments: null,
      typeSelected: null,
      isFromMenu: true,
      showLoading: false,
      searchDate: '',
      selectionChangedValues: [],
      typesDocument: [],
      pageLength: 5,
      dir: false,
      selected: null,
      recherche:null,

      statut: [{
        1: 'EN ATTENTE DE TRANSMISSION',
        2: 'VALIDE',
        3: 'REFUSE',
        4: 'EN COURS DE TRAITEMENT',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-info',
        }],

      columns: [
        {
          label: 'Famille de document',
          field: 'FamilleDeDocument',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Type de document',
          field: 'TypeDeDocument',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Nom de document',
        //   field: 'NomDuDocument',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'N°(contrat, devis, reclamation, sinistre )',
          field: 'numProjet',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Date de l'ajout",
          field: 'CreeLe',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rows: [],
      rowsDocuments: [],
      rowsDocumentsKeep: [],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null,
      },

      // ---------
      collabortateurSelected: null,
      statutSelected: null,
      selectedrows_masse: [],
      selected: null,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'Supprimer', text: ' Supprimer ' }
      ],
    }
  },
  watch: {
    typeSelected() {
      this.filterData()
    },
    contratSearch() {
      this.filterData()
    },
    fresh: {
      deep: true,
      immediate: true,
      handler(){
        this.fetch_documents_tier()

      }
    },
    recherche(){
      this.filterData()
    },
    prospectChosen: {
      deep: true,
      immediate: true,
      handler(){
          this.fetch_documents_tier()
      }
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statutVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'EN ATTENTE DE TRANSMISSION': 'light-primary',
        REFUSE: 'light-danger',
        VALIDE: 'light-success',
        'EN COURS DE TRAITEMENT': 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  updated() {
    this.AutreDocumentNom()
  },
  beforeMount() {
    this.fetchDocumentNatureWithoutTypesDocumentsCabinet()
    // this.fetch_documents_tier()
    // this.docNatureFill()
  },
  methods: {
    countdocument(count){
      console.log('--------------------------------')
      this.$emit('refresh-data-documents',count)

    },
    action_masse(action) {
      switch (action) {
        case 'Supprimer':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              title: 'Êtes-vous sûr de vouloir supprimer les documents ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    const selected_doc = [];
                    for (let i = 0; i < this.selectedrows_masse.selectedRows.length; i++) {
                        selected_doc.push(this.selectedrows_masse.selectedRows[i].id)
                    }
                    this.$http
                        .post('document/deleteDocumentClientMasse/', { 'documents': selected_doc })
                        .then((res) => {
                          if (res.data.success) {
                            this.resetFilter()
                            this.fetch_documents_tier()
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: 'Supprimé!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
          }
          break
          default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params
    },
    resetFilter() {
      this.typeSelected = null
      this.contratSearch = null
      this.recherche = null
    },
    AutreDocumentNom() {
      this.rows.forEach(item=>(item.idTypeDeDocument === 25 || item.idTypeDeDocument === 54 || item.idTypeDeDocument === 55) ? item.TypeDeDocument =  item.NomDuDocument : item.TypeDeDocument )

    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument,ExtDuDocument) {
      var nomDocToDownload = ""
      if(NomDuDocument && ExtDuDocument) {
        nomDocToDownload = NomDuDocument + '.' + ExtDuDocument
      } else {
        nomDocToDownload = name
      }
      
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (res.data.data.isDownload) {
                this.downloadDocument(res.data.data.base64, nomDocToDownload)
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = nomDocToDownload
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = nomDocToDownload
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    deleteDocumentClient(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .post('document/deleteDocumentClient', { document_id: id })
            .then(res => {
              for (let i = 0; i < this.rows.length; i++) {
                if (this.rows[i].id == id) {
                  this.rows.splice(i, 1)
                  break
                }
              }
              for (let i = 0; i < this.rowsDocumentsKeep.length; i++) {
                if (this.rowsDocumentsKeep[i].id == id) {
                  this.rowsDocumentsKeep.splice(i, 1)
                  break
                }
              }
              this.countdocument(this.rowsDocumentsKeep.length)
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    recordDocumentClientReturned(data, message) {
      if (data.is_created){
        this.typeSelected = null
        this.contratSearch = null
        this.recherche = null
        //this.rows.push(data.data)
        this.rowsDocumentsKeep.push(data.data)
        this.countdocument(this.rowsDocumentsKeep.length)
        this.messageToast(message, 'Succès', 'success')
      }
    },
    // docNatureFill(sideBarIsActive) {
    //   const fetchDocumentNatureWithTypes = documentService()
    //   fetchDocumentNatureWithTypes
    //     .then(r => {
    //       this.famille_documents = r.data
    //       if (!sideBarIsActive) {
    //         r.data.forEach(el => {
    //           el.documentype.forEach(DocumentType => {
    //             this.typesDocument.push(DocumentType)
    //           })
    //         })
    //       }
    //     })
    // },
    fetchDocumentNatureWithoutTypesDocumentsCabinet() {
        this.$http
          .post('/document_nature/fetch_document_nature_without_types_documents_cabinet')
          .then(response => {
            this.familleDocuments = []

            response.data.forEach(el => {
              this.familleDocuments.push({
                value: { id: el.id, typeDocument: el.documentype },
                text: el.libelle
              })
            })
            
            response.data.forEach(el => {
              el.documentype.forEach(DocumentType => {
                if(![25,54,55].includes(DocumentType.id))
                this.typesDocument.push(DocumentType)
              })
            })

            this.typesDocument.push({id: "autre", type: "Autre document", document_nature_id: 0})
          })
          .catch(errors => {
            console.log(errors)
          });
    },

    
    fetch_documents_tier() {
      this.showLoading = true
      this.$store
          .dispatch('messageries/fetchDocumentClientByTier',
              {
                tier: {
                  id_client: this.prospectChosen.id,
                  type_client: this.prospectChosen.type,
                  name: ''
                }
              })
          .then(response => {
            let responseData = response.data
            // let projetValue = ''
            // responseData.forEach((element, index) => {

            //   let num_contrat = this.$_.get(element, 'source', '') == 'Interne' ? this.checkIsNull(this.$_.get(element, 'contrat_no', '')) : this.checkIsNull(this.$_.get(element, 'police_no', ''))
            //   let num_projet = this.$_.get(element, 'source', '') == 'Interne' ? this.checkIsNull(this.$_.get(element, 'devis_no_interne', '')) : this.checkIsNull(this.$_.get(element, 'devis_no_externe', ''))
            //   projetValue = num_contrat ? num_contrat : 'NC'
            //   projetValue +=  num_projet ? '/'+num_projet : '' //(this.$_.get(element, 'contrat_no', '') || this.$_.get(element, 'police_no', '')) && (this.$_.get(element, 'devis_no_interne', '') || this.$_.get(element, 'devis_no_externe', '')) ? ' / ' : '' 
            //   responseData[index].numProjet = projetValue
            // })
            // const index = responseData.
            this.rows = responseData
            this.rowsDocumentsKeep = responseData
            this.countdocument(this.rowsDocumentsKeep.length)
            this.AutreDocumentNom()
          })
          .finally(() => {
            this.showLoading = false
          })
    },
    filterData() {
      this.rows = this.rowsDocumentsKeep
      if(this.recherche) this.rows = this.rows.filter(item => item.TypeDeDocument.toLowerCase().includes(this.recherche.toLowerCase()))
      if (this.typeSelected) 
      {
        if(this.typeSelected != "autre")
        {
          this.rows = this.rows.filter(item => item.idTypeDeDocument === this.typeSelected)
        }
        else
        {
          this.rows = this.rows.filter(item => (item.idTypeDeDocument === 25 || item.idTypeDeDocument === 54 || item.idTypeDeDocument === 55))
        }
      }
      if (this.contratSearch) this.rows = this.rows.filter(item => 
      item.contrat_no?.toLowerCase().includes(this.contratSearch?.toLowerCase()) || item.police_no?.toLowerCase().includes(this.contratSearch?.toLowerCase()) || 
      item.devis_no_externe?.toLowerCase().includes(this.contratSearch?.toLowerCase()) || item.devis_no_interne?.toLowerCase().includes(this.contratSearch?.toLowerCase()))
    },
  },
}
</script>
