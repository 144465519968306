const SinistreOptions = {
    CANALS: [
        {
            value: null,
            text: 'Choisissez'
        },
        {
            value: 'COURRIER',
            text: 'Courrier'
        },
        {
            value: 'MAIL',
            text: 'Mail'
        },
        {
            value: 'ORAL',
            text: 'Oral'
        },
        {
            value: 'TELEPHONE',
            text: 'Téléphone'
        },
        {
            value: 'AUTRE',
            text: 'Autre'
        }
    ],

    CRMEXISTE: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
    ],

    CONTRATCRMEXISTE: [
        { value: true, text: 'Oui' },
        { value: false, text: 'Non' }
    ],

    PROSPECTBOOLEAN: [
        { value: 1, text: 'Oui' },
        { value: 0, text: 'Non' }
    ],

    STATUS: [
        { value: null, text: '-- Choisissez --' },
        { value: 'EN_COURS', text: 'En cours' },
        { value: 'CLOS', text: 'Clos' },
        { value: 'REOUVERT', text: 'Réouvert ' },
    ],
    TYPES: [
        { value: null, text: '-- Choisissez --' },
        { value: 'DOMMAGE_CORPOREL', text: 'Dommage corporel' },
        { value: 'DOMMAGE_MATERIEL', text: 'Dommage matériel' },
        { value: 'DOMMAGE_CORPOREL_ET_MATERIEL', text: 'Dommage corporel et matériel' },
    ],

}
export default SinistreOptions;