<template>
  <div>
    <b-sidebar
        id="sidebar-add-new-event"
        :visible="isEventHandlerSidebarActive"
        backdrop
        bg-variant="white"
        no-header
        right
        shadow
        sidebar-class="sidebar-lg"
        @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Mettre à jour' : 'Ajouter' }} événement
          </h5>
          <div>
<!--            <feather-icon-->
<!--                v-if="eventLocal.id"-->
<!--                class="cursor-pointer"-->
<!--                icon="TrashIcon"-->
<!--                @click="$emit('remove-event'); hide();"-->
<!--            />-->
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
        <div>
        <b-alert
            v-if="!isSynch"
            variant="warning"
            show
            class="m-2"
        >
          <div class="alert-body">
            <feather-icon
                icon="AlertCircleIcon"
                class="mr-50"
            /><span> Il semble que vous n'avez pas encore synchronisé votre boite, vos invitées ne seront pas notifiés de l'évènement créé.</span><p class="mt-1">Pour bénéficier de la fonctionnalité veuillez synchroniser votre boîte mail dans le menu Ma messagerie.</p>
          </div>
        </b-alert>
<!--        <b-alert
            v-else
            variant="success"
            show
        >
          <div class="alert-body">
            <feather-icon
                icon="CheckCircleIcon"
                class="mr-50"
            /><span> Votre boîte mail est synchronisée.</span>
          </div>
        </b-alert>-->
        </div>
        <!-- Body -->
        <validation-observer
            ref="refFormObserver"
            #default="{ handleSubmit }"
        >

          <!-- Form -->
          <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
          >
            <!-- Type d'événement -->
            <validation-provider
                #default="validationContext"
                name="type d'événement"
                rules="required"
            >

              <b-form-group
                  :state="getValidationState(validationContext)"
                  label="Type d'événement"
                  label-for="calendar"
              >
                <v-select
                    v-model="eventLocal.extendedProps.calendar"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="calendarOptions"
                    :reduce="calendar => calendar.label"
                    input-id="calendar"
                    label="label"
                >

                  <template #option="{ color, label }">
                    <div
                        :class="`bg-${color}`"
                        class="rounded-circle d-inline-block mr-50"
                        style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                        :class="`bg-${color}`"
                        class="rounded-circle d-inline-block mr-50"
                        style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                  <div slot="no-options">Aucune commercial disponible.</div>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title -->
            <validation-provider
                #default="validationContext"
                name="Titre"
                rules="required"
            >
              <b-form-group
                  label="Titre"
                  label-for="event-title"
              >
                <b-form-input
                    id="event-title"
                    v-model="eventLocal.title"
                    :state="getValidationState(validationContext)"
                    autofocus
                    placeholder="Titre de l'événement"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Date de début -->
            <validation-provider
                #default="validationContext"
                name="Date de début"
                rules="required"
            >
              <b-form-group
                  :state="getValidationState(validationContext)"
                  label="Date de début"
                  label-for="start-date"
              >
                <flat-pickr
                    v-model="eventLocal.start"
                    :config="eventLocal.allDay ? configDateNotTime : configDate"
                    class="form-control"
                    :class="!datesChecker(eventLocal.start, eventLocal.end) ? 'is-invalid' : ''"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <template v-if="eventLocal.allDay">
              <b-form-group
                  label="Date de fin"
                  label-for="end-date"
              >
                <flat-pickr
                    v-model="eventLocal.end"
                    :config="configDateNotTime"
                    class="form-control"
                    disabled
                />
              </b-form-group>
            </template>
            <template v-else>
              <!-- Date de fin -->
            <validation-provider
                #default="validationContext"
                name="Date de fin"
                rules="required"
            >

              <b-form-group
                  :state="getValidationState(validationContext)"
                  label="Date de fin"
                  label-for="end-date"
              >
                <flat-pickr
                    v-model="eventLocal.end"
                    :config="configDate"
                    class="form-control"
                    :class="!datesChecker(eventLocal.start, eventLocal.end) ? 'is-invalid' : ''"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!datesChecker(eventLocal.start, eventLocal.end) ? false : null"> Date fin doit être après la date début
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </template>


            <!-- All Day -->
            <b-form-group>
              <b-form-checkbox
                  v-model="eventLocal.allDay"
                  inline
                  name="check-button"
                  switch
              >
                Toute la journée
              </b-form-checkbox>
            </b-form-group>

            <!-- URL d'événement -->
            <validation-provider
                #default="validationContext"
                name="Lien internet"
                rules="url"
            >

              <b-form-group
                  label="Lien internet"
                  label-for="event-url"
              >
                <b-form-input
                    id="event-url"
                    v-model="eventLocal.extendedProps.eventUrl"
                    :state="getValidationState(validationContext)"
                    placeholder="htttps://www.google.com"
                    trim
                    type="url"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Guests -->
<!--            <validation-provider
                #default="validationContext"
                name="des invités"
                rules="required"
            >-->
            <b-form-group
                label="Ajouter des invités"
                label-for="add-guests"
                v-show="true"
            >
              <v-select
                  v-model="eventLocal.extendedProps.guests"
                  :close-on-select="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="guestsOptions"
                  input-id="add-guests"
                  label="name"
                  multiple
                  taggable
                  push-tags
              >

                <template #option="{ avatar, name, type_personne, categorie_client }">
                  <b-avatar
                      :src="avatar"
                      size="sm"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                  <b-badge class="mr-2 float-right" v-if="type_personne === 'Utilisateur'" :variant="sourceVariant(type_personne)"> Collaborateur </b-badge>
                  <b-badge class="mr-2 float-right" v-else-if="type_personne === 'Client'" :variant="sourceVariant(categorie_client)"> {{ capitalizeFirstLetter(categorie_client) }} </b-badge>
                  <span class="mr-2 float-right" v-else> </span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                      :src="avatar"
                      class="border border-white"
                      size="sm"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
                <div slot="no-options">Aucune invité disponible.</div>
              </v-select>
<!--              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>-->
            </b-form-group>
<!--            </validation-provider>-->

            <!-- Location -->
            <b-form-group
                label="Emplacement"
                label-for="event-location"
            >
              <b-form-input
                  id="event-location"
                  v-model="eventLocal.extendedProps.location"
                  placeholder="Lieu de l'événement"
                  trim
              />
            </b-form-group>

            <b-form-group
                label="Modèle"
                label-for="event-modele"
            >
              <b-form-select v-model="modelsSelected" size="sm" class="form-control">
                <template #first>
                  <b-form-select-option :value="{}">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option :value="model" v-for="(model, i) in modelsOptions" :key="i">{{ model.modele }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <!-- Textarea -->
<!--            <b-form-group
                label="Message"
                label-for="event-description"
            >
              <b-form-textarea
                  id="event-description"
                  v-model="eventLocal.extendedProps.description"
              />
            </b-form-group>-->
            <b-form-group
                label="Message"
                label-for="event-description"
            >
              <div class="message-editor">
                <quill-editor id="event-description" v-model="eventLocal.extendedProps.description" :options="snowOption" />
              </div>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-2"
                  type="submit"
                  variant="primary"
              >
                {{ eventLocal.id ? 'Mettre à jour' : 'Ajouter ' }}
              </b-button>
              <b-button v-if="eventLocal.id && currentUser_id == eventLocal.extendedProps.createur"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="warning"
                  @click="deleteEvenement"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BBadge,
  BSidebar,
  BAlert,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required, url } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs, watch } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import { Vue } from 'vue-property-decorator'
import store from '@/store'

export default {
  components: {
    quillEditor,
    BButton,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    BBadge,
    flatPickr,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    prospectSelected: {
      type: Object,
        required: false,
      default: null
    }
  },
  watch: {
    isEventHandlerSidebarActive: {
      handler(val) {
        if(val && this.prospectSelected !== null) {
          this.eventLocal.extendedProps.guests.push(this.initialProspectSelected(this.prospectSelected))
        }
      }
    },
    "eventLocal.allDay":{
      immediate:true,
      handler(val){
        if (val) {
          this.eventLocal.end = null
          this.event.end = null
        }
      }
    },
    "event.allDay":{
      immediate:true,
      handler(val){
        if (val) {
          this.eventLocal.end = null
          this.event.end = null
        }
      }
    }
  },
  data() {
    return {
      currentUser_id: null,
      isSynch: false,
      required,
      email,
      url,
      configDate: {
        dateFormat: 'd/m/Y H:i',
        enableTime: true,
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      configDateNotTime: {
        dateFormat: 'd/m/Y',
        enableTime: true,
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      snowOption: {
        theme: 'snow',
        placeholder: 'Rédiger le message ici...'
      },
    }
  },
  mounted() {
    this.checkerIsUserHaveBoiteMailSynch()
    this.currentUser_id = JSON.parse(localStorage.getItem('userData')).courtier_user[0].user_id
  },
  beforeMount() {
    if (this.$route.params && this.$route.params.event) {
      this.eventLocal = this.$route.params.event
    }
  },
  methods:{
    checkerIsUserHaveBoiteMailSynch(){
      this.$http.get(`/boite_mail/checkerIsUserHaveBoiteMailSynch`)
          .then(res => {
              this.isSynch = res.data > 0 ? true : false
          })
          .catch(err => {
            this.isSynch = false
                console.log(err)
          })
    },
    annulerEvenement(id,type)
    {
      this.$http.delete(`/calendar/annulerEvenement/${id}/${type}`)
          .then(res => {
              this.$emit('remove-event');
          })
          .catch(err => {
                console.log(err)
          })
    },
    deleteEvenement(){
      this.$swal({
      title: 'Etes-vous sûr de vouloir annuler cet événement ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1'
      },
      buttonsStyling: false
    })
        .then(
          result => {
            if (result.value) {
              this.onDelete();
            }
          }
        )
    },
    initialProspectSelected(prospectData) {
      let prospect = {}
      if (prospectData.type == "PERSONNE_MORALE") {
        prospect = {
          "avatar": null,
          "user_id": null,
          "courtier_user_id": null,
          "id_client": prospectData.id,
          "email": prospectData.email,
          "name": prospectData.denominationCommercial,
          "categorie_client": prospectData.categorie,
          "type_client": prospectData.type,
          "type_personne": 'Client'
        }
      } else {
        prospect = {
          "avatar": null,
          "email": prospectData.moyen_contact.email,
          "name": prospectData.nom + ' ' + prospectData.prenom,
          "id_client": prospectData.id,
          "user_id": prospectData.user_id,
          "type_client": prospectData.type,
          "courtier_user_id": prospectData.user ? prospectData.user.courtier_user[0].id : null,
          "categorie_client": prospectData.user_id ? "Collaborateur" : "PARTICULIER",
          "type_personne": prospectData.user_id ? "Utilisateur" : "Client"
        }
      }
      return prospect
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const modelsSelected = ref({})
    const modelsOptions = ref([])

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      onDelete,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    const fetchModelsByCourtier = () => {
      store.dispatch('messageries/fetchModelsByCourtier').then(response => {
        modelsOptions.value = response.data.data
      })
    }

    fetchModelsByCourtier()
    watch(
        () => modelsSelected.value,
        val => {
          eventLocal.value.extendedProps.description = val.corps_mail
        },
        { immediate: true },
        { deep: true }
    )
    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      onDelete,
      guestsOptions,

      // Fields
      modelsSelected,
      modelsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
