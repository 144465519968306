
<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <app-collapse accordion type="margin" >
      <updated-fiche-professionel-prospect-lien  
                 :prospect-principal-data="prospectPrincipal"
                 :prospect-data="prospectLocal" 
                 @update-client-pro-returned="UpdateClientProReturned"
      >
      </updated-fiche-professionel-prospect-lien>
      <app-collapse-item title="Conjoint" v-if="particulierDataConjoint.length > 0">
        <card-connexite :particulier-data="particulierDataConjoint" @update-connexite-prospect="editProspect"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="particulierColumns"
              :rows="particulierDataConjoint"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'lienable.nom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.nom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.prenom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.prenom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
              </div>
              <span v-else-if="props.column.field === 'lien_familial'">
                <b-badge :variant="sourceVariant(props.row.lien_familial)">
                  {{ capitalizeFirstLetter(props.row.lien_familial) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="editProspect(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Éditer</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeConnexite(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Supprimer la connexité</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
              </template>
              <!-- <template slot="pagination-bottom" slot-scope="props">
                <b-row class="align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template> -->
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
      </app-collapse-item>
      <app-collapse-item title="Enfants" v-if="particulierDataEnfants.length > 0">
        <card-connexite :particulier-data="particulierDataEnfants" @update-connexite-prospect="editProspect"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="particulierColumns"
              :rows="particulierDataEnfants"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'lienable.nom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.nom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.prenom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.prenom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
              </div>
              <span v-else-if="props.column.field === 'lien_familial'">
                <b-badge :variant="sourceVariant(props.row.lien_familial)">
                  {{ capitalizeFirstLetter(props.row.lien_familial) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="editProspect(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Éditer</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeConnexite(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Supprimer la connexité</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <b-row class="align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
      </app-collapse-item>
      <app-collapse-item title="Parents" v-if="particulierDataParent.length > 0">
        <card-connexite :particulier-data="particulierDataParent" @update-connexite-prospect="editProspect"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="particulierColumns"
              :rows="particulierDataParent"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'lienable.nom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.nom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.prenom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.prenom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
              </div>
              <span v-else-if="props.column.field === 'lien_familial'">
                <b-badge :variant="sourceVariant(props.row.lien_familial)">
                  {{ capitalizeFirstLetter(props.row.lien_familial) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="editProspect(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Éditer</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeConnexite(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Supprimer la connexité</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <b-row class="align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
      </app-collapse-item>
      <app-collapse-item title="Amis" v-if="particulierDataAmi.length > 0">
        <card-connexite :particulier-data="particulierDataAmi" @update-connexite-prospect="editProspect"
            @remove-connexite-prospect="removeConnexite"
            @go-to-another-fiche-client="showProspect" />
            <vue-good-table
              v-show="false"
              :columns="particulierColumns"
              :rows="particulierDataAmi"
              :rtl="direction"
              style-class="vgt-table condensed"
              :pagination-options="{
                enabled: false,
                mode: 'records',
                perPage: pageLength,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
            >
              <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'lienable.nom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.nom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.prenom'" @click="showProspect(props.row.lienable.isVisible, props.row.lienable.id, props.row.lienable.categorie, props.row.lienable.type)">
                <a :style="[{'text-decoration': 'underline'}]">
                  {{ props.row.lienable.prenom }}
                </a>
              </div>
              <div v-else-if="props.column.field == 'lienable.statut_fiche'">
                <b-badge :variant="props.row.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(props.row.lienable.statut_fiche)">{{ props.row.lienable.statut_fiche}}</b-badge>
              </div>
              <span v-else-if="props.column.field === 'lien_familial'">
                <b-badge :variant="sourceVariant(props.row.lien_familial)">
                  {{ capitalizeFirstLetter(props.row.lien_familial) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="editProspect(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Éditer</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeConnexite(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Supprimer la connexité</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <b-row class="align-items-center">
                  <b-col>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {  BDropdown, BDropdownItem, BPagination,BFormRadioGroup, BFormRadio, BFormGroup,BCol,BRow,BBadge,BOverlay} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Data from "@/shared/constants/prospect";
import UpdatedFicheProfessionelProspectLien from '../actions-fiche-professionel/UpdatedFicheProfessionelProspectLien.vue'
import store from '@/store'
import CardConnexite from '../../global-components/CardConnexite.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow,
    BBadge,
    VueGoodTable,
    BDropdown, 
    BDropdownItem, 
    BPagination,
    UpdatedFicheProfessionelProspectLien,
    BOverlay,
    CardConnexite
  },
  props: {
    tiersData: {
      type: Object,
      default: {},
      required: true
    }
  },
  computed:{
    getFormeJuridique: function () {
    let findEntreprise = this.FormJuridique.find(obj => {
           return obj.value === this.entreprise.forme_juridique;
        }); 
        return findEntreprise ? findEntreprise.text : ''
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    // particulierDataConjoint: function () {
    //   return this.particulierData.filter(value => {
    //       return value.lien_familial == 'CONJOINT';
    //   }); 
    // },
    particulierDataConjoint: function () {
      return this.particulierData.filter(value => {
          return value.lien_familial == 'CONJOINT';
      }); 
    },
    particulierDataEnfants: function () {
      return this.particulierData.filter(value => {
          return value.lien_familial == 'ENFANT';
      }); 
    },
    particulierDataParent: function () {
      return this.particulierData.filter(value => {
          return value.lien_familial == 'PARENT';
      }); 
    },
    particulierDataAmi: function () {
      return this.particulierData.filter(value => {
          return value.lien_familial == 'AMI';
      }); 
    },
  },
  data() {
    return {
      showLoading: false,
      dir: false,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      FormJuridique : Data["FORMEJURIDIQUE"],
      villeOptions: [],
      codeNafOptions: [],
      isInvalideMailandPhone:false,
      particulierData:[],
      pageLength: 3,
      prospectPrincipal:{},
      prospectLocal:{
        id:null,
        type:null
      },
      particulierColumns: [
        // { label: 'objet de la formation ', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Civilité', field: 'lienable.civilite', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Nom', field: 'lienable.nom', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Prénom', field: 'lienable.prenom', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Statut', field: 'lienable.statut_fiche', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Date de naissance', field: 'lienable.date_naissance', type: 'date', dateInputFormat: 'yyyy-MM-dd', dateOutputFormat: 'dd/MM/yyyy', sortable: false, maxDate:'today', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Famille', field: 'lien_familial', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
    }
  },
  watch: {
    tiersData: {
      deep: true,
      handler() {
        this.affectedDataTierToComposeData()
      }
    },
    'professionnel.code_postal': {
      immediate: true,
      handler(val) {
        if (val) this.GetVille(val)
      }
    },
  },
  mounted(){
    // this.GetCodeNAF()
  },
  methods: {
    GetCodeNAF(){
      this.$http.get(`tiers/getCodeNAf`)
          .then((r) => {
            this.codeNafOptions = r.data
          })
          .catch((e)=>{
            console.log(e);
          })
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villeOptions = []
      }
    },
    UpdateClientProReturned(data, message){
      this.$emit('update-client-pro-returned',data,message)
    },
    affectedDataTierToComposeData() {
      this.particulierData = this.tiersData.prospect.filter(obj => {
          return obj.lien_professionnel == null  ; 
      });
    },
    hideSideBare() {
      this.$refs.sideBarClientEntreprise.hide()
    },
    sourceVariant(status) {
      const statusColor = {
        CONJOINT: 'light-primary',
        ENFANT: 'light-warning',
        PARENT: 'light-danger',
        AMI: 'light-success',
      }
      return statusColor[status]
    },
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          Aucun: 'light-dark',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    updateTiersEntreprise() {
      this.$refs.clientEntrepriseComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              this.$http
                  .post('/prospect/updateTiersEntreprise', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.$emit('update-client-entreprise-returned', res.data.data, res.data.message)
                      this.hideSideBare()
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    },
    editProspect(data){
        this.prospectLocal.id=this.tiersData.id
        this.prospectLocal.type=this.tiersData.type
        this.prospectPrincipal = data.lienable
        this.$root.$emit('bv::toggle::collapse', 'sidebar-client-pro-prospect-custom')
    },
    removeConnexite(data){
      let meta ={
        prospect:{id:this.tiersData.id,type:this.tiersData.type},
        connexite:{id:data.lienable.id,type:data.lienable.type},
        lien_familial:{lien_familial:data.lien_familial},
        lien_professionnel:{lien_professionnel:data.lien_professionnel}
      }

      this.$swal({
        title: "Êtes-vous sûr ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.showLoading=true
          this.$http
            .post('/prospect/removeConnexite', meta)
            .then(res => {
              if (res.data) {
                this.$emit('update-client-pro-returned', res.data.data, res.data.message)
                this.showLoading=false
              }
            })
            .catch(err => {
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      });
    },
    showProspect(isVisible, prospectId, prospectCategorie, prospectType) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    },
  }
}
</script>
<style>
.vgt-responsive{
  min-height: 0px;
}
</style>