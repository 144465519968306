<template>
    <b-row>
        <b-col sm="6" md="6" lg="4" xl="3" v-for="particulier in particulierData" :key="particulier.id">
            <b-card class="card-app-design card-connexite py-2 px-1">

                <b-row >
                    <b-col cols="10">
                        <b-badge
                            :variant="particulier.lienable.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(particulier.lienable.statut_fiche)">
                            {{ particulier.lienable.statut_fiche}}</b-badge>
                    </b-col>
                    <b-col cols="2">
                        <div class="text-right">
                            <span>
                            <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                                <template v-slot:button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16"
                                        class="text-body align-middle mr-25" />
                                </template>
                                <!-- <b-dropdown-item v-if="particulier.lienable.statut_gda!='ORANGE' && particulier.lienable.statut_gda!='ROUGE'" @click="editProspect(particulier)">
                                    <feather-icon icon="EditIcon" class="mr-50" />
                                    <span>Éditer</span>
                                </b-dropdown-item> -->
                                <b-dropdown-item @click="removeConnexite(particulier)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Supprimer la connexité</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            </span>
                        </div>
                    </b-col>
                </b-row>             
                <b-card-title class="mt-1 mb-2 text-truncate cursor-pointer" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="particulier.lienable.type == 'PERSONNE_PHYSIQUE' ? (particulier.lienable.civilite ? particulier.lienable.civilite : '') + ' ' + particulier.lienable.prenom + ' ' + particulier.lienable.nom : particulier.lienable.denomination_commercial">
                    
                    <span class="orange-dot" v-if="particulier.lienable.statut_gda=='ORANGE'" v-b-tooltip.hover.top="'Gel des avoirs : A vérifier'" v-ripple.400="'rgba(255, 255, 255, 0.15)'" >
                            </span>
                            <span class="gray-dot" v-if="particulier.lienable.statut_gda=='GRIS'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                            </span>
                            <span class="white-dot" v-if="particulier.lienable.statut_gda=='BLANC'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                            </span>
                            <span class="green-dot" v-if="particulier.lienable.statut_gda=='VERT'" v-b-tooltip.hover.top="'Gel des avoirs : Contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                            </span>
                            <span class="red-dot" v-if="particulier.lienable.statut_gda=='ROUGE'" v-b-tooltip.hover.top="'Gel des avoirs : Interdit'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                        </span>{{ particulier.lienable.type == 'PERSONNE_PHYSIQUE' ? (particulier.lienable.civilite ? particulier.lienable.civilite : '') + ' ' + particulier.lienable.prenom + ' ' + particulier.lienable.nom : particulier.lienable.denomination_commercial }}
                        <b-row>
                            <b-col>
                                <div>
                                    <div v-if="particulier.lienable.type == 'PERSONNE_PHYSIQUE'">
                                        <b-badge v-if="particulier.lienable.isBloctel" variant="light-info" class="custom-badge-small"> BLOCTEL <span v-if="particulier.lienable.date_bloctel">(depuis le {{ formattingDate(particulier.lienable.date_bloctel) }})</span> </b-badge> 
                                    </div>
                                </div> 
                            </b-col>                  
                        </b-row>
                        <b-row>
                            <b-col>
                                <div>
                                    <div v-if="particulier.lienable.type == 'PERSONNE_PHYSIQUE'">
                                        <div v-if="particulier.lienable.isPPE"><feather-icon icon="AlertTriangleIcon" class="mr-50 custom-badge-small" /> <span style="color:rgb(77 37 188); font-size:13px ;" mt="4">Personne politiquement exposée</span></div> 
                                    </div>    
                                </div>
                            </b-col>
                        </b-row>
                </b-card-title>
                        
                        
                <b-card-body class=" p-0 mb-2">  
                       

                    <div v-if="particulier.lienable.type == 'PERSONNE_PHYSIQUE'">
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="SidebarIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Catégorie
                                    </h6>
                                    <small>{{ particulier.lienable.categorie }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="SmartphoneIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Téléphone portable
                                    </h6>
                                    <small>{{ particulier.lienable.moyen_contact ? particulier.lienable.moyen_contact.tel : '' }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="MailIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Email
                                    </h6>
                                    <small>{{ particulier.lienable.moyen_contact ? particulier.lienable.moyen_contact.email : '' }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="UserIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Gestionnaire
                                    </h6>
                                    <small>{{ particulier.lienable.distributeur ? particulier.lienable.distributeur.user.personne_physique.prenom+ ' ' + particulier.lienable.distributeur.user.personne_physique.nom : '' }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                    </div>
                    <div v-if="particulier.lienable.type == 'PERSONNE_MORALE'">
                        <!-- <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="PhoneIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Téléphone
                                    </h6>
                                    <small>{{ particulier.lienable.moyen_contact.tel }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="MailIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Email
                                    </h6>
                                    <small>{{ particulier.lienable.moyen_contact.email }}</small>
                                </b-media-body>
                            </b-media>
                        </div> -->
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="SettingsIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Fonction
                                    </h6>
                                    <small>{{ setLienClient(particulier.lien_professionnel) }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="AwardIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Siret
                                    </h6>
                                    <small>{{ particulier.lienable.siret }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="ActivityIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Activité
                                    </h6>
                                    <small>{{ particulier.lienable.activite }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                        <div class="transaction-item mb-1">
                            <b-media no-body>
                                <b-media-aside>
                                    <b-avatar rounded size="42" variant="light-primary">
                                        <feather-icon size="18" icon="UserIcon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="transaction-title">
                                        Gestionnaire
                                    </h6>
                                    <small>{{ particulier.lienable.distributeur ? particulier.lienable.distributeur.user.personne_physique.prenom+ ' ' + particulier.lienable.distributeur.user.personne_physique.nom : '' }}</small>
                                </b-media-body>
                            </b-media>
                        </div>
                    </div>
                </b-card-body>

                <!-- button -->
                <b-button variant="primary" block
                    @click="showProspect(particulier.lienable.isVisible, particulier.lienable.id, particulier.lienable.categorie, particulier.lienable.type)">
                    Consulter la fiche
                </b-button>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import {
  BDropdown, BDropdownItem, BCol, BRow, BBadge, BCard, BCardBody, BCardTitle, BAvatar, BButton, BMediaBody, BMedia, BMediaAside, VBTooltip 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BRow,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BAvatar,
    BButton,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    particulierData: {
      type: Array,
      default: () => [],
      required: true
    },
    
    tiersData: {
      type: Object,
      default: {},
      required: true
    }
  },
  methods: {
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          Aucun: 'light-dark',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      } catch (e) {
        return null
      }
    },
    setLienClient(lien) {
      switch (lien) {
        case 'FILIALE_SOCIETE_MERE':
          return 'Filiale / Société mère'
        case 'FILIALE_DE':
          return 'Filiale de'
        case 'ACTIONNAIRE_DE':
          return 'Actionnaire de'
        case 'DETENU_PAR':
          return 'Détenu par'
        default:
          return lien
      }
    },
    editProspect(prospect) {
      this.$emit('update-connexite-prospect', prospect)
    },
    removeConnexite(prospect) {
      this.$swal({
        html: `<p class="text-justify" style="font-size: 15px">Supprimer la connexité, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
                    <br><br>
                    Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
                    <br><br>
                    Souhaitez-vous confirmer cette modification ?</p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          html: 'text-left'
        },
        buttonsStyling: false
      })
        .then(confirmed => {
          if (confirmed.value) {
            this.$emit('remove-connexite-prospect', prospect)
          }
        })
        
    },
    showProspect(isVisible, prospectId, prospectType, prospectCategorie) {
      this.$emit('go-to-another-fiche-client', isVisible, prospectId, prospectType, prospectCategorie)
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("-");
        date_formatting =
          date_formatting[2] +
          "/" +
          date_formatting[1] +
          "/" +
          date_formatting[0];
        return date_formatting;
      }
      return null;
    }
  }
}
</script>
<style scoped>
    .card-connexite {
        margin-bottom: 1.8rem!important;
    }
    .card-connexite h4.card-title {
        padding: 7px 6px;   
    }
    .card-connexite h4.card-title:hover {
        background-color: rgb(233 233 233 / 18%);  
        transition: .3s all;
    }
    .custom-badge-small {
     font-size: 12px; /* Adjust the font size as needed */
     }
</style>
