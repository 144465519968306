<template>
  <div>
    <b-card class="mb-2 custom-card-body">
      <b-card-text>
        <b-row>
          <b-col md="12" sm="12" class="p-0">
            <div class="d-flex bd-highlight">
              <!-- <div class="bd-highlight text-uppercase h3 font-weight-bold">{{ tiersData.denominationCommercial }}</div> -->
              <div class="ml-auto bd-highlight">
                <div class="demo-inline-spacing">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    v-b-toggle.sidebar-client-entreprise
                    v-if="$_.get(tiersData,'statut_gda')!='ORANGE' && $_.get(tiersData,'statut_gda')!='ROUGE'"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span  class="align-middle text-capitalize">Modifier</span>
                  </b-button>
                  <div v-if="$_.get(tiersData,'statut_gda')==='ORANGE'">
                              <b-button 
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="primary"
                              size="sm"
                              v-b-toggle.sidebar-client-identite>
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-50"/>
                          <span  class="align-middle text-capitalize">Vérification de l’identité</span>
                      </b-button>
                  </div>
                 
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)' || currentUser.courtier_user[0].isPrincipal === 1"
                      :aria-expanded="String(isEventHandlerSidebarActive)"
                      aria-controls="sidebar-add-new-event"
                      size="sm"
                      variant="primary"
                      @click="isEventHandlerSidebarActive = true"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                    <span class="align-middle text-capitalize">Ajouter un évènement</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    v-b-toggle.sidebar-client-pro-connexite
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Ajouter Connexité</span>
                  </b-button>
                </div>
                <update-fiche-professionel :tiers-data="tiersData" @update-client-pro-returned="UpdateClientProReturned"/>
                <add-verification-identite  :tiers-data="tiersData" @update-client-pro-returned="UpdateClientProReturned"/>

                <add-fiche-professionel-connexite :tiers-data="tiersData" :code-naf-data="codeNafData" :prospect-options="prospectOptions" @update-client-pro-returned="UpdateClientProReturned"/>
              </div>
            </div>
          </b-col>


          <b-col cols="12">
            <h4 class="font-weight-bolder">
              <!-- <feather-icon
                icon="AIcon"
              /> -->
              <span class="orange-dot" v-if="$_.get(tiersData,'statut_gda')=='ORANGE'" v-b-tooltip.hover.top="'Gel des avoirs : A vérifier'" v-ripple.400="'rgba(255, 255, 255, 0.15)'" >
                  </span>
                  <span class="gray-dot" v-if="$_.get(tiersData,'statut_gda')=='GRIS'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                  </span>
                  <span class="white-dot" v-if="$_.get(tiersData,'statut_gda')=='BLANC'" v-b-tooltip.hover.top="'Gel des avoirs : Non contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                  </span>
                  <span class="green-dot" v-if="$_.get(tiersData,'statut_gda')=='VERT'" v-b-tooltip.hover.top="'Gel des avoirs : Contrôlé'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                  </span>
                  <span class="red-dot" v-if="$_.get(tiersData,'statut_gda')=='ROUGE'" v-b-tooltip.hover.top="'Gel des avoirs : Interdit'" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
              </span>
              {{
                tiersData.civilite ? tiersData.civilite : ''
              }}
              {{
                $_.get(tiersData, "nom", "") +
                " " +
                $_.get(tiersData, "prenom", "")
              }} 
                <b-badge v-if="$_.get(tiersData, 'isBloctel')" variant="light-info"> BLOCTEL <span v-if="$_.get(tiersData, 'date_bloctel')">(depuis le {{formattingDate($_.get(tiersData, 'date_bloctel'))}})</span>  </b-badge> 
            </h4>
            <div v-if="$_.get(tiersData, 'isPPE')" >
                  <feather-icon icon="AlertTriangleIcon" /><span style="color:rgb(77 37 188) ;"> Personne politiquement exposée</span> 
              </div>
            <b-row>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Né(e) le : </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formattingDate(
                          $_.get(
                            tiersData,
                            "date_naissance",
                            null
                          )
                        )
                      }}
                    </td>
                  </tr> -->
                   <tr>
                      <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Nom de naissance : </span
                      >
                    </th>
                    <td>
                     {{
                        $_.get(
                          tiersData,
                          "nom_naissance",
                           ""
                        ) ?   $_.get(
                          tiersData,
                          "nom_naissance",
                           ""
                        ).toUpperCase():""
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date de naissance : </span
                      >
                    </th>
                    <td class="pb-50">
                      {{
                      formattingDate($_.get(tiersData, "date_naissance", "")) ?
                      formattingDate($_.get(tiersData, "date_naissance", "")) + ' '+'('+ calculateAge($_.get(tiersData, "date_naissance", "")) + ' ans'+')' :
                      ''
                    }}
                    </td>
                  </tr>

                  <tr>
                      <th class="pb-50" > <span class="font-weight-bolder"
                        >Code postal de naissance : </span
                      ></th>
                    <td>
                     {{
                        $_.get(
                          tiersData,
                          "code_postal_naissance",
                           ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                      <th class="pb-50" > <span class="font-weight-bolder"
                        >Commune de naissance : </span
                      ></th>
                    <td>
                     {{
                        $_.get(
                          tiersData,
                          "commune_naissance",
                           ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                     <th class="pb-50" > <span class="font-weight-bolder"
                        >Pays de naissance : </span
                      ></th>
                    <td>
                     {{
                        $_.get(
                          tiersData,
                          "pays_naissance",
                           ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Régime de sécurité sociale : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{

                        labelRegimeSocial($_.get(
                          tiersData,
                          "regime_obligatoire",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Catégorie socioprofessionnelle : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{

                        labelActivitePro($_.get(
                          tiersData,
                          "statut",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Domaine d’activité : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{

                    $_.get(
                          tiersData,
                          "secteur_activite",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="$_.get(
                          tiersData,
                          'categorie')=='PROFESSIONNEL' ">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Statut juridique : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">                   
                      {{
        
                        labelStatutJuridique($_.get(
                          tiersData,
                          "statut_juridique",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
               
                </table>
              </b-col>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Téléphone portable : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.indicatif_tel",
                          ""
                        ) &&  $_.get(
                          tiersData,
                          "moyen_contact.tel",
                          ""
                        ) ? '('+$_.get(
                          tiersData,
                          "moyen_contact.indicatif_tel",
                          ""
                        )+') '+ $_.get(
                          tiersData,
                          "moyen_contact.tel",
                          ""
                        ).substring(1)  : $_.get(
                          tiersData,
                          "moyen_contact.tel",
                          ""
                        ) 
                      }}
                      <!-- {{
                        $_.get(
                          tiersData,
                          "moyen_contact.indicatif_tel",
                          ""
                        ) ?
                        $_.get(
                          tiersData,
                          "moyen_contact.tel",
                          ""
                        ).substring(1) 
                        :  $_.get(
                          tiersData,
                          "moyen_contact.tel",
                          ""
                        ) 
                      }} -->
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Téléphone fixe : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                          {{
                        $_.get(
                          tiersData,
                          "moyen_contact.indicatif_tel_sec",
                          ""
                        ) &&   $_.get(
                          tiersData,
                          "moyen_contact.tel_secondaire",
                          ""
                        ) ?
                        '('+$_.get(
                          tiersData,
                          "moyen_contact.indicatif_tel_sec",
                          ""
                        )+') ' +   $_.get(
                          tiersData,
                          "moyen_contact.tel_secondaire",
                          ""
                        ).substring(1): $_.get(
                          tiersData,
                          "moyen_contact.tel_secondaire",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Email : </span>
                    </th>
                    <td class="pb-50 text-lowercase">
                      <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="$emit('show-email')">
                        {{ $_.get(tiersData,'moyen_contact.email', '') }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Adresse complète : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.libelle",
                          ""
                        )
                      }}
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.complement_adresse",
                          ""
                        )
                      }}
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.lieu_dit_ou_bp",
                          ""
                        )
                      }}
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.code_postal",
                          ""
                        )
                      }}
                      {{
                        $_.get(
                          tiersData,
                          "moyen_contact.ville.commune",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Situation familiale : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          tiersData,
                          "situation_familiale",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Enfants à charge : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          tiersData,
                          "enfantCharge",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                    <!-- <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Origine : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.replace($_.get(tiersData, "origine",""), '_', ' ') }}
                    </td>
                  </tr> -->
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                            >Numéro de sécurité social : </span
                          >
                    </th>
                      <td class="pb-50 text-capitalize">
                        {{
                          $_.get(
                            tiersData,
                            "num_secu",
                            ""
                          )
                        }}                      
                      </td>
                    </tr>

                  
                </table>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col md="12" sm="12">
            <Entreprise-fiche :tiers-data="tiersData"  />
          </b-col> -->
          <b-col md="12" sm="12">
            <Connexite-professionelles-fiche :tiers-data="tiersData" :code-naf-data="codeNafData" @update-client-pro-returned="UpdateClientProReturned" @go-to-another-fiche-client="goToAnotherFicheClient" />
          </b-col>
          <b-col md="12" sm="12">
            <Connexite-personnelles-fiche :tiers-data="tiersData" @update-client-pro-returned="UpdateClientProReturned" @go-to-another-fiche-client="goToAnotherFicheClient" />
          </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xl="12">
                <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1 mt-2"><u>Origine</u></h3>
          </b-col>
            <b-col cols="12" xl="8">
              <table class="mt-2 mt-xl-0 w-100">

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Crée par : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.replace($_.get(tiersData, "createur_personne_physique.user.personne_physique.prenom",""), '_', ' ') + ' ' + $_.get(tiersData, "createur_personne_physique.user.personne_physique.nom","") }}
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Intermédiaire(s) : </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      <template v-for="intermediaire in tiersData.courtiers_users_by_intermediares">
                        {{ intermediaire.user.personne_physique.prenom.replace('_', ' ') }} {{ intermediaire.user.personne_physique.nom.replace('_', ' ') }} <br>
                      </template>
                    </td>
                  </tr>

                  

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Type : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.replace($_.get(tiersData, "origine",""), '_', ' ') }}
                    </td>
                  </tr>

              </table>
            </b-col>
         
        </b-row>
      </b-card-text>
    </b-card>
    <calendar-event-handler v-model="isEventHandlerSidebarActive" :event="event" :clear-event-data="clearEventData" @add-event="addEvent" :prospect-selected="tiersData" @go-to-another-fiche-client="goToAnotherFicheClient" />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { BBadge, BButton, BCard, BCardText, BCol, BRow, VBToggle, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EntrepriseFiche from './EntrepriseFiche.vue'

import ConnexitePersonnellesFiche from './connexites-fiche-professionel/ConnexitePersonnellesFiche.vue'
import ConnexiteProfessionellesFiche from './connexites-fiche-professionel/ConnexiteProfessionellesFiche.vue'
// import updateFicheProfessionel from './actions-fiche-professionel/UpdateFicheProfessionel.vue'
import updateFicheProfessionel from './actions-fiche-professionel/UpdatedFicheProfessionel.vue'
import addFicheProfessionelConnexite from './actions-fiche-professionel/AddFicheProfessionelConnexite.vue'
import Besoin from "@/shared/constants/risquebesoin"
import moment from "moment";
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './../../calendries/calendarStoreModule'
import CalendarEventHandler from './../../calendries/calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './../../calendries/useCalendar'
import Activite from "@/shared/constants/activite"
import StatutJuridique from "@/shared/constants/statutjuridique"
import AddVerificationIdentite from './actions-fiche-professionel/AddVerificationIdentite.vue'
export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    FullCalendar,
    // UI
    EntrepriseFiche,
    updateFicheProfessionel,
    addFicheProfessionelConnexite,
    ConnexitePersonnellesFiche,
    ConnexiteProfessionellesFiche,
    CalendarEventHandler,
    AddVerificationIdentite,
},
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    hasEntreprise: {
      type: Boolean,
      required: false,
      default: false
    },
    tiersData: {
      type: Object,
      default: {
        idPersonneMorale: null,
        type: null,
        denominationCommercial: null,
        raisonSociale: null,
        formeJuridique: null,
        chiffreAffaire: null,
        dateCreation: null,
        SIRET: null,
        beneficiairesEffectif: null,
        activite: null,
        IDCC: null,
        effectif: null,
        idMoyenContact: null,
        telephone: null,
        indicatif:null,
        email: null,
        adresse: null,
        libelle: null,
        complementAdresse: null,
        codePostal: null,
        villeId: null,
        ville: null,
        lieuDitOuBp: null,
        siteWeb: null,
        IDCodeNAF: null,
        CodeNAF: null,
        statut_gda: null,
        origine: null,
        secteur_activite:null

      },
      required: true
    },
    codeNafData: {
      type: Array,
      default: [],
      required: true
    },
    prospectOptions: {
      type: Array,
      default: [],
      required: true
    },
    isBoiteSync: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      REGIMESOCIAL:Besoin['REGIMESOCIAL']
    }
  },
  methods: {
    UpdateClientProReturned(data, message){
      this.$emit('update-client-pro-returned', data, message)
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    },
    calculateAge(dateOfBirth) {
      if (dateOfBirth) {
        // Parse the date string to a Date object
        const birthDate = new Date(dateOfBirth);
        
        // Get the current date
        const currentDate = new Date();
        
        // Calculate the difference in years
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        
        // Check if the birthday has already occurred this year
        if (
          currentDate.getMonth() < birthDate.getMonth() ||
          (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
        ) {
          return age - 1;
        } else {
          return age;
        }
      }
      
      return null;
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("-");
        date_formatting =
          date_formatting[2] +
          "/" +
          date_formatting[1] +
          "/" +
          date_formatting[0];
        return date_formatting;
      }
      return null;
    },
    labelRegimeSocial(key) {
        let regimeSocial = this.REGIMESOCIAL.find((regime) => {
          return regime.value === key;
        });

        if(regimeSocial !== undefined)
          return regimeSocial['name']
        
        return key
    },
    labelActivitePro(val){
      let ActivitePro = Activite['ACTIVITE'].find((activite)=>{
          return activite.value ===val
      })
        if(ActivitePro){
          return ActivitePro.name
        }
    },
    labelStatutJuridique(val){
      let statutJuridique = StatutJuridique['STATUT'].find((statut)=>{
          return statut.value ===val
      })
        if(statutJuridique){
          return statutJuridique.name
        }
    },
  },
   setup() {
     const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    } = useCalendar()

    return {
      event,
      clearEventData,
      addEvent,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive
    }
  }
}
</script>
<style >
.custom-card-body >.card-body{
  padding-top: 0px !important;
}
</style>
