var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sideBarDocument",attrs:{"id":"sidebar-document","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v("Nouveau document")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"documentComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Famille de document","label-for":"h-famille"}},[_c('validation-provider',{attrs:{"name":"famille de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-famille-autre-document","options":_vm.familleDocuments,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.changeListoptionType()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.famille),callback:function ($$v) {_vm.famille=$$v},expression:"famille"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.hidetype)?_c('b-form-group',{attrs:{"label":"Type de document","label-for":"h-type"}},[_c('validation-provider',{attrs:{"name":"type de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-type-autre-document","options":_vm.optionsType,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.idType),callback:function ($$v) {_vm.$set(_vm.composeData, "idType", $$v)},expression:"composeData.idType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.idFamille==4)?_c('validation-provider',{attrs:{"name":"sélectionner un devis/contrat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.composeData.idFamille==4)?_c('b-form-group',{attrs:{"label":"devis/contrat","state":errors.length > 0 ? false : null,"label-for":"h-selectionner-projet-contrat"}},[_c('v-select',{attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.projetContratData,"input-id":"projetcontrat","label":"Numero","placeholder":"Sélectionner un devis/contrat"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var idContrat = ref.idContrat;
var idEtude = ref.idEtude;
var idDevis = ref.idDevis;
var Numero = ref.Numero;
var type = ref.type;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(Numero))]),_c('b-badge',{staticClass:"mr-2 float-right",attrs:{"variant":_vm.sourceVariant(type)}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(type) =='Projet'?'Devis': _vm.capitalizeFirstLetter(type))+" ")])]}},{key:"selected-option",fn:function(ref){
var Numero = ref.Numero;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(Numero))])]}}],null,true),model:{value:(_vm.composeData.projetContrat),callback:function ($$v) {_vm.$set(_vm.composeData, "projetContrat", $$v)},expression:"composeData.projetContrat"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune devis/contrat disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)}):_vm._e(),(_vm.composeData.idFamille == '9')?_c('b-form-group',{attrs:{"label":"N° Réclamations","label-for":"r-recla"}},[_c('validation-provider',{attrs:{"name":"N° Sinistre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"r-recla","options":_vm.optionsReclamation,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.reclamation),callback:function ($$v) {_vm.$set(_vm.composeData, "reclamation", $$v)},expression:"composeData.reclamation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.idFamille  == '10')?_c('b-form-group',{attrs:{"label":"N° Sinistre","label-for":"r-Sinistre"}},[_c('validation-provider',{attrs:{"name":"N° Sinistre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"r-Sinistre","options":_vm.optionsSinistre,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.sinistre),callback:function ($$v) {_vm.$set(_vm.composeData, "sinistre", $$v)},expression:"composeData.sinistre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isAutre)?_c('b-form-group',{attrs:{"label":"Nom du document*","label-for":"h-nom-document"}},[_c('validation-provider',{attrs:{"name":"nom du document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nom-document-autre-document","placeholder":"Nom du document","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.nom),callback:function ($$v) {_vm.$set(_vm.composeData, "nom", $$v)},expression:"composeData.nom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Parcourir le fichier'),expression:"'Parcourir le fichier'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openToSelectFile}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"fileautredocumentProfes"},model:{value:(_vm.composeData.file),callback:function ($$v) {_vm.$set(_vm.composeData, "file", $$v)},expression:"composeData.file"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choisissez un document","value":_vm.composeData.file ? _vm.composeData.file.name : '',"state":errors.length > 0 ? false : null}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordDocument}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }